// sonarjs-ignore-file
import { COMPANY_TYPE, HIERARCHY_TYPE } from "src/app/constants/constants";

const losAngleles = "Los Angeles";
const jan2024 = "(JAN 2024)";
const combinedFinResPer = "Combined Finance Reserve Per Contract Sold";
const ServiceConAnalysis = "Service Contract Analysis";
const numberOfSerSold = "Number of Service Contracts Sold";
const numberOfGap = "Number of GAP Insurance Contracts Sold";
const gapInsuranceConPen = "GAP Insurance Contract Penetration";

export const MockCustomReportListResponseDEV = [
  {
    name: 'Lexus PO snapshot executive summary',
    id: 1
  },
  {
    name: 'Lexus Dealer At a Glance',
    id: 2
  },
  {
    name: 'TCUV Dealer At a Glance',
    id: 3
  },
  {
    name: 'Toyota Dealer At A Glance',
    id: 4
  },
  {
    name: 'Lexus Sales At A Glance Report',
    id: 5
  },
  {
    name: 'GST Toyota Dealer At A Glance',
    id: 6
  },
  {
    name: 'TTFO - Dealer at a Glance Report',
    id: 7
  },
  {
    name: 'Dealership Performance Summary',
    id: 8
  },
  {
    name: 'Parts Department',
    id: 9
  },
  {
    name: 'Service Dept Expense And Sales Analysis',
    id: 10
  }
]; //NOSONAR

export interface PeriodicElement {
  position: string;
  lyear20: string;
  lyear21: string;
  lyear22: string;
  lyear23: string,
  lchange23: string;
  year20: string;
  year21: string;
  year22: string;
  year23: string,
  change23: string;
};//NOSONAR

export const ELEMENT_DATA: PeriodicElement[] = [
  { position: 'WESTERN', year20: '16.009', year21: '16.009', year22: '16.009', year23: '16.009', change23: '16.009', lyear20: '16.009', lyear21: '16.009', lyear22: '16.009', lyear23: '16.009', lchange23: '16.009' },
  { position: 'CENTRAL', year20: '16.009', year21: '16.009', year22: '16.009', year23: '16.009', change23: '16.009', lyear20: '16.009', lyear21: '16.009', lyear22: '16.009', lyear23: '16.009', lchange23: '16.009' },
  { position: 'EASTERN', year20: '16.009', year21: '16.009', year22: '16.009', year23: '16.009', change23: '16.009', lyear20: '16.009', lyear21: '16.009', lyear22: '16.009', lyear23: '16.009', lchange23: '16.009' },
  { position: 'SOUTHERN', year20: '16.009', year21: '16.009', year22: '16.009', year23: '16.009', change23: '16.009', lyear20: '16.009', lyear21: '16.009', lyear22: '16.009', lyear23: '16.009', lchange23: '16.009' },
  { position: 'NATIONAL', year20: '16.009', year21: '16.009', year22: '16.009', year23: '16.009', change23: '16.009', lyear20: '16.009', lyear21: '16.009', lyear22: '16.009', lyear23: '16.009', lchange23: '16.009' }
]; //NOSONAR

export const SingleProfileTable = [{
  "Dealership": "TOYOTA OF LONG BEACH",
  "Region": losAngleles,
  "Address": "4401 E. PACIFIC COAST HWY",
  "District": "LA District 1: Los Angeles/No. Orange County",
  "City": "LONG BEACH Market",
  "DistrictManager": "Becky Levy",
}]; //NOSONAR

export const MockTopTenDealersResponsDEV = {

  "CERTIFIED-PRE-OWNED": [
    {
      "RANK": 1,
      "AREA": "southern",
      "DEALER": "JOHNSON LEXUS OF DURHAM",
      "YTD": 115,
    },
    {
      "RANK": 2,
      "AREA": "Eastern",
      "DEALER": "JOHNSON LEXUS OF RALEIGH ",
      "YTD": 97,
    }
  ],
  "TOTAL-LEXUS PRE-OWNED": [
    {
      "RANK": 1,
      "AREA": "SEWELL LEXUS ",
      "DEALER": "One T D1",
      "YTD": 11,
    },
    {
      "RANK": 2,
      "AREA": "Two",
      "DEALER": "One T D2",
      "YTD": 12,
    }
  ]
}; //NOSONAR

export const LexusDealerAtGlancePartsMockData = {
  body: [
    {
      "reportData": [
        {
          "(May 2019)": "Tires Penetration",
          "OBJ": "11%",
          "NAT'L": "33.35",
          "MAY 2019": "33.35",
          "APR 2019": "33.26",
          "MAR 2019": "32.80",
          "FEB 2019": "31.50",
          "JAN 2019": "31.691",
          "DEC 2018": "31.691"
        },
        {
          "(May 2019)": "Tires Penetration Missed Opportunity in $",
          "OBJ": "11%",
          "NAT'L": "33.35",
          "MAY 2019": "33.35",
          "APR 2019": "33.26",
          "MAR 2019": "32.80",
          "FEB 2019": "31.50",
          "JAN 2019": "31.691",
          "DEC 2018": "31.691"
        },
        {
          "(May 2019)": "Oil Sales Penetration",
          "OBJ": "11%",
          "NAT'L": "33.35",
          "MAY 2019": "33.35",
          "APR 2019": "33.26",
          "MAR 2019": "32.80",
          "FEB 2019": "31.50",
          "JAN 2019": "31.691",
          "DEC 2018": "31.691"
        },
        {
          "(May 2019)": "Oil Sales Penetration Missed Opportunity in $",
          "OBJ": "11%",
          "NAT'L": "33.35",
          "MAY 2019": "33.35",
          "APR 2019": "33.26",
          "MAR 2019": "32.80",
          "FEB 2019": "31.50",
          "JAN 2019": "31.691",
          "DEC 2018": "31.691"
        },
        {
          "(May 2019)": "Lexus Wipers Penetration",
          "OBJ": "11%",
          "NAT'L": "33.35",
          "MAY 2019": "33.35",
          "APR 2019": "33.26",
          "MAR 2019": "32.80",
          "FEB 2019": "31.50",
          "JAN 2019": "31.691",
          "DEC 2018": "31.691"
        },
        {
          "(May 2019)": "Lexus Wipers Penetration Missed Opportunity in $",
          "OBJ": "11%",
          "NAT'L": "33.35",
          "MAY 2019": "33.35",
          "APR 2019": "33.26",
          "MAR 2019": "32.80",
          "FEB 2019": "31.50",
          "JAN 2019": "31.691",
          "DEC 2018": "31.691"
        },
        {
          "(May 2019)": "Chemicals Penetration",
          "OBJ": "11%",
          "NAT'L": "33.35",
          "MAY 2019": "33.35",
          "APR 2019": "33.26",
          "MAR 2019": "32.80",
          "FEB 2019": "31.50",
          "JAN 2019": "31.691",
          "DEC 2018": "31.691"
        },
        {
          "(May 2019)": "Chemicals Penetration Missed Opportunity in $",
          "OBJ": "11%",
          "NAT'L": "33.35",
          "MAY 2019": "33.35",
          "APR 2019": "33.26",
          "MAR 2019": "32.80",
          "FEB 2019": "31.50",
          "JAN 2019": "31.691",
          "DEC 2018": "31.691"
        }]
    }
  ],
  status: 200
}; //NOSONAR


export const LexusSalesAtGlanceMockData = {
  body: [
    {
      "reportData": [
        {
          "MEASURE": "L/Cert Retail Sales",
          "OBJ": 95,
          "MONTH1": "136.3",
          "MONTH2": "158.3",
          "MONTH3": "122.7",
          "MONTH4": "109.0",
          "MONTH5": "78.5",
          "MONTH6": "118.5",
          "DLR": "136.4",
          "DIST": "95.4",
          "DLR_AREA": "100.0",
          "COMPANY": "99.1",
          "X_DISPLAY_SEQ": 1
        },
        {
          "MEASURE": "New Retail Sales",
          "OBJ": 95,
          "MONTH1": null,
          "MONTH2": null,
          "MONTH3": null,
          "MONTH4": null,
          "MONTH5": null,
          "MONTH6": null,
          "DLR": null,
          "DIST": null,
          "DLR_AREA": null,
          "COMPANY": null,
          "X_DISPLAY_SEQ": 2
        }
      ],
      "reportHeaderData": [
        {
          "HEADER": jan2024,
          "ROW": "1",
          "MONTH_NM": "JAN 24"
        },
        {
          "HEADER": jan2024,
          "ROW": "2",
          "MONTH_NM": "DEC 23"
        },
        {
          "HEADER": jan2024,
          "ROW": "3",
          "MONTH_NM": "NOV 23"
        },
        {
          "HEADER": jan2024,
          "ROW": "4",
          "MONTH_NM": "OCT 23"
        },
        {
          "HEADER": jan2024,
          "ROW": "5",
          "MONTH_NM": "SEP 23"
        },
        {
          "HEADER": jan2024,
          "ROW": "6",
          "MONTH_NM": "AUG 23"
        }
      ]
    }
  ]
};//NOSONAR




export const tfsnationalDprMockData = {
  status: 200,
  body: {
    "combined": [
      {
        "reportData": [
          {
            "SECTIONNO": "1",
            "SECTIONTITLE": "Overall Gross & Contract Penetration",
            "sectionData": [
              {
                "SECTIONID": 1,
                "SECTIONTITLE": "Overall Gross & Contract Penetration",
                "ACCT_NO": "B9003",
                "ACCT_TITLE": "Combined FS Office Gross PRU",
                "01 - West": "1752.3200",
                "02 - East": "1506.2830",
                "03 - Central": "1642.3670",
                "NATLAVG": "1719.9380",
                "UNITS_FLAG": "81",
                "SECTIONNO": 1,
                "ACCTSEQNO": 4
              },
              {
                "SECTIONID": 1,
                "SECTIONTITLE": "Overall Gross & Contract Penetration",
                "ACCT_NO": "B9006",
                "ACCT_TITLE": combinedFinResPer,
                "01 - West": "1752.3200",
                "02 - East": "1506.2830",
                "03 - Central": "1642.3670",
                "NATLAVG": "828.6210",
                "UNITS_FLAG": "81",
                "SECTIONNO": 1,
                "ACCTSEQNO": 7
              },
              {
                "SECTIONID": 1,
                "SECTIONTITLE": "Overall Gross & Contract Penetration",
                "ACCT_NO": "B10024",
                "ACCT_TITLE": "Combined EWU Penetration (New Lease Only)",
                "01 - West": "1752.3200",
                "02 - East": "1506.2830",
                "03 - Central": "1642.3670",
                "NATLAVG": "17.9260",
                "UNITS_FLAG": "810",
                "SECTIONNO": 1,
                "ACCTSEQNO": 12
              }
            ]
          },
          {
            "SECTIONNO": "2",
            "SECTIONTITLE": "FSD Income/Chargeback Analysis",
            "sectionData": [
              {
                "SECTIONID": 2,
                "SECTIONTITLE": "FSD Income/Chargeback Analysis",
                "ACCT_NO": "B9013",
                "ACCT_TITLE": "Combined FS Department Penetration",
                "01 - West": "1752.3200",
                "02 - East": "1506.2830",
                "03 - Central": "1642.3670",
                "NATLAVG": "0.0000",
                "UNITS_FLAG": "810",
                "SECTIONNO": 2,
                "ACCTSEQNO": 16
              },
              {
                "SECTIONID": 2,
                "SECTIONTITLE": "FSD Income/Chargeback Analysis",
                "ACCT_NO": "B9015",
                "ACCT_TITLE": "Combined FS Department Chargebacks PRU",
                "01 - West": "1752.3200",
                "02 - East": "1506.2830",
                "03 - Central": "1642.3670",
                "NATLAVG": "1152.7330",
                "UNITS_FLAG": "81",
                "SECTIONNO": 2,
                "ACCTSEQNO": 18
              },
              {
                "SECTIONID": 2,
                "SECTIONTITLE": "FSD Income/Chargeback Analysis",
                "ACCT_NO": "B9014",
                "ACCT_TITLE": "Combined FS Department Chargebacks",
                "01 - West": "1752.3200",
                "02 - East": "1506.2830",
                "03 - Central": "1642.3670",
                "NATLAVG": "1339371.0240",
                "UNITS_FLAG": "81",
                "SECTIONNO": 2,
                "ACCTSEQNO": 17
              }
            ]
          }
        ],
        "reportHeaderData": [
          {
            "COMPANY_CODE": "15",
            "COMPANY_NAME": "Lexus",
            "COMPANY": "15 - Lexus",
            "REGION_CODE": "330",
            "REGION": "330 - Eastern",
            "REGION_NAME": "Eastern",
            "REGION_ALPHA_CODE": "EAS     "
          },
          {
            "COMPANY_CODE": "15",
            "COMPANY_NAME": "Lexus",
            "COMPANY": "15 - Lexus",
            "REGION_CODE": "390",
            "REGION": "390 - 390th",
            "REGION_NAME": "390th",
            "REGION_ALPHA_CODE": "390TH   "
          },
          {
            "COMPANY_CODE": "15",
            "COMPANY_NAME": "Lexus",
            "COMPANY": "15 - Lexus",
            "REGION_CODE": "310",
            "REGION": "310 - Western",
            "REGION_NAME": "Western",
            "REGION_ALPHA_CODE": "WES     "
          },
          {
            "COMPANY_CODE": "15",
            "COMPANY_NAME": "Lexus",
            "COMPANY": "15 - Lexus",
            "REGION_CODE": "320",
            "REGION": "320 - Central",
            "REGION_NAME": "Central",
            "REGION_ALPHA_CODE": "CEN     "
          },
          {
            "COMPANY_CODE": "15",
            "COMPANY_NAME": "Lexus",
            "COMPANY": "15 - Lexus",
            "REGION_CODE": "340",
            "REGION": "340 - Southern",
            "REGION_NAME": "Southern",
            "REGION_ALPHA_CODE": "SOU     "
          }
        ]
      }
    ],
    "new": [
      {
        "reportData": [
          {
            "SECTIONNO": "2",
            "SECTIONTITLE": "Finance Analysis",
            "sectionData": [
              {
                "SECTIONID": 2,
                "SECTIONTITLE": "Finance Analysis",
                "ACCT_NO": "B9023",
                "ACCT_TITLE": "Number Of New Veh Finance & Lease Contracts Sold",
                "01 - West": "1752.3200",
                "02 - East": "1506.2830",
                "03 - Central": "1642.3670",
                "NATLAVG": "393.8520",
                "UNITS_FLAG": "84",
                "SECTIONNO": 2,
                "ACCTSEQNO": 4
              },
              {
                "SECTIONID": 2,
                "SECTIONTITLE": "Finance Analysis",
                "ACCT_NO": "B9024",
                "ACCT_TITLE": "Number of Lease Contracts Sold",
                "01 - West": "1752.3200",
                "02 - East": "1506.2830",
                "03 - Central": "1642.3670",
                "NATLAVG": "153.3440",
                "UNITS_FLAG": "84",
                "SECTIONNO": 2,
                "ACCTSEQNO": 5
              }
            ]
          },
          {
            "SECTIONNO": "3",
            "SECTIONTITLE": ServiceConAnalysis,
            "sectionData": [
              {
                "SECTIONID": 3,
                "SECTIONTITLE": ServiceConAnalysis,
                "ACCT_NO": "B9036",
                "ACCT_TITLE": numberOfSerSold,
                "01 - West": "1752.3200",
                "02 - East": "1506.2830",
                "03 - Central": "1642.3670",
                "NATLAVG": "146.2830",
                "UNITS_FLAG": "84",
                "SECTIONNO": 3,
                "ACCTSEQNO": 17
              }
            ]
          },
          {
            "SECTIONNO": "5",
            "SECTIONTITLE": "Gap Insurance Analysis",
            "sectionData": [
              {
                "SECTIONID": 5,
                "SECTIONTITLE": "Gap Insurance Analysis",
                "ACCT_NO": "B9052",
                "ACCT_TITLE": numberOfGap,
                "01 - West": "1752.3200",
                "02 - East": "1506.2830",
                "03 - Central": "1642.3670",
                "NATLAVG": "45.6390",
                "UNITS_FLAG": "84",
                "SECTIONNO": 5,
                "ACCTSEQNO": 33
              },
              {
                "SECTIONID": 5,
                "SECTIONTITLE": "Gap Insurance Analysis",
                "ACCT_NO": "B9054",
                "ACCT_TITLE": gapInsuranceConPen,
                "01 - West": "1752.3200",
                "02 - East": "1506.2830",
                "03 - Central": "1642.3670",
                "NATLAVG": "18.9760",
                "UNITS_FLAG": "810",
                "SECTIONNO": 5,
                "ACCTSEQNO": 35
              }
            ]
          },
          {
            "SECTIONNO": "6",
            "SECTIONTITLE": "Excess Wear & Use Analysis",
            "sectionData": [
              {
                "SECTIONID": 6,
                "SECTIONTITLE": "Excess Wear & Use Analysis",
                "ACCT_NO": "B10025",
                "ACCT_TITLE": "Excess Wear & Use Gross Per Contract Sold",
                "01 - West": "1752.3200",
                "02 - East": "1506.2830",
                "03 - Central": "1642.3670",
                "NATLAVG": "532.8740",
                "UNITS_FLAG": "81",
                "SECTIONNO": 6,
                "ACCTSEQNO": 42
              },

              {
                "SECTIONID": 6,
                "SECTIONTITLE": "Excess Wear & Use Analysis",
                "ACCT_NO": "B10031",
                "ACCT_TITLE": "Number of Excess Wear & Use Contracts Sold",
                "01 - West": "1752.3200",
                "02 - East": "1506.2830",
                "03 - Central": "1642.3670",
                "NATLAVG": "34.1560",
                "UNITS_FLAG": "84",
                "SECTIONNO": 6,
                "ACCTSEQNO": 41
              },

            ]
          },
          {
            "SECTIONNO": "7",
            "SECTIONTITLE": "Prepaid Maintenance Analysis",
            "sectionData": [
              {
                "SECTIONID": 7,
                "SECTIONTITLE": "Prepaid Maintenance Analysis",
                "ACCT_NO": "B9060",
                "ACCT_TITLE": "Number of Prepaid Maintenance Contracts Sold",
                "01 - West": "1752.3200",
                "02 - East": "1506.2830",
                "03 - Central": "1642.3670",
                "NATLAVG": "158.0980",
                "UNITS_FLAG": "84",
                "SECTIONNO": 7,
                "ACCTSEQNO": 49
              }
            ]
          },
          {
            "SECTIONNO": "9",
            "SECTIONTITLE": "Key Replacement",
            "sectionData": [
              {
                "SECTIONID": 10,
                "SECTIONTITLE": "Key Replacement",
                "ACCT_NO": "B10121",
                "ACCT_TITLE": "Number of Key Replacement Protection Contracts Sold",
                "01 - West": "1752.3200",
                "02 - East": "1506.2830",
                "03 - Central": "1642.3670",
                "NATLAVG": "13.8160",
                "UNITS_FLAG": "84",
                "SECTIONNO": 9,
                "ACCTSEQNO": 76
              }
            ]
          },
          {
            "SECTIONNO": "10",
            "SECTIONTITLE": "Aftermarket Analysis",
            "sectionData": [
              {
                "SECTIONID": 1,
                "SECTIONTITLE": "Aftermarket Analysis",
                "ACCT_NO": "B9020",
                "ACCT_TITLE": "Total New Veh  Aftermarket Gross Profit",
                "01 - West": "1752.3200",
                "02 - East": "1506.2830",
                "03 - Central": "1642.3670",
                "NATLAVG": "255499.8610",
                "UNITS_FLAG": "81",
                "SECTIONNO": 10,
                "ACCTSEQNO": 1
              }
            ]
          },
          {
            "SECTIONNO": "11",
            "SECTIONTITLE": "FSD Income/Charge Back Analysis",
            "sectionData": [
              {
                "SECTIONID": 8,
                "SECTIONTITLE": "FSD Income/Charge Back Analysis",
                "ACCT_NO": "B9070",
                "ACCT_TITLE": "FS Department Contracts Sold",
                "01 - West": "1752.3200",
                "02 - East": "1506.2830",
                "03 - Central": "1642.3670",
                "NATLAVG": "1148.6560",
                "UNITS_FLAG": "84",
                "SECTIONNO": 11,
                "ACCTSEQNO": 59
              },
            ]
          }
        ],
        "reportHeaderData": [
          {
            "COMPANY_CODE": "15",
            "COMPANY_NAME": "Lexus",
            "COMPANY": "15 - Lexus",
            "REGION_CODE": "330",
            "REGION": "330 - Eastern",
            "REGION_NAME": "Eastern",
            "REGION_ALPHA_CODE": "EAS     "
          },
          {
            "COMPANY_CODE": "15",
            "COMPANY_NAME": "Lexus",
            "COMPANY": "15 - Lexus",
            "REGION_CODE": "390",
            "REGION": "390 - 390th",
            "REGION_NAME": "390th",
            "REGION_ALPHA_CODE": "390TH   "
          },
          {
            "COMPANY_CODE": "15",
            "COMPANY_NAME": "Lexus",
            "COMPANY": "15 - Lexus",
            "REGION_CODE": "310",
            "REGION": "310 - Western",
            "REGION_NAME": "Western",
            "REGION_ALPHA_CODE": "WES     "
          },
          {
            "COMPANY_CODE": "15",
            "COMPANY_NAME": "Lexus",
            "COMPANY": "15 - Lexus",
            "REGION_CODE": "320",
            "REGION": "320 - Central",
            "REGION_NAME": "Central",
            "REGION_ALPHA_CODE": "CEN     "
          },
          {
            "COMPANY_CODE": "15",
            "COMPANY_NAME": "Lexus",
            "COMPANY": "15 - Lexus",
            "REGION_CODE": "340",
            "REGION": "340 - Southern",
            "REGION_NAME": "Southern",
            "REGION_ALPHA_CODE": "SOU     "
          }
        ]
      }
    ],
    "used": [
      {
        "reportData": [],
        "reportHeaderData": [
          {
            "COMPANY_CODE": "15",
            "COMPANY_NAME": "Lexus",
            "COMPANY": "15 - Lexus",
            "REGION_CODE": "310",
            "REGION": "310 - Western",
            "REGION_NAME": "Western",
            "REGION_ALPHA_CODE": "WES     "
          },
          {
            "COMPANY_CODE": "15",
            "COMPANY_NAME": "Lexus",
            "COMPANY": "15 - Lexus",
            "REGION_CODE": "320",
            "REGION": "320 - Central",
            "REGION_NAME": "Central",
            "REGION_ALPHA_CODE": "CEN     "
          },
          {
            "COMPANY_CODE": "15",
            "COMPANY_NAME": "Lexus",
            "COMPANY": "15 - Lexus",
            "REGION_CODE": "340",
            "REGION": "340 - Southern",
            "REGION_NAME": "Southern",
            "REGION_ALPHA_CODE": "SOU     "
          },
          {
            "COMPANY_CODE": "15",
            "COMPANY_NAME": "Lexus",
            "COMPANY": "15 - Lexus",
            "REGION_CODE": "330",
            "REGION": "330 - Eastern",
            "REGION_NAME": "Eastern",
            "REGION_ALPHA_CODE": "EAS     "
          },
          {
            "COMPANY_CODE": "15",
            "COMPANY_NAME": "Lexus",
            "COMPANY": "15 - Lexus",
            "REGION_CODE": "390",
            "REGION": "390 - 390th",
            "REGION_NAME": "390th",
            "REGION_ALPHA_CODE": "390TH   "
          }
        ]
      }
    ]
  }
}; //NOSONAR

export const PartsDepartmentAnalysisMockData = {
  body: [
    {
      "reportHeaderData": [
        {
          "COMPANY_CODE": "14",
          "COMPANY_NAME": "Toyota",
          "COMPANY": "14 - Toyota",
          "REGION_CODE": "120",
          "REGION": "120 - San Francisco",
          "REGION_NAME": "San Francisco",
          "DISTRICT_CODE": "120D",
          "DISTRICT_NAME": "District 4 - SF Metro/North Bay",
          "DEALER_CODE": "4143",
          "DEALER_NAME": "City Toyota",
          "DEALER": "04143 - City Toyota",
          "NATIONAL": "NATIONAL"
        }
      ],
      "reportData": [
        {
          "SECTIONID": 1,
          "SECTIONTITLE": "Sales",
          "sectionData": [
            {

              "IS_USER_INPUT": false,
              "SECTIONID": 1,
              "SECTIONTITLE": "Sales",
              "SECTIONLINENO": 1,
              "ACCT_NO": "B9659",
              "ACCT_NO_GP": null,
              "acct_name": "P&A Cust Mech Sls",
              "DLR_CY": "6205260.0000",
              "DLR_GP_CY": null,
              "DLR_PY": "4342484.0000",
              "DLR_GP_PY": null,
              "DEALER_DIFF": "42.89",
              "DIST_CY": "3218154",
              "DIST_GP_CY": null,
              "DIST_PY": "2786534",
              "DIST_GP_PY": null,
              "DISTRICT_DIFF": "15.48",
              "RGN_CY": "2901781",
              "RGN_GP_CY": null,
              "RGN_PY": "2591865",
              "RGN_GP_PY": null,
              "REGION_DIFF": "11.95",
              "NTNL_CY": "2192125",
              "NTNL_GP_CY": null,
              "NTNL_PY": "2007870",
              "NTNL_GP_PY": null,
              "NATIONAL_DIFF": "9.17"
            },
            {

              "IS_USER_INPUT": false,
              "SECTIONID": 1,
              "SECTIONTITLE": "Sales",
              "SECTIONLINENO": 2,
              "ACCT_NO": "4710S",
              "ACCT_NO_GP": null,
              "acct_name": "P&A Body Shop Sls",
              "DLR_CY": "0.0000",
              "DLR_GP_CY": null,
              "DLR_PY": "0.0000",
              "DLR_GP_PY": null,
              "DEALER_DIFF": "0.00",
              "DIST_CY": "152028",
              "DIST_GP_CY": null,
              "DIST_PY": "135914",
              "DIST_GP_PY": null,
              "DISTRICT_DIFF": "11.85",
              "RGN_CY": "131857",
              "RGN_GP_CY": null,
              "RGN_PY": "114463",
              "RGN_GP_PY": null,
              "REGION_DIFF": "15.19",
              "NTNL_CY": "364408",
              "NTNL_GP_CY": null,
              "NTNL_PY": "336516",
              "NTNL_GP_PY": null,
              "NATIONAL_DIFF": "8.28"
            }
          ]
        },
        {
          "SECTIONID": "2",
          "SECTIONTITLE": "Gross Profit $/%",
          "sectionData": [
            {
              "IS_USER_INPUT": false,
              "SECTIONID": 2,
              "SECTIONTITLE": "Gross Profit $/%",
              "SECTIONLINENO": 1,
              "ACCT_NO": "B9660",
              "ACCT_NO_GP": "B8046",
              "acct_name": "Total  P & A Mechanical/TC/TC+ GP",
              "DLR_CY": "1877303.0000",
              "DLR_GP_CY": "38.0",
              "DLR_PY": "1316164.0000",
              "DLR_GP_PY": "38.1",
              "DEALER_DIFF": "42.63",
              "DIST_CY": "1108915",
              "DIST_GP_CY": "42.2",
              "DIST_PY": "959660",
              "DIST_GP_PY": "42.0",
              "DISTRICT_DIFF": "15.55",
              "RGN_CY": "987690",
              "RGN_GP_CY": "41.5",
              "RGN_PY": "871637",
              "RGN_GP_PY": "40.8",
              "REGION_DIFF": "13.31",
              "NTNL_CY": "679932",
              "NTNL_GP_CY": "0.4",
              "NTNL_PY": "622945",
              "NTNL_GP_PY": "0.4",
              "NATIONAL_DIFF": "9.14"
            },
            {
              "IS_USER_INPUT": false,
              "SECTIONID": 2,
              "SECTIONTITLE": "Gross Profit $/%",
              "SECTIONLINENO": 2,
              "ACCT_NO": "4710T",
              "ACCT_NO_GP": "B8049",
              "acct_name": "Body Shop P & A  GP",
              "DLR_CY": "0.0000",
              "DLR_GP_CY": "0.0",
              "DLR_PY": "0.0000",
              "DLR_GP_PY": "0.0",
              "DEALER_DIFF": "0.00",
              "DIST_CY": "54653",
              "DIST_GP_CY": "35.9",
              "DIST_PY": "49471",
              "DIST_GP_PY": "36.4",
              "DISTRICT_DIFF": "10.47",
              "RGN_CY": "44929",
              "RGN_GP_CY": "34.1",
              "RGN_PY": "39523",
              "RGN_GP_PY": "34.5",
              "REGION_DIFF": "13.67",
              "NTNL_CY": "111221",
              "NTNL_GP_CY": "0.3",
              "NTNL_PY": "103238",
              "NTNL_GP_PY": "0.3",
              "NATIONAL_DIFF": "7.73"
            },
            {
              "sectionNo": 3,
              "sectionTitle": "Inventory",
              "sectionData": [
                {
                  "Line": "1",
                  "Gross_profit": "P & A Inventory",
                  "YTD": "111",
                  "GP_Sales": "2.2%",
                  "Prior_YTD": "342102",
                  "GP_Sales1": "42.4%",
                  "+/-%": "-100",
                  "K_YTD": "111",
                  "K_GP_Sales": "0.0%",
                  "K_Prior_YTD": "720831",
                  "K_GP_Sales1": "38.7%",
                  "K_+/-%": "-100",
                  "KA_YTD": "111",
                  "KA_GP_Sale": "0.0%",
                  "KA_Prior_YTD": "720831",
                  "KA_GP_Sale1": "37.8%",
                  "KA_+/-%": "-100",
                  "N_YTD": "111",
                  "N_GP_Sale": "0.4%",
                  "N_Prior_YTD": "720831",
                  "N_GP_Sale1": "0.4%",
                  "N_+/-%": "-100",
                  // "IS_USER_INPUT": false
                },
                {
                  "Line": "2",
                  "Gross_profit": "Inv.- P and A - Other",
                  "YTD": "111",
                  "GP_Sales": "NULL",
                  "Prior_YTD": "342102",
                  "GP_Sales1": "42.4%",
                  "+/-%": "100",
                  "K_YTD": "111",
                  "K_GP_Sales": "0.0%",
                  "K_Prior_YTD": "720831",
                  "K_GP_Sales1": "38.7%",
                  "K_+/-%": "-100",
                  "KA_YTD": "111",
                  "KA_GP_Sale": "0.0%",
                  "KA_Prior_YTD": "720831",
                  "KA_GP_Sale1": "37.8%",
                  "KA_+/-%": "-100",
                  "N_YTD": "111",
                  "N_GP_Sale": "0.4%",
                  "N_Prior_YTD": "720831",
                  "N_GP_Sale1": "0.4%",
                  "N_+/-%": "-100",
                  // "IS_USER_INPUT": false
                }
              ]
            }
          ]

        }
      ]
    }
  ]
}; //NOSONAR

export const dealerPerformanceMockData = {
  body: [
    {
      "SECTIONNO": "1",
      "SECTIONTITLE": "FS Office Analysis",
      "sectionData": [
        {
          "SECTIONNO": 1,
          "ROW": "1",
          "SECTIONTITLE": "FS Office Analysis",
          "ACCT_TITLE": "FS Department Gross PNVRL",
          "NEW_LAST_YTD": "1724.5160",
          "NEW_CURR_YTD": "1683.6630",
          "NEW_CURR_MTD": "1706.8850",
          "USED_LAST_YTD": "1746.8090",
          "USED_CURR_YTD": "1595.1090",
          "USED_CURR_MTD": "1597.6100",
          "COMB_LAST_YTD": "1916.6410",
          "COMB_CURR_YTD": "1831.2870",
          "COMB_CURR_MTD": "1814.8970",
          "UNITS_FLAG": "81",
          "NEW_CLR_FLAG": 0,
          "USED_CLR_FLAG": 0,
          "COMB_CLR_FLAG": 0
        },
        {
          "SECTIONNO": 1,
          "ROW": "2",
          "SECTIONTITLE": "FS Office Analysis",
          "ACCT_TITLE": "Retail Units",
          "NEW_LAST_YTD": "387.0350",
          "NEW_CURR_YTD": "462.1850",
          "NEW_CURR_MTD": "126.7600",
          "USED_LAST_YTD": "335.3890",
          "USED_CURR_YTD": "356.5130",
          "USED_CURR_MTD": "87.8870",
          "COMB_LAST_YTD": "751.7390",
          "COMB_CURR_YTD": "854.8340",
          "COMB_CURR_MTD": "227.4260",
          "UNITS_FLAG": "84",
          "NEW_CLR_FLAG": 0,
          "USED_CLR_FLAG": 0,
          "COMB_CLR_FLAG": 0
        },
        {
          "SECTIONNO": 1,
          "ROW": "3",
          "SECTIONTITLE": "FS Office Analysis",
          "ACCT_TITLE": "Certified Units",
          "NEW_LAST_YTD": "0.0000",
          "NEW_CURR_YTD": "0.0000",
          "NEW_CURR_MTD": "0.0000",
          "USED_LAST_YTD": "98.4630",
          "USED_CURR_YTD": "81.8090",
          "USED_CURR_MTD": "20.4100",
          "COMB_LAST_YTD": "0.0000",
          "COMB_CURR_YTD": "0.0000",
          "COMB_CURR_MTD": "0.0000",
          "UNITS_FLAG": "84",
          "NEW_CLR_FLAG": 1,
          "USED_CLR_FLAG": 0,
          "COMB_CLR_FLAG": 1
        },
        {
          "SECTIONNO": 1,
          "ROW": "4",
          "SECTIONTITLE": "FS Office Analysis",
          "ACCT_TITLE": "FS Department Gross Profit",
          "NEW_LAST_YTD": "770330.7980",
          "NEW_CURR_YTD": "905509.4740",
          "NEW_CURR_MTD": "248994.5460",
          "USED_LAST_YTD": "588406.3690",
          "USED_CURR_YTD": "572583.1620",
          "USED_CURR_MTD": "141350.3060",
          "COMB_LAST_YTD": "1440814.0570",
          "COMB_CURR_YTD": "1565446.1180",
          "COMB_CURR_MTD": "412754.8560",
          "UNITS_FLAG": "81",
          "NEW_CLR_FLAG": 0,
          "USED_CLR_FLAG": 0,
          "COMB_CLR_FLAG": 0
        },
        {
          "SECTIONNO": 1,
          "ROW": "5",
          "SECTIONTITLE": "FS Office Analysis",
          "ACCT_TITLE": "FS Department Net After Chargebacks PRU",
          "NEW_LAST_YTD": "1441.3000",
          "NEW_CURR_YTD": "1434.4010",
          "NEW_CURR_MTD": "1460.1300",
          "USED_LAST_YTD": "1759.6870",
          "USED_CURR_YTD": "1611.2560",
          "USED_CURR_MTD": "1613.2400",
          "COMB_LAST_YTD": "1673.4680",
          "COMB_CURR_YTD": "1582.7830",
          "COMB_CURR_MTD": "1575.0200",
          "UNITS_FLAG": "81",
          "NEW_CLR_FLAG": 0,
          "USED_CLR_FLAG": 0,
          "COMB_CLR_FLAG": 0
        },
        {
          "SECTIONNO": 1,
          "ROW": "6",
          "SECTIONTITLE": "FS Office Analysis",
          "ACCT_TITLE": "FS Dept. Net After Chargebacks, Compensation & Repos PRU",
          "NEW_LAST_YTD": "1045.1630",
          "NEW_CURR_YTD": "1070.7570",
          "NEW_CURR_MTD": "1077.9370",
          "USED_LAST_YTD": "1324.5830",
          "USED_CURR_YTD": "1204.4370",
          "USED_CURR_MTD": "1195.9320",
          "COMB_LAST_YTD": "1172.0900",
          "COMB_CURR_YTD": "1127.5900",
          "COMB_CURR_MTD": "1124.4090",
          "UNITS_FLAG": "81",
          "NEW_CLR_FLAG": 0,
          "USED_CLR_FLAG": 0,
          "COMB_CLR_FLAG": 0
        },
        {
          "SECTIONNO": 1,
          "ROW": "7",
          "SECTIONTITLE": "FS Office Analysis",
          "ACCT_TITLE": "FS Department Contract Penetration",
          "NEW_LAST_YTD": "168.2560",
          "NEW_CURR_YTD": "172.1820",
          "NEW_CURR_MTD": "172.9540",
          "USED_LAST_YTD": "189.1360",
          "USED_CURR_YTD": "177.3320",
          "USED_CURR_MTD": "178.0060",
          "COMB_LAST_YTD": "178.1850",
          "COMB_CURR_YTD": "174.5960",
          "COMB_CURR_MTD": "175.1710",
          "UNITS_FLAG": "810",
          "NEW_CLR_FLAG": 0,
          "USED_CLR_FLAG": 0,
          "COMB_CLR_FLAG": 0
        },
        {
          "SECTIONNO": 1,
          "ROW": "8",
          "SECTIONTITLE": "FS Office Analysis",
          "ACCT_TITLE": "FS Department Chargebacks % FS Gross",
          "NEW_LAST_YTD": "-13.3800",
          "NEW_CURR_YTD": "-14.1190",
          "NEW_CURR_MTD": "-13.1550",
          "USED_LAST_YTD": "90.8510",
          "USED_CURR_YTD": "91.0350",
          "USED_CURR_MTD": "91.0100",
          "COMB_LAST_YTD": "-12.6870",
          "COMB_CURR_YTD": "-13.5700",
          "COMB_CURR_MTD": "-13.2170",
          "UNITS_FLAG": "810",
          "NEW_CLR_FLAG": 0,
          "USED_CLR_FLAG": 0,
          "COMB_CLR_FLAG": 0
        }
      ]
    },
    {
      "SECTIONNO": "2",
      "SECTIONTITLE": "Finance Reserve",
      "sectionData": [
        {
          "SECTIONNO": 2,
          "ROW": "9",
          "SECTIONTITLE": "Finance Reserve",
          "ACCT_TITLE": "Lease % (new controlled business only)",
          "NEW_LAST_YTD": "9.7820",
          "NEW_CURR_YTD": "13.4930",
          "NEW_CURR_MTD": "14.8700",
          "USED_LAST_YTD": "0.0000",
          "USED_CURR_YTD": "0.0000",
          "USED_CURR_MTD": "0.0000",
          "COMB_LAST_YTD": "0.0000",
          "COMB_CURR_YTD": "0.0000",
          "COMB_CURR_MTD": "0.0000",
          "UNITS_FLAG": "810",
          "NEW_CLR_FLAG": 0,
          "USED_CLR_FLAG": 1,
          "COMB_CLR_FLAG": 1
        },
        {
          "SECTIONNO": 2,
          "ROW": "10",
          "SECTIONTITLE": "Finance Reserve",
          "ACCT_TITLE": "Retail % (new controlled business only)",
          "NEW_LAST_YTD": "89.9590",
          "NEW_CURR_YTD": "86.1610",
          "NEW_CURR_MTD": "84.7580",
          "USED_LAST_YTD": "0.0000",
          "USED_CURR_YTD": "0.0000",
          "USED_CURR_MTD": "0.0000",
          "COMB_LAST_YTD": "0.0000",
          "COMB_CURR_YTD": "0.0000",
          "COMB_CURR_MTD": "0.0000",
          "UNITS_FLAG": "810",
          "NEW_CLR_FLAG": 0,
          "USED_CLR_FLAG": 1,
          "COMB_CLR_FLAG": 1
        },
        {
          "SECTIONNO": 2,
          "ROW": "11",
          "SECTIONTITLE": "Finance Reserve",
          "ACCT_TITLE": "Retail and Lease Penetration (combined only)",
          "NEW_LAST_YTD": "0.0000",
          "NEW_CURR_YTD": "0.0000",
          "NEW_CURR_MTD": "0.0000",
          "USED_LAST_YTD": "0.0000",
          "USED_CURR_YTD": "0.0000",
          "USED_CURR_MTD": "0.0000",
          "COMB_LAST_YTD": "55.7550",
          "COMB_CURR_YTD": "58.4550",
          "COMB_CURR_MTD": "58.4120",
          "UNITS_FLAG": "810",
          "NEW_CLR_FLAG": 1,
          "USED_CLR_FLAG": 1,
          "COMB_CLR_FLAG": 0
        },
        {
          "SECTIONNO": 2,
          "ROW": "12",
          "SECTIONTITLE": "Finance Reserve",
          "ACCT_TITLE": "Retail Finance & Lease Gross Per Contract Sold",
          "NEW_LAST_YTD": "24247.6750",
          "NEW_CURR_YTD": "36342.1940",
          "NEW_CURR_MTD": "9232.0330",
          "USED_LAST_YTD": "822.5120",
          "USED_CURR_YTD": "794.2430",
          "USED_CURR_MTD": "791.4400",
          "COMB_LAST_YTD": "745.7220",
          "COMB_CURR_YTD": "705.0160",
          "COMB_CURR_MTD": "710.1180",
          "UNITS_FLAG": "81",
          "NEW_CLR_FLAG": 0,
          "USED_CLR_FLAG": 0,
          "COMB_CLR_FLAG": 0
        },
        {
          "SECTIONNO": 2,
          "ROW": "13",
          "SECTIONTITLE": "Finance Reserve",
          "ACCT_TITLE": "Retail Finance & Lease Net Profit After Chargebacks Per Contract Sold",
          "NEW_LAST_YTD": "677.0390",
          "NEW_CURR_YTD": "638.6900",
          "NEW_CURR_MTD": "656.3590",
          "USED_LAST_YTD": "822.5120",
          "USED_CURR_YTD": "794.2430",
          "USED_CURR_MTD": "791.4400",
          "COMB_LAST_YTD": "0.0000",
          "COMB_CURR_YTD": "0.0000",
          "COMB_CURR_MTD": "0.0000",
          "UNITS_FLAG": "81",
          "NEW_CLR_FLAG": 0,
          "USED_CLR_FLAG": 0,
          "COMB_CLR_FLAG": 0
        }
      ]
    },
    {
      "SECTIONNO": "3",
      "SECTIONTITLE": "Service Contract",
      "sectionData": [
        {
          "SECTIONNO": 3,
          "ROW": "14",
          "SECTIONTITLE": "Service Contract",
          "ACCT_TITLE": "Service Contract Penetration",
          "NEW_LAST_YTD": "46.4760",
          "NEW_CURR_YTD": "45.5960",
          "NEW_CURR_MTD": "44.3720",
          "USED_LAST_YTD": "57.2780",
          "USED_CURR_YTD": "53.9960",
          "USED_CURR_MTD": "54.2870",
          "COMB_LAST_YTD": "50.4670",
          "COMB_CURR_YTD": "48.2330",
          "COMB_CURR_MTD": "47.3690",
          "UNITS_FLAG": "810",
          "NEW_CLR_FLAG": 0,
          "USED_CLR_FLAG": 0,
          "COMB_CLR_FLAG": 0
        },
        {
          "SECTIONNO": 3,
          "ROW": "15",
          "SECTIONTITLE": "Service Contract",
          "ACCT_TITLE": "Service Contract Gross Per Contract Sold",
          "NEW_LAST_YTD": "1627.5580",
          "NEW_CURR_YTD": "1614.9740",
          "NEW_CURR_MTD": "1617.3280",
          "USED_LAST_YTD": "1332.8740",
          "USED_CURR_YTD": "1291.1290",
          "USED_CURR_MTD": "1289.8650",
          "COMB_LAST_YTD": "1344.3410",
          "COMB_CURR_YTD": "1318.5780",
          "COMB_CURR_MTD": "1324.8250",
          "UNITS_FLAG": "81",
          "NEW_CLR_FLAG": 0,
          "USED_CLR_FLAG": 0,
          "COMB_CLR_FLAG": 0
        },
        {
          "SECTIONNO": 3,
          "ROW": "16",
          "SECTIONTITLE": "Service Contract",
          "ACCT_TITLE": "Service Contract Net After Chargebacks Per Contract Sold",
          "NEW_LAST_YTD": "1356.1030",
          "NEW_CURR_YTD": "1342.6160",
          "NEW_CURR_MTD": "1352.6160",
          "USED_LAST_YTD": "0.0000",
          "USED_CURR_YTD": "0.0000",
          "USED_CURR_MTD": "0.0000",
          "COMB_LAST_YTD": "0.0000",
          "COMB_CURR_YTD": "0.0000",
          "COMB_CURR_MTD": "0.0000",
          "UNITS_FLAG": "81",
          "NEW_CLR_FLAG": 0,
          "USED_CLR_FLAG": 0,
          "COMB_CLR_FLAG": 0
        },
        {
          "SECTIONNO": 3,
          "ROW": "17",
          "SECTIONTITLE": "Service Contract",
          "ACCT_TITLE": "Number of Contracts",
          "NEW_LAST_YTD": "187.2730",
          "NEW_CURR_YTD": "219.8060",
          "NEW_CURR_MTD": "60.0190",
          "USED_LAST_YTD": "192.1040",
          "USED_CURR_YTD": "192.5030",
          "USED_CURR_MTD": "47.7110",
          "COMB_LAST_YTD": "379.3770",
          "COMB_CURR_YTD": "412.3090",
          "COMB_CURR_MTD": "107.7300",
          "UNITS_FLAG": "84",
          "NEW_CLR_FLAG": 0,
          "USED_CLR_FLAG": 0,
          "COMB_CLR_FLAG": 0
        }
      ]
    },
    {
      "SECTIONNO": "4",
      "SECTIONTITLE": "Insurance",
      "sectionData": [
        {
          "SECTIONNO": 4,
          "ROW": "18",
          "SECTIONTITLE": "Insurance",
          "ACCT_TITLE": "Credit Insurance Contract Penetration of Finance Contracts",
          "NEW_LAST_YTD": "-1.4320",
          "NEW_CURR_YTD": "0.9620",
          "NEW_CURR_MTD": "0.9720",
          "USED_LAST_YTD": "1.2470",
          "USED_CURR_YTD": "0.4940",
          "USED_CURR_MTD": "0.4620",
          "COMB_LAST_YTD": "-0.1070",
          "COMB_CURR_YTD": "0.4900",
          "COMB_CURR_MTD": "0.4920",
          "UNITS_FLAG": "810",
          "NEW_CLR_FLAG": 0,
          "USED_CLR_FLAG": 0,
          "COMB_CLR_FLAG": 0
        },
        {
          "SECTIONNO": 4,
          "ROW": "19",
          "SECTIONTITLE": "Insurance",
          "ACCT_TITLE": "Credit Insurance Gross Per Contract Sold",
          "NEW_LAST_YTD": "-341.6720",
          "NEW_CURR_YTD": "230.3600",
          "NEW_CURR_MTD": "254.6970",
          "USED_LAST_YTD": "388.4160",
          "USED_CURR_YTD": "255.1880",
          "USED_CURR_MTD": "257.5430",
          "COMB_LAST_YTD": "-2907.8230",
          "COMB_CURR_YTD": "237.2180",
          "COMB_CURR_MTD": "255.3770",
          "UNITS_FLAG": "81",
          "NEW_CLR_FLAG": 0,
          "USED_CLR_FLAG": 0,
          "COMB_CLR_FLAG": 0
        },
        {
          "SECTIONNO": 4,
          "ROW": "20",
          "SECTIONTITLE": "Insurance",
          "ACCT_TITLE": "Credit Insurance Net After Chargebacks Per Contract Sold",
          "NEW_LAST_YTD": "0.0000",
          "NEW_CURR_YTD": "0.0000",
          "NEW_CURR_MTD": "0.0000",
          "USED_LAST_YTD": "0.0000",
          "USED_CURR_YTD": "0.0000",
          "USED_CURR_MTD": "0.0000",
          "COMB_LAST_YTD": "0.0000",
          "COMB_CURR_YTD": "0.0000",
          "COMB_CURR_MTD": "0.0000",
          "UNITS_FLAG": "81",
          "NEW_CLR_FLAG": 0,
          "USED_CLR_FLAG": 0,
          "COMB_CLR_FLAG": 0
        },
        {
          "SECTIONNO": 4,
          "ROW": "21",
          "SECTIONTITLE": "Insurance",
          "ACCT_TITLE": "Number of Credit Insurance Contracts Sold",
          "NEW_LAST_YTD": "-3.6480",
          "NEW_CURR_YTD": "3.0320",
          "NEW_CURR_MTD": "0.8510",
          "USED_LAST_YTD": "2.8400",
          "USED_CURR_YTD": "1.1570",
          "USED_CURR_MTD": "0.2670",
          "COMB_LAST_YTD": "-0.8080",
          "COMB_CURR_YTD": "4.1890",
          "COMB_CURR_MTD": "1.1180",
          "UNITS_FLAG": "84",
          "NEW_CLR_FLAG": 0,
          "USED_CLR_FLAG": 0,
          "COMB_CLR_FLAG": 0
        }
      ]
    },
  ]
}; //NOSONAR


export const mockforkeyInsightTdaag = [
  {
    "reportHeaderData": [
      {
        "COMPANY_CODE": "14",
        "COMPANY_NAME": "Toyota",
        "COMPANY": "14 - Toyota",
        "REGION_CODE": "120",
        "REGION": "120 - San Francisco",
        "REGION_NAME": "San Francisco",
        "DISTRICT_CODE": "120D",
        "DISTRICT_NAME": "District 4 - SF Metro/North Bay",
        "DEALER_CODE": "4143",
        "DEALER_NAME": "City Toyota",
        "DEALER": "04143 - City Toyota",
        "NATIONAL": "NATIONAL"
      }
    ],
    "reportData": [
      {
        "SECTIONID": 1,
        "SECTIONTITLE": "Sales",
        "sectionData": [
          {

            "IS_USER_INPUT": false,
            "SECTIONID": 1,
            "SECTIONTITLE": "Sales",
            "SECTIONLINENO": 1,
            "ACCT_NO": "B9659",
            "ACCT_NO_GP": null,
            "acct_name": "P&A Cust Mech Sls",
            "DLR_CY": "6205260.0000",
            "DLR_GP_CY": null,
            "DLR_PY": "4342484.0000",
            "DLR_GP_PY": null,
            "DEALER_DIFF": "42.89",
            "DIST_CY": "3218154",
            "DIST_GP_CY": null,
            "DIST_PY": "2786534",
            "DIST_GP_PY": null,
            "DISTRICT_DIFF": "15.48",
            "RGN_CY": "2901781",
            "RGN_GP_CY": null,
            "RGN_PY": "2591865",
            "RGN_GP_PY": null,
            "REGION_DIFF": "11.95",
            "NTNL_CY": "2192125",
            "NTNL_GP_CY": null,
            "NTNL_PY": "2007870",
            "NTNL_GP_PY": null,
            "NATIONAL_DIFF": "9.17"
          },
          {

            "IS_USER_INPUT": false,
            "SECTIONID": 1,
            "SECTIONTITLE": "Sales",
            "SECTIONLINENO": 2,
            "ACCT_NO": "4710S",
            "ACCT_NO_GP": null,
            "acct_name": "P&A Body Shop Sls",
            "DLR_CY": "0.0000",
            "DLR_GP_CY": null,
            "DLR_PY": "0.0000",
            "DLR_GP_PY": null,
            "DEALER_DIFF": "0.00",
            "DIST_CY": "152028",
            "DIST_GP_CY": null,
            "DIST_PY": "135914",
            "DIST_GP_PY": null,
            "DISTRICT_DIFF": "11.85",
            "RGN_CY": "131857",
            "RGN_GP_CY": null,
            "RGN_PY": "114463",
            "RGN_GP_PY": null,
            "REGION_DIFF": "15.19",
            "NTNL_CY": "364408",
            "NTNL_GP_CY": null,
            "NTNL_PY": "336516",
            "NTNL_GP_PY": null,
            "NATIONAL_DIFF": "8.28"
          }
        ]
      },
      {
        "SECTIONID": "2",
        "SECTIONTITLE": "Gross Profit $/%",
        "sectionData": [
          {
            "IS_USER_INPUT": false,
            "SECTIONID": 2,
            "SECTIONTITLE": "Gross Profit $/%",
            "SECTIONLINENO": 1,
            "ACCT_NO": "B9660",
            "ACCT_NO_GP": "B8046",
            "acct_name": "Total  P & A Mechanical/TC/TC+ GP",
            "DLR_CY": "1877303.0000",
            "DLR_GP_CY": "38.0",
            "DLR_PY": "1316164.0000",
            "DLR_GP_PY": "38.1",
            "DEALER_DIFF": "42.63",
            "DIST_CY": "1108915",
            "DIST_GP_CY": "42.2",
            "DIST_PY": "959660",
            "DIST_GP_PY": "42.0",
            "DISTRICT_DIFF": "15.55",
            "RGN_CY": "987690",
            "RGN_GP_CY": "41.5",
            "RGN_PY": "871637",
            "RGN_GP_PY": "40.8",
            "REGION_DIFF": "13.31",
            "NTNL_CY": "679932",
            "NTNL_GP_CY": "0.4",
            "NTNL_PY": "622945",
            "NTNL_GP_PY": "0.4",
            "NATIONAL_DIFF": "9.14"
          },
          {
            "IS_USER_INPUT": false,
            "SECTIONID": 2,
            "SECTIONTITLE": "Gross Profit $/%",
            "SECTIONLINENO": 2,
            "ACCT_NO": "4710T",
            "ACCT_NO_GP": "B8049",
            "acct_name": "Body Shop P & A  GP",
            "DLR_CY": "0.0000",
            "DLR_GP_CY": "0.0",
            "DLR_PY": "0.0000",
            "DLR_GP_PY": "0.0",
            "DEALER_DIFF": "0.00",
            "DIST_CY": "54653",
            "DIST_GP_CY": "35.9",
            "DIST_PY": "49471",
            "DIST_GP_PY": "36.4",
            "DISTRICT_DIFF": "10.47",
            "RGN_CY": "44929",
            "RGN_GP_CY": "34.1",
            "RGN_PY": "39523",
            "RGN_GP_PY": "34.5",
            "REGION_DIFF": "13.67",
            "NTNL_CY": "111221",
            "NTNL_GP_CY": "0.3",
            "NTNL_PY": "103238",
            "NTNL_GP_PY": "0.3",
            "NATIONAL_DIFF": "7.73"
          },
          {
            "sectionNo": 3,
            "sectionTitle": "Inventory",
            "sectionData": [
              {
                "Line": "1",
                "Gross_profit": "P & A Inventory",
                "YTD": "111",
                "GP_Sales": "2.2%",
                "Prior_YTD": "342102",
                "GP_Sales1": "42.4%",
                "+/-%": "-100",
                "K_YTD": "111",
                "K_GP_Sales": "0.0%",
                "K_Prior_YTD": "720831",
                "K_GP_Sales1": "38.7%",
                "K_+/-%": "-100",
                "KA_YTD": "111",
                "KA_GP_Sale": "0.0%",
                "KA_Prior_YTD": "720831",
                "KA_GP_Sale1": "37.8%",
                "KA_+/-%": "-100",
                "N_YTD": "111",
                "N_GP_Sale": "0.4%",
                "N_Prior_YTD": "720831",
                "N_GP_Sale1": "0.4%",
                "N_+/-%": "-100",
                // "IS_USER_INPUT": false
              },
              {
                "Line": "2",
                "Gross_profit": "Inv.- P and A - Other",
                "YTD": "111",
                "GP_Sales": "NULL",
                "Prior_YTD": "342102",
                "GP_Sales1": "42.4%",
                "+/-%": "100",
                "K_YTD": "111",
                "K_GP_Sales": "0.0%",
                "K_Prior_YTD": "720831",
                "K_GP_Sales1": "38.7%",
                "K_+/-%": "-100",
                "KA_YTD": "111",
                "KA_GP_Sale": "0.0%",
                "KA_Prior_YTD": "720831",
                "KA_GP_Sale1": "37.8%",
                "KA_+/-%": "-100",
                "N_YTD": "111",
                "N_GP_Sale": "0.4%",
                "N_Prior_YTD": "720831",
                "N_GP_Sale1": "0.4%",
                "N_+/-%": "-100",
                // "IS_USER_INPUT": false
              }
            ]
          }
        ]

      }
    ]
  }
]; //NOSONAR

export const TdaagSlmMockData = {
  body: [
    {
      "reportData": [
        {
          "SECTIONID": "1",
          "SECTIONTITLE": "TLE Efficiency(TLEe) 12 MONTH ROLLING",
          "sectionData": [
            {
              "SECTIONTITLE": "TLE Efficiency(TLEe) 12 MONTH ROLLING",
              "ROWTYPE": "District - Metro or Single Point",
              "COL1": "0.00",
              "COL2": "0.00",
              "COL3": "84.36",
              "COL4": "83.67",
              "COL5": "82.42",
              "X_SEQ": 3,
              "SECTIONID": 1
            },
            {
              "SECTIONTITLE": "TLE Efficiency(TLEe) 12 MONTH ROLLING",
              "ROWTYPE": "Region/PD - Metro or Single Point",
              "COL1": "0.00",
              "COL2": "0.00",
              "COL3": "83.40",
              "COL4": "83.06",
              "COL5": "82.24",
              "X_SEQ": 4,
              "SECTIONID": 1
            },
            {
              "SECTIONTITLE": "TLE Efficiency(TLEe) 12 MONTH ROLLING",
              "ROWTYPE": "Dealer - Metro or Single Point",
              "COL1": "0.00",
              "COL2": "0.00",
              "COL3": "81.14",
              "COL4": "81.62",
              "COL5": "79.71",
              "X_SEQ": 1,
              "SECTIONID": 1
            }
          ]
        },
        {
          "SECTIONID": "2",
          "SECTIONTITLE": "TLE 12 MONTH ROLLING",
          "sectionData": [
            {
              "SECTIONTITLE": "TLE 12 MONTH ROLLING",
              "ROWTYPE": "Dealer - Metro or Single Point",
              "COL1": "0.00",
              "COL2": "0.00",
              "COL3": "97.29",
              "COL4": "98.27",
              "COL5": "96.92",
              "X_SEQ": 1,
              "SECTIONID": 2
            },
            {
              "SECTIONTITLE": "TLE 12 MONTH ROLLING",
              "ROWTYPE": "TLEe Bottom 10% in Region/PD - All Dealers",
              "COL1": null,
              "COL2": null,
              "COL3": "N",
              "COL4": "N",
              "COL5": "N",
              "X_SEQ": 6,
              "SECTIONID": 2
            },
            {
              "SECTIONTITLE": "TLE 12 MONTH ROLLING",
              "ROWTYPE": "TLEe Rank in Region/PD - All Dealers",
              "COL1": null,
              "COL2": null,
              "COL3": "137/177",
              "COL4": "125/177",
              "COL5": "141/177",
              "X_SEQ": 5,
              "SECTIONID": 2
            }
          ]
        }
      ],
      "reportHeaderData": [
        [
          {
            "TITLE": "10017 - Butler Toyota",
            "PYEAR": 2022,
            "CYEAR": 2023
          }
        ],
        [
          {
            "LEVELID": 10,
            "LEVEL_NAME": "dealer"
          },
          {
            "LEVELID": 11,
            "LEVEL_NAME": "district"
          },
          {
            "LEVELID": 12,
            "LEVEL_NAME": "region"
          }
        ]
      ]
    }
  ]
}; //NOSONAR


export const ServiceDeptAnalysisMockData = {
  body: [
    {
      "reportHeaderData": [
        {
          "COMPANY_CODE": "14",
          "COMPANY_NAME": "Toyota",
          "COMPANY": "14 - Toyota",
          "REGION_CODE": "120",
          "REGION": "120 - San Francisco",
          "REGION_NAME": "San Francisco",
          "DISTRICT_CODE": "120D",
          "DISTRICT_NAME": "District 4 - SF Metro/North Bay",
          "DEALER_CODE": "4143",
          "DEALER_NAME": "City Toyota",
          "DEALER": "04143 - City Toyota",
          "NATIONAL": "NATIONAL",
          'TITLE': "TLE EFFICEINCY 12 MONTH ROLLING",
        }
      ],
      "reportData": [
        {
          "SECTIONID": 1,
          "SECTIONTITLE": "Sales",
          "sectionData": [
            {

              "IS_USER_INPUT": false,
              "SECTIONID": 1,
              "SECTIONTITLE": "Sales",
              "SECTIONLINENO": 1,
              "ACCT_NO": "B9659",
              "ACCT_NO_GP": null,
              "acct_name": "P&A Cust Mech Sls",
              "DLR_CY": "6205260.0000",
              "DLR_GP_CY": null,
              "DLR_PY": "4342484.0000",
              "DLR_GP_PY": null,
              "DEALER_DIFF": "42.89",
              "DIST_CY": "3218154",
              "DIST_GP_CY": null,
              "DIST_PY": "2786534",
              "DIST_GP_PY": null,
              "DISTRICT_DIFF": "15.48",
              "RGN_CY": "2901781",
              "RGN_GP_CY": null,
              "RGN_PY": "2591865",
              "RGN_GP_PY": null,
              "REGION_DIFF": "11.95",
              "NTNL_CY": "2192125",
              "NTNL_GP_CY": null,
              "NTNL_PY": "2007870",
              "NTNL_GP_PY": null,
              "NATIONAL_DIFF": "9.17",
              "GUIDES": "<20%"
            },
            {

              "IS_USER_INPUT": false,
              "SECTIONID": 1,
              "SECTIONTITLE": "Sales",
              "SECTIONLINENO": 2,
              "ACCT_NO": "4710S",
              "ACCT_NO_GP": null,
              "acct_name": "P&A Body Shop Sls",
              "DLR_CY": "0.0000",
              "DLR_GP_CY": null,
              "DLR_PY": "0.0000",
              "DLR_GP_PY": null,
              "DEALER_DIFF": "0.00",
              "DIST_CY": "152028",
              "DIST_GP_CY": null,
              "DIST_PY": "135914",
              "DIST_GP_PY": null,
              "DISTRICT_DIFF": "11.85",
              "RGN_CY": "131857",
              "RGN_GP_CY": null,
              "RGN_PY": "114463",
              "RGN_GP_PY": null,
              "REGION_DIFF": "15.19",
              "NTNL_CY": "364408",
              "NTNL_GP_CY": null,
              "NTNL_PY": "336516",
              "NTNL_GP_PY": null,
              "NATIONAL_DIFF": "8.28",
              "GUIDES": "<20%"
            }
          ]
        },
        {
          "SECTIONID": "2",
          "SECTIONTITLE": "Gross Profit $/%",
          "sectionData": [
            {
              "IS_USER_INPUT": false,
              "SECTIONID": 2,
              "SECTIONTITLE": "Gross Profit $/%",
              "SECTIONLINENO": 1,
              "ACCT_NO": "B9660",
              "ACCT_NO_GP": "B8046",
              "acct_name": "Total  P & A Mechanical/TC/TC+ GP",
              "DLR_CY": "1877303.0000",
              "DLR_GP_CY": "38.0",
              "DLR_PY": "1316164.0000",
              "DLR_GP_PY": "38.1",
              "DEALER_DIFF": "42.63",
              "DIST_CY": "1108915",
              "DIST_GP_CY": "42.2",
              "DIST_PY": "959660",
              "DIST_GP_PY": "42.0",
              "DISTRICT_DIFF": "15.55",
              "RGN_CY": "987690",
              "RGN_GP_CY": "41.5",
              "RGN_PY": "871637",
              "RGN_GP_PY": "40.8",
              "REGION_DIFF": "13.31",
              "NTNL_CY": "679932",
              "NTNL_GP_CY": "0.4",
              "NTNL_PY": "622945",
              "NTNL_GP_PY": "0.4",
              "NATIONAL_DIFF": "9.14",
              "GUIDES": "<20%"
            },
            {
              "IS_USER_INPUT": false,
              "SECTIONID": 2,
              "SECTIONTITLE": "Gross Profit $/%",
              "SECTIONLINENO": 2,
              "ACCT_NO": "4710T",
              "ACCT_NO_GP": "B8049",
              "acct_name": "Body Shop P & A  GP",
              "DLR_CY": "0.0000",
              "DLR_GP_CY": "0.0",
              "DLR_PY": "0.0000",
              "DLR_GP_PY": "0.0",
              "DEALER_DIFF": "0.00",
              "DIST_CY": "54653",
              "DIST_GP_CY": "35.9",
              "DIST_PY": "49471",
              "DIST_GP_PY": "36.4",
              "DISTRICT_DIFF": "10.47",
              "RGN_CY": "44929",
              "RGN_GP_CY": "34.1",
              "RGN_PY": "39523",
              "RGN_GP_PY": "34.5",
              "REGION_DIFF": "13.67",
              "NTNL_CY": "111221",
              "NTNL_GP_CY": "0.3",
              "NTNL_PY": "103238",
              "NTNL_GP_PY": "0.3",
              "NATIONAL_DIFF": "7.73",
              "GUIDES": "<20%"
            },
            {
              "sectionNo": 3,
              "sectionTitle": "Inventory",
              "sectionData": [
                {
                  "Line": "1",
                  "Gross_profit": "P & A Inventory",
                  "YTD": "111",
                  "GP_Sales": "2.2%",
                  "Prior_YTD": "342102",
                  "GP_Sales1": "42.4%",
                  "+/-%": "-100",
                  "K_YTD": "111",
                  "K_GP_Sales": "0.0%",
                  "K_Prior_YTD": "720831",
                  "K_GP_Sales1": "38.7%",
                  "K_+/-%": "-100",
                  "KA_YTD": "111",
                  "KA_GP_Sale": "0.0%",
                  "KA_Prior_YTD": "720831",
                  "KA_GP_Sale1": "37.8%",
                  "KA_+/-%": "-100",
                  "N_YTD": "111",
                  "N_GP_Sale": "0.4%",
                  "N_Prior_YTD": "720831",
                  "N_GP_Sale1": "0.4%",
                  "N_+/-%": "-100",
                  "GUIDES": "<20%"
                },
                {
                  "Line": "2",
                  "Gross_profit": "Inv.- P and A - Other",
                  "YTD": "111",
                  "GP_Sales": "NULL",
                  "Prior_YTD": "342102",
                  "GP_Sales1": "42.4%",
                  "+/-%": "100",
                  "K_YTD": "111",
                  "K_GP_Sales": "0.0%",
                  "K_Prior_YTD": "720831",
                  "K_GP_Sales1": "38.7%",
                  "K_+/-%": "-100",
                  "KA_YTD": "111",
                  "KA_GP_Sale": "0.0%",
                  "KA_Prior_YTD": "720831",
                  "KA_GP_Sale1": "37.8%",
                  "KA_+/-%": "-100",
                  "N_YTD": "111",
                  "N_GP_Sale": "0.4%",
                  "N_Prior_YTD": "720831",
                  "N_GP_Sale1": "0.4%",
                  "N_+/-%": "-100",
                  "GUIDES": "<20%"
                }
              ]
            }
          ]

        }
      ]
    }
  ]
}; //NOSONAR

export const SalesAndPartsAnalysisMockData = {
  body: [
    {
      "reportHeaderData": [
        {
          "COMPANY_CODE": "14",
          "COMPANY_NAME": "Toyota",
          "COMPANY": "14 - Toyota",
          "REGION_CODE": "120",
          "REGION": "120 - San Francisco",
          "REGION_NAME": "San Francisco",
          "DISTRICT_CODE": "120D",
          "DISTRICT_NAME": "District 4 - SF Metro/North Bay",
          "DEALER_CODE": "04285",
          "DEALER_NAME": "ENVISION TOYOTA/NORWALK",
          "DEALER": "04143 - City Toyota",
          "NATIONAL": "NATIONAL"
        }
      ],
      "reportData": [
        {
          "SECTIONID": 1,
          "SECTIONTITLE": "Sales",
          "sectionData": [
            {
              "SECTIONID": 1,
              "SECTIONTITLE": "Sales",
              "SECTIONLINENO": 1,
              "ACCT_NAME": "Customer Pay Labor Sales",
              "CLASS_AVG": "34",
              "SELECTED_MONTH": "29",
              "YTD": "265",
              "TMS_GUIDE": null,
              "NCM_GUIDE": null
            },
            {
              "SECTIONID": 1,
              "SECTIONTITLE": "Sales",
              "SECTIONLINENO": 1,
              "ACCT_NAME": "Customer Pay Labor Sales",
              "CLASS_AVG": "65",
              "SELECTED_MONTH": "71",
              "YTD": "456",
              "TMS_GUIDE": null,
              "NCM_GUIDE": null
            },
            {
              "SECTIONID": 1,
              "SECTIONTITLE": "Sales",
              "SECTIONLINENO": 1,
              "ACCT_NAME": "Customer Pay Labor Sales",
              "CLASS_AVG": "98",
              "SELECTED_MONTH": "100",
              "YTD": "722",
              "TMS_GUIDE": null,
              "NCM_GUIDE": null
            }
          ]
        },
        {
          "SECTIONID": 2,
          "SECTIONTITLE": "PROFITABILITY",
          "sectionData": [
            {
              "SECTIONID": 2,
              "SECTIONTITLE": "PROFITABILITY",
              "SECTIONLINENO": 2,
              "ACCT_NAME": "Cust Mech Labor GP",
              "CLASS_AVG": "34",
              "SELECTED_MONTH": "60",
              "YTD": "306",
              "TMS_GUIDE": null,
              "NCM_GUIDE": null
            },
            {
              "SECTIONID": 2,
              "SECTIONTITLE": "PROFITABILITY",
              "SECTIONLINENO": 2,
              "ACCT_NAME": "Cust Mech Labor GP",
              "CLASS_AVG": "1",
              "SELECTED_MONTH": "0",
              "YTD": "0",
              "TMS_GUIDE": null,
              "NCM_GUIDE": null
            },
            {
              "SECTIONID": 2,
              "SECTIONTITLE": "PROFITABILITY",
              "SECTIONLINENO": 2,
              "ACCT_NAME": "Cust Mech Labor GP",
              "CLASS_AVG": "(18.5%)",
              "SELECTED_MONTH": "(51.7%)",
              "YTD": "(13.4%)",
              "TMS_GUIDE": null,
              "NCM_GUIDE": null
            }
          ]
        },
      ]
    }
  ]
}; //NOSONAR


export const TCUVPerformanceSummaryMockData = {
  body: [
    {
      "reportHeaderData": [
        {
          "COMPANY_CODE": "14",
          "COMPANY_NAME": "Toyota",
          "COMPANY": "14 - Toyota",
          "REGION_CODE": "120",
          "REGION": "120 - San Francisco",
          "REGION_NAME": "San Francisco",
          "DISTRICT_CODE": "120D",
          "DISTRICT_NAME": "District 4 - SF Metro/North Bay",
          "DEALER_CODE": "04285",
          "DEALER_NAME": "ENVISION TOYOTA/NORWALK",
          "DEALER": "04143 - City Toyota",
          "NATIONAL": "NATIONAL"
        }
      ],
      "reportData": [
        {
          "SECTIONID": 1,
          "SECTIONTITLE": "Measure",
          "sectionData": [
            {
              "SECTIONID": 1,
              "SECTIONTITLE": "Measure",
              "SECTIONLINENO": 1,
              "ACCT_NAME": "Total Dealership GP % Sales",
              "MEASURE_EMPTY": "NULL",
              "DEALERSHIP_MTD": "11.73",
              "DEALERSHIP_MTD_EMPTY": "NULL",
              "DEALERSHIP_YTD": "12.90",
              "GUIDE": "11% to 13%",
              "FONTCOLOR": 'green'

            },
            {
              "SECTIONID": 1,
              "SECTIONTITLE": "Measure",
              "SECTIONLINENO": 2,
              "ACCT_NAME": "Used Department GP % Sales",
              "MEASURE_EMPTY": "NULL",
              "DEALERSHIP_MTD": "7.27",
              "DEALERSHIP_MTD_EMPTY": "NULL",
              "DEALERSHIP_YTD": "7.83",
              "GUIDE": "10% to 13%",
              "FONTCOLOR": 'red'
            },
            {
              "SECTIONID": 1,
              "SECTIONTITLE": "Measure",
              "SECTIONLINENO": 3,
              "ACCT_NAME": "Used GP Contribution",
              "MEASURE_EMPTY": "NULL",
              "DEALERSHIP_MTD": "19.95",
              "DEALERSHIP_YTD": "19.26",
              "DEALERSHIP_MTD_EMPTY": "NULL",
              "GUIDE": "10% to 15%",
              "FONTCOLOR": 'red'
            },
            {
              "SECTIONID": 1,
              "SECTIONTITLE": "Measure",
              "SECTIONLINENO": 4,
              "ACCT_NAME": "Used Vehicle Equity",
              "MEASURE_EMPTY": "NULL",
              "DEALERSHIP_MTD": "100.00",
              "DEALERSHIP_YTD": "100.00",
              "DEALERSHIP_MTD_EMPTY": "NULL",
              "GUIDE": ">50%",
              "FONTCOLOR": 'green'
            },
            {
              "SECTIONID": 1,
              "SECTIONTITLE": "Measure",
              "SECTIONLINENO": 5,
              "ACCT_NAME": "Floor Plan Expense",
              "MEASURE_EMPTY": "NULL",
              "DEALERSHIP_MTD": "11.68",
              "DEALERSHIP_YTD": "10.73",
              "GUIDE": "1% to 2%",
              "DEALERSHIP_MTD_EMPTY": "NULL",
              "FONTCOLOR": 'red'
            }

          ]
        },
        {
          "SECTIONID": 2,
          "SECTIONTITLE": "Key Metric",
          "sectionData": [
            {
              "SECTIONID": 2,
              "SECTIONTITLE": "Key Metric",
              "SECTIONLINENO": 1,
              "ACCT_NAME": "TCUV GP PUVR/L",
              "MEASURE_EMPTY": "NULL",
              "DEALER_TCUV": "1,682",
              "DISTRICT": "811",
              "REGION_TCUV": "1,068",
              "NATIONAL": "1,265",
              "FONTCOLOR": 'green'

            },
            {
              "SECTIONID": 2,
              "SECTIONTITLE": "Key Metric",
              "SECTIONLINENO": 2,
              "ACCT_NAME": "Other Toyota GP PUVR/L",
              "MEASURE_EMPTY": "NULL",
              "DEALER_TCUV": "1,682",
              "DISTRICT": "259",
              "REGION_TCUV": "843",
              "NATIONAL": "1,267",
              "FONTCOLOR": 'green'
            },
            {
              "SECTIONID": 2,
              "SECTIONTITLE": "Key Metric",
              "SECTIONLINENO": 3,
              "ACCT_NAME": "Other Used GP PUVR/L",
              "MEASURE_EMPTY": "NULL",
              "DEALER_TCUV": "1,833",
              "DISTRICT": "792",
              "REGION_TCUV": "743",
              "NATIONAL": "1,184",
              "FONTCOLOR": 'red'
            },
            {
              "SECTIONID": 2,
              "SECTIONTITLE": "Key Metric",
              "SECTIONLINENO": 4,
              "ACCT_NAME": "Total Used Vehicle GP PUVR/L",
              "MEASURE_EMPTY": "NULL",
              "DEALER_TCUV": "1,334",
              "DISTRICT": "603",
              "REGION_TCUV": "906",
              "NATIONAL": "1,237",
              "FONTCOLOR": 'green'
            },
          ]
        },
        {
          "SECTIONID": 3,
          "SECTIONTITLE": "Monthly Store Average",
          "sectionData": [
            {
              "SECTIONID": 3,
              "SECTIONTITLE": "Monthly Store Average",
              "SECTIONLINENO": 1,
              "ACCT_NAME": "TCUV GP PUVR/L",
              "DEALER_TCUV": "1,682",
              "DISTRICT": "811",
              "REGION_TCUV": "1,068",
              "NATIONAL": "1,265"
            },
            {
              "SECTIONID": 3,
              "SECTIONTITLE": "Monthly Store Average",
              "SECTIONLINENO": 2,
              "ACCT_NAME": "Other Toyota GP PUVR/L",
              "DEALER_TCUV": "1,682",
              "DISTRICT": "259",
              "REGION_TCUV": "843",
              "NATIONAL": "1,267"
            },
            {
              "SECTIONID": 3,
              "SECTIONTITLE": "Monthly Store Average",
              "SECTIONLINENO": 3,
              "ACCT_NAME": "Other Used GP PUVR/L",
              "DEALER_TCUV": "1,833",
              "DISTRICT": "792",
              "REGION_TCUV": "743",
              "NATIONAL": "1,184"
            },
            {
              "SECTIONID": 3,
              "SECTIONTITLE": "Key Metric",
              "SECTIONLINENO": 4,
              "ACCT_NAME": "Total Used Vehicle GP PUVR/L",
              "DEALER_TCUV": "1,334",
              "DISTRICT": "603",
              "REGION_TCUV": "906",
              "NATIONAL": "1,237"
            },
          ]
        },
      ]
    }
  ],
  status: 200
}; //NOSONAR

export const TCUVPerformanceDashboardMockData = {
  body: {
    "operations": [
      {
        "reportData": [
          {
            "SECTIONNO": "1",
            "SECTIONTITLE": "Total Dealership Gross Profit % of Sales",
            "sectionData": [
              {
                "SECTIONID": 1,
                "SECTIONTITLE": "Total Dealership Gross Profit % of Sales",
                "OPERTAIONS_TYPE": "MTD",
                "DLR_GROSS_PROFIT": "928,000",
                "/": "NULL",
                "DLR_SALES": "5,756,820",
                "=": "NULL",
                "GP_SALES": "16.12",
                "GUIDE": "11% to 13%"

              },
              {
                "SECTIONID": 1,
                "SECTIONTITLE": "Total Dealership Gross Profit % of Sales",
                "OPERTAIONS_TYPE": "YTD",
                "DLR_GROSS_PROFIT": "928,000",
                "/": "NULL",
                "DLR_SALES": "5,756,820",
                "=": "NULL",
                "GP_SALES": "16.12",
                "GUIDE": "11% to 13%"

              }
            ]
          },
          {
            "SECTIONNO": "2",
            "SECTIONTITLE": "Total Dealership Net Profit % of Sales",
            "sectionData": [
              {
                "SECTIONID": 2,
                "SECTIONTITLE": "Total Dealership Net Profit % of Sales",
                "OPERTAIONS_TYPE": "MTD",
                "DLR_GROSS_PROFIT": "928,000",
                "/": "NULL",
                "DLR_SALES": "5,756,820",
                "=": "NULL",
                "GP_SALES": "16.12",
                "GUIDE": "11% to 13%"

              },
              {
                "SECTIONID": 2,
                "SECTIONTITLE": "Total Dealership Net Profit % of Sales",
                "OPERTAIONS_TYPE": "YTD",
                "DLR_GROSS_PROFIT": "928,000",
                "/": "NULL",
                "DLR_SALES": "5,756,820",
                "=": "NULL",
                "GP_SALES": "16.12",
                "GUIDE": "11% to 13%"

              }
            ]
          }
        ],
        "reportHeaderData": [
          {
            "COMPANY_CODE": "14",
            "COMPANY_NAME": "Toyota",
            "COMPANY": "14 - Toyota",
            "REGION_CODE": "120",
            "REGION": "120 - San Francisco",
            "REGION_NAME": "San Francisco",
            "DISTRICT_CODE": "120D",
            "DISTRICT_NAME": "District 4 - SF Metro/North Bay",
            "DEALER_CODE": "04285",
            "DEALER_NAME": "ENVISION TOYOTA/NORWALK",
            "DEALER": "04143 - City Toyota",
            "NATIONAL": "NATIONAL",
            "OPERTAIONS_TYPE": "",
            "DLR_GROSS_PROFIT": "Total Dealership Gross Profit $ 6999T Pg. 6 Ln 66",
            "/": "/",
            "DLR_SALES": "Total Dealership Sales $ 6998T Pg. 6 Ln 66	",
            "=": "=",
            "GP_SALES": "GP % of Sales",
            "GUIDE": "Guide"
          }
        ],
      }
    ],
    "usedvehicleinventory": [
      {
        "reportData": [
          {
            "SECTIONNO": "1",
            "SECTIONTITLE": "Total Dealership Gross Profit % of Sales",
            "sectionData": [
              {
                "SECTIONID": 1,
                "SECTIONTITLE": "Total Dealership Gross Profit % of Sales",
                "OPERTAIONS_TYPE": "MTD",
                "DLR_GROSS_PROFIT": "928,000",
                "/": "NULL",
                "DLR_SALES": "5,756,820",
                "=": "NULL",
                "GP_SALES": "16.12",
                "GUIDE": "11% to 13%"

              },
              {
                "SECTIONID": 1,
                "SECTIONTITLE": "Total Dealership Gross Profit % of Sales",
                "OPERTAIONS_TYPE": "YTD",
                "DLR_GROSS_PROFIT": "928,000",
                "/": "NULL",
                "DLR_SALES": "5,756,820",
                "=": "NULL",
                "GP_SALES": "16.12",
                "GUIDE": "11% to 13%"

              }
            ]
          },
          {
            "SECTIONNO": "2",
            "SECTIONTITLE": "Total Dealership Net Profit % of Sales",
            "sectionData": [
              {
                "SECTIONID": 2,
                "SECTIONTITLE": "Total Dealership Net Profit % of Sales",
                "OPERTAIONS_TYPE": "MTD",
                "DLR_GROSS_PROFIT": "928,000",
                "/": "NULL",
                "DLR_SALES": "5,756,820",
                "=": "NULL",
                "GP_SALES": "16.12",
                "GUIDE": "11% to 13%"

              },
              {
                "SECTIONID": 2,
                "SECTIONTITLE": "Total Dealership Net Profit % of Sales",
                "OPERTAIONS_TYPE": "YTD",
                "DLR_GROSS_PROFIT": "928,000",
                "/": "NULL",
                "DLR_SALES": "5,756,820",
                "=": "NULL",
                "GP_SALES": "16.12",
                "GUIDE": "11% to 13%"

              }
            ]
          }
        ],
        "reportHeaderData": [
          {
            "COMPANY_CODE": "14",
            "COMPANY_NAME": "Toyota",
            "COMPANY": "14 - Toyota",
            "REGION_CODE": "120",
            "REGION": "120 - San Francisco",
            "REGION_NAME": "San Francisco",
            "DISTRICT_CODE": "120D",
            "DISTRICT_NAME": "District 4 - SF Metro/North Bay",
            "DEALER_CODE": "04285",
            "DEALER_NAME": "ENVISION TOYOTA/NORWALK",
            "DEALER": "04143 - City Toyota",
            "NATIONAL": "NATIONAL",
            "OPERTAIONS_TYPE": "",
            "DLR_GROSS_PROFIT": "Total Dealership Gross Profit $ 6999T Pg. 6 Ln 66",
            "/": "/",
            "DLR_SALES": "Total Dealership Sales $ 6998T Pg. 6 Ln 66	",
            "=": "=",
            "GP_SALES": "GP % of Sales",
            "GUIDE": "Guide"
          }
        ],
      }
    ]
  },
  status: 200
}; //NOSONAR

export const TCUVPerformanceCalendarYearTurnsMockData = {
  body: [
    {
      "reportHeaderData": [
        {
          "COMPANY_CODE": "14",
          "COMPANY_NAME": "Toyota",
          "COMPANY": "14 - Toyota",
          "REGION_CODE": "120",
          "REGION": "120 - San Francisco",
          "REGION_NAME": "San Francisco",
          "DISTRICT_CODE": "120D",
          "DISTRICT_NAME": "District 4 - SF Metro/North Bay",
          "DEALER_CODE": "04285",
          "DEALER_NAME": "ENVISION TOYOTA/NORWALK",
          "DEALER": "04143 - City Toyota",
          "NATIONAL": "NATIONAL"
        }
      ],
      "reportData": [
        {
          "SECTIONID": 1,
          "SECTIONTITLE": "Measure",
          "sectionData": [
            {
              "SECTIONID": 1,
              "SECTIONTITLE": "Measure",
              "SECTIONLINENO": 1,
              "STEP": "NULL",
              "ACCT_NAME": "YTD Retail TCUV Sales - $",
              "FINANCE_INFO": "	4321T+4331T Page 6 Ln 1, 2",
              "OPERATOR": "NULL",
              "TOTALS": "17,478,526	",
              "GUIDE": "NULL",
              "FORMATTYPE": 345

            },
            {
              "SECTIONID": 1,
              "SECTIONTITLE": "Measure",
              "SECTIONLINENO": 2,
              "STEP": "NULL",
              "ACCT_NAME": "YTD Retail Used Toyota Sales - $",
              "FINANCE_INFO": "	4321T+4331T Page 6 Ln 1, 2",
              "OPERATOR": "+",
              "TOTALS": "17,478,526	",
              "GUIDE": "NULL",
              "FORMATTYPE": 345
            },
            {
              "SECTIONID": 1,
              "SECTIONTITLE": "Measure",
              "SECTIONLINENO": 3,
              "STEP": "Step 1",
              "ACCT_NAME": "	YTD Retail Other Used Sales - $",
              "FINANCE_INFO": "	4321T+4331T Page 6 Ln 1, 2",
              "OPERATOR": "+",
              "TOTALS": "17,478,526	",
              "GUIDE": "NULL",
              "FORMATTYPE": 345
            },
            {
              "SECTIONID": 1,
              "SECTIONTITLE": "Measure",
              "SECTIONLINENO": 4,
              "STEP": "",
              "ACCT_NAME": "YTD Wholesale Used Sales - $",
              "FINANCE_INFO": "	4321T+4331T Page 6 Ln 1, 2",
              "OPERATOR": "+",
              "TOTALS": "17,478,526	",
              "GUIDE": "NULL",
              "FORMATTYPE": 345
            },
            {
              "SECTIONID": 1,
              "SECTIONTITLE": "Measure",
              "SECTIONLINENO": 5,
              "STEP": "",
              "ACCT_NAME": "YTD Total Used Vehicle Sales - $",
              "FINANCE_INFO": "	4321T+4331T Page 6 Ln 1, 2",
              "OPERATOR": "+",
              "TOTALS": "17,478,526	",
              "GUIDE": "NULL",
              "FORMATTYPE": 342
            },

            {
              "SECTIONID": 1,
              "SECTIONTITLE": "Measure",
              "SECTIONLINENO": 6,
              "STEP": "NULL",
              "ACCT_NAME": "	YTD Retail TCUV Gross - $",
              "FINANCE_INFO": "	4321T+4331T Page 6 Ln 1, 2",
              "OPERATOR": "NULL",
              "TOTALS": "17,478,526	",
              "GUIDE": "NULL",
              "FORMATTYPE": 345

            },
            {
              "SECTIONID": 1,
              "SECTIONTITLE": "Measure",
              "SECTIONLINENO": 7,
              "STEP": "NULL",
              "ACCT_NAME": "YTD Retail Used Toyota Gross - $",
              "FINANCE_INFO": "	4321T+4331T Page 6 Ln 1, 2",
              "OPERATOR": "+",
              "TOTALS": "17,478,526	",
              "GUIDE": "NULL",
              "FORMATTYPE": 345
            },
            {
              "SECTIONID": 1,
              "SECTIONTITLE": "Measure",
              "SECTIONLINENO": 8,
              "STEP": "Step 2",
              "ACCT_NAME": "	YTD Retail Other Used Gross - $",
              "FINANCE_INFO": "	4321T+4331T Page 6 Ln 1, 2",
              "OPERATOR": "+",
              "TOTALS": "17,478,526	",
              "GUIDE": "NULL",
              "FORMATTYPE": 345
            },
            {
              "SECTIONID": 1,
              "SECTIONTITLE": "Measure",
              "SECTIONLINENO": 9,
              "STEP": "",
              "ACCT_NAME": "YTD Wholesale Used Gross - $",
              "FINANCE_INFO": "	4321T+4331T Page 6 Ln 1, 2",
              "OPERATOR": "+",
              "TOTALS": "17,478,526	",
              "GUIDE": "NULL",
              "FORMATTYPE": 345
            },
            {
              "SECTIONID": 1,
              "SECTIONTITLE": "Measure",
              "SECTIONLINENO": 10,
              "STEP": "",
              "ACCT_NAME": "YTD Total Used Vehicle Gross - $",
              "FINANCE_INFO": "	4321T+4331T Page 6 Ln 1, 2",
              "OPERATOR": "+",
              "TOTALS": "17,478,526	",
              "GUIDE": "NULL",
              "FORMATTYPE": 342
            }


          ]
        }

      ]
    }
  ],
  status: 200
}; //NOSONAR

export const mockTableHeader = [
  {
    "Parts": '2',
    "MTD(AVERAGE DEALER)": '1',
    "DEALER 6-MONTH HISTORY": '6'
  }
]; //NOSONAR

export const dealerPerformanceNucFsData = {
  body:
  {
    "new": [
      {
        "SECTIONNO": "2",
        "SECTIONTITLE": "Finance Analysis",
        "sectionData": [
          {
            "SECTIONNO": 2,
            "SECTIONTITLE": "Finance Analysis",
            "ACCT_NO": "B9023",
            "ACCT_TITLE": "New Number of Finance & Lease Contracts Sold",
            "ROW": "1",
            "UNITS_FLAG": "84",
            "SEL_MTH_FONT_CLR": 0,
            "SEL_MTH_CY": "74.2370",
            "ROLL_3_MTH": "62.8916",
            "YTD_CY": "188.6760",
            "SEL_MTH_PY": "63.5450",
            "YTD_PY": "162.7710",
            "REG_AVG": null,
            "TOP_25_REG": null,
            "CLASS_AVG": null,
            "TOP_25_NATL": null,
            "NATL_AVG": "74.2370"
          },
          {
            "SECTIONNO": 2,
            "SECTIONTITLE": "Finance Analysis",
            "ACCT_NO": "B9024",
            "ACCT_TITLE": "New Number of Lease Contracts Sold",
            "ROW": "2",
            "UNITS_FLAG": "84",
            "SEL_MTH_FONT_CLR": 0,
            "SEL_MTH_CY": "30.3850",
            "ROLL_3_MTH": "23.0243",
            "YTD_CY": "69.0740",
            "SEL_MTH_PY": "21.0530",
            "YTD_PY": "47.1190",
            "REG_AVG": null,
            "TOP_25_REG": null,
            "CLASS_AVG": null,
            "TOP_25_NATL": null,
            "NATL_AVG": "30.3850"
          }
        ]
      },
    ],
    "certifiedUsed": [
      {
        "SECTIONNO": "2",
        "SECTIONTITLE": "Finance Analysis",
        "sectionData": [
          {
            "SECTIONNO": 2,
            "SECTIONTITLE": "Finance Analysis",
            "ACCT_NO": "B9081",
            "ACCT_TITLE": "Retail Finance and Lease Gross Profit",
            "ROW": "1",
            "UNITS_FLAG": "81",
            "SEL_MTH_FONT_CLR": 0,
            "SEL_MTH_CY": "38638.0980",
            "ROLL_3_MTH": "35958.1390",
            "YTD_CY": "107874.4180",
            "SEL_MTH_PY": "40865.9550",
            "YTD_PY": "109814.5210",
            "REG_AVG": null,
            "TOP_25_REG": null,
            "CLASS_AVG": null,
            "TOP_25_NATL": null,
            "NATL_AVG": "38638.0980"
          },
          {
            "SECTIONNO": 2,
            "SECTIONTITLE": "Finance Analysis",
            "ACCT_NO": "B9088",
            "ACCT_TITLE": "Finance and Lease Net Profit After Chargebacks Per Contract Sold",
            "ROW": "8",
            "UNITS_FLAG": "81",
            "SEL_MTH_FONT_CLR": 0,
            "SEL_MTH_CY": "0.0000",
            "ROLL_3_MTH": "0.0000",
            "YTD_CY": "0.0000",
            "SEL_MTH_PY": "0.0000",
            "YTD_PY": "0.0000",
            "REG_AVG": null,
            "TOP_25_REG": null,
            "CLASS_AVG": null,
            "TOP_25_NATL": null,
            "NATL_AVG": "0.0000"
          }
        ]
      },
      {
        "SECTIONNO": "3",
        "SECTIONTITLE": ServiceConAnalysis,
        "sectionData": [
          {
            "SECTIONNO": 3,
            "SECTIONTITLE": ServiceConAnalysis,
            "ACCT_NO": "B9089",
            "ACCT_TITLE": "Service Contract Gross Profit",
            "ROW": "9",
            "UNITS_FLAG": "81",
            "SEL_MTH_FONT_CLR": 0,
            "SEL_MTH_CY": "50854.2630",
            "ROLL_3_MTH": "46542.2746",
            "YTD_CY": "139626.8230",
            "SEL_MTH_PY": "47814.2620",
            "YTD_PY": "136249.5120",
            "REG_AVG": null,
            "TOP_25_REG": null,
            "CLASS_AVG": null,
            "TOP_25_NATL": null,
            "NATL_AVG": "50854.2630"
          },
          {
            "SECTIONNO": 3,
            "SECTIONTITLE": ServiceConAnalysis,
            "ACCT_NO": "B9096",
            "ACCT_TITLE": "Service Contract Net Profit After Chargebacks Per Contract Sold",
            "ROW": "16",
            "UNITS_FLAG": "81",
            "SEL_MTH_FONT_CLR": 0,
            "SEL_MTH_CY": "0.0000",
            "ROLL_3_MTH": "0.0000",
            "YTD_CY": "0.0000",
            "SEL_MTH_PY": "0.0000",
            "YTD_PY": "0.0000",
            "REG_AVG": null,
            "TOP_25_REG": null,
            "CLASS_AVG": null,
            "TOP_25_NATL": null,
            "NATL_AVG": "0.0000"
          }
        ]
      },
      {
        "SECTIONNO": "4",
        "SECTIONTITLE": "Insurance Analysis",
        "sectionData": [
          {
            "SECTIONNO": 4,
            "SECTIONTITLE": "Insurance Analysis",
            "ACCT_NO": "B9097",
            "ACCT_TITLE": "Credit Insurance Gross Profit",
            "ROW": "17",
            "UNITS_FLAG": "81",
            "SEL_MTH_FONT_CLR": 0,
            "SEL_MTH_CY": "281.5330",
            "ROLL_3_MTH": "301.1516",
            "YTD_CY": "903.4550",
            "SEL_MTH_PY": "456.3200",
            "YTD_PY": "1299.7790",
            "REG_AVG": null,
            "TOP_25_REG": null,
            "CLASS_AVG": null,
            "TOP_25_NATL": null,
            "NATL_AVG": "281.5330"
          },
          {
            "SECTIONNO": 4,
            "SECTIONTITLE": "Insurance Analysis",
            "ACCT_NO": "B9102",
            "ACCT_TITLE": "Credit Insurance Chargebacks",
            "ROW": "22",
            "UNITS_FLAG": "81",
            "SEL_MTH_FONT_CLR": 0,
            "SEL_MTH_CY": "281.5330",
            "ROLL_3_MTH": "301.1516",
            "YTD_CY": "903.4550",
            "SEL_MTH_PY": "456.3200",
            "YTD_PY": "1299.7790",
            "REG_AVG": null,
            "TOP_25_REG": null,
            "CLASS_AVG": null,
            "TOP_25_NATL": "1171.6900",
            "NATL_AVG": "281.5330"
          },
        ]
      },
      {
        "SECTIONNO": "5",
        "SECTIONTITLE": "Gap Insurance Analysis",
        "sectionData": [
          {
            "SECTIONNO": 5,
            "SECTIONTITLE": "Gap Insurance Analysis",
            "ACCT_NO": "B9108",
            "ACCT_TITLE": gapInsuranceConPen,
            "ROW": "28",
            "UNITS_FLAG": null,
            "SEL_MTH_FONT_CLR": 0,
            "SEL_MTH_CY": "17.5520",
            "ROLL_3_MTH": "17.1540",
            "YTD_CY": "17.1620",
            "SEL_MTH_PY": "17.4900",
            "YTD_PY": "17.2520",
            "REG_AVG": null,
            "TOP_25_REG": null,
            "CLASS_AVG": null,
            "TOP_25_NATL": null,
            "NATL_AVG": "17.5520"
          },
          {
            "SECTIONNO": 5,
            "SECTIONTITLE": "Gap Insurance Analysis",
            "ACCT_NO": "B9112",
            "ACCT_TITLE": "GAP Insurance Net After Chargebacks Per Contract Sold",
            "ROW": "32",
            "UNITS_FLAG": null,
            "SEL_MTH_FONT_CLR": 0,
            "SEL_MTH_CY": "0.0000",
            "ROLL_3_MTH": "0.0000",
            "YTD_CY": "0.0000",
            "SEL_MTH_PY": "0.0000",
            "YTD_PY": "0.0000",
            "REG_AVG": null,
            "TOP_25_REG": null,
            "CLASS_AVG": null,
            "TOP_25_NATL": null,
            "NATL_AVG": "0.0000"
          }
        ]
      },
      {
        "SECTIONNO": "6",
        "SECTIONTITLE": "Prepaid Maintenance Analysis",
        "sectionData": [

          {
            "SECTIONNO": 6,
            "SECTIONTITLE": "Prepaid Maintenance Analysis",
            "ACCT_NO": "B9116",
            "ACCT_TITLE": "Prepaid Maintenance Contract Penetration",
            "ROW": "36",
            "UNITS_FLAG": "810",
            "SEL_MTH_FONT_CLR": 0,
            "SEL_MTH_CY": "12.9710",
            "ROLL_3_MTH": "13.0916",
            "YTD_CY": "13.0890",
            "SEL_MTH_PY": "13.5830",
            "YTD_PY": "13.6970",
            "REG_AVG": null,
            "TOP_25_REG": null,
            "CLASS_AVG": null,
            "TOP_25_NATL": null,
            "NATL_AVG": "12.9710"
          },
        ]
      },

      {
        "SECTIONNO": "8",
        "SECTIONTITLE": "Key Replacement",
        "sectionData": [
          {
            "SECTIONNO": 8,
            "SECTIONTITLE": "Key Replacement",
            "ACCT_NO": "B10136",
            "ACCT_TITLE": "Key Replacement Protection Gross",
            "ROW": "49",
            "UNITS_FLAG": "81",
            "SEL_MTH_FONT_CLR": 0,
            "SEL_MTH_CY": "1157.3270",
            "ROLL_3_MTH": "1058.3520",
            "YTD_CY": "3175.0570",
            "SEL_MTH_PY": "1241.3030",
            "YTD_PY": "3469.2170",
            "REG_AVG": null,
            "TOP_25_REG": null,
            "CLASS_AVG": null,
            "TOP_25_NATL": null,
            "NATL_AVG": "1157.3270"
          },
          {
            "SECTIONNO": 8,
            "SECTIONTITLE": "Key Replacement",
            "ACCT_NO": "B10125",
            "ACCT_TITLE": "Number of Key Replacement Protection Contracts Sold",
            "ROW": "50",
            "UNITS_FLAG": "84",
            "SEL_MTH_FONT_CLR": 0,
            "SEL_MTH_CY": "2.2210",
            "ROLL_3_MTH": "2.0890",
            "YTD_CY": "6.2660",
            "SEL_MTH_PY": "2.2450",
            "YTD_PY": "6.3520",
            "REG_AVG": null,
            "TOP_25_REG": null,
            "CLASS_AVG": null,
            "TOP_25_NATL": null,
            "NATL_AVG": "2.2210"
          },
          {
            "SECTIONNO": 8,
            "SECTIONTITLE": "Key Replacement",
            "ACCT_NO": "B10123",
            "ACCT_TITLE": "Key Replacement Protection Gross Per Contract Sold",
            "ROW": "51",
            "UNITS_FLAG": "81",
            "SEL_MTH_FONT_CLR": 0,
            "SEL_MTH_CY": "521.0840",
            "ROLL_3_MTH": "506.0490",
            "YTD_CY": "506.7120",
            "SEL_MTH_PY": "552.9190",
            "YTD_PY": "546.1610",
            "REG_AVG": null,
            "TOP_25_REG": null,
            "CLASS_AVG": null,
            "TOP_25_NATL": null,
            "NATL_AVG": "521.0840"
          },
          {
            "SECTIONNO": 8,
            "SECTIONTITLE": "Key Replacement",
            "ACCT_NO": "B10122",
            "ACCT_TITLE": "Key Replacement Protection Contract Penetration",
            "ROW": "52",
            "UNITS_FLAG": "810",
            "SEL_MTH_FONT_CLR": 0,
            "SEL_MTH_CY": "2.6590",
            "ROLL_3_MTH": "2.5876",
            "YTD_CY": "2.5890",
            "SEL_MTH_PY": "2.8660",
            "YTD_PY": "2.8520",
            "REG_AVG": null,
            "TOP_25_REG": null,
            "CLASS_AVG": null,
            "TOP_25_NATL": null,
            "NATL_AVG": "2.6590"
          },
          {
            "SECTIONNO": 8,
            "SECTIONTITLE": "Key Replacement",
            "ACCT_NO": "B10137",
            "ACCT_TITLE": "Key Replacement Protection Gross % Total FS gross",
            "ROW": "53",
            "UNITS_FLAG": "810",
            "SEL_MTH_FONT_CLR": 0,
            "SEL_MTH_CY": "0.9230",
            "ROLL_3_MTH": "0.9140",
            "YTD_CY": "0.9140",
            "SEL_MTH_PY": "1.0170",
            "YTD_PY": "1.0230",
            "REG_AVG": null,
            "TOP_25_REG": null,
            "CLASS_AVG": null,
            "TOP_25_NATL": null,
            "NATL_AVG": "0.9230"
          },
          {
            "SECTIONNO": 8,
            "SECTIONTITLE": "Key Replacement",
            "ACCT_NO": "B10138",
            "ACCT_TITLE": "Key Replacement Protection Chargebacks",
            "ROW": "54",
            "UNITS_FLAG": "81",
            "SEL_MTH_FONT_CLR": 0,
            "SEL_MTH_CY": "1157.3270",
            "ROLL_3_MTH": "1058.3520",
            "YTD_CY": "3175.0570",
            "SEL_MTH_PY": "1241.3030",
            "YTD_PY": "3469.2170",
            "REG_AVG": null,
            "TOP_25_REG": null,
            "CLASS_AVG": null,
            "TOP_25_NATL": null,
            "NATL_AVG": "1157.3270"
          },
          {
            "SECTIONNO": 8,
            "SECTIONTITLE": "Key Replacement",
            "ACCT_NO": "B10139",
            "ACCT_TITLE": "Key Replacement Protection Chargebacks % Total Chargebacks",
            "ROW": "55",
            "UNITS_FLAG": "810",
            "SEL_MTH_FONT_CLR": 0,
            "SEL_MTH_CY": "1.0460",
            "ROLL_3_MTH": "1.0353",
            "YTD_CY": "1.0350",
            "SEL_MTH_PY": "1.1430",
            "YTD_PY": "1.1490",
            "REG_AVG": null,
            "TOP_25_REG": null,
            "CLASS_AVG": null,
            "TOP_25_NATL": null,
            "NATL_AVG": "1.0460"
          },
          {
            "SECTIONNO": 8,
            "SECTIONTITLE": "Key Replacement",
            "ACCT_NO": "B10124",
            "ACCT_TITLE": "Key Replacement Protection Net After Chargebacks Per Contract Sold",
            "ROW": "56",
            "UNITS_FLAG": "81",
            "SEL_MTH_FONT_CLR": 0,
            "SEL_MTH_CY": "0.0000",
            "ROLL_3_MTH": "0.0000",
            "YTD_CY": "0.0000",
            "SEL_MTH_PY": "0.0000",
            "YTD_PY": "0.0000",
            "REG_AVG": null,
            "TOP_25_REG": null,
            "CLASS_AVG": null,
            "TOP_25_NATL": null,
            "NATL_AVG": "0.0000"
          }
        ]
      },
      {
        "SECTIONNO": "9",
        "SECTIONTITLE": "Aftermarket Analysis",
        "sectionData": [
          {
            "SECTIONNO": 9,
            "SECTIONTITLE": "Aftermarket Analysis",
            "ACCT_NO": "B9078",
            "ACCT_TITLE": "Total Aftermarket Gross Profit",
            "ROW": "57",
            "UNITS_FLAG": "81",
            "SEL_MTH_FONT_CLR": 0,
            "SEL_MTH_CY": "14721.0980",
            "ROLL_3_MTH": "13548.5873",
            "YTD_CY": "40645.7620",
            "SEL_MTH_PY": "13420.0410",
            "YTD_PY": "37143.4260",
            "REG_AVG": null,
            "TOP_25_REG": null,
            "CLASS_AVG": null,
            "TOP_25_NATL": null,
            "NATL_AVG": "14721.0980"
          },
          {
            "SECTIONNO": 9,
            "SECTIONTITLE": "Aftermarket Analysis",
            "ACCT_NO": "B9079",
            "ACCT_TITLE": "Aftermarket Gross Profit % Total Financial Services Gross",
            "ROW": "58",
            "UNITS_FLAG": "810",
            "SEL_MTH_FONT_CLR": 0,
            "SEL_MTH_CY": "11.7390",
            "ROLL_3_MTH": "11.7020",
            "YTD_CY": "11.7040",
            "SEL_MTH_PY": "10.9990",
            "YTD_PY": "10.9530",
            "REG_AVG": null,
            "TOP_25_REG": null,
            "CLASS_AVG": null,
            "TOP_25_NATL": null,
            "NATL_AVG": "11.7390"
          },
          {
            "SECTIONNO": 9,
            "SECTIONTITLE": "Aftermarket Analysis",
            "ACCT_NO": "B9080",
            "ACCT_TITLE": "Aftermarket Gross PUVR",
            "ROW": "59",
            "UNITS_FLAG": "81",
            "SEL_MTH_FONT_CLR": 0,
            "SEL_MTH_CY": "176.2750",
            "ROLL_3_MTH": "167.7796",
            "YTD_CY": "167.9290",
            "SEL_MTH_PY": "171.3310",
            "YTD_PY": "166.7800",
            "REG_AVG": null,
            "TOP_25_REG": null,
            "CLASS_AVG": null,
            "TOP_25_NATL": null,
            "NATL_AVG": "176.2750"
          }
        ]
      },
      {
        "SECTIONNO": "10",
        "SECTIONTITLE": "FSD Income/Charge Back Analysis",
        "sectionData": [
          {
            "SECTIONNO": 10,
            "SECTIONTITLE": "FSD Income/Charge Back Analysis",
            "ACCT_NO": "B9121",
            "ACCT_TITLE": "Retail Units",
            "ROW": "60",
            "UNITS_FLAG": "84",
            "SEL_MTH_FONT_CLR": 0,
            "SEL_MTH_CY": "83.5120",
            "ROLL_3_MTH": "80.6803",
            "YTD_CY": "242.0410",
            "SEL_MTH_PY": "78.3280",
            "YTD_PY": "222.7090",
            "REG_AVG": null,
            "TOP_25_REG": null,
            "CLASS_AVG": null,
            "TOP_25_NATL": null,
            "NATL_AVG": "83.5120"
          },
          {
            "SECTIONNO": 10,
            "SECTIONTITLE": "FSD Income/Charge Back Analysis",
            "ACCT_NO": "B9122",
            "ACCT_TITLE": "Financial Services Department Gross Profit",
            "ROW": "61",
            "UNITS_FLAG": "81",
            "SEL_MTH_FONT_CLR": 0,
            "SEL_MTH_CY": "125399.0770",
            "ROLL_3_MTH": "115763.3840",
            "YTD_CY": "347290.1530",
            "SEL_MTH_PY": "122010.6590",
            "YTD_PY": "339113.9510",
            "REG_AVG": null,
            "TOP_25_REG": null,
            "CLASS_AVG": null,
            "TOP_25_NATL": null,
            "NATL_AVG": "125399.0770"
          },
          {
            "SECTIONNO": 10,
            "SECTIONTITLE": "FSD Income/Charge Back Analysis",
            "ACCT_NO": "B9123",
            "ACCT_TITLE": "Financial Services Department Gross PUVR",
            "ROW": "62",
            "UNITS_FLAG": "81",
            "SEL_MTH_FONT_CLR": 0,
            "SEL_MTH_CY": "1458.5210",
            "ROLL_3_MTH": "1399.2063",
            "YTD_CY": "1400.3720",
            "SEL_MTH_PY": "1493.3670",
            "YTD_PY": "1461.3210",
            "REG_AVG": null,
            "TOP_25_REG": null,
            "CLASS_AVG": null,
            "TOP_25_NATL": null,
            "NATL_AVG": "1458.5210"
          },
          {
            "SECTIONNO": 10,
            "SECTIONTITLE": "FSD Income/Charge Back Analysis",
            "ACCT_NO": "B9124",
            "ACCT_TITLE": "Financial Services Department Contracts Sold",
            "ROW": "63",
            "UNITS_FLAG": "84",
            "SEL_MTH_FONT_CLR": 0,
            "SEL_MTH_CY": "68.8980",
            "ROLL_3_MTH": "65.8160",
            "YTD_CY": "197.4460",
            "SEL_MTH_PY": "69.4380",
            "YTD_PY": "196.2780",
            "REG_AVG": null,
            "TOP_25_REG": null,
            "CLASS_AVG": null,
            "TOP_25_NATL": null,
            "NATL_AVG": "68.8980"
          },
          {
            "SECTIONNO": 10,
            "SECTIONTITLE": "FSD Income/Charge Back Analysis",
            "ACCT_NO": "B9125",
            "ACCT_TITLE": "Financial Services Department Contract Penetration",
            "ROW": "64",
            "UNITS_FLAG": "810",
            "SEL_MTH_FONT_CLR": 0,
            "SEL_MTH_CY": "82.5010",
            "ROLL_3_MTH": "81.5613",
            "YTD_CY": "81.5750",
            "SEL_MTH_PY": "88.6500",
            "YTD_PY": "88.1320",
            "REG_AVG": null,
            "TOP_25_REG": null,
            "CLASS_AVG": null,
            "TOP_25_NATL": null,
            "NATL_AVG": "82.5010"
          },
          {
            "SECTIONNO": 10,
            "SECTIONTITLE": "FSD Income/Charge Back Analysis",
            "ACCT_NO": "B9126",
            "ACCT_TITLE": "Financial Services Department Chargebacks",
            "ROW": "65",
            "UNITS_FLAG": "81",
            "SEL_MTH_FONT_CLR": 0,
            "SEL_MTH_CY": "109520.6520",
            "ROLL_3_MTH": "101156.4446",
            "YTD_CY": "303469.3340",
            "SEL_MTH_PY": "107349.3150",
            "YTD_PY": "298501.3080",
            "REG_AVG": null,
            "TOP_25_REG": null,
            "CLASS_AVG": null,
            "TOP_25_NATL": null,
            "NATL_AVG": "109520.6520"
          },
          {
            "SECTIONNO": 10,
            "SECTIONTITLE": "FSD Income/Charge Back Analysis",
            "ACCT_NO": "B9127",
            "ACCT_TITLE": "Financial Services Department Chargebacks % Financial Services Gross Profit",
            "ROW": "66",
            "UNITS_FLAG": "810",
            "SEL_MTH_FONT_CLR": 0,
            "SEL_MTH_CY": "88.1510",
            "ROLL_3_MTH": "88.1896",
            "YTD_CY": "88.1880",
            "SEL_MTH_PY": "88.8880",
            "YTD_PY": "88.9340",
            "REG_AVG": null,
            "TOP_25_REG": null,
            "CLASS_AVG": null,
            "TOP_25_NATL": null,
            "NATL_AVG": "88.1510"
          },
          {
            "SECTIONNO": 10,
            "SECTIONTITLE": "FSD Income/Charge Back Analysis",
            "ACCT_NO": "B9128",
            "ACCT_TITLE": "Financial Services Department Net Profit After Chargebacks",
            "ROW": "67",
            "UNITS_FLAG": "81",
            "SEL_MTH_FONT_CLR": 0,
            "SEL_MTH_CY": "129196.6550",
            "ROLL_3_MTH": "119285.6610",
            "YTD_CY": "357856.9840",
            "SEL_MTH_PY": "124981.4920",
            "YTD_PY": "348330.2660",
            "REG_AVG": null,
            "TOP_25_REG": null,
            "CLASS_AVG": null,
            "TOP_25_NATL": null,
            "NATL_AVG": "129196.6550"
          },
          {
            "SECTIONNO": 10,
            "SECTIONTITLE": "FSD Income/Charge Back Analysis",
            "ACCT_NO": "B9129",
            "ACCT_TITLE": "Financial Services Department Net Profit After Chargebacks PUVR",
            "ROW": "68",
            "UNITS_FLAG": "81",
            "SEL_MTH_FONT_CLR": 0,
            "SEL_MTH_CY": "1547.0430",
            "ROLL_3_MTH": "1477.2910",
            "YTD_CY": "1478.4970",
            "SEL_MTH_PY": "1595.6170",
            "YTD_PY": "1564.0600",
            "REG_AVG": null,
            "TOP_25_REG": null,
            "CLASS_AVG": null,
            "TOP_25_NATL": null,
            "NATL_AVG": "1547.0430"
          },
          {
            "SECTIONNO": 10,
            "SECTIONTITLE": "FSD Income/Charge Back Analysis",
            "ACCT_NO": "B9130",
            "ACCT_TITLE": "Financial Services Department Net Profit After Chargebacks, Comp & Repos",
            "ROW": "69",
            "UNITS_FLAG": "81",
            "SEL_MTH_FONT_CLR": 0,
            "SEL_MTH_CY": "92864.8850",
            "ROLL_3_MTH": "85953.1380",
            "YTD_CY": "257859.4150",
            "SEL_MTH_PY": "90416.3570",
            "YTD_PY": "252662.2870",
            "REG_AVG": null,
            "TOP_25_REG": null,
            "CLASS_AVG": null,
            "TOP_25_NATL": null,
            "NATL_AVG": "92864.8850"
          },
          {
            "SECTIONNO": 10,
            "SECTIONTITLE": "FSD Income/Charge Back Analysis",
            "ACCT_NO": "B9131",
            "ACCT_TITLE": "Financial Services Dept. Net Profit After Chargebacks, Comp & Repos PUVR",
            "ROW": "70",
            "UNITS_FLAG": "81",
            "SEL_MTH_FONT_CLR": 0,
            "SEL_MTH_CY": "1111.9950",
            "ROLL_3_MTH": "1064.5546",
            "YTD_CY": "1065.3540",
            "SEL_MTH_PY": "1154.3300",
            "YTD_PY": "1134.4950",
            "REG_AVG": null,
            "TOP_25_REG": null,
            "CLASS_AVG": null,
            "TOP_25_NATL": null,
            "NATL_AVG": "1111.9950"
          }
        ]
      }
    ],
    "combined": [
      {
        "SECTIONNO": "1",
        "SECTIONTITLE": "Overall Gross & Contract Penetration",
        "sectionData": [
          {
            "SECTIONNO": 1,
            "SECTIONTITLE": "Overall Gross & Contract Penetration",
            "ACCT_NO": "B9003",
            "ACCT_TITLE": "Combined Financial Services Office Gross PRU",
            "ROW": "1",
            "UNITS_FLAG": "81",
            "SEL_MTH_FONT_CLR": 0,
            "SEL_MTH_CY": "1726.4140",
            "ROLL_3_MTH": "1710.9146",
            "YTD_CY": "1711.6510",
            "SEL_MTH_PY": "1725.6200",
            "YTD_PY": "1734.9700",
            "REG_AVG": null,
            "TOP_25_REG": null,
            "CLASS_AVG": null,
            "TOP_25_NATL": null,
            "NATL_AVG": "1726.4140"
          },
          {
            "SECTIONNO": 1,
            "SECTIONTITLE": "Overall Gross & Contract Penetration",
            "ACCT_NO": "B9006",
            "ACCT_TITLE": combinedFinResPer,
            "ROW": "2",
            "UNITS_FLAG": "81",
            "SEL_MTH_FONT_CLR": 0,
            "SEL_MTH_CY": "803.8150",
            "ROLL_3_MTH": "819.1690",
            "YTD_CY": "818.2380",
            "SEL_MTH_PY": "804.7310",
            "YTD_PY": "812.3550",
            "REG_AVG": null,
            "TOP_25_REG": null,
            "CLASS_AVG": null,
            "TOP_25_NATL": "1186.1070",
            "NATL_AVG": "803.8150"
          },
          {
            "SECTIONNO": 1,
            "SECTIONTITLE": "Overall Gross & Contract Penetration",
            "ACCT_NO": "B9011",
            "ACCT_TITLE": "Comb. Financial Services Core Product Penetration (do not incl. Finance & Lease) Contracts",
            "ROW": "3",
            "UNITS_FLAG": "810",
            "SEL_MTH_FONT_CLR": 0,
            "SEL_MTH_CY": "0.0000",
            "ROLL_3_MTH": "0.0000",
            "YTD_CY": "0.0000",
            "SEL_MTH_PY": "0.0000",
            "YTD_PY": "0.0000",
            "REG_AVG": null,
            "TOP_25_REG": null,
            "CLASS_AVG": null,
            "TOP_25_NATL": null,
            "NATL_AVG": "0.0000"
          }
        ]
      },
    ]
  }
}; //NOSONAR

export const SalesAnalysisNewVehicleMockData = {
  body: [
    {
      "reportHeaderData": [
        {
          "COMPANY_CODE": "14",
          "COMPANY_NAME": "Toyota",
          "COMPANY": "14 - Toyota",
          "REGION_CODE": "120",
          "REGION": "120 - San Francisco",
          "REGION_NAME": "San Francisco",
          "DISTRICT_CODE": "120D",
          "DISTRICT_NAME": "District 4 - SF Metro/North Bay",
          "DEALER_CODE": "04285",
          "DEALER_NAME": "ENVISION TOYOTA/NORWALK",
          "DEALER": "04143 - City Toyota",
          "NATIONAL": "NATIONAL"
        }
      ],
      "reportData": [
        {
          "SECTIONID": 1,
          "SECTIONTITLE": "Sales",
          "sectionData": [
            {
              "SECTIONID": 1,
              "SECTIONTITLE": "Sales",
              "SECTIONLINENO": 1,
              "ACCT_NAME": "Retail Total Sales",
              "CLASS AVG": "34",
              "SELECTED MONTH": "29",
              "YTD": "265",
              "TMS GUIDE": null,
              "NCM GUIDE": null
            },
            {
              "SECTIONID": 1,
              "SECTIONTITLE": "Sales",
              "SECTIONLINENO": 1,
              "ACCT_NAME": "Total New Trk Toy Retail/Lease",
              "CLASS AVG": "65",
              "SELECTED MONTH": "71",
              "YTD": "456",
              "TMS GUIDE": null,
              "NCM GUIDE": null
            },
            {
              "SECTIONID": 1,
              "SECTIONTITLE": "Sales",
              "SECTIONLINENO": 1,
              "ACCT_NAME": "New Toyota Vehicle Units",
              "CLASS AVG": "98",
              "SELECTED MONTH": "100",
              "YTD": "722",
              "TMS GUIDE": null,
              "NCM GUIDE": null
            }
          ]
        },
        {
          "SECTIONID": 2,
          "SECTIONTITLE": "SALES - SAME MONTH PRIOR YEAR",
          "sectionData": [
            {
              "SECTIONID": 2,
              "SECTIONTITLE": "SALES - SAME MONTH PRIOR YEAR",
              "SECTIONLINENO": 2,
              "ACCT_NAME": "Retail Total Sales",
              "CLASS AVG": "34",
              "SELECTED MONTH": "60",
              "YTD": "306",
              "TMS GUIDE": null,
              "NCM GUIDE": null
            },
            {
              "SECTIONID": 2,
              "SECTIONTITLE": "SALES - SAME MONTH PRIOR YEAR",
              "SECTIONLINENO": 2,
              "ACCT_NAME": "Fleet - Total Sales",
              "CLASS AVG": "1",
              "SELECTED MONTH": "0",
              "YTD": "0",
              "TMS GUIDE": null,
              "NCM GUIDE": null
            },
            {
              "SECTIONID": 2,
              "SECTIONTITLE": "SALES - SAME MONTH PRIOR YEAR",
              "SECTIONLINENO": 2,
              "ACCT_NAME": "Toyota Car Sales YTD% Change",
              "CLASS AVG": "(18.5%)",
              "SELECTED MONTH": "(51.7%)",
              "YTD": "(13.4%)",
              "TMS GUIDE": null,
              "NCM GUIDE": null
            }
          ]
        },
      ]
    }
  ]
}; //NOSONAR

export const PsFinancialMockData = {
  body: [
    {
      "reportData": [
        {
          "SECTIONID": "TOYOTA",
          "sectionData": [
            {
              "SECTIONID": "TOYOTA",
              "SECTIONTITLE": "",
              "DATALOADED": 1,
              "ACCTNO": "B9659",
              "SUBRPTID": "TW35",
              "SEQNO": 1,
              "SUBRPTTITLRFROMREPACCTS": null,
              "FORMATTYPE": 341,
              "CHANGECALC": 352,
              "ACCTYR": 2023,
              "ACCTMO": 2,
              "ACCTNAME": "P & A Customer Mechanical Sales",
              "UNITS_FLAG": "81",
              "CURRMOVALUE": "148736.0000",
              "PREVMOVALUE": "142672.0000",
              "MONTHCHANGE": "4.2500",
              "CURRYRVALUE": "314653.0000",
              "PREVYRVALUE": "274877.0000",
              "YEARCHANGE": "14.4700"
            },
            {
              "SECTIONID": "TOYOTA",
              "SECTIONTITLE": "",
              "DATALOADED": 1,
              "ACCTNO": "B9631",
              "SUBRPTID": "TW35",
              "SEQNO": 3,
              "SUBRPTTITLRFROMREPACCTS": null,
              "FORMATTYPE": 341,
              "CHANGECALC": 352,
              "ACCTYR": 2023,
              "ACCTMO": 2,
              "ACCTNAME": "Total P & A MRO + Exp Lube",
              "UNITS_FLAG": "81",
              "CURRMOVALUE": "132657.0000",
              "PREVMOVALUE": "128785.0000",
              "MONTHCHANGE": "3.0000",
              "CURRYRVALUE": "276303.0000",
              "PREVYRVALUE": "252324.0000",
              "YEARCHANGE": "9.5000"
            },
            {
              "SECTIONID": "TOYOTA",
              "SECTIONTITLE": "",
              "DATALOADED": 1,
              "ACCTNO": "B9660",
              "SUBRPTID": "TW35",
              "SEQNO": 4,
              "SUBRPTTITLRFROMREPACCTS": null,
              "FORMATTYPE": 341,
              "CHANGECALC": 352,
              "ACCTYR": 2023,
              "ACCTMO": 2,
              "ACCTNAME": "Total  P & A Mechanical/TC/TC+ GP",
              "UNITS_FLAG": "81",
              "CURRMOVALUE": "40425.0000",
              "PREVMOVALUE": "48665.0000",
              "MONTHCHANGE": "-16.9300",
              "CURRYRVALUE": "84157.0000",
              "PREVYRVALUE": "93039.0000",
              "YEARCHANGE": "-9.5400"
            },
            {
              "SECTIONID": "TOYOTA",
              "SECTIONTITLE": "",
              "DATALOADED": 1,
              "ACCTNO": "B9632",
              "SUBRPTID": "TW35",
              "SEQNO": 5,
              "SUBRPTTITLRFROMREPACCTS": null,
              "FORMATTYPE": 341,
              "CHANGECALC": 351,
              "ACCTYR": 2023,
              "ACCTMO": 2,
              "ACCTNAME": "P & A Customer Mechanical GP % of Sales",
              "UNITS_FLAG": "82",
              "CURRMOVALUE": "29.0170",
              "PREVMOVALUE": "35.1810",
              "MONTHCHANGE": "0.0000",
              "CURRYRVALUE": "28.4370",
              "PREVYRVALUE": "34.6370",
              "YEARCHANGE": "0.0000"
            },
            {
              "SECTIONID": "TOYOTA",
              "SECTIONTITLE": "",
              "DATALOADED": 1,
              "ACCTNO": "B9633",
              "SUBRPTID": "TW35",
              "SEQNO": 6,
              "SUBRPTTITLRFROMREPACCTS": null,
              "FORMATTYPE": 341,
              "CHANGECALC": 352,
              "ACCTYR": 2023,
              "ACCTMO": 2,
              "ACCTNAME": "P & A Mechanical RO Tickets",
              "UNITS_FLAG": "84",
              "CURRMOVALUE": "1232.0000",
              "PREVMOVALUE": "991.0000",
              "MONTHCHANGE": "24.3100",
              "CURRYRVALUE": "2568.0000",
              "PREVYRVALUE": "2081.0000",
              "YEARCHANGE": "23.4000"
            },
            {
              "SECTIONID": "TOYOTA",
              "SECTIONTITLE": "",
              "DATALOADED": 1,
              "ACCTNO": "B9634",
              "SUBRPTID": "TW35",
              "SEQNO": 7,
              "SUBRPTTITLRFROMREPACCTS": null,
              "FORMATTYPE": 342,
              "CHANGECALC": 351,
              "ACCTYR": 2023,
              "ACCTMO": 2,
              "ACCTNAME": "Mechanical Sales % of Total",
              "UNITS_FLAG": "82",
              "CURRMOVALUE": "21.5240",
              "PREVMOVALUE": "18.7800",
              "MONTHCHANGE": "0.0000",
              "CURRYRVALUE": "21.3820",
              "PREVYRVALUE": "19.1510",
              "YEARCHANGE": "0.0000"
            },
            {
              "SECTIONID": "TOYOTA",
              "SECTIONTITLE": "",
              "DATALOADED": 1,
              "ACCTNO": "B9657",
              "SUBRPTID": "TW35",
              "SEQNO": 8,
              "SUBRPTTITLRFROMREPACCTS": null,
              "FORMATTYPE": 341,
              "CHANGECALC": 352,
              "ACCTYR": 2023,
              "ACCTMO": 2,
              "ACCTNAME": "Customer Pay Labor Sales",
              "UNITS_FLAG": "81",
              "CURRMOVALUE": "293135.0000",
              "PREVMOVALUE": "264390.0000",
              "MONTHCHANGE": "10.8700",
              "CURRYRVALUE": "601961.0000",
              "PREVYRVALUE": "531980.0000",
              "YEARCHANGE": "13.1500"
            },
            {
              "SECTIONID": "TOYOTA",
              "SECTIONTITLE": "",
              "DATALOADED": 1,
              "ACCTNO": "B9968",
              "SUBRPTID": "TW35",
              "SEQNO": 10,
              "SUBRPTTITLRFROMREPACCTS": null,
              "FORMATTYPE": 341,
              "CHANGECALC": 352,
              "ACCTYR": 2023,
              "ACCTMO": 2,
              "ACCTNAME": "Customer Labor Sales",
              "UNITS_FLAG": "81",
              "CURRMOVALUE": "275564.0000",
              "PREVMOVALUE": "240664.0000",
              "MONTHCHANGE": "14.5000",
              "CURRYRVALUE": "562757.0000",
              "PREVYRVALUE": "481058.0000",
              "YEARCHANGE": "16.9800"
            },
            {
              "SECTIONID": "TOYOTA",
              "SECTIONTITLE": "",
              "DATALOADED": 1,
              "ACCTNO": "B9658",
              "SUBRPTID": "TW35",
              "SEQNO": 11,
              "SUBRPTTITLRFROMREPACCTS": null,
              "FORMATTYPE": 341,
              "CHANGECALC": 352,
              "ACCTYR": 2023,
              "ACCTMO": 2,
              "ACCTNAME": "Total Customer Labor GP",
              "UNITS_FLAG": "81",
              "CURRMOVALUE": "198310.0000",
              "PREVMOVALUE": "172373.0000",
              "MONTHCHANGE": "15.0400",
              "CURRYRVALUE": "413978.0000",
              "PREVYRVALUE": "348174.0000",
              "YEARCHANGE": "18.8900"
            },
            {
              "SECTIONID": "TOYOTA",
              "SECTIONTITLE": "",
              "DATALOADED": 1,
              "ACCTNO": "B9635",
              "SUBRPTID": "TW35",
              "SEQNO": 12,
              "SUBRPTTITLRFROMREPACCTS": null,
              "FORMATTYPE": 341,
              "CHANGECALC": 351,
              "ACCTYR": 2023,
              "ACCTMO": 2,
              "ACCTNAME": "Cust Mech Labor GP",
              "UNITS_FLAG": "82",
              "CURRMOVALUE": "67.6510",
              "PREVMOVALUE": "65.1960",
              "MONTHCHANGE": "0.0000",
              "CURRYRVALUE": "68.7720",
              "PREVYRVALUE": "65.4490",
              "YEARCHANGE": "0.0000"
            },
            {
              "SECTIONID": "TOYOTA",
              "SECTIONTITLE": "",
              "DATALOADED": 1,
              "ACCTNO": "B9656",
              "SUBRPTID": "TW35",
              "SEQNO": 13,
              "SUBRPTTITLRFROMREPACCTS": null,
              "FORMATTYPE": 341,
              "CHANGECALC": 352,
              "ACCTYR": 2023,
              "ACCTMO": 2,
              "ACCTNAME": "Customer Pay Labor ROs",
              "UNITS_FLAG": "84",
              "CURRMOVALUE": "1671.0000",
              "PREVMOVALUE": "1710.0000",
              "MONTHCHANGE": "-2.2800",
              "CURRYRVALUE": "3568.0000",
              "PREVYRVALUE": "3569.0000",
              "YEARCHANGE": "-0.0200"
            },
            {
              "SECTIONID": "TOYOTA",
              "SECTIONTITLE": "",
              "DATALOADED": 1,
              "ACCTNO": "B9636",
              "SUBRPTID": "TW35",
              "SEQNO": 15,
              "SUBRPTTITLRFROMREPACCTS": null,
              "FORMATTYPE": 341,
              "CHANGECALC": 352,
              "ACCTYR": 2023,
              "ACCTMO": 2,
              "ACCTNAME": "Cust Mech Labor Rate ",
              "UNITS_FLAG": "81",
              "CURRMOVALUE": "154.0000",
              "PREVMOVALUE": "170.0000",
              "MONTHCHANGE": "-9.4100",
              "CURRYRVALUE": "154.0000",
              "PREVYRVALUE": "170.0000",
              "YEARCHANGE": "-9.4100"
            },
            {
              "SECTIONID": "TOYOTA",
              "SECTIONTITLE": "",
              "DATALOADED": 1,
              "ACCTNO": "B9637",
              "SUBRPTID": "TW35",
              "SEQNO": 16,
              "SUBRPTTITLRFROMREPACCTS": null,
              "FORMATTYPE": 343,
              "CHANGECALC": 351,
              "ACCTYR": 2023,
              "ACCTMO": 2,
              "ACCTNAME": "Cust Mech Labor % of Ttl Labor",
              "UNITS_FLAG": "82",
              "CURRMOVALUE": "86.8900",
              "PREVMOVALUE": "82.7770",
              "MONTHCHANGE": "0.0000",
              "CURRYRVALUE": "84.4540",
              "PREVYRVALUE": "81.9860",
              "YEARCHANGE": "0.0000"
            },
            {
              "SECTIONID": "TOYOTA",
              "SECTIONTITLE": "",
              "DATALOADED": 1,
              "ACCTNO": "4710S",
              "SUBRPTID": "TW35",
              "SEQNO": 17,
              "SUBRPTTITLRFROMREPACCTS": null,
              "FORMATTYPE": 341,
              "CHANGECALC": 352,
              "ACCTYR": 2023,
              "ACCTMO": 2,
              "ACCTNAME": "P & A Body Shop Sales",
              "UNITS_FLAG": "81",
              "CURRMOVALUE": "53065.0000",
              "PREVMOVALUE": "44759.0000",
              "MONTHCHANGE": "18.5500",
              "CURRYRVALUE": "108865.0000",
              "PREVYRVALUE": "84564.0000",
              "YEARCHANGE": "28.7300"
            },
            {
              "SECTIONID": "TOYOTA",
              "SECTIONTITLE": "",
              "DATALOADED": 1,
              "ACCTNO": "4710T",
              "SUBRPTID": "TW35",
              "SEQNO": 18,
              "SUBRPTTITLRFROMREPACCTS": null,
              "FORMATTYPE": 341,
              "CHANGECALC": 352,
              "ACCTYR": 2023,
              "ACCTMO": 2,
              "ACCTNAME": "Body Shop P & A  GP",
              "UNITS_FLAG": "81",
              "CURRMOVALUE": "18842.0000",
              "PREVMOVALUE": "16340.0000",
              "MONTHCHANGE": "15.3100",
              "CURRYRVALUE": "39396.0000",
              "PREVYRVALUE": "31034.0000",
              "YEARCHANGE": "26.9400"
            },
            {
              "SECTIONID": "TOYOTA",
              "SECTIONTITLE": "",
              "DATALOADED": 1,
              "ACCTNO": "B8049",
              "SUBRPTID": "TW35",
              "SEQNO": 19,
              "SUBRPTTITLRFROMREPACCTS": null,
              "FORMATTYPE": 341,
              "CHANGECALC": 351,
              "ACCTYR": 2023,
              "ACCTMO": 2,
              "ACCTNAME": "Body Shop P & A  GP%",
              "UNITS_FLAG": "82",
              "CURRMOVALUE": "35.5070",
              "PREVMOVALUE": "36.5070",
              "MONTHCHANGE": "0.0000",
              "CURRYRVALUE": "36.1880",
              "PREVYRVALUE": "36.6990",
              "YEARCHANGE": "0.0000"
            },
            {
              "SECTIONID": "TOYOTA",
              "SECTIONTITLE": "",
              "DATALOADED": 1,
              "ACCTNO": "4710A",
              "SUBRPTID": "TW35",
              "SEQNO": 20,
              "SUBRPTTITLRFROMREPACCTS": null,
              "FORMATTYPE": 341,
              "CHANGECALC": 352,
              "ACCTYR": 2023,
              "ACCTMO": 2,
              "ACCTNAME": "Body Shop P & A  Tickets",
              "UNITS_FLAG": "84",
              "CURRMOVALUE": "42.0000",
              "PREVMOVALUE": "31.0000",
              "MONTHCHANGE": "35.4800",
              "CURRYRVALUE": "78.0000",
              "PREVYRVALUE": "60.0000",
              "YEARCHANGE": "30.0000"
            },
            {
              "SECTIONID": "TOYOTA",
              "SECTIONTITLE": "",
              "DATALOADED": 1,
              "ACCTNO": "B9639",
              "SUBRPTID": "TW35",
              "SEQNO": 21,
              "SUBRPTTITLRFROMREPACCTS": null,
              "FORMATTYPE": 343,
              "CHANGECALC": 351,
              "ACCTYR": 2023,
              "ACCTMO": 2,
              "ACCTNAME": "Body Shop Sales % of Total",
              "UNITS_FLAG": "82",
              "CURRMOVALUE": "8.6100",
              "PREVMOVALUE": "6.3340",
              "MONTHCHANGE": "0.0000",
              "CURRYRVALUE": "8.4250",
              "PREVYRVALUE": "6.2100",
              "YEARCHANGE": "0.0000"
            },
            {
              "SECTIONID": "TOYOTA",
              "SECTIONTITLE": "",
              "DATALOADED": 1,
              "ACCTNO": "4720S",
              "SUBRPTID": "TW35",
              "SEQNO": 22,
              "SUBRPTTITLRFROMREPACCTS": null,
              "FORMATTYPE": 341,
              "CHANGECALC": 352,
              "ACCTYR": 2023,
              "ACCTMO": 2,
              "ACCTNAME": "P & A Warranty Sales",
              "UNITS_FLAG": "81",
              "CURRMOVALUE": "19056.0000",
              "PREVMOVALUE": "28097.0000",
              "MONTHCHANGE": "-32.1700",
              "CURRYRVALUE": "52484.0000",
              "PREVYRVALUE": "61006.0000",
              "YEARCHANGE": "-13.9600"
            },
            {
              "SECTIONID": "TOYOTA",
              "SECTIONTITLE": "",
              "DATALOADED": 1,
              "ACCTNO": "4720T",
              "SUBRPTID": "TW35",
              "SEQNO": 23,
              "SUBRPTTITLRFROMREPACCTS": null,
              "FORMATTYPE": 341,
              "CHANGECALC": 352,
              "ACCTYR": 2023,
              "ACCTMO": 2,
              "ACCTNAME": "Warranty P & A  GP",
              "UNITS_FLAG": "81",
              "CURRMOVALUE": "5915.0000",
              "PREVMOVALUE": "9442.0000",
              "MONTHCHANGE": "-37.3500",
              "CURRYRVALUE": "17263.0000",
              "PREVYRVALUE": "21390.0000",
              "YEARCHANGE": "-19.2900"
            },
            {
              "SECTIONID": "TOYOTA",
              "SECTIONTITLE": "",
              "DATALOADED": 1,
              "ACCTNO": "B8050",
              "SUBRPTID": "TW35",
              "SEQNO": 24,
              "SUBRPTTITLRFROMREPACCTS": null,
              "FORMATTYPE": 341,
              "CHANGECALC": 351,
              "ACCTYR": 2023,
              "ACCTMO": 2,
              "ACCTNAME": "Warranty P & A  GP%",
              "UNITS_FLAG": "82",
              "CURRMOVALUE": "31.0980",
              "PREVMOVALUE": "33.7230",
              "MONTHCHANGE": "0.0000",
              "CURRYRVALUE": "32.9260",
              "PREVYRVALUE": "35.1250",
              "YEARCHANGE": "0.0000"
            },
            {
              "SECTIONID": "TOYOTA",
              "SECTIONTITLE": "",
              "DATALOADED": 1,
              "ACCTNO": "4720A",
              "SUBRPTID": "TW35",
              "SEQNO": 25,
              "SUBRPTTITLRFROMREPACCTS": null,
              "FORMATTYPE": 341,
              "CHANGECALC": 352,
              "ACCTYR": 2023,
              "ACCTMO": 2,
              "ACCTNAME": "P & A Warranty ROs",
              "UNITS_FLAG": "84",
              "CURRMOVALUE": "49.0000",
              "PREVMOVALUE": "97.0000",
              "MONTHCHANGE": "-49.4800",
              "CURRYRVALUE": "120.0000",
              "PREVYRVALUE": "200.0000",
              "YEARCHANGE": "-40.0000"
            },
            {
              "SECTIONID": "TOYOTA",
              "SECTIONTITLE": "",
              "DATALOADED": 1,
              "ACCTNO": "B9641",
              "SUBRPTID": "TW35",
              "SEQNO": 26,
              "SUBRPTTITLRFROMREPACCTS": null,
              "FORMATTYPE": 342,
              "CHANGECALC": 351,
              "ACCTYR": 2023,
              "ACCTMO": 2,
              "ACCTNAME": "Warranty Sales % of Total",
              "UNITS_FLAG": "82",
              "CURRMOVALUE": "3.0920",
              "PREVMOVALUE": "3.9760",
              "MONTHCHANGE": "0.0000",
              "CURRYRVALUE": "4.0620",
              "PREVYRVALUE": "4.4800",
              "YEARCHANGE": "0.0000"
            },
            {
              "SECTIONID": "TOYOTA",
              "SECTIONTITLE": "",
              "DATALOADED": 1,
              "ACCTNO": "4420S",
              "SUBRPTID": "TW35",
              "SEQNO": 27,
              "SUBRPTTITLRFROMREPACCTS": null,
              "FORMATTYPE": 341,
              "CHANGECALC": 352,
              "ACCTYR": 2023,
              "ACCTMO": 2,
              "ACCTNAME": "Warranty Labor Sales",
              "UNITS_FLAG": "81",
              "CURRMOVALUE": "13885.0000",
              "PREVMOVALUE": "32579.0000",
              "MONTHCHANGE": "-57.3800",
              "CURRYRVALUE": "42086.0000",
              "PREVYRVALUE": "67363.0000",
              "YEARCHANGE": "-37.5200"
            },
            {
              "SECTIONID": "TOYOTA",
              "SECTIONTITLE": "",
              "DATALOADED": 1,
              "ACCTNO": "4420T",
              "SUBRPTID": "TW35",
              "SEQNO": 28,
              "SUBRPTTITLRFROMREPACCTS": null,
              "FORMATTYPE": 341,
              "CHANGECALC": 352,
              "ACCTYR": 2023,
              "ACCTMO": 2,
              "ACCTNAME": "Warranty Labor  GP",
              "UNITS_FLAG": "81",
              "CURRMOVALUE": "10160.0000",
              "PREVMOVALUE": "25623.0000",
              "MONTHCHANGE": "-60.3400",
              "CURRYRVALUE": "29230.0000",
              "PREVYRVALUE": "52460.0000",
              "YEARCHANGE": "-44.2800"
            },
            {
              "SECTIONID": "TOYOTA",
              "SECTIONTITLE": "",
              "DATALOADED": 1,
              "ACCTNO": "B9642",
              "SUBRPTID": "TW35",
              "SEQNO": 29,
              "SUBRPTTITLRFROMREPACCTS": null,
              "FORMATTYPE": 341,
              "CHANGECALC": 351,
              "ACCTYR": 2023,
              "ACCTMO": 2,
              "ACCTNAME": "Warranty Labor GP",
              "UNITS_FLAG": "82",
              "CURRMOVALUE": "73.1720",
              "PREVMOVALUE": "78.6490",
              "MONTHCHANGE": "0.0000",
              "CURRYRVALUE": "69.4530",
              "PREVYRVALUE": "77.8770",
              "YEARCHANGE": "0.0000"
            },
            {
              "SECTIONID": "TOYOTA",
              "SECTIONTITLE": "",
              "DATALOADED": 1,
              "ACCTNO": "4420A",
              "SUBRPTID": "TW35",
              "SEQNO": 30,
              "SUBRPTTITLRFROMREPACCTS": null,
              "FORMATTYPE": 341,
              "CHANGECALC": 352,
              "ACCTYR": 2023,
              "ACCTMO": 2,
              "ACCTNAME": "Warranty Labor ROs",
              "UNITS_FLAG": "84",
              "CURRMOVALUE": "81.0000",
              "PREVMOVALUE": "179.0000",
              "MONTHCHANGE": "-54.7400",
              "CURRYRVALUE": "189.0000",
              "PREVYRVALUE": "361.0000",
              "YEARCHANGE": "-47.6400"
            },
            {
              "SECTIONID": "TOYOTA",
              "SECTIONTITLE": "",
              "DATALOADED": 1,
              "ACCTNO": "B9643",
              "SUBRPTID": "TW35",
              "SEQNO": 31,
              "SUBRPTTITLRFROMREPACCTS": null,
              "FORMATTYPE": 341,
              "CHANGECALC": 352,
              "ACCTYR": 2023,
              "ACCTMO": 2,
              "ACCTNAME": "Warranty Labor Rate",
              "UNITS_FLAG": "81",
              "CURRMOVALUE": "151.4500",
              "PREVMOVALUE": "152.0100",
              "MONTHCHANGE": "-0.3600",
              "CURRYRVALUE": "151.4500",
              "PREVYRVALUE": "152.0100",
              "YEARCHANGE": "-0.3600"
            },
            {
              "SECTIONID": "TOYOTA",
              "SECTIONTITLE": "",
              "DATALOADED": 1,
              "ACCTNO": "B9644",
              "SUBRPTID": "TW35",
              "SEQNO": 32,
              "SUBRPTTITLRFROMREPACCTS": null,
              "FORMATTYPE": 343,
              "CHANGECALC": 351,
              "ACCTYR": 2023,
              "ACCTMO": 2,
              "ACCTNAME": "Warranty % of Total Mech Labor",
              "UNITS_FLAG": "82",
              "CURRMOVALUE": "4.1160",
              "PREVMOVALUE": "10.2000",
              "MONTHCHANGE": "0.0000",
              "CURRYRVALUE": "5.9050",
              "PREVYRVALUE": "10.3820",
              "YEARCHANGE": "0.0000"
            },
            {
              "SECTIONID": "TOYOTA",
              "SECTIONTITLE": "",
              "DATALOADED": 1,
              "ACCTNO": "4730S",
              "SUBRPTID": "TW35",
              "SEQNO": 33,
              "SUBRPTTITLRFROMREPACCTS": null,
              "FORMATTYPE": 341,
              "CHANGECALC": 352,
              "ACCTYR": 2023,
              "ACCTMO": 2,
              "ACCTNAME": "Internal P & A  Sales",
              "UNITS_FLAG": "81",
              "CURRMOVALUE": "15726.0000",
              "PREVMOVALUE": "21084.0000",
              "MONTHCHANGE": "-25.4100",
              "CURRYRVALUE": "33188.0000",
              "PREVYRVALUE": "39514.0000",
              "YEARCHANGE": "-16.0000"
            },
            {
              "SECTIONID": "TOYOTA",
              "SECTIONTITLE": "",
              "DATALOADED": 1,
              "ACCTNO": "4730T",
              "SUBRPTID": "TW35",
              "SEQNO": 34,
              "SUBRPTTITLRFROMREPACCTS": null,
              "FORMATTYPE": 341,
              "CHANGECALC": 352,
              "ACCTYR": 2023,
              "ACCTMO": 2,
              "ACCTNAME": "Internal P & A  GP",
              "UNITS_FLAG": "81",
              "CURRMOVALUE": "2143.0000",
              "PREVMOVALUE": "3132.0000",
              "MONTHCHANGE": "-31.5700",
              "CURRYRVALUE": "4552.0000",
              "PREVYRVALUE": "5734.0000",
              "YEARCHANGE": "-20.6100"
            },
            {
              "SECTIONID": "TOYOTA",
              "SECTIONTITLE": "",
              "DATALOADED": 1,
              "ACCTNO": "B9645",
              "SUBRPTID": "TW35",
              "SEQNO": 35,
              "SUBRPTTITLRFROMREPACCTS": null,
              "FORMATTYPE": 341,
              "CHANGECALC": 351,
              "ACCTYR": 2023,
              "ACCTMO": 2,
              "ACCTNAME": "P & A Internal GP % of Sales",
              "UNITS_FLAG": "82",
              "CURRMOVALUE": "15.1380",
              "PREVMOVALUE": "13.9480",
              "MONTHCHANGE": "0.0000",
              "CURRYRVALUE": "15.3400",
              "PREVYRVALUE": "13.8080",
              "YEARCHANGE": "0.0000"
            },
            {
              "SECTIONID": "TOYOTA",
              "SECTIONTITLE": "",
              "DATALOADED": 1,
              "ACCTNO": "4730A",
              "SUBRPTID": "TW35",
              "SEQNO": 36,
              "SUBRPTTITLRFROMREPACCTS": null,
              "FORMATTYPE": 341,
              "CHANGECALC": 352,
              "ACCTYR": 2023,
              "ACCTMO": 2,
              "ACCTNAME": "Intenal P & A  Tickets",
              "UNITS_FLAG": "84",
              "CURRMOVALUE": "265.0000",
              "PREVMOVALUE": "304.0000",
              "MONTHCHANGE": "-12.8200",
              "CURRYRVALUE": "614.0000",
              "PREVYRVALUE": "614.0000",
              "YEARCHANGE": "0.0000"
            },
            {
              "SECTIONID": "TOYOTA",
              "SECTIONTITLE": "",
              "DATALOADED": 1,
              "ACCTNO": "B9646",
              "SUBRPTID": "TW35",
              "SEQNO": 37,
              "SUBRPTTITLRFROMREPACCTS": null,
              "FORMATTYPE": 342,
              "CHANGECALC": 351,
              "ACCTYR": 2023,
              "ACCTMO": 2,
              "ACCTNAME": "Internal Sales % of Total",
              "UNITS_FLAG": "82",
              "CURRMOVALUE": "2.5520",
              "PREVMOVALUE": "2.9840",
              "MONTHCHANGE": "0.0000",
              "CURRYRVALUE": "2.5680",
              "PREVYRVALUE": "2.9020",
              "YEARCHANGE": "0.0000"
            },
            {
              "SECTIONID": "TOYOTA",
              "SECTIONTITLE": "",
              "DATALOADED": 1,
              "ACCTNO": "4430S",
              "SUBRPTID": "TW35",
              "SEQNO": 38,
              "SUBRPTTITLRFROMREPACCTS": null,
              "FORMATTYPE": 341,
              "CHANGECALC": 352,
              "ACCTYR": 2023,
              "ACCTMO": 2,
              "ACCTNAME": "Internal Labor Sales",
              "UNITS_FLAG": "81",
              "CURRMOVALUE": "20144.0000",
              "PREVMOVALUE": "12262.0000",
              "MONTHCHANGE": "64.2700",
              "CURRYRVALUE": "43439.0000",
              "PREVYRVALUE": "29772.0000",
              "YEARCHANGE": "45.9000"
            },
            {
              "SECTIONID": "TOYOTA",
              "SECTIONTITLE": "",
              "DATALOADED": 1,
              "ACCTNO": "4430T",
              "SUBRPTID": "TW35",
              "SEQNO": 39,
              "SUBRPTTITLRFROMREPACCTS": null,
              "FORMATTYPE": 341,
              "CHANGECALC": 352,
              "ACCTYR": 2023,
              "ACCTMO": 2,
              "ACCTNAME": "Internal Labor  GP",
              "UNITS_FLAG": "81",
              "CURRMOVALUE": "10376.0000",
              "PREVMOVALUE": "8513.0000",
              "MONTHCHANGE": "21.8800",
              "CURRYRVALUE": "23341.0000",
              "PREVYRVALUE": "20638.0000",
              "YEARCHANGE": "13.0900"
            },
            {
              "SECTIONID": "TOYOTA",
              "SECTIONTITLE": "",
              "DATALOADED": 1,
              "ACCTNO": "B9647",
              "SUBRPTID": "TW35",
              "SEQNO": 40,
              "SUBRPTTITLRFROMREPACCTS": null,
              "FORMATTYPE": 341,
              "CHANGECALC": 351,
              "ACCTYR": 2023,
              "ACCTMO": 2,
              "ACCTNAME": "Internal Labor GP",
              "UNITS_FLAG": "82",
              "CURRMOVALUE": "51.5090",
              "PREVMOVALUE": "69.4260",
              "MONTHCHANGE": "0.0000",
              "CURRYRVALUE": "53.7330",
              "PREVYRVALUE": "69.3200",
              "YEARCHANGE": "0.0000"
            },
            {
              "SECTIONID": "TOYOTA",
              "SECTIONTITLE": "",
              "DATALOADED": 1,
              "ACCTNO": "4430A",
              "SUBRPTID": "TW35",
              "SEQNO": 41,
              "SUBRPTTITLRFROMREPACCTS": null,
              "FORMATTYPE": 341,
              "CHANGECALC": 352,
              "ACCTYR": 2023,
              "ACCTMO": 2,
              "ACCTNAME": "Internal RO's",
              "UNITS_FLAG": "84",
              "CURRMOVALUE": "251.0000",
              "PREVMOVALUE": "53.0000",
              "MONTHCHANGE": "373.5800",
              "CURRYRVALUE": "564.0000",
              "PREVYRVALUE": "125.0000",
              "YEARCHANGE": "351.2000"
            },
            {
              "SECTIONID": "TOYOTA",
              "SECTIONTITLE": "",
              "DATALOADED": 1,
              "ACCTNO": "B9648",
              "SUBRPTID": "TW35",
              "SEQNO": 42,
              "SUBRPTTITLRFROMREPACCTS": null,
              "FORMATTYPE": 341,
              "CHANGECALC": 352,
              "ACCTYR": 2023,
              "ACCTMO": 2,
              "ACCTNAME": "Internal Labor Rate",
              "UNITS_FLAG": "81",
              "CURRMOVALUE": "60.0000",
              "PREVMOVALUE": "60.0000",
              "MONTHCHANGE": "0.0000",
              "CURRYRVALUE": "60.0000",
              "PREVYRVALUE": "60.0000",
              "YEARCHANGE": "0.0000"
            },
            {
              "SECTIONID": "TOYOTA",
              "SECTIONTITLE": "",
              "DATALOADED": 1,
              "ACCTNO": "B9649",
              "SUBRPTID": "TW35",
              "SEQNO": 43,
              "SUBRPTTITLRFROMREPACCTS": null,
              "FORMATTYPE": 343,
              "CHANGECALC": 351,
              "ACCTYR": 2023,
              "ACCTMO": 2,
              "ACCTNAME": "Internal Labor % of Total",
              "UNITS_FLAG": "82",
              "CURRMOVALUE": "5.9740",
              "PREVMOVALUE": "3.8490",
              "MONTHCHANGE": "0.0000",
              "CURRYRVALUE": "6.0970",
              "PREVYRVALUE": "4.5950",
              "YEARCHANGE": "0.0000"
            },
            {
              "SECTIONID": "TOYOTA",
              "SECTIONTITLE": "",
              "DATALOADED": 1,
              "ACCTNO": "4440S",
              "SUBRPTID": "TW35",
              "SEQNO": 44,
              "SUBRPTTITLRFROMREPACCTS": null,
              "FORMATTYPE": 343,
              "CHANGECALC": 352,
              "ACCTYR": 2023,
              "ACCTMO": 2,
              "ACCTNAME": "Pre-Del. Svc Labor Sales",
              "UNITS_FLAG": "81",
              "CURRMOVALUE": "10033.0000",
              "PREVMOVALUE": "10003.0000",
              "MONTHCHANGE": "0.2900",
              "CURRYRVALUE": "24945.0000",
              "PREVYRVALUE": "19412.0000",
              "YEARCHANGE": "28.5000"
            },
            {
              "SECTIONID": "TOYOTA",
              "SECTIONTITLE": "",
              "DATALOADED": 1,
              "ACCTNO": "4740S",
              "SUBRPTID": "TW35",
              "SEQNO": 45,
              "SUBRPTTITLRFROMREPACCTS": null,
              "FORMATTYPE": 341,
              "CHANGECALC": 352,
              "ACCTYR": 2023,
              "ACCTMO": 2,
              "ACCTNAME": "P & A Counter Retail Sales",
              "UNITS_FLAG": "81",
              "CURRMOVALUE": "57913.0000",
              "PREVMOVALUE": "61754.0000",
              "MONTHCHANGE": "-6.2100",
              "CURRYRVALUE": "113558.0000",
              "PREVYRVALUE": "130647.0000",
              "YEARCHANGE": "-13.0800"
            },
            {
              "SECTIONID": "TOYOTA",
              "SECTIONTITLE": "",
              "DATALOADED": 1,
              "ACCTNO": "4740T",
              "SUBRPTID": "TW35",
              "SEQNO": 46,
              "SUBRPTTITLRFROMREPACCTS": null,
              "FORMATTYPE": 341,
              "CHANGECALC": 352,
              "ACCTYR": 2023,
              "ACCTMO": 2,
              "ACCTNAME": "P & A Retail  GP",
              "UNITS_FLAG": "81",
              "CURRMOVALUE": "16299.0000",
              "PREVMOVALUE": "18057.0000",
              "MONTHCHANGE": "-9.7300",
              "CURRYRVALUE": "32337.0000",
              "PREVYRVALUE": "38909.0000",
              "YEARCHANGE": "-16.8900"
            },
            {
              "SECTIONID": "TOYOTA",
              "SECTIONTITLE": "",
              "DATALOADED": 1,
              "ACCTNO": "B8052",
              "SUBRPTID": "TW35",
              "SEQNO": 47,
              "SUBRPTTITLRFROMREPACCTS": null,
              "FORMATTYPE": 341,
              "CHANGECALC": 351,
              "ACCTYR": 2023,
              "ACCTMO": 2,
              "ACCTNAME": "P & A Counter Retail GP % of Sales",
              "UNITS_FLAG": "82",
              "CURRMOVALUE": "28.0340",
              "PREVMOVALUE": "25.6750",
              "MONTHCHANGE": "0.0000",
              "CURRYRVALUE": "28.2390",
              "PREVYRVALUE": "26.5950",
              "YEARCHANGE": "0.0000"
            },
            {
              "SECTIONID": "TOYOTA",
              "SECTIONTITLE": "",
              "DATALOADED": 1,
              "ACCTNO": "4740A",
              "SUBRPTID": "TW35",
              "SEQNO": 48,
              "SUBRPTTITLRFROMREPACCTS": null,
              "FORMATTYPE": 341,
              "CHANGECALC": 352,
              "ACCTYR": 2023,
              "ACCTMO": 2,
              "ACCTNAME": "P & A Retail Tickets",
              "UNITS_FLAG": "84",
              "CURRMOVALUE": "754.0000",
              "PREVMOVALUE": "722.0000",
              "MONTHCHANGE": "4.4300",
              "CURRYRVALUE": "1517.0000",
              "PREVYRVALUE": "1492.0000",
              "YEARCHANGE": "1.6700"
            },
            {
              "SECTIONID": "TOYOTA",
              "SECTIONTITLE": "",
              "DATALOADED": 1,
              "ACCTNO": "B9650",
              "SUBRPTID": "TW35",
              "SEQNO": 49,
              "SUBRPTTITLRFROMREPACCTS": null,
              "FORMATTYPE": 343,
              "CHANGECALC": 351,
              "ACCTYR": 2023,
              "ACCTMO": 2,
              "ACCTNAME": "Retail Sales % of Total",
              "UNITS_FLAG": "82",
              "CURRMOVALUE": "9.3970",
              "PREVMOVALUE": "8.7390",
              "MONTHCHANGE": "0.0000",
              "CURRYRVALUE": "8.7880",
              "PREVYRVALUE": "9.5940",
              "YEARCHANGE": "0.0000"
            },
            {
              "SECTIONID": "TOYOTA",
              "SECTIONTITLE": "",
              "DATALOADED": 1,
              "ACCTNO": "4750S",
              "SUBRPTID": "TW35",
              "SEQNO": 50,
              "SUBRPTTITLRFROMREPACCTS": null,
              "FORMATTYPE": 341,
              "CHANGECALC": 352,
              "ACCTYR": 2023,
              "ACCTMO": 2,
              "ACCTNAME": "P & A Wholesale Sales",
              "UNITS_FLAG": "81",
              "CURRMOVALUE": "315443.0000",
              "PREVMOVALUE": "395346.0000",
              "MONTHCHANGE": "-20.2100",
              "CURRYRVALUE": "651109.0000",
              "PREVYRVALUE": "747725.0000",
              "YEARCHANGE": "-12.9200"
            },
            {
              "SECTIONID": "TOYOTA",
              "SECTIONTITLE": "",
              "DATALOADED": 1,
              "ACCTNO": "4750T",
              "SUBRPTID": "TW35",
              "SEQNO": 51,
              "SUBRPTTITLRFROMREPACCTS": null,
              "FORMATTYPE": 341,
              "CHANGECALC": 352,
              "ACCTYR": 2023,
              "ACCTMO": 2,
              "ACCTNAME": "GP-P&A Wholesale",
              "UNITS_FLAG": "81",
              "CURRMOVALUE": "54160.0000",
              "PREVMOVALUE": "54805.0000",
              "MONTHCHANGE": "-1.1700",
              "CURRYRVALUE": "113894.0000",
              "PREVYRVALUE": "107606.0000",
              "YEARCHANGE": "5.8400"
            },
            {
              "SECTIONID": "TOYOTA",
              "SECTIONTITLE": "",
              "DATALOADED": 1,
              "ACCTNO": "B9651",
              "SUBRPTID": "TW35",
              "SEQNO": 52,
              "SUBRPTTITLRFROMREPACCTS": null,
              "FORMATTYPE": 341,
              "CHANGECALC": 351,
              "ACCTYR": 2023,
              "ACCTMO": 2,
              "ACCTNAME": "P & A Wholesale GP % of Sales",
              "UNITS_FLAG": "82",
              "CURRMOVALUE": "17.1930",
              "PREVMOVALUE": "13.8840",
              "MONTHCHANGE": "0.0000",
              "CURRYRVALUE": "17.5150",
              "PREVYRVALUE": "14.4140",
              "YEARCHANGE": "0.0000"
            },
            {
              "SECTIONID": "TOYOTA",
              "SECTIONTITLE": "",
              "DATALOADED": 1,
              "ACCTNO": "4750A",
              "SUBRPTID": "TW35",
              "SEQNO": 53,
              "SUBRPTTITLRFROMREPACCTS": null,
              "FORMATTYPE": 341,
              "CHANGECALC": 352,
              "ACCTYR": 2023,
              "ACCTMO": 2,
              "ACCTNAME": "P & A Wholesale Tickets",
              "UNITS_FLAG": "84",
              "CURRMOVALUE": "1227.0000",
              "PREVMOVALUE": "1519.0000",
              "MONTHCHANGE": "-19.2200",
              "CURRYRVALUE": "2462.0000",
              "PREVYRVALUE": "3073.0000",
              "YEARCHANGE": "-19.8800"
            },
            {
              "SECTIONID": "TOYOTA",
              "SECTIONTITLE": "",
              "DATALOADED": 1,
              "ACCTNO": "B9652",
              "SUBRPTID": "TW35",
              "SEQNO": 54,
              "SUBRPTTITLRFROMREPACCTS": null,
              "FORMATTYPE": 343,
              "CHANGECALC": 351,
              "ACCTYR": 2023,
              "ACCTMO": 2,
              "ACCTNAME": "Wholesale Sales % of Total",
              "UNITS_FLAG": "82",
              "CURRMOVALUE": "51.1830",
              "PREVMOVALUE": "55.9470",
              "MONTHCHANGE": "0.0000",
              "CURRYRVALUE": "50.3870",
              "PREVYRVALUE": "54.9070",
              "YEARCHANGE": "0.0000"
            },
            {
              "SECTIONID": "TOYOTA",
              "SECTIONTITLE": "",
              "DATALOADED": 1,
              "ACCTNO": "6602T",
              "SUBRPTID": "TW35",
              "SEQNO": 55,
              "SUBRPTTITLRFROMREPACCTS": null,
              "FORMATTYPE": 341,
              "CHANGECALC": 352,
              "ACCTYR": 2023,
              "ACCTMO": 2,
              "ACCTNAME": "Total Toyota & Lex Parts-Sls",
              "UNITS_FLAG": "81",
              "CURRMOVALUE": "616308.0000",
              "PREVMOVALUE": "706641.0000",
              "MONTHCHANGE": "-12.7800",
              "CURRYRVALUE": "1292220.0000",
              "PREVYRVALUE": "1361808.0000",
              "YEARCHANGE": "-5.1000"
            },
            {
              "SECTIONID": "TOYOTA",
              "SECTIONTITLE": "",
              "DATALOADED": 1,
              "ACCTNO": "B9655",
              "SUBRPTID": "TW35",
              "SEQNO": 56,
              "SUBRPTTITLRFROMREPACCTS": null,
              "FORMATTYPE": 341,
              "CHANGECALC": 352,
              "ACCTYR": 2023,
              "ACCTMO": 2,
              "ACCTNAME": "Total P & A Mech. Sales less Internals & Warr.",
              "UNITS_FLAG": "81",
              "CURRMOVALUE": "582802.0000",
              "PREVMOVALUE": "672951.0000",
              "MONTHCHANGE": "-13.3900",
              "CURRYRVALUE": "1210904.0000",
              "PREVYRVALUE": "1292662.0000",
              "YEARCHANGE": "-6.3200"
            },
            {
              "SECTIONID": "TOYOTA",
              "SECTIONTITLE": "",
              "DATALOADED": 1,
              "ACCTNO": "6603T",
              "SUBRPTID": "TW35",
              "SEQNO": 57,
              "SUBRPTTITLRFROMREPACCTS": null,
              "FORMATTYPE": 341,
              "CHANGECALC": 352,
              "ACCTYR": 2023,
              "ACCTMO": 2,
              "ACCTNAME": "Total P & A  GP",
              "UNITS_FLAG": "81",
              "CURRMOVALUE": "141569.0000",
              "PREVMOVALUE": "153513.0000",
              "MONTHCHANGE": "-7.7800",
              "CURRYRVALUE": "299978.0000",
              "PREVYRVALUE": "302641.0000",
              "YEARCHANGE": "-0.8700"
            },
            {
              "SECTIONID": "TOYOTA",
              "SECTIONTITLE": "",
              "DATALOADED": 1,
              "ACCTNO": "B9653",
              "SUBRPTID": "TW35",
              "SEQNO": 58,
              "SUBRPTTITLRFROMREPACCTS": null,
              "FORMATTYPE": 341,
              "CHANGECALC": 351,
              "ACCTYR": 2023,
              "ACCTMO": 2,
              "ACCTNAME": "Total Toyota P & A GP % of Sales",
              "UNITS_FLAG": "82",
              "CURRMOVALUE": "22.9480",
              "PREVMOVALUE": "21.5070",
              "MONTHCHANGE": "0.0000",
              "CURRYRVALUE": "23.1780",
              "PREVYRVALUE": "22.0320",
              "YEARCHANGE": "0.0000"
            },
            {
              "SECTIONID": "TOYOTA",
              "SECTIONTITLE": "",
              "DATALOADED": 1,
              "ACCTNO": "6601T",
              "SUBRPTID": "TW35",
              "SEQNO": 59,
              "SUBRPTTITLRFROMREPACCTS": null,
              "FORMATTYPE": 341,
              "CHANGECALC": 352,
              "ACCTYR": 2023,
              "ACCTMO": 2,
              "ACCTNAME": "Total P & A  Tickets",
              "UNITS_FLAG": "84",
              "CURRMOVALUE": "3617.0000",
              "PREVMOVALUE": "3972.0000",
              "MONTHCHANGE": "-8.9300",
              "CURRYRVALUE": "7485.0000",
              "PREVYRVALUE": "8121.0000",
              "YEARCHANGE": "-7.8300"
            },
            {
              "SECTIONID": "TOYOTA",
              "SECTIONTITLE": "",
              "DATALOADED": 1,
              "ACCTNO": "B9630",
              "SUBRPTID": "TW35",
              "SEQNO": 60,
              "SUBRPTTITLRFROMREPACCTS": null,
              "FORMATTYPE": 341,
              "CHANGECALC": 352,
              "ACCTYR": 2023,
              "ACCTMO": 2,
              "ACCTNAME": "Total Toyota P & A (Less W & I) Sales",
              "UNITS_FLAG": "81",
              "CURRMOVALUE": "576185.0000",
              "PREVMOVALUE": "658891.0000",
              "MONTHCHANGE": "-12.5500",
              "CURRYRVALUE": "1191534.0000",
              "PREVYRVALUE": "1267389.0000",
              "YEARCHANGE": "-5.9800"
            },
            {
              "SECTIONID": "TOYOTA",
              "SECTIONTITLE": "",
              "DATALOADED": 1,
              "ACCTNO": "B9488",
              "SUBRPTID": "TW35",
              "SEQNO": 61,
              "SUBRPTTITLRFROMREPACCTS": null,
              "FORMATTYPE": 342,
              "CHANGECALC": 352,
              "ACCTYR": 2023,
              "ACCTMO": 2,
              "ACCTNAME": "Total Toyota P & A less Warr Sales",
              "UNITS_FLAG": "81",
              "CURRMOVALUE": "602596.0000",
              "PREVMOVALUE": "694035.0000",
              "MONTHCHANGE": "-13.1700",
              "CURRYRVALUE": "1253080.0000",
              "PREVYRVALUE": "1332176.0000",
              "YEARCHANGE": "-5.9300"
            },
            {
              "SECTIONID": "TOYOTA",
              "SECTIONTITLE": "",
              "DATALOADED": 1,
              "ACCTNO": "6402T",
              "SUBRPTID": "TW35",
              "SEQNO": 62,
              "SUBRPTTITLRFROMREPACCTS": null,
              "FORMATTYPE": 341,
              "CHANGECALC": 352,
              "ACCTYR": 2023,
              "ACCTMO": 2,
              "ACCTNAME": "Total Mech Labor Sales",
              "UNITS_FLAG": "81",
              "CURRMOVALUE": "337197.0000",
              "PREVMOVALUE": "318599.0000",
              "MONTHCHANGE": "5.8300",
              "CURRYRVALUE": "712431.0000",
              "PREVYRVALUE": "647892.0000",
              "YEARCHANGE": "9.9600"
            },
            {
              "SECTIONID": "TOYOTA",
              "SECTIONTITLE": "",
              "DATALOADED": 1,
              "ACCTNO": "6403T",
              "SUBRPTID": "TW35",
              "SEQNO": 63,
              "SUBRPTTITLRFROMREPACCTS": null,
              "FORMATTYPE": 341,
              "CHANGECALC": 352,
              "ACCTYR": 2023,
              "ACCTMO": 2,
              "ACCTNAME": "Total Labor GP",
              "UNITS_FLAG": "81",
              "CURRMOVALUE": "226913.0000",
              "PREVMOVALUE": "215937.0000",
              "MONTHCHANGE": "5.0800",
              "CURRYRVALUE": "486845.0000",
              "PREVYRVALUE": "440109.0000",
              "YEARCHANGE": "10.6100"
            },
            {
              "SECTIONID": "TOYOTA",
              "SECTIONTITLE": "",
              "DATALOADED": 1,
              "ACCTNO": "B9654",
              "SUBRPTID": "TW35",
              "SEQNO": 64,
              "SUBRPTTITLRFROMREPACCTS": null,
              "FORMATTYPE": 341,
              "CHANGECALC": 351,
              "ACCTYR": 2023,
              "ACCTMO": 2,
              "ACCTNAME": "Total Mech & Acc Labor GP ",
              "UNITS_FLAG": "82",
              "CURRMOVALUE": "67.2900",
              "PREVMOVALUE": "67.8180",
              "MONTHCHANGE": "0.0000",
              "CURRYRVALUE": "68.3320",
              "PREVYRVALUE": "67.9470",
              "YEARCHANGE": "0.0000"
            },
            {
              "SECTIONID": "TOYOTA",
              "SECTIONTITLE": "",
              "DATALOADED": 1,
              "ACCTNO": "6401T",
              "SUBRPTID": "TW35",
              "SEQNO": 65,
              "SUBRPTTITLRFROMREPACCTS": null,
              "FORMATTYPE": 341,
              "CHANGECALC": 352,
              "ACCTYR": 2023,
              "ACCTMO": 2,
              "ACCTNAME": "Total Mech Labor ROs",
              "UNITS_FLAG": "84",
              "CURRMOVALUE": "2059.0000",
              "PREVMOVALUE": "2000.0000",
              "MONTHCHANGE": "2.9500",
              "CURRYRVALUE": "4450.0000",
              "PREVYRVALUE": "4167.0000",
              "YEARCHANGE": "6.7900"
            }
          ]
        }
      ],
      "reportHeaderData": [
        {
          "COMPANY_CODE": "14",
          "COMPANY_NAME": "Toyota",
          "COMPANY": "14 - Toyota",
          "REGION_CODE": "110",
          "REGION": "110 - Los Angeles",
          "REGION_NAME": losAngleles,
          "DISTRICT_CODE": "110A",
          "DISTRICT_NAME": "LA District 1: Los Angeles/No. Orange County",
          "DEALER_CODE": "4033",
          "DEALER_NAME": "Cabe Toyota Long Beach",
          "DEALER": "04033 - Cabe Toyota Long Beach"
        }
      ]
    }
  ]

}; //NOSONAR

export const NationalDprMockData = {
  body: {
    "new": [
      {
        "reportData": [
          {
            "SECTIONNO": "1",
            "SECTIONTITLE": "Overall Gross & Contract Penetration",
            "sectionData": [
              {
                "SECTIONID": 1,
                "SECTIONTITLE": "Overall Gross & Contract Penetration",
                "ACCT_NO": "B9001",
                "ACCT_TITLE": "Combined Units Retailed",
                "R110": "515.1200",
                "R120": "377.7190",
                "R130": "346.6570",
                "R150": "450.8430",
                "R160": "277.4780",
                "R170": "286.0000",
                "R210": "265.1250",
                "R220": "280.0000",
                "R230": "234.2000",
                "R800": "307.8880",
                "NATLAVG": "358.3790",
                "UNITS_FLAG": "84",
                "SECTIONNO": 1,
                "ACCTSEQNO": 1
              },
              {
                "SECTIONID": 1,
                "SECTIONTITLE": "Overall Gross & Contract Penetration",
                "ACCT_NO": "B9002",
                "ACCT_TITLE": "Combined FS Office Gross Total",
                "R110": "1073146.7600",
                "R120": "803048.4390",
                "R130": "668381.0550",
                "R150": "900179.9860",
                "R160": "537700.3270",
                "R170": "564317.2540",
                "R210": "449882.6070",
                "R220": "415495.1320",
                "R230": "320547.7730",
                "R800": "544118.2300",
                "NATLAVG": "676800.9830",
                "UNITS_FLAG": "81",
                "SECTIONNO": 1,
                "ACCTSEQNO": 2
              },
              {
                "SECTIONID": 1,
                "SECTIONTITLE": "Overall Gross & Contract Penetration",
                "ACCT_NO": "B9160",
                "ACCT_TITLE": "Certified Units",
                "R110": "85.7340",
                "R120": "51.2980",
                "R130": "49.4930",
                "R150": "76.9430",
                "R160": "46.1770",
                "R170": "44.9010",
                "R210": "41.5540",
                "R220": "32.0160",
                "R230": "24.7070",
                "R800": "39.6380",
                "NATLAVG": "49.0290",
                "UNITS_FLAG": "84",
                "SECTIONNO": 1,
                "ACCTSEQNO": 3
              },
              {
                "SECTIONID": 1,
                "SECTIONTITLE": "Overall Gross & Contract Penetration",
                "ACCT_NO": "B9003",
                "ACCT_TITLE": "Combined FS Office Gross PRU",
                "R110": "2083.2950",
                "R120": "2126.0470",
                "R130": "1928.0760",
                "R150": "1996.6600",
                "R160": "1937.8120",
                "R170": "1973.1370",
                "R210": "1696.8700",
                "R220": "1483.9110",
                "R230": "1368.6920",
                "R800": "1767.2600",
                "NATLAVG": "1888.5060",
                "UNITS_FLAG": "81",
                "SECTIONNO": 1,
                "ACCTSEQNO": 4
              },
              {
                "SECTIONID": 1,
                "SECTIONTITLE": "Overall Gross & Contract Penetration",
                "ACCT_NO": "B9004",
                "ACCT_TITLE": "Combined Finance Penetration (including lease)",
                "R110": "56.6570",
                "R120": "58.9500",
                "R130": "54.0980",
                "R150": "51.9980",
                "R160": "56.0420",
                "R170": "58.7810",
                "R210": "52.0640",
                "R220": "51.8560",
                "R230": "50.0480",
                "R800": "54.4670",
                "NATLAVG": "55.4630",
                "UNITS_FLAG": "810",
                "SECTIONNO": 1,
                "ACCTSEQNO": 5
              },
              {
                "SECTIONID": 1,
                "SECTIONTITLE": "Overall Gross & Contract Penetration",
                "ACCT_NO": "B9005",
                "ACCT_TITLE": "Combined Lease Penetration (New Only)",
                "R110": "12.9180",
                "R120": "5.5240",
                "R130": "10.2920",
                "R150": "8.5870",
                "R160": "21.6250",
                "R170": "19.6310",
                "R210": "7.1610",
                "R220": "9.2020",
                "R230": "5.8380",
                "R800": "5.6780",
                "NATLAVG": "9.3360",
                "UNITS_FLAG": "810",
                "SECTIONNO": 1,
                "ACCTSEQNO": 6
              },
              {
                "SECTIONID": 1,
                "SECTIONTITLE": "Overall Gross & Contract Penetration",
                "ACCT_NO": "B9006",
                "ACCT_TITLE": combinedFinResPer,
                "R110": "533.8890",
                "R120": "566.0750",
                "R130": "658.5340",
                "R150": "750.8870",
                "R160": "815.3680",
                "R170": "783.9070",
                "R210": "665.3020",
                "R220": "781.5960",
                "R230": "676.1140",
                "R800": "617.8380",
                "NATLAVG": "733.0970",
                "UNITS_FLAG": "81",
                "SECTIONNO": 1,
                "ACCTSEQNO": 7
              },
              {
                "SECTIONID": 1,
                "SECTIONTITLE": "Overall Gross & Contract Penetration",
                "ACCT_NO": "B9007",
                "ACCT_TITLE": "Combined Insurance Penetration of Finance Contracts",
                "R110": "3.1160",
                "R120": "0.0000",
                "R130": "0.1940",
                "R150": "2.8110",
                "R160": "0.0290",
                "R170": "0.3890",
                "R210": "1.6000",
                "R220": "0.1980",
                "R230": "1.0880",
                "R800": "0.3800",
                "NATLAVG": "1.0940",
                "UNITS_FLAG": "810",
                "SECTIONNO": 1,
                "ACCTSEQNO": 8
              },
              {
                "SECTIONID": 1,
                "SECTIONTITLE": "Overall Gross & Contract Penetration",
                "ACCT_NO": "B9008",
                "ACCT_TITLE": "Combined Gap Penetration",
                "R110": "35.4010",
                "R120": "30.7710",
                "R130": "24.5480",
                "R150": "30.9520",
                "R160": "21.0490",
                "R170": "23.1360",
                "R210": "24.3550",
                "R220": "23.8550",
                "R230": "22.4190",
                "R800": "30.7300",
                "NATLAVG": "30.2030",
                "UNITS_FLAG": "810",
                "SECTIONNO": 1,
                "ACCTSEQNO": 9
              },
              {
                "SECTIONID": 1,
                "SECTIONTITLE": "Overall Gross & Contract Penetration",
                "ACCT_NO": "B9009",
                "ACCT_TITLE": "Combined VSA Penetration",
                "R110": "45.4700",
                "R120": "50.9340",
                "R130": "49.3960",
                "R150": "53.1420",
                "R160": "45.8230",
                "R170": "46.8430",
                "R210": "49.3800",
                "R220": "43.0640",
                "R230": "45.4030",
                "R800": "51.4860",
                "NATLAVG": "50.3250",
                "UNITS_FLAG": "810",
                "SECTIONNO": 1,
                "ACCTSEQNO": 10
              },
              {
                "SECTIONID": 1,
                "SECTIONTITLE": "Overall Gross & Contract Penetration",
                "ACCT_NO": "B9010",
                "ACCT_TITLE": "Combined PPM Penetration",
                "R110": "24.0200",
                "R120": "27.4870",
                "R130": "27.4440",
                "R150": "24.1330",
                "R160": "11.7910",
                "R170": "13.9470",
                "R210": "9.4530",
                "R220": "11.1040",
                "R230": "8.4370",
                "R800": "13.5790",
                "NATLAVG": "18.8810",
                "UNITS_FLAG": "810",
                "SECTIONNO": 1,
                "ACCTSEQNO": 11
              },
              {
                "SECTIONID": 1,
                "SECTIONTITLE": "Overall Gross & Contract Penetration",
                "ACCT_NO": "B10024",
                "ACCT_TITLE": "Combined EWU Penetration (New Lease Only)",
                "R110": "14.3140",
                "R120": "38.6510",
                "R130": "16.0170",
                "R150": "16.1220",
                "R160": "7.8430",
                "R170": "16.9080",
                "R210": "24.3910",
                "R220": "37.6690",
                "R230": "9.3470",
                "R800": "15.1930",
                "NATLAVG": "18.4260",
                "UNITS_FLAG": "810",
                "SECTIONNO": 1,
                "ACCTSEQNO": 12
              },
              {
                "SECTIONID": 1,
                "SECTIONTITLE": "Overall Gross & Contract Penetration",
                "ACCT_NO": "B10099",
                "ACCT_TITLE": "Combined Tire & Wheel Protection Penetration",
                "R110": "11.4560",
                "R120": "9.5540",
                "R130": "5.9120",
                "R150": "11.9330",
                "R160": "11.9340",
                "R170": "15.4980",
                "R210": "12.4780",
                "R220": "11.0710",
                "R230": "8.0450",
                "R800": "13.4730",
                "NATLAVG": "11.4250",
                "UNITS_FLAG": "82",
                "SECTIONNO": 1,
                "ACCTSEQNO": 13
              },
              {
                "SECTIONID": 1,
                "SECTIONTITLE": "Overall Gross & Contract Penetration",
                "ACCT_NO": "B10140",
                "ACCT_TITLE": "Combined Key Replacement Penetration",
                "R110": "0.9910",
                "R120": "0.9480",
                "R130": "3.1930",
                "R150": "2.8450",
                "R160": "2.8610",
                "R170": "1.2510",
                "R210": "1.1950",
                "R220": "1.7300",
                "R230": "1.2470",
                "R800": "0.4160",
                "NATLAVG": "1.7830",
                "UNITS_FLAG": "810",
                "SECTIONNO": 1,
                "ACCTSEQNO": 14
              }
            ]
          },
          {
            "SECTIONNO": "2",
            "SECTIONTITLE": "FSD Income/Chargeback Analysis",
            "sectionData": [
              {
                "SECTIONID": 2,
                "SECTIONTITLE": "FSD Income/Chargeback Analysis",
                "ACCT_NO": "B9012",
                "ACCT_TITLE": "Combined Number of Finance & Insurance Contracts Sold",
                "R110": "307.9070",
                "R120": "222.6670",
                "R130": "188.2060",
                "R150": "247.1000",
                "R160": "155.5850",
                "R170": "169.2260",
                "R210": "142.2770",
                "R220": "145.7510",
                "R230": "119.7600",
                "R800": "168.8670",
                "NATLAVG": "202.6880",
                "UNITS_FLAG": "84",
                "SECTIONNO": 2,
                "ACCTSEQNO": 15
              },
              {
                "SECTIONID": 2,
                "SECTIONTITLE": "FSD Income/Chargeback Analysis",
                "ACCT_NO": "B9013",
                "ACCT_TITLE": "Combined FS Department Penetration",
                "R110": "186.1800",
                "R120": "185.7850",
                "R130": "176.5460",
                "R150": "191.9350",
                "R160": "161.2680",
                "R170": "166.4640",
                "R210": "157.5840",
                "R220": "156.2970",
                "R230": "147.5910",
                "R800": "174.3970",
                "NATLAVG": "178.6810",
                "UNITS_FLAG": "810",
                "SECTIONNO": 2,
                "ACCTSEQNO": 16
              },
              {
                "SECTIONID": 2,
                "SECTIONTITLE": "FSD Income/Chargeback Analysis",
                "ACCT_NO": "B9014",
                "ACCT_TITLE": "Combined FS Department Chargebacks",
                "R110": "1198601.0940",
                "R120": "978198.4220",
                "R130": "797184.7420",
                "R150": "1074507.7700",
                "R160": "637656.4400",
                "R170": "699183.1820",
                "R210": "542630.1960",
                "R220": "567369.5260",
                "R230": "414978.5340",
                "R800": "606957.6620",
                "NATLAVG": "402966.2110",
                "UNITS_FLAG": "81",
                "SECTIONNO": 2,
                "ACCTSEQNO": 17
              },
              {
                "SECTIONID": 2,
                "SECTIONTITLE": "FSD Income/Chargeback Analysis",
                "ACCT_NO": "B9015",
                "ACCT_TITLE": "Combined FS Department Chargebacks PRU",
                "R110": "2393.9480",
                "R120": "2638.8560",
                "R130": "2313.4800",
                "R150": "2467.8380",
                "R160": "2371.2800",
                "R170": "2477.8860",
                "R210": "2058.9920",
                "R220": "2058.3440",
                "R230": "1805.4640",
                "R800": "1994.9960",
                "NATLAVG": "1150.5620",
                "UNITS_FLAG": "81",
                "SECTIONNO": 2,
                "ACCTSEQNO": 18
              },
              {
                "SECTIONID": 2,
                "SECTIONTITLE": "FSD Income/Chargeback Analysis",
                "ACCT_NO": "B9016",
                "ACCT_TITLE": "Combined FS Department Chargebacks % Total FS Income",
                "R110": "-12.6950",
                "R120": "-10.8600",
                "R130": "-11.0050",
                "R150": "-11.3490",
                "R160": "-11.1700",
                "R170": "-11.7970",
                "R210": "-13.0510",
                "R220": "-10.2770",
                "R230": "-12.1010",
                "R800": "-15.7800",
                "NATLAVG": "-13.2290",
                "UNITS_FLAG": "810",
                "SECTIONNO": 2,
                "ACCTSEQNO": 19
              },
              {
                "SECTIONID": 2,
                "SECTIONTITLE": "FSD Income/Chargeback Analysis",
                "ACCT_NO": "B9017",
                "ACCT_TITLE": "Combined FS Department Net After Chargebacks",
                "R110": "936908.8800",
                "R120": "715839.3160",
                "R130": "594825.3840",
                "R150": "798017.9430",
                "R160": "477640.2740",
                "R170": "497745.4230",
                "R210": "391169.8480",
                "R220": "372794.2230",
                "R230": "281757.8800",
                "R800": "458256.3190",
                "NATLAVG": "587269.4790",
                "UNITS_FLAG": "81",
                "SECTIONNO": 2,
                "ACCTSEQNO": 20
              },
              {
                "SECTIONID": 2,
                "SECTIONTITLE": "FSD Income/Chargeback Analysis",
                "ACCT_NO": "B9018",
                "ACCT_TITLE": "Combined FS Department. Net After Chargebacks PRU",
                "R110": "1818.8170",
                "R120": "1895.1640",
                "R130": "1715.8900",
                "R150": "1770.0570",
                "R160": "1721.3630",
                "R170": "1740.3690",
                "R210": "1475.4170",
                "R220": "1331.4080",
                "R230": "1203.0650",
                "R800": "1488.3860",
                "NATLAVG": "1638.6830",
                "UNITS_FLAG": "81",
                "SECTIONNO": 2,
                "ACCTSEQNO": 21
              }
            ]
          }
        ],
        "reportHeaderData": [
          {
            "COMPANY_CODE": "14",
            "COMPANY_NAME": "Toyota",
            "COMPANY": "14 - Toyota",
            "REGION_CODE": "110",
            "REGION": "110 - Los Angeles",
            "REGION_NAME": losAngleles,
            "REGION_ALPHA_CODE": "LA      "
          },
          {
            "COMPANY_CODE": "14",
            "COMPANY_NAME": "Toyota",
            "COMPANY": "14 - Toyota",
            "REGION_CODE": "130",
            "REGION": "130 - Portland",
            "REGION_NAME": "Portland",
            "REGION_ALPHA_CODE": "PTL     "
          },
          {
            "COMPANY_CODE": "14",
            "COMPANY_NAME": "Toyota",
            "COMPANY": "14 - Toyota",
            "REGION_CODE": "160",
            "REGION": "160 - New York",
            "REGION_NAME": "New York",
            "REGION_ALPHA_CODE": "NY      "
          },
          {
            "COMPANY_CODE": "14",
            "COMPANY_NAME": "Toyota",
            "COMPANY": "14 - Toyota",
            "REGION_CODE": "170",
            "REGION": "170 - Boston",
            "REGION_NAME": "Boston",
            "REGION_ALPHA_CODE": "BOS     "
          },
          {
            "COMPANY_CODE": "14",
            "COMPANY_NAME": "Toyota",
            "COMPANY": "14 - Toyota",
            "REGION_CODE": "220",
            "REGION": "220 - Cincinnati",
            "REGION_NAME": "Cincinnati",
            "REGION_ALPHA_CODE": "CIN     "
          },
          {
            "COMPANY_CODE": "14",
            "COMPANY_NAME": "Toyota",
            "COMPANY": "14 - Toyota",
            "REGION_CODE": "120",
            "REGION": "120 - San Francisco",
            "REGION_NAME": "San Francisco",
            "REGION_ALPHA_CODE": "SF      "
          },
          {
            "COMPANY_CODE": "14",
            "COMPANY_NAME": "Toyota",
            "COMPANY": "14 - Toyota",
            "REGION_CODE": "150",
            "REGION": "150 - Denver",
            "REGION_NAME": "Denver",
            "REGION_ALPHA_CODE": "DEN     "
          },
          {
            "COMPANY_CODE": "14",
            "COMPANY_NAME": "Toyota",
            "COMPANY": "14 - Toyota",
            "REGION_CODE": "210",
            "REGION": "210 - Chicago",
            "REGION_NAME": "Chicago",
            "REGION_ALPHA_CODE": "CHI     "
          },
          {
            "COMPANY_CODE": "14",
            "COMPANY_NAME": "Toyota",
            "COMPANY": "14 - Toyota",
            "REGION_CODE": "230",
            "REGION": "230 - Kansas City",
            "REGION_NAME": "Kansas City",
            "REGION_ALPHA_CODE": "KC      "
          },
          {
            "COMPANY_CODE": "14",
            "COMPANY_NAME": "Toyota",
            "COMPANY": "14 - Toyota",
            "REGION_CODE": "800",
            "REGION": "800 - Central Atlantic Toyota",
            "REGION_NAME": "Central Atlantic Toyota",
            "REGION_ALPHA_CODE": "CAT     "
          }
        ]
      }
    ],
    "used": [
      {
        "reportData": [
          {
            "SECTIONNO": "2",
            "SECTIONTITLE": "Finance Analysis",
            "sectionData": [
              {
                "SECTIONID": 2,
                "SECTIONTITLE": "Finance Analysis",
                "ACCT_NO": "B9023",
                "ACCT_TITLE": "Number Of New Veh Finance & Lease Contracts Sold",
                "R110": "214.9070",
                "R120": "158.2810",
                "R130": "110.8360",
                "R150": "138.7710",
                "R160": "107.3010",
                "R170": "101.3100",
                "R210": "70.5450",
                "R220": "73.2890",
                "R230": "54.0400",
                "R800": "102.4810",
                "NATLAVG": "118.2020",
                "UNITS_FLAG": "84",
                "SECTIONNO": 2,
                "ACCTSEQNO": 4
              }
            ]
          },
          {
            "SECTIONNO": "3",
            "SECTIONTITLE": ServiceConAnalysis,
            "sectionData": [
              {
                "SECTIONID": 3,
                "SECTIONTITLE": ServiceConAnalysis,
                "ACCT_NO": "B9035",
                "ACCT_TITLE": "Service Contract Gross",
                "R110": "246713.3600",
                "R120": "210090.7020",
                "R130": "143540.2880",
                "R150": "182604.4850",
                "R160": "103634.5220",
                "R170": "111710.5070",
                "R210": "88204.4910",
                "R220": "76006.9090",
                "R230": "62368.4400",
                "R800": "130911.6960",
                "NATLAVG": "139618.6350",
                "UNITS_FLAG": "81",
                "SECTIONNO": 3,
                "ACCTSEQNO": 16
              },
              {
                "SECTIONID": 3,
                "SECTIONTITLE": ServiceConAnalysis,
                "ACCT_NO": "B9036",
                "ACCT_TITLE": numberOfSerSold,
                "R110": "121.3330",
                "R120": "113.3860",
                "R130": "80.9180",
                "R150": "110.4710",
                "R160": "61.0440",
                "R170": "62.6060",
                "R210": "55.6430",
                "R220": "51.6530",
                "R230": "43.2930",
                "R800": "80.6590",
                "NATLAVG": "86.0250",
                "UNITS_FLAG": "84",
                "SECTIONNO": 3,
                "ACCTSEQNO": 17
              }

            ]
          },
          {
            "SECTIONNO": "5",
            "SECTIONTITLE": "Gap Insurance Analysis",
            "sectionData": [
              {
                "SECTIONID": 5,
                "SECTIONTITLE": "Gap Insurance Analysis",
                "ACCT_NO": "B9051",
                "ACCT_TITLE": "GAP Insurance Gross",
                "R110": "61588.9600",
                "R120": "37504.4740",
                "R130": "19863.5210",
                "R150": "30005.5580",
                "R160": "10948.1950",
                "R170": "18624.6760",
                "R210": "13149.6520",
                "R220": "12468.6370",
                "R230": "7034.9060",
                "R800": "23837.2000",
                "NATLAVG": "26504.6280",
                "UNITS_FLAG": "81",
                "SECTIONNO": 5,
                "ACCTSEQNO": 32
              },
              {
                "SECTIONID": 5,
                "SECTIONTITLE": "Gap Insurance Analysis",
                "ACCT_NO": "B9052",
                "ACCT_TITLE": numberOfGap,
                "R110": "110.5870",
                "R120": "67.6140",
                "R130": "38.5480",
                "R150": "61.3570",
                "R160": "32.8050",
                "R170": "32.6480",
                "R210": "24.0270",
                "R220": "23.6120",
                "R230": "14.8800",
                "R800": "45.1330",
                "NATLAVG": "51.1910",
                "UNITS_FLAG": "84",
                "SECTIONNO": 5,
                "ACCTSEQNO": 33
              }
            ]
          }
        ],
        "reportHeaderData": [
          {
            "COMPANY_CODE": "14",
            "COMPANY_NAME": "Toyota",
            "COMPANY": "14 - Toyota",
            "REGION_CODE": "110",
            "REGION": "110 - Los Angeles",
            "REGION_NAME": losAngleles,
            "REGION_ALPHA_CODE": "LA      "
          },
          {
            "COMPANY_CODE": "14",
            "COMPANY_NAME": "Toyota",
            "COMPANY": "14 - Toyota",
            "REGION_CODE": "130",
            "REGION": "130 - Portland",
            "REGION_NAME": "Portland",
            "REGION_ALPHA_CODE": "PTL     "
          },

        ]
      }
    ],
    "combined": [
      {
        "reportData": [
          {
            "SECTIONNO": "2",
            "SECTIONTITLE": "Finance Analysis",
            "sectionData": [
              {
                "SECTIONID": 2,
                "SECTIONTITLE": "Finance Analysis",
                "ACCT_NO": "B21001",
                "ACCT_TITLE": "Retail Finance & Lease Gross",
                "R110": "42713.3200",
                "R120": "22197.2630",
                "R130": "23508.3420",
                "R150": "42948.8710",
                "R160": "33438.4070",
                "R170": "22466.6620",
                "R210": "24306.0270",
                "R220": "16484.0750",
                "R230": "12221.0800",
                "R800": "17635.9560",
                "NATLAVG": "28370.8090",
                "UNITS_FLAG": null,
                "SECTIONNO": 2,
                "ACCTSEQNO": 4
              },
              {
                "SECTIONID": 2,
                "SECTIONTITLE": "Finance Analysis",
                "ACCT_NO": "B21002",
                "ACCT_TITLE": "Number of Retail Finance Contracts Sold",
                "R110": "59.2530",
                "R120": "30.5610",
                "R130": "29.4110",
                "R150": "47.5570",
                "R160": "31.4160",
                "R170": "25.8730",
                "R210": "26.2950",
                "R220": "17.6450",
                "R230": "13.8270",
                "R800": "26.0150",
                "NATLAVG": "31.0280",
                "UNITS_FLAG": null,
                "SECTIONNO": 2,
                "ACCTSEQNO": 5
              },
              {
                "SECTIONID": 2,
                "SECTIONTITLE": "Finance Analysis",
                "ACCT_NO": "B21003",
                "ACCT_TITLE": "Retail Finance & Lease Gross Per Contract Sold",
                "R110": "720.8630",
                "R120": "726.3260",
                "R130": "799.3040",
                "R150": "903.1030",
                "R160": "1064.3750",
                "R170": "868.3440",
                "R210": "924.3590",
                "R220": "934.2070",
                "R230": "883.8560",
                "R800": "677.9150",
                "NATLAVG": "914.3620",
                "UNITS_FLAG": null,
                "SECTIONNO": 2,
                "ACCTSEQNO": 6
              },
              {
                "SECTIONID": 2,
                "SECTIONTITLE": "Finance Analysis",
                "ACCT_NO": "B21004",
                "ACCT_TITLE": "Retail Finance & Lease Contract Penetration",
                "R110": "70.2820",
                "R120": "61.9920",
                "R130": "60.9770",
                "R150": "62.2940",
                "R160": "70.2830",
                "R170": "59.4110",
                "R210": "65.2710",
                "R220": "56.2890",
                "R230": "56.8540",
                "R800": "67.1890",
                "NATLAVG": "64.7560",
                "UNITS_FLAG": null,
                "SECTIONNO": 2,
                "ACCTSEQNO": 7
              },
              {
                "SECTIONID": 2,
                "SECTIONTITLE": "Finance Analysis",
                "ACCT_NO": "B21005",
                "ACCT_TITLE": "Retail Finance & Lease Gross % Total FS Gross",
                "R110": "3.9800",
                "R120": "2.7640",
                "R130": "3.5170",
                "R150": "4.7710",
                "R160": "6.2190",
                "R170": "3.9810",
                "R210": "5.4030",
                "R220": "3.9670",
                "R230": "3.8130",
                "R800": "3.2410",
                "NATLAVG": "4.1920",
                "UNITS_FLAG": null,
                "SECTIONNO": 2,
                "ACCTSEQNO": 8
              },
              {
                "SECTIONID": 2,
                "SECTIONTITLE": "Finance Analysis",
                "ACCT_NO": "B21006",
                "ACCT_TITLE": "Retail Finance & Lease Chargebacks",
                "R110": "-713.3870",
                "R120": "-540.1050",
                "R130": "-653.8630",
                "R150": "-1270.9000",
                "R160": "-1973.8580",
                "R170": "-2041.5770",
                "R210": "-1113.8840",
                "R220": "-1377.1490",
                "R230": "-260.1200",
                "R800": "-1222.9260",
                "NATLAVG": "-1253.5980",
                "UNITS_FLAG": null,
                "SECTIONNO": 2,
                "ACCTSEQNO": 9
              },
              {
                "SECTIONID": 2,
                "SECTIONTITLE": "Finance Analysis",
                "ACCT_NO": "B21007",
                "ACCT_TITLE": "Retail Finance & Lease Chargebacks % Total Chargebacks ",
                "R110": "5.0680",
                "R120": "8.1300",
                "R130": "10.1870",
                "R150": "13.3810",
                "R160": "26.5270",
                "R170": "36.1330",
                "R210": "22.6370",
                "R220": "63.6320",
                "R230": "12.7330",
                "R800": "22.1980",
                "NATLAVG": "19.4880",
                "UNITS_FLAG": null,
                "SECTIONNO": 2,
                "ACCTSEQNO": 10
              },
              {
                "SECTIONID": 2,
                "SECTIONTITLE": "Finance Analysis",
                "ACCT_NO": "B21008",
                "ACCT_TITLE": "Finance Net After Chargebacks Per Contract Sold",
                "R110": "708.8240",
                "R120": "708.6530",
                "R130": "777.0720",
                "R150": "876.3790",
                "R160": "1001.5450",
                "R170": "789.4360",
                "R210": "881.9980",
                "R220": "856.1590",
                "R230": "865.0440",
                "R800": "630.9060",
                "NATLAVG": "873.9590",
                "UNITS_FLAG": null,
                "SECTIONNO": 2,
                "ACCTSEQNO": 11
              }
            ]
          },
          {
            "SECTIONNO": "3",
            "SECTIONTITLE": ServiceConAnalysis,
            "sectionData": [
              {
                "SECTIONID": 3,
                "SECTIONTITLE": ServiceConAnalysis,
                "ACCT_NO": "B21009",
                "ACCT_TITLE": "Service Contract Gross",
                "R110": "100290.9200",
                "R120": "57220.9470",
                "R130": "50987.0830",
                "R150": "75770.0570",
                "R160": "44902.0440",
                "R170": "34737.8590",
                "R210": "38911.6340",
                "R220": "23604.1660",
                "R230": "18705.3860",
                "R800": "37256.4070",
                "NATLAVG": "45734.2860",
                "UNITS_FLAG": null,
                "SECTIONNO": 3,
                "ACCTSEQNO": 12
              },
              {
                "SECTIONID": 3,
                "SECTIONTITLE": ServiceConAnalysis,
                "ACCT_NO": "B21010",
                "ACCT_TITLE": numberOfSerSold,
                "R110": "49.9070",
                "R120": "29.4210",
                "R130": "27.4380",
                "R150": "44.2140",
                "R160": "24.5400",
                "R170": "18.9720",
                "R210": "22.1700",
                "R220": "15.4130",
                "R230": "11.7600",
                "R800": "23.8220",
                "NATLAVG": "27.4920",
                "UNITS_FLAG": null,
                "SECTIONNO": 3,
                "ACCTSEQNO": 13
              },
              {
                "SECTIONID": 3,
                "SECTIONTITLE": ServiceConAnalysis,
                "ACCT_NO": "B21011",
                "ACCT_TITLE": "Service Contract Gross Per Contract Sold",
                "R110": "2009.5560",
                "R120": "1944.9010",
                "R130": "1858.2650",
                "R150": "1713.7120",
                "R160": "1829.7490",
                "R170": "1831.0070",
                "R210": "1755.1480",
                "R220": "1531.4450",
                "R230": "1590.5940",
                "R800": "1563.9500",
                "NATLAVG": "1663.5490",
                "UNITS_FLAG": null,
                "SECTIONNO": 3,
                "ACCTSEQNO": 14
              },
              {
                "SECTIONID": 3,
                "SECTIONTITLE": ServiceConAnalysis,
                "ACCT_NO": "B21012",
                "ACCT_TITLE": "Service Contract Penetration",
                "R110": "58.2110",
                "R120": "57.3530",
                "R130": "55.4380",
                "R150": "57.4630",
                "R160": "53.1430",
                "R170": "42.2530",
                "R210": "53.3520",
                "R220": "48.1420",
                "R230": "47.5980",
                "R800": "60.0990",
                "NATLAVG": "56.0730",
                "UNITS_FLAG": null,
                "SECTIONNO": 3,
                "ACCTSEQNO": 15
              },
              {
                "SECTIONID": 3,
                "SECTIONTITLE": ServiceConAnalysis,
                "ACCT_NO": "B21013",
                "ACCT_TITLE": "Service Contract Gross % Total FS gross",
                "R110": "9.3450",
                "R120": "7.1250",
                "R130": "7.6280",
                "R150": "8.4170",
                "R160": "8.3510",
                "R170": "6.1560",
                "R210": "8.6490",
                "R220": "5.6810",
                "R230": "5.8350",
                "R800": "6.8470",
                "NATLAVG": "6.7570",
                "UNITS_FLAG": null,
                "SECTIONNO": 3,
                "ACCTSEQNO": 16
              },
              {
                "SECTIONID": 3,
                "SECTIONTITLE": ServiceConAnalysis,
                "ACCT_NO": "B21014",
                "ACCT_TITLE": "Service Contract Chargebacks",
                "R110": "-8006.2530",
                "R120": "-2987.3680",
                "R130": "-4173.5620",
                "R150": "-5175.2570",
                "R160": "-4047.6020",
                "R170": "-2655.6760",
                "R210": "-2623.2050",
                "R220": "-495.8600",
                "R230": "-1208.5330",
                "R800": "-2832.1630",
                "NATLAVG": "-3369.6710",
                "UNITS_FLAG": null,
                "SECTIONNO": 3,
                "ACCTSEQNO": 17
              },
              {
                "SECTIONID": 3,
                "SECTIONTITLE": ServiceConAnalysis,
                "ACCT_NO": "B21015",
                "ACCT_TITLE": "Service Contract Chargebacks % Total Chargebacks",
                "R110": "56.8740",
                "R120": "44.9650",
                "R130": "65.0220",
                "R150": "54.4880",
                "R160": "54.3960",
                "R170": "47.0020",
                "R210": "53.3100",
                "R220": "22.9110",
                "R230": "59.1590",
                "R800": "51.4070",
                "NATLAVG": "52.3830",
                "UNITS_FLAG": null,
                "SECTIONNO": 3,
                "ACCTSEQNO": 18
              }
            ]
          },
          {
            "SECTIONNO": "5",
            "SECTIONTITLE": "Gap Insurance Analysis",
            "sectionData": [
              {
                "SECTIONID": 5,
                "SECTIONTITLE": "Gap Insurance Analysis",
                "ACCT_NO": "B21017",
                "ACCT_TITLE": "GAP Insurance Gross",
                "R110": "17848.6930",
                "R120": "9111.5960",
                "R130": "6918.3420",
                "R150": "12436.3140",
                "R160": "4359.5130",
                "R170": "4400.6760",
                "R210": "6625.8750",
                "R220": "4674.4540",
                "R230": "2659.2540",
                "R800": "7603.8820",
                "NATLAVG": "8129.4740",
                "UNITS_FLAG": null,
                "SECTIONNO": 5,
                "ACCTSEQNO": 28
              },
              {
                "SECTIONID": 5,
                "SECTIONTITLE": "Gap Insurance Analysis",
                "ACCT_NO": "B21018",
                "ACCT_TITLE": numberOfGap,
                "R110": "33.4000",
                "R120": "18.0180",
                "R130": "12.8360",
                "R150": "25.8570",
                "R160": "11.0800",
                "R170": "7.3100",
                "R210": "10.8750",
                "R220": "7.9670",
                "R230": "5.6530",
                "R800": "14.6300",
                "NATLAVG": "15.7300",
                "UNITS_FLAG": null,
                "SECTIONNO": 5,
                "ACCTSEQNO": 29
              },
              {
                "SECTIONID": 5,
                "SECTIONTITLE": "Gap Insurance Analysis",
                "ACCT_NO": "B21019",
                "ACCT_TITLE": "GAP Insurance Gross Per Contract Sold",
                "R110": "534.3920",
                "R120": "505.6940",
                "R130": "538.9800",
                "R150": "480.9650",
                "R160": "393.4580",
                "R170": "602.0080",
                "R210": "609.2760",
                "R220": "586.7270",
                "R230": "470.4150",
                "R800": "519.7460",
                "NATLAVG": "516.8130",
                "UNITS_FLAG": null,
                "SECTIONNO": 5,
                "ACCTSEQNO": 30
              },
              {
                "SECTIONID": 5,
                "SECTIONTITLE": "Gap Insurance Analysis",
                "ACCT_NO": "B21020",
                "ACCT_TITLE": gapInsuranceConPen,
                "R110": "38.9580",
                "R120": "35.1240",
                "R130": "25.9350",
                "R150": "33.6050",
                "R160": "23.9950",
                "R170": "16.2800",
                "R210": "26.1710",
                "R220": "24.8840",
                "R230": "22.8800",
                "R800": "36.9090",
                "NATLAVG": "32.0830",
                "UNITS_FLAG": null,
                "SECTIONNO": 5,
                "ACCTSEQNO": 31
              },
              {
                "SECTIONID": 5,
                "SECTIONTITLE": "Gap Insurance Analysis",
                "ACCT_NO": "B21021",
                "ACCT_TITLE": "GAP Insurance Gross % Total FS gross",
                "R110": "1.6630",
                "R120": "1.1350",
                "R130": "1.0350",
                "R150": "1.3820",
                "R160": "0.8110",
                "R170": "0.7800",
                "R210": "1.4730",
                "R220": "1.1250",
                "R230": "0.8300",
                "R800": "1.3970",
                "NATLAVG": "1.2010",
                "UNITS_FLAG": null,
                "SECTIONNO": 5,
                "ACCTSEQNO": 32
              },
              {
                "SECTIONID": 5,
                "SECTIONTITLE": "Gap Insurance Analysis",
                "ACCT_NO": "B21022",
                "ACCT_TITLE": "GAP Insurance Chargebacks",
                "R110": "-3295.3200",
                "R120": "-1844.7190",
                "R130": "-1065.1230",
                "R150": "-2232.1430",
                "R160": "-659.4160",
                "R170": "-705.6760",
                "R210": "-867.3930",
                "R220": "-211.2230",
                "R230": "-284.9470",
                "R800": "-895.9780",
                "NATLAVG": "-1121.9440",
                "UNITS_FLAG": null,
                "SECTIONNO": 5,
                "ACCTSEQNO": 33
              },
              {
                "SECTIONID": 5,
                "SECTIONTITLE": "Gap Insurance Analysis",
                "ACCT_NO": "B21023",
                "ACCT_TITLE": "GAP Insurance Chargebacks % Total Chargebacks",
                "R110": "23.4090",
                "R120": "27.7660",
                "R130": "16.5940",
                "R150": "23.5010",
                "R160": "8.8620",
                "R170": "12.4890",
                "R210": "17.6280",
                "R220": "9.7600",
                "R230": "13.9480",
                "R800": "16.2630",
                "NATLAVG": "17.4410",
                "UNITS_FLAG": null,
                "SECTIONNO": 5,
                "ACCTSEQNO": 34
              },
              {
                "SECTIONID": 5,
                "SECTIONTITLE": "Gap Insurance Analysis",
                "ACCT_NO": "B21024",
                "ACCT_TITLE": "GAP Insurance Net After Chargebacks Per Contract Sold",
                "R110": "435.7300",
                "R120": "403.3120",
                "R130": "456.0000",
                "R150": "394.6390",
                "R160": "333.9440",
                "R170": "505.4720",
                "R210": "529.5160",
                "R220": "560.2150",
                "R230": "420.0080",
                "R800": "458.5030",
                "NATLAVG": "445.4880",
                "UNITS_FLAG": null,
                "SECTIONNO": 5,
                "ACCTSEQNO": 35
              }
            ]
          },
          {
            "SECTIONNO": "6",
            "SECTIONTITLE": "Prepaid Maintenance Analysis",
            "sectionData": [
              {
                "SECTIONID": 6,
                "SECTIONTITLE": "Prepaid Maintenance Analysis",
                "ACCT_NO": "B21025",
                "ACCT_TITLE": "Prepaid Maintenance Gross",
                "R110": "14605.8000",
                "R120": "6877.5260",
                "R130": "4969.9040",
                "R150": "5486.7720",
                "R160": "5156.9820",
                "R170": "1336.4220",
                "R210": "1062.8750",
                "R220": "644.6040",
                "R230": "420.1870",
                "R800": "1836.1630",
                "NATLAVG": "4611.1100",
                "UNITS_FLAG": null,
                "SECTIONNO": 6,
                "ACCTSEQNO": 36
              },
              {
                "SECTIONID": 6,
                "SECTIONTITLE": "Prepaid Maintenance Analysis",
                "ACCT_NO": "B21026",
                "ACCT_TITLE": "Number of Prepaid Maintenance Contracts Sold",
                "R110": "21.8270",
                "R120": "9.9650",
                "R130": "13.7120",
                "R150": "17.3140",
                "R160": "6.9120",
                "R170": "3.8310",
                "R210": "3.6250",
                "R220": "2.5540",
                "R230": "2.3070",
                "R800": "5.3930",
                "NATLAVG": "8.7910",
                "UNITS_FLAG": null,
                "SECTIONNO": 6,
                "ACCTSEQNO": 37
              },
              {
                "SECTIONID": 6,
                "SECTIONTITLE": "Prepaid Maintenance Analysis",
                "ACCT_NO": "B21027",
                "ACCT_TITLE": "Prepaid Maintenance Gross Per Contract Sold",
                "R110": "669.1620",
                "R120": "690.1680",
                "R130": "362.4490",
                "R150": "316.8980",
                "R160": "746.0910",
                "R170": "348.8440",
                "R210": "293.2070",
                "R220": "252.3900",
                "R230": "182.1360",
                "R800": "340.4720",
                "NATLAVG": "524.5260",
                "UNITS_FLAG": null,
                "SECTIONNO": 6,
                "ACCTSEQNO": 38
              },
              {
                "SECTIONID": 6,
                "SECTIONTITLE": "Prepaid Maintenance Analysis",
                "ACCT_NO": "B21028",
                "ACCT_TITLE": "Prepaid Maintenance Contract Penetration",
                "R110": "25.4590",
                "R120": "19.4260",
                "R130": "27.7050",
                "R150": "22.5020",
                "R160": "14.9680",
                "R170": "8.5320",
                "R210": "8.7240",
                "R220": "7.9770",
                "R230": "9.3370",
                "R800": "13.6060",
                "NATLAVG": "17.9300",
                "UNITS_FLAG": null,
                "SECTIONNO": 6,
                "ACCTSEQNO": 39
              },
              {
                "SECTIONID": 6,
                "SECTIONTITLE": "Prepaid Maintenance Analysis",
                "ACCT_NO": "B21029",
                "ACCT_TITLE": "Prepaid Maintenance Gross % Total FS gross",
                "R110": "1.3610",
                "R120": "0.8560",
                "R130": "0.7440",
                "R150": "0.6100",
                "R160": "0.9590",
                "R170": "0.2370",
                "R210": "0.2360",
                "R220": "0.1550",
                "R230": "0.1310",
                "R800": "0.3370",
                "NATLAVG": "0.6810",
                "UNITS_FLAG": null,
                "SECTIONNO": 6,
                "ACCTSEQNO": 40
              },
              {
                "SECTIONID": 6,
                "SECTIONTITLE": "Prepaid Maintenance Analysis",
                "ACCT_NO": "B21030",
                "ACCT_TITLE": "Prepaid Maintenance Chargebacks",
                "R110": "-1378.3870",
                "R120": "-787.1400",
                "R130": "-323.0960",
                "R150": "-414.9430",
                "R160": "-538.2210",
                "R170": "-162.8590",
                "R210": "-26.6070",
                "R220": "-16.4300",
                "R230": "-128.0670",
                "R800": "-115.4150",
                "NATLAVG": "-411.4230",
                "UNITS_FLAG": null,
                "SECTIONNO": 6,
                "ACCTSEQNO": 41
              },
              {
                "SECTIONID": 6,
                "SECTIONTITLE": "Prepaid Maintenance Analysis",
                "ACCT_NO": "B21031",
                "ACCT_TITLE": "Prepaid Maintenance Chargebacks % Total Chargebacks",
                "R110": "9.7920",
                "R120": "11.8480",
                "R130": "5.0340",
                "R150": "4.3690",
                "R160": "7.2330",
                "R170": "2.8820",
                "R210": "0.5410",
                "R220": "0.7590",
                "R230": "6.2690",
                "R800": "2.0950",
                "NATLAVG": "6.3960",
                "UNITS_FLAG": null,
                "SECTIONNO": 6,
                "ACCTSEQNO": 42
              },
              {
                "SECTIONID": 6,
                "SECTIONTITLE": "Prepaid Maintenance Analysis",
                "ACCT_NO": "B21032",
                "ACCT_TITLE": "Prepaid Maintenance Net After Chargebacks Per Contract Sold",
                "R110": "646.5380",
                "R120": "1151.9440",
                "R130": "538.2420",
                "R150": "395.3910",
                "R160": "617.7840",
                "R170": "945.3680",
                "R210": "519.3790",
                "R220": "1296.2360",
                "R230": "590.6490",
                "R800": "947.3130",
                "NATLAVG": "919.5730",
                "UNITS_FLAG": null,
                "SECTIONNO": 6,
                "ACCTSEQNO": 43
              }
            ]
          },
          {
            "SECTIONNO": "7",
            "SECTIONTITLE": "Tire&Wheel Protection Analysis",
            "sectionData": [
              {
                "SECTIONID": 8,
                "SECTIONTITLE": "Tire&Wheel Protection Analysis",
                "ACCT_NO": "B21033",
                "ACCT_TITLE": "Tire&Wheel Protection Gross",
                "R110": "6293.8270",
                "R120": "3185.3160",
                "R130": "2585.2060",
                "R150": "6399.8570",
                "R160": "3917.5840",
                "R170": "2757.2530",
                "R210": "2797.0630",
                "R220": "2631.2480",
                "R230": "1511.6800",
                "R800": "3485.1850",
                "NATLAVG": "3521.2900",
                "UNITS_FLAG": null,
                "SECTIONNO": 7,
                "ACCTSEQNO": 56
              },
              {
                "SECTIONID": 8,
                "SECTIONTITLE": "Tire&Wheel Protection Analysis",
                "ACCT_NO": "B21034",
                "ACCT_TITLE": "Number of Tire&Wheel Protection Contracts Sold",
                "R110": "9.1470",
                "R120": "4.1230",
                "R130": "3.8770",
                "R150": "13.0290",
                "R160": "5.4510",
                "R170": "4.0700",
                "R210": "4.2860",
                "R220": "4.0830",
                "R230": "2.2270",
                "R800": "4.9190",
                "NATLAVG": "5.6700",
                "UNITS_FLAG": null,
                "SECTIONNO": 7,
                "ACCTSEQNO": 57
              },
              {
                "SECTIONID": 8,
                "SECTIONTITLE": "Tire&Wheel Protection Analysis",
                "ACCT_NO": "B21035",
                "ACCT_TITLE": "Tire&Wheel Protection Gross Per Contract Sold",
                "R110": "688.0760",
                "R120": "772.5720",
                "R130": "666.8060",
                "R150": "491.2010",
                "R160": "718.6910",
                "R170": "677.4580",
                "R210": "652.6050",
                "R220": "644.4400",
                "R230": "678.7970",
                "R800": "708.5150",
                "NATLAVG": "621.0390",
                "UNITS_FLAG": null,
                "SECTIONNO": 7,
                "ACCTSEQNO": 58
              },
              {
                "SECTIONID": 8,
                "SECTIONTITLE": "Tire&Wheel Protection Analysis",
                "ACCT_NO": "B21036",
                "ACCT_TITLE": "Tire&Wheel Protection Contract Penetration",
                "R110": "10.6690",
                "R120": "8.0370",
                "R130": "7.8330",
                "R150": "16.9330",
                "R160": "11.8050",
                "R170": "9.0640",
                "R210": "10.3140",
                "R220": "12.7530",
                "R230": "9.0140",
                "R800": "12.4100",
                "NATLAVG": "11.5650",
                "UNITS_FLAG": null,
                "SECTIONNO": 7,
                "ACCTSEQNO": 59
              },
              {
                "SECTIONID": 8,
                "SECTIONTITLE": "Tire&Wheel Protection Analysis",
                "ACCT_NO": "B21037",
                "ACCT_TITLE": "Tire&Wheel Protection Gross % Total FS gross",
                "R110": "0.5860",
                "R120": "0.3970",
                "R130": "0.3870",
                "R150": "0.7110",
                "R160": "0.7290",
                "R170": "0.4890",
                "R210": "0.6220",
                "R220": "0.6330",
                "R230": "0.4720",
                "R800": "0.6410",
                "NATLAVG": "0.5200",
                "UNITS_FLAG": null,
                "SECTIONNO": 7,
                "ACCTSEQNO": 60
              },
              {
                "SECTIONID": 8,
                "SECTIONTITLE": "Tire&Wheel Protection Analysis",
                "ACCT_NO": "B21038",
                "ACCT_TITLE": "Tire&Wheel Protection Chargebacks",
                "R110": "-683.8670",
                "R120": "-454.9650",
                "R130": "-178.1510",
                "R150": "-401.1860",
                "R160": "-221.9470",
                "R170": "-84.3940",
                "R210": "-288.6520",
                "R220": "-63.5950",
                "R230": "-161.2000",
                "R800": "-436.2740",
                "NATLAVG": "-264.7900",
                "UNITS_FLAG": null,
                "SECTIONNO": 7,
                "ACCTSEQNO": 61
              },
              {
                "SECTIONID": 8,
                "SECTIONTITLE": "Tire&Wheel Protection Analysis",
                "ACCT_NO": "B21039",
                "ACCT_TITLE": "Tire&Wheel Protection Chargebacks % Total Chargebacks",
                "R110": "4.8580",
                "R120": "6.8480",
                "R130": "2.7760",
                "R150": "4.2240",
                "R160": "2.9830",
                "R170": "1.4940",
                "R210": "5.8660",
                "R220": "2.9380",
                "R230": "7.8910",
                "R800": "7.9190",
                "NATLAVG": "4.1160",
                "UNITS_FLAG": null,
                "SECTIONNO": 7,
                "ACCTSEQNO": 62
              },
              {
                "SECTIONID": 8,
                "SECTIONTITLE": "Tire&Wheel Protection Analysis",
                "ACCT_NO": "B21040",
                "ACCT_TITLE": "Tire&Wheel Protection Net After Chargebacks Per Contract Sold",
                "R110": "613.3110",
                "R120": "662.2240",
                "R130": "620.8550",
                "R150": "460.4090",
                "R160": "677.9740",
                "R170": "656.7220",
                "R210": "585.2570",
                "R220": "628.8640",
                "R230": "606.4120",
                "R800": "619.8230",
                "NATLAVG": "574.3390",
                "UNITS_FLAG": null,
                "SECTIONNO": 7,
                "ACCTSEQNO": 63
              }
            ]
          },
          {
            "SECTIONNO": "8",
            "SECTIONTITLE": "Key Replacement",
            "sectionData": [
              {
                "SECTIONID": 9,
                "SECTIONTITLE": "Key Replacement",
                "ACCT_NO": "B21041",
                "ACCT_TITLE": "Key Replacement Protection Gross",
                "R110": "137.2670",
                "R120": "210.4560",
                "R130": "304.9590",
                "R150": "610.2000",
                "R160": "266.2210",
                "R170": "80.4510",
                "R210": "95.6520",
                "R220": "352.5870",
                "R230": "153.4530",
                "R800": "19.6370",
                "NATLAVG": "205.8770",
                "UNITS_FLAG": null,
                "SECTIONNO": 8,
                "ACCTSEQNO": 64
              },
              {
                "SECTIONID": 9,
                "SECTIONTITLE": "Key Replacement",
                "ACCT_NO": "B21042",
                "ACCT_TITLE": "Number of Key Replacement Protection Contracts Sold",
                "R110": "0.9600",
                "R120": "0.4740",
                "R130": "1.5480",
                "R150": "2.8140",
                "R160": "1.6280",
                "R170": "0.2680",
                "R210": "0.2230",
                "R220": "0.9260",
                "R230": "0.5200",
                "R800": "0.0590",
                "NATLAVG": "0.8260",
                "UNITS_FLAG": null,
                "SECTIONNO": 8,
                "ACCTSEQNO": 65
              },
              {
                "SECTIONID": 9,
                "SECTIONTITLE": "Key Replacement",
                "ACCT_NO": "B21043",
                "ACCT_TITLE": "Key Replacement Protection Gross Per Contract Sold",
                "R110": "142.9860",
                "R120": "444.0000",
                "R130": "197.0020",
                "R150": "216.8440",
                "R160": "163.5260",
                "R170": "300.1900",
                "R210": "428.9330",
                "R220": "380.7630",
                "R230": "295.1020",
                "R800": "332.8310",
                "NATLAVG": "249.2460",
                "UNITS_FLAG": null,
                "SECTIONNO": 8,
                "ACCTSEQNO": 66
              },
              {
                "SECTIONID": 9,
                "SECTIONTITLE": "Key Replacement",
                "ACCT_NO": "B21044",
                "ACCT_TITLE": "Key Replacement Protection Contract Penetration",
                "R110": "1.1200",
                "R120": "0.9240",
                "R130": "3.1280",
                "R150": "3.6570",
                "R160": "3.5260",
                "R170": "0.5970",
                "R210": "0.5370",
                "R220": "2.8920",
                "R230": "2.1050",
                "R800": "0.1490",
                "NATLAVG": "1.6850",
                "UNITS_FLAG": null,
                "SECTIONNO": 8,
                "ACCTSEQNO": 67
              },
              {
                "SECTIONID": 9,
                "SECTIONTITLE": "Key Replacement",
                "ACCT_NO": "B21045",
                "ACCT_TITLE": "Key Replacement Protection Gross % Total FS gross",
                "R110": "16801875.7000",
                "R120": "9218883.4000",
                "R130": "8297271.6000",
                "R150": "13667023.5000",
                "R160": "8462116.0000",
                "R170": "6013619.7000",
                "R210": "7015553.7000",
                "R220": "4624160.9000",
                "R230": "3363482.2000",
                "R800": "6241198.2000",
                "NATLAVG": "8453020.6000",
                "UNITS_FLAG": null,
                "SECTIONNO": 8,
                "ACCTSEQNO": 68
              },
              {
                "SECTIONID": 9,
                "SECTIONTITLE": "Key Replacement",
                "ACCT_NO": "B21046",
                "ACCT_TITLE": "Key Replacement Protection Chargebacks",
                "R110": "0.0000",
                "R120": "-29.4560",
                "R130": "-15.5750",
                "R150": "-2.6710",
                "R160": "0.0000",
                "R170": "0.0000",
                "R210": "-0.9020",
                "R220": "0.0000",
                "R230": "0.0000",
                "R800": "0.0000",
                "NATLAVG": "-3.6960",
                "UNITS_FLAG": null,
                "SECTIONNO": 8,
                "ACCTSEQNO": 69
              },
              {
                "SECTIONID": 9,
                "SECTIONTITLE": "Key Replacement",
                "ACCT_NO": "B21047",
                "ACCT_TITLE": "Key Replacement Protection Chargebacks % Total Chargebacks",
                "R110": "0.0000",
                "R120": "0.4430",
                "R130": "0.2430",
                "R150": "0.0280",
                "R160": "0.0000",
                "R170": "0.0000",
                "R210": "0.0180",
                "R220": "0.0000",
                "R230": "0.0000",
                "R800": "0.0000",
                "NATLAVG": "0.0570",
                "UNITS_FLAG": null,
                "SECTIONNO": 8,
                "ACCTSEQNO": 70
              },
              {
                "SECTIONID": 9,
                "SECTIONTITLE": "Key Replacement",
                "ACCT_NO": "B21048",
                "ACCT_TITLE": "Key Replacement Protection Net After Chargebacks Per Contract Sold",
                "R110": "142.9860",
                "R120": "381.8570",
                "R130": "186.9410",
                "R150": "215.8950",
                "R160": "163.5260",
                "R170": "300.1900",
                "R210": "424.8880",
                "R220": "380.7630",
                "R230": "295.1020",
                "R800": "332.8310",
                "NATLAVG": "244.7710",
                "UNITS_FLAG": null,
                "SECTIONNO": 8,
                "ACCTSEQNO": 71
              }
            ]
          },
          {
            "SECTIONNO": "9",
            "SECTIONTITLE": "Aftermarket Analysis",
            "sectionData": [
              {
                "SECTIONID": 1,
                "SECTIONTITLE": "Aftermarket Analysis",
                "ACCT_NO": "B21049",
                "ACCT_TITLE": "Total Aftermarket Gross",
                "R110": "23103.4270",
                "R120": "8654.2110",
                "R130": "8790.7530",
                "R150": "17991.3570",
                "R160": "9188.6280",
                "R170": "2230.7040",
                "R210": "4308.0000",
                "R220": "2808.8930",
                "R230": "1671.1730",
                "R800": "3857.0070",
                "NATLAVG": "7130.0540",
                "UNITS_FLAG": null,
                "SECTIONNO": 9,
                "ACCTSEQNO": 1
              },
              {
                "SECTIONID": 1,
                "SECTIONTITLE": "Aftermarket Analysis",
                "ACCT_NO": "B21050",
                "ACCT_TITLE": "Aftermarket Gross % Total FS Gross",
                "R110": "12.6880",
                "R120": "8.7590",
                "R130": "9.8360",
                "R150": "12.3090",
                "R160": "9.9810",
                "R170": "3.3910",
                "R210": "5.7380",
                "R220": "5.8030",
                "R230": "4.6840",
                "R800": "5.6790",
                "NATLAVG": "7.8390",
                "UNITS_FLAG": null,
                "SECTIONNO": 9,
                "ACCTSEQNO": 2
              },
              {
                "SECTIONID": 1,
                "SECTIONTITLE": "Aftermarket Analysis",
                "ACCT_NO": "B21051",
                "ACCT_TITLE": "Aftermarket Gross PUVR",
                "R110": "269.4780",
                "R120": "168.7050",
                "R130": "177.6160",
                "R150": "233.8270",
                "R160": "198.9870",
                "R170": "49.6800",
                "R210": "103.6720",
                "R220": "87.7340",
                "R230": "67.6400",
                "R800": "97.3060",
                "NATLAVG": "145.4250",
                "UNITS_FLAG": null,
                "SECTIONNO": 9,
                "ACCTSEQNO": 3
              }
            ]
          },
          {
            "SECTIONNO": "10",
            "SECTIONTITLE": "FSD Income/Charge Back Analysis",
            "sectionData": [
              {
                "SECTIONID": 7,
                "SECTIONTITLE": "FSD Income/Charge Back Analysis",
                "ACCT_NO": "B21052",
                "ACCT_TITLE": "Retail Units",
                "R110": "205.0530",
                "R120": "142.6840",
                "R130": "162.1780",
                "R150": "216.6000",
                "R160": "114.6900",
                "R170": "133.2540",
                "R210": "146.5450",
                "R220": "141.2310",
                "R230": "126.6270",
                "R800": "136.2070",
                "NATLAVG": "167.1790",
                "UNITS_FLAG": null,
                "SECTIONNO": 10,
                "ACCTSEQNO": 44
              },
              {
                "SECTIONID": 7,
                "SECTIONTITLE": "FSD Income/Charge Back Analysis",
                "ACCT_NO": "B21053",
                "ACCT_TITLE": "Certified Units",
                "R110": "85.7340",
                "R120": "51.2980",
                "R130": "49.4930",
                "R150": "76.9430",
                "R160": "46.1770",
                "R170": "44.9010",
                "R210": "41.5540",
                "R220": "32.0160",
                "R230": "24.7070",
                "R800": "39.6380",
                "NATLAVG": "49.0290",
                "UNITS_FLAG": null,
                "SECTIONNO": 10,
                "ACCTSEQNO": 45
              },
              {
                "SECTIONID": 7,
                "SECTIONTITLE": "FSD Income/Charge Back Analysis",
                "ACCT_NO": "B21054",
                "ACCT_TITLE": "FS Department Gross",
                "R110": "205199.3870",
                "R120": "107457.3160",
                "R130": "98166.5200",
                "R150": "164156.9000",
                "R160": "101250.7960",
                "R170": "68017.0700",
                "R210": "79383.2590",
                "R220": "51214.5950",
                "R230": "37348.7870",
                "R800": "71778.2290",
                "NATLAVG": "98089.2670",
                "UNITS_FLAG": null,
                "SECTIONNO": 10,
                "ACCTSEQNO": 46
              },
              {
                "SECTIONID": 7,
                "SECTIONTITLE": "FSD Income/Charge Back Analysis",
                "ACCT_NO": "B21055",
                "ACCT_TITLE": "FS Department Gross PUVR",
                "R110": "2393.4420",
                "R120": "2094.7660",
                "R130": "1983.4430",
                "R150": "2133.4870",
                "R160": "2192.6670",
                "R170": "1514.8230",
                "R210": "1910.3640",
                "R220": "1599.6560",
                "R230": "1511.6680",
                "R800": "1810.8440",
                "NATLAVG": "2000.6380",
                "UNITS_FLAG": null,
                "SECTIONNO": 10,
                "ACCTSEQNO": 47
              },
              {
                "SECTIONID": 7,
                "SECTIONTITLE": "FSD Income/Charge Back Analysis",
                "ACCT_NO": "B21058",
                "ACCT_TITLE": "FS Department Chargebacks",
                "R110": "-14077.2130",
                "R120": "-6643.7540",
                "R130": "-6418.6710",
                "R150": "-9498.0430",
                "R160": "-7441.0440",
                "R170": "-5650.1830",
                "R210": "-4920.6430",
                "R220": "-2164.2560",
                "R230": "-2042.8670",
                "R800": "-5509.2440",
                "NATLAVG": "-6432.7340",
                "UNITS_FLAG": null,
                "SECTIONNO": 10,
                "ACCTSEQNO": 50
              },
              {
                "SECTIONID": 7,
                "SECTIONTITLE": "FSD Income/Charge Back Analysis",
                "ACCT_NO": "B21059",
                "ACCT_TITLE": "FS Department Chargebacks % FS Gross",
                "R110": "2310335.0000",
                "R120": "865414.4000",
                "R130": "879068.1000",
                "R150": "1799129.2000",
                "R160": "918854.7000",
                "R170": "223061.8000",
                "R210": "430793.4000",
                "R220": "280884.8000",
                "R230": "167111.6000",
                "R800": "385692.6000",
                "NATLAVG": "712998.3000",
                "UNITS_FLAG": null,
                "SECTIONNO": 10,
                "ACCTSEQNO": 51
              },
              {
                "SECTIONID": 7,
                "SECTIONTITLE": "FSD Income/Charge Back Analysis",
                "ACCT_NO": "B21060",
                "ACCT_TITLE": "FS Department Net After Chargebacks",
                "R110": "168018.7470",
                "R120": "92159.3510",
                "R130": "82957.0960",
                "R150": "136667.5000",
                "R160": "84621.1240",
                "R170": "60136.1830",
                "R210": "70154.6160",
                "R220": "46241.4460",
                "R230": "33634.7470",
                "R800": "62411.9780",
                "NATLAVG": "84526.4790",
                "UNITS_FLAG": null,
                "SECTIONNO": 10,
                "ACCTSEQNO": 52
              },
              {
                "SECTIONID": 7,
                "SECTIONTITLE": "FSD Income/Charge Back Analysis",
                "ACCT_NO": "B21061",
                "ACCT_TITLE": "FS Department Net After Chargebacks PUVR",
                "R110": "1959.7680",
                "R120": "1796.5490",
                "R130": "1676.1380",
                "R150": "1776.2170",
                "R160": "1832.5380",
                "R170": "1339.3060",
                "R210": "1688.2760",
                "R220": "1444.3230",
                "R230": "1361.3450",
                "R800": "1574.5490",
                "NATLAVG": "1724.0100",
                "UNITS_FLAG": null,
                "SECTIONNO": 10,
                "ACCTSEQNO": 53
              }
            ]
          }
        ],
        "reportHeaderData": [
          {
            "COMPANY_CODE": "14",
            "COMPANY_NAME": "Toyota",
            "COMPANY": "14 - Toyota",
            "REGION_CODE": "110",
            "REGION": "110 - Los Angeles",
            "REGION_NAME": losAngleles,
            "REGION_ALPHA_CODE": "LA      "
          },
          {
            "COMPANY_CODE": "14",
            "COMPANY_NAME": "Toyota",
            "COMPANY": "14 - Toyota",
            "REGION_CODE": "130",
            "REGION": "130 - Portland",
            "REGION_NAME": "Portland",
            "REGION_ALPHA_CODE": "PTL     "
          },
          {
            "COMPANY_CODE": "14",
            "COMPANY_NAME": "Toyota",
            "COMPANY": "14 - Toyota",
            "REGION_CODE": "160",
            "REGION": "160 - New York",
            "REGION_NAME": "New York",
            "REGION_ALPHA_CODE": "NY      "
          },
          {
            "COMPANY_CODE": "14",
            "COMPANY_NAME": "Toyota",
            "COMPANY": "14 - Toyota",
            "REGION_CODE": "170",
            "REGION": "170 - Boston",
            "REGION_NAME": "Boston",
            "REGION_ALPHA_CODE": "BOS     "
          },
          {
            "COMPANY_CODE": "14",
            "COMPANY_NAME": "Toyota",
            "COMPANY": "14 - Toyota",
            "REGION_CODE": "220",
            "REGION": "220 - Cincinnati",
            "REGION_NAME": "Cincinnati",
            "REGION_ALPHA_CODE": "CIN     "
          },
          {
            "COMPANY_CODE": "14",
            "COMPANY_NAME": "Toyota",
            "COMPANY": "14 - Toyota",
            "REGION_CODE": "120",
            "REGION": "120 - San Francisco",
            "REGION_NAME": "San Francisco",
            "REGION_ALPHA_CODE": "SF      "
          },
          {
            "COMPANY_CODE": "14",
            "COMPANY_NAME": "Toyota",
            "COMPANY": "14 - Toyota",
            "REGION_CODE": "150",
            "REGION": "150 - Denver",
            "REGION_NAME": "Denver",
            "REGION_ALPHA_CODE": "DEN     "
          },
          {
            "COMPANY_CODE": "14",
            "COMPANY_NAME": "Toyota",
            "COMPANY": "14 - Toyota",
            "REGION_CODE": "210",
            "REGION": "210 - Chicago",
            "REGION_NAME": "Chicago",
            "REGION_ALPHA_CODE": "CHI     "
          },
          {
            "COMPANY_CODE": "14",
            "COMPANY_NAME": "Toyota",
            "COMPANY": "14 - Toyota",
            "REGION_CODE": "230",
            "REGION": "230 - Kansas City",
            "REGION_NAME": "Kansas City",
            "REGION_ALPHA_CODE": "KC      "
          },
          {
            "COMPANY_CODE": "14",
            "COMPANY_NAME": "Toyota",
            "COMPANY": "14 - Toyota",
            "REGION_CODE": "800",
            "REGION": "800 - Central Atlantic Toyota",
            "REGION_NAME": "Central Atlantic Toyota",
            "REGION_ALPHA_CODE": "CAT     "
          }
        ]
      }
    ]
  },
  status: 200
}; //NOSONAR

export const SalesAnalysisUsedVehicleMockData = {
  body: [
    {
      "reportHeaderData": [
        {
          "COMPANY_CODE": "14",
          "COMPANY_NAME": "Toyota",
          "COMPANY": "14 - Toyota",
          "REGION_CODE": "120",
          "REGION": "120 - San Francisco",
          "REGION_NAME": "San Francisco",
          "DISTRICT_CODE": "120D",
          "DISTRICT_NAME": "District 4 - SF Metro/North Bay",
          "DEALER_CODE": "04285",
          "DEALER_NAME": "ENVISION TOYOTA/NORWALK",
          "DEALER": "04143 - City Toyota",
          "NATIONAL": "NATIONAL"
        }
      ],
      "reportData": [
        {
          "SECTIONID": 1,
          "SECTIONTITLE": "Sales",
          "sectionData": [
            {
              "SECTIONID": 1,
              "SECTIONTITLE": "Sales",
              "SECTIONLINENO": 1,
              "ACCT_NAME": "TTL Toyota/Trac Used Veh R/L Aft Recon Units",
              "CLASS AVG": "34",
              "SELECTED MONTH": "29",
              "YTD": "265",
              "TMS GUIDE": null,
              "NCM GUIDE": null
            }
          ]
        },
        {
          "SECTIONID": 2,
          "SECTIONTITLE": "SALES - SAME MONTH PRIOR YEAR",
          "sectionData": [
            {
              "SECTIONID": 2,
              "SECTIONTITLE": "SALES - SAME MONTH PRIOR YEAR",
              "SECTIONLINENO": 2,
              "ACCT_NAME": "TTL Toyota/Trac Used Veh R/L Aft Recon Units",
              "CLASS AVG": "34",
              "SELECTED MONTH": "60",
              "YTD": "306",
              "TMS GUIDE": null,
              "NCM GUIDE": null
            },
            {
              "SECTIONID": 2,
              "SECTIONTITLE": "SALES - SAME MONTH PRIOR YEAR",
              "SECTIONLINENO": 2,
              "ACCT_NAME": "%Change-Tot UsedRetail & Lease",
              "CLASS AVG": "1",
              "SELECTED MONTH": "0",
              "YTD": "0",
              "TMS GUIDE": null,
              "NCM GUIDE": null
            }
          ]
        },
        {
          "SECTIONID": 3,
          "SECTIONTITLE": "ASSET MANAGEMENT",
          "sectionData": [
            {
              "SECTIONID": 3,
              "SECTIONTITLE": "ASSET MANAGEMENT",
              "SECTIONLINENO": 3,
              "ACCT_NAME": "Other Used Veh Inventory-$",
              "CLASS AVG": "34",
              "SELECTED MONTH": "60",
              "YTD": "306",
              "TMS GUIDE": null,
              "NCM GUIDE": null
            },
            {
              "SECTIONID": 3,
              "SECTIONTITLE": "ASSET MANAGEMENT",
              "SECTIONLINENO": 3,
              "ACCT_NAME": "Other Used Vehicles-Inv",
              "CLASS AVG": "1",
              "SELECTED MONTH": "0",
              "YTD": "0",
              "TMS GUIDE": null,
              "NCM GUIDE": null
            },
            {
              "SECTIONID": 3,
              "SECTIONTITLE": "ASSET MANAGEMENT",
              "SECTIONLINENO": 3,
              "ACCT_NAME": "Toyota Car Sales YTD% Change",
              "CLASS AVG": "(18.5%)",
              "SELECTED MONTH": "(51.7%)",
              "YTD": "(13.4%)",
              "TMS GUIDE": null,
              "NCM GUIDE": null
            }
          ]
        }
      ]
    }
  ]
}; //NOSONAR

export const customSearchObject = [
  { key: 'date', value: '12/01/2023' },
  { key: 'dealer', value: '202202' },
  { key: 'company', value: 'lexus' }
]; //NOSONAR

export const mockCompanyDealers = [
  { id: 1, region: 'LA', district: 'A', dealer_code: '04011', dealername: 'HOOMAN toyota of long beach' },
  { id: 2, region: 'LA', district: 'A', dealer_code: '04011', dealername: 'Cabe toyota of long beach' },
  { id: 3, region: 'LA', district: 'A', dealer_code: '04011', dealername: 'NORWALK toyota of long beach' },
  { id: 4, region: 'LA', district: 'A', dealer_code: '04011', dealername: 'BEACH CITIES toyota of long beach' },
  { id: 5, region: 'LA', district: 'A', dealer_code: '04011', dealername: 'Toyota of long beach' },
  { id: 6, region: 'LA', district: 'A', dealer_code: '04011', dealername: 'PENSKY toyota of long beach' }

]; //NOSONAR

export const HrcyMemMock = [
  { dealer_code: 310, dealer_name: 'Western' },
  { dealer_code: 320, dealer_name: 'Central' },
  { dealer_code: 330, dealer_name: 'Estern' },
  { dealer_code: 340, dealer_name: 'Southern' },
]; //NOSONAR

export const HrcyMock = [
  { name: 'Dealer', id: HIERARCHY_TYPE.DEALER },
  { name: 'District', id: HIERARCHY_TYPE.DISTRICT },
  { name: 'Region', id: HIERARCHY_TYPE.REGION },
  { name: 'National', id: HIERARCHY_TYPE.NATIONAL },
  { name: "Area", id: HIERARCHY_TYPE.AREA },
  { name: "DSSO", id: HIERARCHY_TYPE.DSSO },
]; //NOSONAR



export const CompanyTypeMock = [
  { name: 'Toyota', id: COMPANY_TYPE.TOYOTA },
  { name: 'Lexus', id: COMPANY_TYPE.LEXUS }
]; //NOSONAR



export const financemock = [
  {
    "1": "TCUV Sales",
    "2000": "37.0",
    "2001": "33.35",
    "% change": "33.35",
    "2002": "37.0",
    "2003": "33.35",
    "DISTRICT": "33.35",
    "REGIONS": "31.691",
    "NATIONAL": "31.691"
  },
  {
    "1": "TCUV TRAC Sls",
    "2000": "37.0",
    "2001": "33.35",
    "% change": "33.35",
    "2002": "37.0",
    "2003": "33.35",
    "DISTRICT": "33.35",
    "REGIONS": "31.691",
    "NATIONAL": "31.691"
  },
  {
    "1": "TCUV TRAC Sls",
    "2000": "37.0",
    "2001": "33.35",
    "% change": "33.35",
    "2002": "37.0",
    "2003": "33.35",
    "DISTRICT": "33.35",
    "REGIONS": "31.691",
    "NATIONAL": "31.691"
  },
  {
    "1": "Total TCUV Sales",
    "2000": "37.0",
    "2001": "33.35",
    "% change": "33.35",
    "2002": "37.0",
    "2003": "33.35",
    "DISTRICT": "33.35",
    "REGIONS": "31.691",
    "NATIONAL": "31.691"
  },
  {
    "1": "Toyota Used",
    "2000": "37.0",
    "2001": "33.35",
    "% change": "33.35",
    "2002": "37.0",
    "2003": "33.35",
    "DISTRICT": "33.35",
    "REGIONS": "31.691",
    "NATIONAL": "31.691"
  },
  {
    "1": "Non Toyota Used",
    "2000": "37.0",
    "2001": "33.35",
    "% change": "33.35",
    "2002": "37.0",
    "2003": "33.35",
    "DISTRICT": "33.35",
    "REGIONS": "31.691",
    "NATIONAL": "31.691"
  },
  {
    "1": "Total Used Sales",
    "2000": "37.0",
    "2001": "33.35",
    "% change": "33.35",
    "2002": "37.0",
    "2003": "33.35",
    "DISTRICT": "33.35",
    "REGIONS": "31.691",
    "NATIONAL": "31.691"
  },
  {
    "1": "TCUV to Ttl Used",
    "2000": "37.0",
    "2001": "33.35",
    "% change": "33.35",
    "2002": "37.0",
    "2003": "33.35",
    "DISTRICT": "33.35",
    "REGIONS": "31.691",
    "NATIONAL": "31.691"
  }
]; //NOSONAR

export const DealerReviewReportMockData = {
  body: [
    {
      "reportData": [
        [
          {
            "SECTIONID": "10",
            "SECTIONTITLE": "Year",
            "sectionData": [
              {
                "SECTIONID": "10",
                "SECTIONTITLE": "Year",
                "YEAR_TAG": "2ndP",
                "PCAR_SALES": "217",
                "PCAR_SALES_CHANGE": "-24.7%",
                "PCAR_SALES_EFFICIENCY": "165.60",
                "PCAR_MARKET_SHARE": "22.19",
                "PCAR_LEADERSHIP": "TOY",
                "LTRUCK_SALES": "487",
                "LTRUCK_SALES_CHANGE": "-1.8%",
                "LTRUCK_SALES_EFFICIENCY": "257.50",
                "LTRUCK_MARKET_SHARE": "18.45",
                "LTRUCK_LEADERSHIP": "TOY",
                "TOTAL_NEW_SALES": "704",
                "TOTAL_NEW_SALES_CHANGE": "-10.2%",
                "TOTAL_INSDUSTRY_REGIS": "3,866",
                "TOTAL_INSDUSTRY_REGIS_CHANGE": "35.7%",
                "TOTAL_TOYOTA_REGIS": "767",
                "TOTAL_TOYOTA_REGIS_CHANGE": "41.8%",
                "TOTAL_USED_SALES": "146",
                "TOTAL_USED_SALES_CHANGE": "21.7%"
              },
              {
                "SECTIONID": "10",
                "SECTIONTITLE": "Year",
                "YEAR_TAG": "2ndP",
                "PCAR_SALES": "236",
                "PCAR_SALES_CHANGE": "8.8%",
                "PCAR_SALES_EFFICIENCY": "142.80",
                "PCAR_MARKET_SHARE": "21.92",
                "PCAR_LEADERSHIP": "HON",
                "LTRUCK_SALES": "492",
                "LTRUCK_SALES_CHANGE": "1.0%",
                "LTRUCK_SALES_EFFICIENCY": "211.50",
                "LTRUCK_MARKET_SHARE": "16.66",
                "LTRUCK_LEADERSHIP": "TOY",
                "TOTAL_NEW_SALES": "728",
                "TOTAL_NEW_SALES_CHANGE": "3.4%",
                "TOTAL_INSDUSTRY_REGIS": "4,372",
                "TOTAL_INSDUSTRY_REGIS_CHANGE": "13.1%",
                "TOTAL_TOYOTA_REGIS": "872",
                "TOTAL_TOYOTA_REGIS_CHANGE": "13.7%",
                "TOTAL_USED_SALES": "143",
                "TOTAL_USED_SALES_CHANGE": "-2.1%"
              },
              {
                "SECTIONID": "10",
                "SECTIONTITLE": "Year",
                "YEAR_TAG": "2ndP",
                "PCAR_SALES": "223",
                "PCAR_SALES_CHANGE": "-5.5%",
                "PCAR_SALES_EFFICIENCY": "134.70",
                "PCAR_MARKET_SHARE": "22.29",
                "PCAR_LEADERSHIP": "HON",
                "LTRUCK_SALES": "554",
                "LTRUCK_SALES_CHANGE": "12.6%",
                "LTRUCK_SALES_EFFICIENCY": "152.90",
                "LTRUCK_MARKET_SHARE": "18.15",
                "LTRUCK_LEADERSHIP": "TOY",
                "TOTAL_NEW_SALES": "777",
                "TOTAL_NEW_SALES_CHANGE": "6.7%",
                "TOTAL_INSDUSTRY_REGIS": "4,474",
                "TOTAL_INSDUSTRY_REGIS_CHANGE": "2.3%",
                "TOTAL_TOYOTA_REGIS": "876",
                "TOTAL_TOYOTA_REGIS_CHANGE": "0.5%",
                "TOTAL_USED_SALES": "106",
                "TOTAL_USED_SALES_CHANGE": "-25.9%"
              }
            ]
          },
          {
            "SECTIONID": "12",
            "SECTIONTITLE": "Region 110",
            "sectionData": [
              {
                "SECTIONID": "12",
                "SECTIONTITLE": "Region 110",
                "YEAR_TAG": "2ndP",
                "PCAR_SALES": "730",
                "PCAR_SALES_CHANGE": "-23.6%",
                "PCAR_SALES_EFFICIENCY": "NULL",
                "PCAR_MARKET_SHARE": "21.53",
                "PCAR_LEADERSHIP": "NULL",
                "LTRUCK_SALES": "1,061",
                "LTRUCK_SALES_CHANGE": "-13.3%",
                "LTRUCK_SALES_EFFICIENCY": "NULL",
                "LTRUCK_MARKET_SHARE": "15.27",
                "LTRUCK_LEADERSHIP": "NULL",
                "TOTAL_NEW_SALES": "1,792",
                "TOTAL_NEW_SALES_CHANGE": "-17.8%",
                "TOTAL_INSDUSTRY_REGIS": "11623",
                "TOTAL_INSDUSTRY_REGIS_CHANGE": "29.9%",
                "TOTAL_TOYOTA_REGIS": "2332",
                "TOTAL_TOYOTA_REGIS_CHANGE": "39.7%",
                "TOTAL_USED_SALES": "415",
                "TOTAL_USED_SALES_CHANGE": "NULL"
              },
              {
                "SECTIONID": "12",
                "SECTIONTITLE": "Region 110",
                "YEAR_TAG": "2ndP",
                "PCAR_SALES": "747",
                "PCAR_SALES_CHANGE": "2.3%",
                "PCAR_SALES_EFFICIENCY": "NULL",
                "PCAR_MARKET_SHARE": "19.99",
                "PCAR_LEADERSHIP": "NULL",
                "LTRUCK_SALES": "1,099",
                "LTRUCK_SALES_CHANGE": "3.6%",
                "LTRUCK_SALES_EFFICIENCY": "NULL",
                "LTRUCK_MARKET_SHARE": "13.93",
                "LTRUCK_LEADERSHIP": "NULL",
                "TOTAL_NEW_SALES": "1,846",
                "TOTAL_NEW_SALES_CHANGE": "3.1%",
                "TOTAL_INSDUSTRY_REGIS": "13,020",
                "TOTAL_INSDUSTRY_REGIS_CHANGE": "12.0%",
                "TOTAL_TOYOTA_REGIS": "2510",
                "TOTAL_TOYOTA_REGIS_CHANGE": "7.6%",
                "TOTAL_USED_SALES": "406",
                "TOTAL_USED_SALES_CHANGE": "NULL"
              },
              {
                "SECTIONID": "12",
                "SECTIONTITLE": "Region 110",
                "YEAR_TAG": "2ndP",
                "PCAR_SALES": "734",
                "PCAR_SALES_CHANGE": "-1.8%",
                "PCAR_SALES_EFFICIENCY": "NULL",
                "PCAR_MARKET_SHARE": "21.38",
                "PCAR_LEADERSHIP": "NULL",
                "LTRUCK_SALES": "1,186",
                "LTRUCK_SALES_CHANGE": "7.9%",
                "LTRUCK_SALES_EFFICIENCY": "NULL",
                "LTRUCK_MARKET_SHARE": "14.22",
                "LTRUCK_LEADERSHIP": "NULL",
                "TOTAL_NEW_SALES": "1,920",
                "TOTAL_NEW_SALES_CHANGE": "4.0%",
                "TOTAL_INSDUSTRY_REGIS": "13,333",
                "TOTAL_INSDUSTRY_REGIS_CHANGE": "2.4%",
                "TOTAL_TOYOTA_REGIS": "2567",
                "TOTAL_TOYOTA_REGIS_CHANGE": "2.3%",
                "TOTAL_USED_SALES": "393",
                "TOTAL_USED_SALES_CHANGE": "NULL"
              }
            ]
          }
        ],
        [
          {
            "SECTIONID": "10",
            "SECTIONTITLE": "Year",
            "sectionData": [
              {
                "SECTIONID": "10",
                "SECTIONTITLE": "Year",
                "YEAR_TAG": "2ndP",
                "CUST_PAY_LABOR": "2,824,589",
                "CUST_PAY_LABOR_CHANGE": "26.8%",
                "CUST_PAY_RO": "17,133",
                "CUST_PAY_RO_CHANGE": "1.0%",
                "RO_MECH_PARTS": "1,445,719",
                "RO_MECH_PARTS_CHANGE": "10.9%",
                "RETAIL_PARTS": "613,306",
                "RETAIL_PARTS_CHANGE": "14.6%",
                "WHOLESALES_PARTS": "3,463,349",
                "WHOLESALES_PARTS_CHANGE": "13.9%",
                "TOTAL_SOLD": "NULL",
                "ONE_VISIT_SOLD": "NULL",
                "TWO_VISIT_SOLD": "NULL",
                "SSI": "NULL",
                "VDQ": "NULL",
                "SPSI": "NULL",
                "PERCENT_SALES": "5.03",
                "BEFORE_TAXES": "2,578,990"
              },
              {
                "SECTIONID": "10",
                "SECTIONTITLE": "Year",
                "YEAR_TAG": "2ndP",
                "CUST_PAY_LABOR": "2,824,589",
                "CUST_PAY_LABOR_CHANGE": "26.8%",
                "CUST_PAY_RO": "17,133",
                "CUST_PAY_RO_CHANGE": "1.0%",
                "RO_MECH_PARTS": "1,445,719",
                "RO_MECH_PARTS_CHANGE": "10.9%",
                "RETAIL_PARTS": "613,306",
                "RETAIL_PARTS_CHANGE": "14.6%",
                "WHOLESALES_PARTS": "3,463,349",
                "WHOLESALES_PARTS_CHANGE": "13.9%",
                "TOTAL_SOLD": "NULL",
                "ONE_VISIT_SOLD": "NULL",
                "TWO_VISIT_SOLD": "NULL",
                "SSI": "NULL",
                "VDQ": "NULL",
                "SPSI": "NULL",
                "PERCENT_SALES": "5.03",
                "BEFORE_TAXES": "2,578,990"
              },
              {
                "SECTIONID": "10",
                "SECTIONTITLE": "Year",
                "YEAR_TAG": "2ndP",
                "CUST_PAY_LABOR": "2,824,589",
                "CUST_PAY_LABOR_CHANGE": "26.8%",
                "CUST_PAY_RO": "17,133",
                "CUST_PAY_RO_CHANGE": "1.0%",
                "RO_MECH_PARTS": "1,445,719",
                "RO_MECH_PARTS_CHANGE": "10.9%",
                "RETAIL_PARTS": "613,306",
                "RETAIL_PARTS_CHANGE": "14.6%",
                "WHOLESALES_PARTS": "3,463,349",
                "WHOLESALES_PARTS_CHANGE": "13.9%",
                "TOTAL_SOLD": "NULL",
                "ONE_VISIT_SOLD": "NULL",
                "TWO_VISIT_SOLD": "NULL",
                "SSI": "NULL",
                "VDQ": "NULL",
                "SPSI": "NULL",
                "PERCENT_SALES": "5.03",
                "BEFORE_TAXES": "2,578,990"
              }
            ]
          },
          {
            "SECTIONID": "12",
            "SECTIONTITLE": "Region 110",
            "sectionData": [
              {
                "SECTIONID": "12",
                "SECTIONTITLE": "Region 110",
                "YEAR_TAG": "2ndP",
                "CUST_PAY_LABOR": "2,824,589",
                "CUST_PAY_LABOR_CHANGE": "26.8%",
                "CUST_PAY_RO": "17,133",
                "CUST_PAY_RO_CHANGE": "1.0%",
                "RO_MECH_PARTS": "1,445,719",
                "RO_MECH_PARTS_CHANGE": "10.9%",
                "RETAIL_PARTS": "613,306",
                "RETAIL_PARTS_CHANGE": "14.6%",
                "WHOLESALES_PARTS": "3,463,349",
                "WHOLESALES_PARTS_CHANGE": "13.9%",
                "TOTAL_SOLD": "NULL",
                "ONE_VISIT_SOLD": "NULL",
                "TWO_VISIT_SOLD": "NULL",
                "SSI": "NULL",
                "VDQ": "NULL",
                "SPSI": "NULL",
                "PERCENT_SALES": "5.03",
                "BEFORE_TAXES": "2,578,990"
              },
              {
                "SECTIONID": "12",
                "SECTIONTITLE": "Region 110",
                "YEAR_TAG": "2ndP",
                "CUST_PAY_LABOR": "2,824,589",
                "CUST_PAY_LABOR_CHANGE": "26.8%",
                "CUST_PAY_RO": "17,133",
                "CUST_PAY_RO_CHANGE": "1.0%",
                "RO_MECH_PARTS": "1,445,719",
                "RO_MECH_PARTS_CHANGE": "10.9%",
                "RETAIL_PARTS": "613,306",
                "RETAIL_PARTS_CHANGE": "14.6%",
                "WHOLESALES_PARTS": "3,463,349",
                "WHOLESALES_PARTS_CHANGE": "13.9%",
                "TOTAL_SOLD": "NULL",
                "ONE_VISIT_SOLD": "NULL",
                "TWO_VISIT_SOLD": "NULL",
                "SSI": "NULL",
                "VDQ": "NULL",
                "SPSI": "NULL",
                "PERCENT_SALES": "5.03",
                "BEFORE_TAXES": "2,578,990"
              },
              {
                "SECTIONID": "12",
                "SECTIONTITLE": "Region 110",
                "YEAR_TAG": "2ndP",
                "CUST_PAY_LABOR": "2,824,589",
                "CUST_PAY_LABOR_CHANGE": "26.8%",
                "CUST_PAY_RO": "17,133",
                "CUST_PAY_RO_CHANGE": "1.0%",
                "RO_MECH_PARTS": "1,445,719",
                "RO_MECH_PARTS_CHANGE": "10.9%",
                "RETAIL_PARTS": "613,306",
                "RETAIL_PARTS_CHANGE": "14.6%",
                "WHOLESALES_PARTS": "3,463,349",
                "WHOLESALES_PARTS_CHANGE": "13.9%",
                "TOTAL_SOLD": "NULL",
                "ONE_VISIT_SOLD": "NULL",
                "TWO_VISIT_SOLD": "NULL",
                "SSI": "NULL",
                "VDQ": "NULL",
                "SPSI": "NULL",
                "PERCENT_SALES": "5.03",
                "BEFORE_TAXES": "2,578,990"
              }
            ]
          },
        ],
        [

          {
            "EXP": "NULL",
            "TDA": "24 Months",
            "PROVISIONS": "Facility Non-Waiver - Submit detailed written facility action plan",
            "DEADLINES": "NULL",
            "DEADLINES_EMPTY": "NULL",
            "PHASE": "Image USA II- Compliant",
            "DATE_LAST_MEASURED": "NULL"
          },
          {
            "EXP": "NULL",
            "TDA": "NULL",
            "PROVISIONS": "Compliance with Capitalization and Financial Requirements - Non-Waiver",
            "DEADLINES": "NULL",
            "DEADLINES_EMPTY": "NULL",
            "PHASE": "NULL",
            "DATE_LAST_MEASURED": "NULL"
          },
          {
            "EXP": "NULL",
            "TDA": "NULL",
            "PROVISIONS": "Achieve 90% Sales Efficiency",
            "DEADLINES": "NULL",
            "DEADLINES_EMPTY": "NULL",
            "PHASE": "NULL",
            "DATE_LAST_MEASURED": "NULL"
          }

        ]
      ],
      "reportHeaderData": [
        {
          "REGION_CODE": "110"
        }
      ]
    },
    {
      "reportData": [
        [
          {
            "SECTIONID": "10",
            "SECTIONTITLE": "Year",
            "sectionData": [
              {
                "SECTIONID": "10",
                "SECTIONTITLE": "Year",
                "YEAR_TAG": "2ndP",
                "PCAR_SALES": "217",
                "PCAR_SALES_CHANGE": "-24.7%",
                "PCAR_SALES_EFFICIENCY": "165.60",
                "PCAR_MARKET_SHARE": "22.19",
                "PCAR_LEADERSHIP": "TOY",
                "LTRUCK_SALES": "487",
                "LTRUCK_SALES_CHANGE": "-1.8%",
                "LTRUCK_SALES_EFFICIENCY": "257.50",
                "LTRUCK_MARKET_SHARE": "18.45",
                "LTRUCK_LEADERSHIP": "TOY",
                "TOTAL_NEW_SALES": "704",
                "TOTAL_NEW_SALES_CHANGE": "-10.2%",
                "TOTAL_INSDUSTRY_REGIS": "3,866",
                "TOTAL_INSDUSTRY_REGIS_CHANGE": "35.7%",
                "TOTAL_TOYOTA_REGIS": "767",
                "TOTAL_TOYOTA_REGIS_CHANGE": "41.8%",
                "TOTAL_USED_SALES": "146",
                "TOTAL_USED_SALES_CHANGE": "21.7%"
              },
              {
                "SECTIONID": "10",
                "SECTIONTITLE": "Year",
                "YEAR_TAG": "2ndP",
                "PCAR_SALES": "236",
                "PCAR_SALES_CHANGE": "8.8%",
                "PCAR_SALES_EFFICIENCY": "142.80",
                "PCAR_MARKET_SHARE": "21.92",
                "PCAR_LEADERSHIP": "HON",
                "LTRUCK_SALES": "492",
                "LTRUCK_SALES_CHANGE": "1.0%",
                "LTRUCK_SALES_EFFICIENCY": "211.50",
                "LTRUCK_MARKET_SHARE": "16.66",
                "LTRUCK_LEADERSHIP": "TOY",
                "TOTAL_NEW_SALES": "728",
                "TOTAL_NEW_SALES_CHANGE": "3.4%",
                "TOTAL_INSDUSTRY_REGIS": "4,372",
                "TOTAL_INSDUSTRY_REGIS_CHANGE": "13.1%",
                "TOTAL_TOYOTA_REGIS": "872",
                "TOTAL_TOYOTA_REGIS_CHANGE": "13.7%",
                "TOTAL_USED_SALES": "143",
                "TOTAL_USED_SALES_CHANGE": "-2.1%"
              },
              {
                "SECTIONID": "10",
                "SECTIONTITLE": "Year",
                "YEAR_TAG": "2ndP",
                "PCAR_SALES": "223",
                "PCAR_SALES_CHANGE": "-5.5%",
                "PCAR_SALES_EFFICIENCY": "134.70",
                "PCAR_MARKET_SHARE": "22.29",
                "PCAR_LEADERSHIP": "HON",
                "LTRUCK_SALES": "554",
                "LTRUCK_SALES_CHANGE": "12.6%",
                "LTRUCK_SALES_EFFICIENCY": "152.90",
                "LTRUCK_MARKET_SHARE": "18.15",
                "LTRUCK_LEADERSHIP": "TOY",
                "TOTAL_NEW_SALES": "777",
                "TOTAL_NEW_SALES_CHANGE": "6.7%",
                "TOTAL_INSDUSTRY_REGIS": "4,474",
                "TOTAL_INSDUSTRY_REGIS_CHANGE": "2.3%",
                "TOTAL_TOYOTA_REGIS": "876",
                "TOTAL_TOYOTA_REGIS_CHANGE": "0.5%",
                "TOTAL_USED_SALES": "106",
                "TOTAL_USED_SALES_CHANGE": "-25.9%"
              }
            ]
          },
          {
            "SECTIONID": "12",
            "SECTIONTITLE": "Region 110",
            "sectionData": [
              {
                "SECTIONID": "12",
                "SECTIONTITLE": "Region 110",
                "YEAR_TAG": "2ndP",
                "PCAR_SALES": "730",
                "PCAR_SALES_CHANGE": "-23.6%",
                "PCAR_SALES_EFFICIENCY": "NULL",
                "PCAR_MARKET_SHARE": "21.53",
                "PCAR_LEADERSHIP": "NULL",
                "LTRUCK_SALES": "1,061",
                "LTRUCK_SALES_CHANGE": "-13.3%",
                "LTRUCK_SALES_EFFICIENCY": "NULL",
                "LTRUCK_MARKET_SHARE": "15.27",
                "LTRUCK_LEADERSHIP": "NULL",
                "TOTAL_NEW_SALES": "1,792",
                "TOTAL_NEW_SALES_CHANGE": "-17.8%",
                "TOTAL_INSDUSTRY_REGIS": "11623",
                "TOTAL_INSDUSTRY_REGIS_CHANGE": "29.9%",
                "TOTAL_TOYOTA_REGIS": "2332",
                "TOTAL_TOYOTA_REGIS_CHANGE": "39.7%",
                "TOTAL_USED_SALES": "415",
                "TOTAL_USED_SALES_CHANGE": "NULL"
              },
              {
                "SECTIONID": "12",
                "SECTIONTITLE": "Region 110",
                "YEAR_TAG": "2ndP",
                "PCAR_SALES": "747",
                "PCAR_SALES_CHANGE": "2.3%",
                "PCAR_SALES_EFFICIENCY": "NULL",
                "PCAR_MARKET_SHARE": "19.99",
                "PCAR_LEADERSHIP": "NULL",
                "LTRUCK_SALES": "1,099",
                "LTRUCK_SALES_CHANGE": "3.6%",
                "LTRUCK_SALES_EFFICIENCY": "NULL",
                "LTRUCK_MARKET_SHARE": "13.93",
                "LTRUCK_LEADERSHIP": "NULL",
                "TOTAL_NEW_SALES": "1,846",
                "TOTAL_NEW_SALES_CHANGE": "3.1%",
                "TOTAL_INSDUSTRY_REGIS": "13,020",
                "TOTAL_INSDUSTRY_REGIS_CHANGE": "12.0%",
                "TOTAL_TOYOTA_REGIS": "2510",
                "TOTAL_TOYOTA_REGIS_CHANGE": "7.6%",
                "TOTAL_USED_SALES": "406",
                "TOTAL_USED_SALES_CHANGE": "NULL"
              },
              {
                "SECTIONID": "12",
                "SECTIONTITLE": "Region 110",
                "YEAR_TAG": "2ndP",
                "PCAR_SALES": "734",
                "PCAR_SALES_CHANGE": "-1.8%",
                "PCAR_SALES_EFFICIENCY": "NULL",
                "PCAR_MARKET_SHARE": "21.38",
                "PCAR_LEADERSHIP": "NULL",
                "LTRUCK_SALES": "1,186",
                "LTRUCK_SALES_CHANGE": "7.9%",
                "LTRUCK_SALES_EFFICIENCY": "NULL",
                "LTRUCK_MARKET_SHARE": "14.22",
                "LTRUCK_LEADERSHIP": "NULL",
                "TOTAL_NEW_SALES": "1,920",
                "TOTAL_NEW_SALES_CHANGE": "4.0%",
                "TOTAL_INSDUSTRY_REGIS": "13,333",
                "TOTAL_INSDUSTRY_REGIS_CHANGE": "2.4%",
                "TOTAL_TOYOTA_REGIS": "2567",
                "TOTAL_TOYOTA_REGIS_CHANGE": "2.3%",
                "TOTAL_USED_SALES": "393",
                "TOTAL_USED_SALES_CHANGE": "NULL"
              }
            ]
          }
        ],
        [
          {
            "SECTIONID": "10",
            "SECTIONTITLE": "Year",
            "sectionData": [
              {
                "SECTIONID": "10",
                "SECTIONTITLE": "Year",
                "YEAR_TAG": "2ndP",
                "CUST_PAY_LABOR": "2,824,589",
                "CUST_PAY_LABOR_CHANGE": "26.8%",
                "CUST_PAY_RO": "17,133",
                "CUST_PAY_RO_CHANGE": "1.0%",
                "RO_MECH_PARTS": "1,445,719",
                "RO_MECH_PARTS_CHANGE": "10.9%",
                "RETAIL_PARTS": "613,306",
                "RETAIL_PARTS_CHANGE": "14.6%",
                "WHOLESALES_PARTS": "3,463,349",
                "WHOLESALES_PARTS_CHANGE": "13.9%",
                "TOTAL_SOLD": "NULL",
                "ONE_VISIT_SOLD": "NULL",
                "TWO_VISIT_SOLD": "NULL",
                "SSI": "NULL",
                "VDQ": "NULL",
                "SPSI": "NULL",
                "PERCENT_SALES": "5.03",
                "BEFORE_TAXES": "2,578,990"
              },
              {
                "SECTIONID": "10",
                "SECTIONTITLE": "Year",
                "YEAR_TAG": "2ndP",
                "CUST_PAY_LABOR": "2,824,589",
                "CUST_PAY_LABOR_CHANGE": "26.8%",
                "CUST_PAY_RO": "17,133",
                "CUST_PAY_RO_CHANGE": "1.0%",
                "RO_MECH_PARTS": "1,445,719",
                "RO_MECH_PARTS_CHANGE": "10.9%",
                "RETAIL_PARTS": "613,306",
                "RETAIL_PARTS_CHANGE": "14.6%",
                "WHOLESALES_PARTS": "3,463,349",
                "WHOLESALES_PARTS_CHANGE": "13.9%",
                "TOTAL_SOLD": "NULL",
                "ONE_VISIT_SOLD": "NULL",
                "TWO_VISIT_SOLD": "NULL",
                "SSI": "NULL",
                "VDQ": "NULL",
                "SPSI": "NULL",
                "PERCENT_SALES": "5.03",
                "BEFORE_TAXES": "2,578,990"
              },
              {
                "SECTIONID": "10",
                "SECTIONTITLE": "Year",
                "YEAR_TAG": "2ndP",
                "CUST_PAY_LABOR": "2,824,589",
                "CUST_PAY_LABOR_CHANGE": "26.8%",
                "CUST_PAY_RO": "17,133",
                "CUST_PAY_RO_CHANGE": "1.0%",
                "RO_MECH_PARTS": "1,445,719",
                "RO_MECH_PARTS_CHANGE": "10.9%",
                "RETAIL_PARTS": "613,306",
                "RETAIL_PARTS_CHANGE": "14.6%",
                "WHOLESALES_PARTS": "3,463,349",
                "WHOLESALES_PARTS_CHANGE": "13.9%",
                "TOTAL_SOLD": "NULL",
                "ONE_VISIT_SOLD": "NULL",
                "TWO_VISIT_SOLD": "NULL",
                "SSI": "NULL",
                "VDQ": "NULL",
                "SPSI": "NULL",
                "PERCENT_SALES": "5.03",
                "BEFORE_TAXES": "2,578,990"
              }
            ]
          },
          {
            "SECTIONID": "12",
            "SECTIONTITLE": "Region 110",
            "sectionData": [
              {
                "SECTIONID": "12",
                "SECTIONTITLE": "Region 110",
                "YEAR_TAG": "2ndP",
                "CUST_PAY_LABOR": "2,824,589",
                "CUST_PAY_LABOR_CHANGE": "26.8%",
                "CUST_PAY_RO": "17,133",
                "CUST_PAY_RO_CHANGE": "1.0%",
                "RO_MECH_PARTS": "1,445,719",
                "RO_MECH_PARTS_CHANGE": "10.9%",
                "RETAIL_PARTS": "613,306",
                "RETAIL_PARTS_CHANGE": "14.6%",
                "WHOLESALES_PARTS": "3,463,349",
                "WHOLESALES_PARTS_CHANGE": "13.9%",
                "TOTAL_SOLD": "NULL",
                "ONE_VISIT_SOLD": "NULL",
                "TWO_VISIT_SOLD": "NULL",
                "SSI": "NULL",
                "VDQ": "NULL",
                "SPSI": "NULL",
                "PERCENT_SALES": "5.03",
                "BEFORE_TAXES": "2,578,990"
              },
              {
                "SECTIONID": "12",
                "SECTIONTITLE": "Region 110",
                "YEAR_TAG": "2ndP",
                "CUST_PAY_LABOR": "2,824,589",
                "CUST_PAY_LABOR_CHANGE": "26.8%",
                "CUST_PAY_RO": "17,133",
                "CUST_PAY_RO_CHANGE": "1.0%",
                "RO_MECH_PARTS": "1,445,719",
                "RO_MECH_PARTS_CHANGE": "10.9%",
                "RETAIL_PARTS": "613,306",
                "RETAIL_PARTS_CHANGE": "14.6%",
                "WHOLESALES_PARTS": "3,463,349",
                "WHOLESALES_PARTS_CHANGE": "13.9%",
                "TOTAL_SOLD": "NULL",
                "ONE_VISIT_SOLD": "NULL",
                "TWO_VISIT_SOLD": "NULL",
                "SSI": "NULL",
                "VDQ": "NULL",
                "SPSI": "NULL",
                "PERCENT_SALES": "5.03",
                "BEFORE_TAXES": "2,578,990"
              },
              {
                "SECTIONID": "12",
                "SECTIONTITLE": "Region 110",
                "YEAR_TAG": "2ndP",
                "CUST_PAY_LABOR": "2,824,589",
                "CUST_PAY_LABOR_CHANGE": "26.8%",
                "CUST_PAY_RO": "17,133",
                "CUST_PAY_RO_CHANGE": "1.0%",
                "RO_MECH_PARTS": "1,445,719",
                "RO_MECH_PARTS_CHANGE": "10.9%",
                "RETAIL_PARTS": "613,306",
                "RETAIL_PARTS_CHANGE": "14.6%",
                "WHOLESALES_PARTS": "3,463,349",
                "WHOLESALES_PARTS_CHANGE": "13.9%",
                "TOTAL_SOLD": "NULL",
                "ONE_VISIT_SOLD": "NULL",
                "TWO_VISIT_SOLD": "NULL",
                "SSI": "NULL",
                "VDQ": "NULL",
                "SPSI": "NULL",
                "PERCENT_SALES": "5.03",
                "BEFORE_TAXES": "2,578,990"
              }
            ]
          },
        ],
        [

          {
            "EXP": "NULL",
            "TDA": "24 Months",
            "PROVISIONS": "Facility Non-Waiver - Submit detailed written facility action plan",
            "DEADLINES": "NULL",
            "DEADLINES_EMPTY": "NULL",
            "PHASE": "Image USA II- Compliant",
            "DATE_LAST_MEASURED": "NULL"
          },
          {
            "EXP": "NULL",
            "TDA": "NULL",
            "PROVISIONS": "Compliance with Capitalization and Financial Requirements - Non-Waiver",
            "DEADLINES": "NULL",
            "DEADLINES_EMPTY": "NULL",
            "PHASE": "NULL",
            "DATE_LAST_MEASURED": "NULL"
          },
          {
            "EXP": "NULL",
            "TDA": "NULL",
            "PROVISIONS": "Achieve 90% Sales Efficiency",
            "DEADLINES": "NULL",
            "DEADLINES_EMPTY": "NULL",
            "PHASE": "NULL",
            "DATE_LAST_MEASURED": "NULL"
          }

        ]
      ],
      "reportHeaderData": [
        {
          "REGION_CODE": "110"
        }
      ]
    },
    {
      "reportData": [
        [
          {
            "SECTIONID": "10",
            "SECTIONTITLE": "Year",
            "sectionData": [
              {
                "SECTIONID": "10",
                "SECTIONTITLE": "Year",
                "YEAR_TAG": "2ndP",
                "PCAR_SALES": "217",
                "PCAR_SALES_CHANGE": "-24.7%",
                "PCAR_SALES_EFFICIENCY": "165.60",
                "PCAR_MARKET_SHARE": "22.19",
                "PCAR_LEADERSHIP": "TOY",
                "LTRUCK_SALES": "487",
                "LTRUCK_SALES_CHANGE": "-1.8%",
                "LTRUCK_SALES_EFFICIENCY": "257.50",
                "LTRUCK_MARKET_SHARE": "18.45",
                "LTRUCK_LEADERSHIP": "TOY",
                "TOTAL_NEW_SALES": "704",
                "TOTAL_NEW_SALES_CHANGE": "-10.2%",
                "TOTAL_INSDUSTRY_REGIS": "3,866",
                "TOTAL_INSDUSTRY_REGIS_CHANGE": "35.7%",
                "TOTAL_TOYOTA_REGIS": "767",
                "TOTAL_TOYOTA_REGIS_CHANGE": "41.8%",
                "TOTAL_USED_SALES": "146",
                "TOTAL_USED_SALES_CHANGE": "21.7%"
              },
              {
                "SECTIONID": "10",
                "SECTIONTITLE": "Year",
                "YEAR_TAG": "2ndP",
                "PCAR_SALES": "236",
                "PCAR_SALES_CHANGE": "8.8%",
                "PCAR_SALES_EFFICIENCY": "142.80",
                "PCAR_MARKET_SHARE": "21.92",
                "PCAR_LEADERSHIP": "HON",
                "LTRUCK_SALES": "492",
                "LTRUCK_SALES_CHANGE": "1.0%",
                "LTRUCK_SALES_EFFICIENCY": "211.50",
                "LTRUCK_MARKET_SHARE": "16.66",
                "LTRUCK_LEADERSHIP": "TOY",
                "TOTAL_NEW_SALES": "728",
                "TOTAL_NEW_SALES_CHANGE": "3.4%",
                "TOTAL_INSDUSTRY_REGIS": "4,372",
                "TOTAL_INSDUSTRY_REGIS_CHANGE": "13.1%",
                "TOTAL_TOYOTA_REGIS": "872",
                "TOTAL_TOYOTA_REGIS_CHANGE": "13.7%",
                "TOTAL_USED_SALES": "143",
                "TOTAL_USED_SALES_CHANGE": "-2.1%"
              },
              {
                "SECTIONID": "10",
                "SECTIONTITLE": "Year",
                "YEAR_TAG": "2ndP",
                "PCAR_SALES": "223",
                "PCAR_SALES_CHANGE": "-5.5%",
                "PCAR_SALES_EFFICIENCY": "134.70",
                "PCAR_MARKET_SHARE": "22.29",
                "PCAR_LEADERSHIP": "HON",
                "LTRUCK_SALES": "554",
                "LTRUCK_SALES_CHANGE": "12.6%",
                "LTRUCK_SALES_EFFICIENCY": "152.90",
                "LTRUCK_MARKET_SHARE": "18.15",
                "LTRUCK_LEADERSHIP": "TOY",
                "TOTAL_NEW_SALES": "777",
                "TOTAL_NEW_SALES_CHANGE": "6.7%",
                "TOTAL_INSDUSTRY_REGIS": "4,474",
                "TOTAL_INSDUSTRY_REGIS_CHANGE": "2.3%",
                "TOTAL_TOYOTA_REGIS": "876",
                "TOTAL_TOYOTA_REGIS_CHANGE": "0.5%",
                "TOTAL_USED_SALES": "106",
                "TOTAL_USED_SALES_CHANGE": "-25.9%"
              }
            ]
          },
          {
            "SECTIONID": "12",
            "SECTIONTITLE": "Region 110",
            "sectionData": [
              {
                "SECTIONID": "12",
                "SECTIONTITLE": "Region 110",
                "YEAR_TAG": "2ndP",
                "PCAR_SALES": "730",
                "PCAR_SALES_CHANGE": "-23.6%",
                "PCAR_SALES_EFFICIENCY": "NULL",
                "PCAR_MARKET_SHARE": "21.53",
                "PCAR_LEADERSHIP": "NULL",
                "LTRUCK_SALES": "1,061",
                "LTRUCK_SALES_CHANGE": "-13.3%",
                "LTRUCK_SALES_EFFICIENCY": "NULL",
                "LTRUCK_MARKET_SHARE": "15.27",
                "LTRUCK_LEADERSHIP": "NULL",
                "TOTAL_NEW_SALES": "1,792",
                "TOTAL_NEW_SALES_CHANGE": "-17.8%",
                "TOTAL_INSDUSTRY_REGIS": "11623",
                "TOTAL_INSDUSTRY_REGIS_CHANGE": "29.9%",
                "TOTAL_TOYOTA_REGIS": "2332",
                "TOTAL_TOYOTA_REGIS_CHANGE": "39.7%",
                "TOTAL_USED_SALES": "415",
                "TOTAL_USED_SALES_CHANGE": "NULL"
              },
              {
                "SECTIONID": "12",
                "SECTIONTITLE": "Region 110",
                "YEAR_TAG": "2ndP",
                "PCAR_SALES": "747",
                "PCAR_SALES_CHANGE": "2.3%",
                "PCAR_SALES_EFFICIENCY": "NULL",
                "PCAR_MARKET_SHARE": "19.99",
                "PCAR_LEADERSHIP": "NULL",
                "LTRUCK_SALES": "1,099",
                "LTRUCK_SALES_CHANGE": "3.6%",
                "LTRUCK_SALES_EFFICIENCY": "NULL",
                "LTRUCK_MARKET_SHARE": "13.93",
                "LTRUCK_LEADERSHIP": "NULL",
                "TOTAL_NEW_SALES": "1,846",
                "TOTAL_NEW_SALES_CHANGE": "3.1%",
                "TOTAL_INSDUSTRY_REGIS": "13,020",
                "TOTAL_INSDUSTRY_REGIS_CHANGE": "12.0%",
                "TOTAL_TOYOTA_REGIS": "2510",
                "TOTAL_TOYOTA_REGIS_CHANGE": "7.6%",
                "TOTAL_USED_SALES": "406",
                "TOTAL_USED_SALES_CHANGE": "NULL"
              },
              {
                "SECTIONID": "12",
                "SECTIONTITLE": "Region 110",
                "YEAR_TAG": "2ndP",
                "PCAR_SALES": "734",
                "PCAR_SALES_CHANGE": "-1.8%",
                "PCAR_SALES_EFFICIENCY": "NULL",
                "PCAR_MARKET_SHARE": "21.38",
                "PCAR_LEADERSHIP": "NULL",
                "LTRUCK_SALES": "1,186",
                "LTRUCK_SALES_CHANGE": "7.9%",
                "LTRUCK_SALES_EFFICIENCY": "NULL",
                "LTRUCK_MARKET_SHARE": "14.22",
                "LTRUCK_LEADERSHIP": "NULL",
                "TOTAL_NEW_SALES": "1,920",
                "TOTAL_NEW_SALES_CHANGE": "4.0%",
                "TOTAL_INSDUSTRY_REGIS": "13,333",
                "TOTAL_INSDUSTRY_REGIS_CHANGE": "2.4%",
                "TOTAL_TOYOTA_REGIS": "2567",
                "TOTAL_TOYOTA_REGIS_CHANGE": "2.3%",
                "TOTAL_USED_SALES": "393",
                "TOTAL_USED_SALES_CHANGE": "NULL"
              }
            ]
          }
        ],
        [
          {
            "SECTIONID": "10",
            "SECTIONTITLE": "Year",
            "sectionData": [
              {
                "SECTIONID": "10",
                "SECTIONTITLE": "Year",
                "YEAR_TAG": "2ndP",
                "CUST_PAY_LABOR": "2,824,589",
                "CUST_PAY_LABOR_CHANGE": "26.8%",
                "CUST_PAY_RO": "17,133",
                "CUST_PAY_RO_CHANGE": "1.0%",
                "RO_MECH_PARTS": "1,445,719",
                "RO_MECH_PARTS_CHANGE": "10.9%",
                "RETAIL_PARTS": "613,306",
                "RETAIL_PARTS_CHANGE": "14.6%",
                "WHOLESALES_PARTS": "3,463,349",
                "WHOLESALES_PARTS_CHANGE": "13.9%",
                "TOTAL_SOLD": "NULL",
                "ONE_VISIT_SOLD": "NULL",
                "TWO_VISIT_SOLD": "NULL",
                "SSI": "NULL",
                "VDQ": "NULL",
                "SPSI": "NULL",
                "PERCENT_SALES": "5.03",
                "BEFORE_TAXES": "2,578,990"
              },
              {
                "SECTIONID": "10",
                "SECTIONTITLE": "Year",
                "YEAR_TAG": "2ndP",
                "CUST_PAY_LABOR": "2,824,589",
                "CUST_PAY_LABOR_CHANGE": "26.8%",
                "CUST_PAY_RO": "17,133",
                "CUST_PAY_RO_CHANGE": "1.0%",
                "RO_MECH_PARTS": "1,445,719",
                "RO_MECH_PARTS_CHANGE": "10.9%",
                "RETAIL_PARTS": "613,306",
                "RETAIL_PARTS_CHANGE": "14.6%",
                "WHOLESALES_PARTS": "3,463,349",
                "WHOLESALES_PARTS_CHANGE": "13.9%",
                "TOTAL_SOLD": "NULL",
                "ONE_VISIT_SOLD": "NULL",
                "TWO_VISIT_SOLD": "NULL",
                "SSI": "NULL",
                "VDQ": "NULL",
                "SPSI": "NULL",
                "PERCENT_SALES": "5.03",
                "BEFORE_TAXES": "2,578,990"
              },
              {
                "SECTIONID": "10",
                "SECTIONTITLE": "Year",
                "YEAR_TAG": "2ndP",
                "CUST_PAY_LABOR": "2,824,589",
                "CUST_PAY_LABOR_CHANGE": "26.8%",
                "CUST_PAY_RO": "17,133",
                "CUST_PAY_RO_CHANGE": "1.0%",
                "RO_MECH_PARTS": "1,445,719",
                "RO_MECH_PARTS_CHANGE": "10.9%",
                "RETAIL_PARTS": "613,306",
                "RETAIL_PARTS_CHANGE": "14.6%",
                "WHOLESALES_PARTS": "3,463,349",
                "WHOLESALES_PARTS_CHANGE": "13.9%",
                "TOTAL_SOLD": "NULL",
                "ONE_VISIT_SOLD": "NULL",
                "TWO_VISIT_SOLD": "NULL",
                "SSI": "NULL",
                "VDQ": "NULL",
                "SPSI": "NULL",
                "PERCENT_SALES": "5.03",
                "BEFORE_TAXES": "2,578,990"
              }
            ]
          },
          {
            "SECTIONID": "12",
            "SECTIONTITLE": "Region 110",
            "sectionData": [
              {
                "SECTIONID": "12",
                "SECTIONTITLE": "Region 110",
                "YEAR_TAG": "2ndP",
                "CUST_PAY_LABOR": "2,824,589",
                "CUST_PAY_LABOR_CHANGE": "26.8%",
                "CUST_PAY_RO": "17,133",
                "CUST_PAY_RO_CHANGE": "1.0%",
                "RO_MECH_PARTS": "1,445,719",
                "RO_MECH_PARTS_CHANGE": "10.9%",
                "RETAIL_PARTS": "613,306",
                "RETAIL_PARTS_CHANGE": "14.6%",
                "WHOLESALES_PARTS": "3,463,349",
                "WHOLESALES_PARTS_CHANGE": "13.9%",
                "TOTAL_SOLD": "NULL",
                "ONE_VISIT_SOLD": "NULL",
                "TWO_VISIT_SOLD": "NULL",
                "SSI": "NULL",
                "VDQ": "NULL",
                "SPSI": "NULL",
                "PERCENT_SALES": "5.03",
                "BEFORE_TAXES": "2,578,990"
              },
              {
                "SECTIONID": "12",
                "SECTIONTITLE": "Region 110",
                "YEAR_TAG": "2ndP",
                "CUST_PAY_LABOR": "2,824,589",
                "CUST_PAY_LABOR_CHANGE": "26.8%",
                "CUST_PAY_RO": "17,133",
                "CUST_PAY_RO_CHANGE": "1.0%",
                "RO_MECH_PARTS": "1,445,719",
                "RO_MECH_PARTS_CHANGE": "10.9%",
                "RETAIL_PARTS": "613,306",
                "RETAIL_PARTS_CHANGE": "14.6%",
                "WHOLESALES_PARTS": "3,463,349",
                "WHOLESALES_PARTS_CHANGE": "13.9%",
                "TOTAL_SOLD": "NULL",
                "ONE_VISIT_SOLD": "NULL",
                "TWO_VISIT_SOLD": "NULL",
                "SSI": "NULL",
                "VDQ": "NULL",
                "SPSI": "NULL",
                "PERCENT_SALES": "5.03",
                "BEFORE_TAXES": "2,578,990"
              },
              {
                "SECTIONID": "12",
                "SECTIONTITLE": "Region 110",
                "YEAR_TAG": "2ndP",
                "CUST_PAY_LABOR": "2,824,589",
                "CUST_PAY_LABOR_CHANGE": "26.8%",
                "CUST_PAY_RO": "17,133",
                "CUST_PAY_RO_CHANGE": "1.0%",
                "RO_MECH_PARTS": "1,445,719",
                "RO_MECH_PARTS_CHANGE": "10.9%",
                "RETAIL_PARTS": "613,306",
                "RETAIL_PARTS_CHANGE": "14.6%",
                "WHOLESALES_PARTS": "3,463,349",
                "WHOLESALES_PARTS_CHANGE": "13.9%",
                "TOTAL_SOLD": "NULL",
                "ONE_VISIT_SOLD": "NULL",
                "TWO_VISIT_SOLD": "NULL",
                "SSI": "NULL",
                "VDQ": "NULL",
                "SPSI": "NULL",
                "PERCENT_SALES": "5.03",
                "BEFORE_TAXES": "2,578,990"
              }
            ]
          },
        ],
        [

          {
            "EXP": "NULL",
            "TDA": "24 Months",
            "PROVISIONS": "Facility Non-Waiver - Submit detailed written facility action plan",
            "DEADLINES": "NULL",
            "DEADLINES_EMPTY": "NULL",
            "PHASE": "Image USA II- Compliant",
            "DATE_LAST_MEASURED": "NULL"
          },
          {
            "EXP": "NULL",
            "TDA": "NULL",
            "PROVISIONS": "Compliance with Capitalization and Financial Requirements - Non-Waiver",
            "DEADLINES": "NULL",
            "DEADLINES_EMPTY": "NULL",
            "PHASE": "NULL",
            "DATE_LAST_MEASURED": "NULL"
          },
          {
            "EXP": "NULL",
            "TDA": "NULL",
            "PROVISIONS": "Achieve 90% Sales Efficiency",
            "DEADLINES": "NULL",
            "DEADLINES_EMPTY": "NULL",
            "PHASE": "NULL",
            "DATE_LAST_MEASURED": "NULL"
          }

        ]
      ],
      "reportHeaderData": [
        {
          "REGION_CODE": "110"
        }
      ]
    },
    {
      "reportData": [
        [
          {
            "SECTIONID": "10",
            "SECTIONTITLE": "Year",
            "sectionData": [
              {
                "SECTIONID": "10",
                "SECTIONTITLE": "Year",
                "YEAR_TAG": "2ndP",
                "PCAR_SALES": "217",
                "PCAR_SALES_CHANGE": "-24.7%",
                "PCAR_SALES_EFFICIENCY": "165.60",
                "PCAR_MARKET_SHARE": "22.19",
                "PCAR_LEADERSHIP": "TOY",
                "LTRUCK_SALES": "487",
                "LTRUCK_SALES_CHANGE": "-1.8%",
                "LTRUCK_SALES_EFFICIENCY": "257.50",
                "LTRUCK_MARKET_SHARE": "18.45",
                "LTRUCK_LEADERSHIP": "TOY",
                "TOTAL_NEW_SALES": "704",
                "TOTAL_NEW_SALES_CHANGE": "-10.2%",
                "TOTAL_INSDUSTRY_REGIS": "3,866",
                "TOTAL_INSDUSTRY_REGIS_CHANGE": "35.7%",
                "TOTAL_TOYOTA_REGIS": "767",
                "TOTAL_TOYOTA_REGIS_CHANGE": "41.8%",
                "TOTAL_USED_SALES": "146",
                "TOTAL_USED_SALES_CHANGE": "21.7%"
              },
              {
                "SECTIONID": "10",
                "SECTIONTITLE": "Year",
                "YEAR_TAG": "2ndP",
                "PCAR_SALES": "236",
                "PCAR_SALES_CHANGE": "8.8%",
                "PCAR_SALES_EFFICIENCY": "142.80",
                "PCAR_MARKET_SHARE": "21.92",
                "PCAR_LEADERSHIP": "HON",
                "LTRUCK_SALES": "492",
                "LTRUCK_SALES_CHANGE": "1.0%",
                "LTRUCK_SALES_EFFICIENCY": "211.50",
                "LTRUCK_MARKET_SHARE": "16.66",
                "LTRUCK_LEADERSHIP": "TOY",
                "TOTAL_NEW_SALES": "728",
                "TOTAL_NEW_SALES_CHANGE": "3.4%",
                "TOTAL_INSDUSTRY_REGIS": "4,372",
                "TOTAL_INSDUSTRY_REGIS_CHANGE": "13.1%",
                "TOTAL_TOYOTA_REGIS": "872",
                "TOTAL_TOYOTA_REGIS_CHANGE": "13.7%",
                "TOTAL_USED_SALES": "143",
                "TOTAL_USED_SALES_CHANGE": "-2.1%"
              },
              {
                "SECTIONID": "10",
                "SECTIONTITLE": "Year",
                "YEAR_TAG": "2ndP",
                "PCAR_SALES": "223",
                "PCAR_SALES_CHANGE": "-5.5%",
                "PCAR_SALES_EFFICIENCY": "134.70",
                "PCAR_MARKET_SHARE": "22.29",
                "PCAR_LEADERSHIP": "HON",
                "LTRUCK_SALES": "554",
                "LTRUCK_SALES_CHANGE": "12.6%",
                "LTRUCK_SALES_EFFICIENCY": "152.90",
                "LTRUCK_MARKET_SHARE": "18.15",
                "LTRUCK_LEADERSHIP": "TOY",
                "TOTAL_NEW_SALES": "777",
                "TOTAL_NEW_SALES_CHANGE": "6.7%",
                "TOTAL_INSDUSTRY_REGIS": "4,474",
                "TOTAL_INSDUSTRY_REGIS_CHANGE": "2.3%",
                "TOTAL_TOYOTA_REGIS": "876",
                "TOTAL_TOYOTA_REGIS_CHANGE": "0.5%",
                "TOTAL_USED_SALES": "106",
                "TOTAL_USED_SALES_CHANGE": "-25.9%"
              }
            ]
          },
          {
            "SECTIONID": "12",
            "SECTIONTITLE": "Region 110",
            "sectionData": [
              {
                "SECTIONID": "12",
                "SECTIONTITLE": "Region 110",
                "YEAR_TAG": "2ndP",
                "PCAR_SALES": "730",
                "PCAR_SALES_CHANGE": "-23.6%",
                "PCAR_SALES_EFFICIENCY": "NULL",
                "PCAR_MARKET_SHARE": "21.53",
                "PCAR_LEADERSHIP": "NULL",
                "LTRUCK_SALES": "1,061",
                "LTRUCK_SALES_CHANGE": "-13.3%",
                "LTRUCK_SALES_EFFICIENCY": "NULL",
                "LTRUCK_MARKET_SHARE": "15.27",
                "LTRUCK_LEADERSHIP": "NULL",
                "TOTAL_NEW_SALES": "1,792",
                "TOTAL_NEW_SALES_CHANGE": "-17.8%",
                "TOTAL_INSDUSTRY_REGIS": "11623",
                "TOTAL_INSDUSTRY_REGIS_CHANGE": "29.9%",
                "TOTAL_TOYOTA_REGIS": "2332",
                "TOTAL_TOYOTA_REGIS_CHANGE": "39.7%",
                "TOTAL_USED_SALES": "415",
                "TOTAL_USED_SALES_CHANGE": "NULL"
              },
              {
                "SECTIONID": "12",
                "SECTIONTITLE": "Region 110",
                "YEAR_TAG": "2ndP",
                "PCAR_SALES": "747",
                "PCAR_SALES_CHANGE": "2.3%",
                "PCAR_SALES_EFFICIENCY": "NULL",
                "PCAR_MARKET_SHARE": "19.99",
                "PCAR_LEADERSHIP": "NULL",
                "LTRUCK_SALES": "1,099",
                "LTRUCK_SALES_CHANGE": "3.6%",
                "LTRUCK_SALES_EFFICIENCY": "NULL",
                "LTRUCK_MARKET_SHARE": "13.93",
                "LTRUCK_LEADERSHIP": "NULL",
                "TOTAL_NEW_SALES": "1,846",
                "TOTAL_NEW_SALES_CHANGE": "3.1%",
                "TOTAL_INSDUSTRY_REGIS": "13,020",
                "TOTAL_INSDUSTRY_REGIS_CHANGE": "12.0%",
                "TOTAL_TOYOTA_REGIS": "2510",
                "TOTAL_TOYOTA_REGIS_CHANGE": "7.6%",
                "TOTAL_USED_SALES": "406",
                "TOTAL_USED_SALES_CHANGE": "NULL"
              },
              {
                "SECTIONID": "12",
                "SECTIONTITLE": "Region 110",
                "YEAR_TAG": "2ndP",
                "PCAR_SALES": "734",
                "PCAR_SALES_CHANGE": "-1.8%",
                "PCAR_SALES_EFFICIENCY": "NULL",
                "PCAR_MARKET_SHARE": "21.38",
                "PCAR_LEADERSHIP": "NULL",
                "LTRUCK_SALES": "1,186",
                "LTRUCK_SALES_CHANGE": "7.9%",
                "LTRUCK_SALES_EFFICIENCY": "NULL",
                "LTRUCK_MARKET_SHARE": "14.22",
                "LTRUCK_LEADERSHIP": "NULL",
                "TOTAL_NEW_SALES": "1,920",
                "TOTAL_NEW_SALES_CHANGE": "4.0%",
                "TOTAL_INSDUSTRY_REGIS": "13,333",
                "TOTAL_INSDUSTRY_REGIS_CHANGE": "2.4%",
                "TOTAL_TOYOTA_REGIS": "2567",
                "TOTAL_TOYOTA_REGIS_CHANGE": "2.3%",
                "TOTAL_USED_SALES": "393",
                "TOTAL_USED_SALES_CHANGE": "NULL"
              }
            ]
          }
        ],
        [
          {
            "SECTIONID": "10",
            "SECTIONTITLE": "Year",
            "sectionData": [
              {
                "SECTIONID": "10",
                "SECTIONTITLE": "Year",
                "YEAR_TAG": "2ndP",
                "CUST_PAY_LABOR": "2,824,589",
                "CUST_PAY_LABOR_CHANGE": "26.8%",
                "CUST_PAY_RO": "17,133",
                "CUST_PAY_RO_CHANGE": "1.0%",
                "RO_MECH_PARTS": "1,445,719",
                "RO_MECH_PARTS_CHANGE": "10.9%",
                "RETAIL_PARTS": "613,306",
                "RETAIL_PARTS_CHANGE": "14.6%",
                "WHOLESALES_PARTS": "3,463,349",
                "WHOLESALES_PARTS_CHANGE": "13.9%",
                "TOTAL_SOLD": "NULL",
                "ONE_VISIT_SOLD": "NULL",
                "TWO_VISIT_SOLD": "NULL",
                "SSI": "NULL",
                "VDQ": "NULL",
                "SPSI": "NULL",
                "PERCENT_SALES": "5.03",
                "BEFORE_TAXES": "2,578,990"
              },
              {
                "SECTIONID": "10",
                "SECTIONTITLE": "Year",
                "YEAR_TAG": "2ndP",
                "CUST_PAY_LABOR": "2,824,589",
                "CUST_PAY_LABOR_CHANGE": "26.8%",
                "CUST_PAY_RO": "17,133",
                "CUST_PAY_RO_CHANGE": "1.0%",
                "RO_MECH_PARTS": "1,445,719",
                "RO_MECH_PARTS_CHANGE": "10.9%",
                "RETAIL_PARTS": "613,306",
                "RETAIL_PARTS_CHANGE": "14.6%",
                "WHOLESALES_PARTS": "3,463,349",
                "WHOLESALES_PARTS_CHANGE": "13.9%",
                "TOTAL_SOLD": "NULL",
                "ONE_VISIT_SOLD": "NULL",
                "TWO_VISIT_SOLD": "NULL",
                "SSI": "NULL",
                "VDQ": "NULL",
                "SPSI": "NULL",
                "PERCENT_SALES": "5.03",
                "BEFORE_TAXES": "2,578,990"
              },
              {
                "SECTIONID": "10",
                "SECTIONTITLE": "Year",
                "YEAR_TAG": "2ndP",
                "CUST_PAY_LABOR": "2,824,589",
                "CUST_PAY_LABOR_CHANGE": "26.8%",
                "CUST_PAY_RO": "17,133",
                "CUST_PAY_RO_CHANGE": "1.0%",
                "RO_MECH_PARTS": "1,445,719",
                "RO_MECH_PARTS_CHANGE": "10.9%",
                "RETAIL_PARTS": "613,306",
                "RETAIL_PARTS_CHANGE": "14.6%",
                "WHOLESALES_PARTS": "3,463,349",
                "WHOLESALES_PARTS_CHANGE": "13.9%",
                "TOTAL_SOLD": "NULL",
                "ONE_VISIT_SOLD": "NULL",
                "TWO_VISIT_SOLD": "NULL",
                "SSI": "NULL",
                "VDQ": "NULL",
                "SPSI": "NULL",
                "PERCENT_SALES": "5.03",
                "BEFORE_TAXES": "2,578,990"
              }
            ]
          },
          {
            "SECTIONID": "12",
            "SECTIONTITLE": "Region 110",
            "sectionData": [
              {
                "SECTIONID": "12",
                "SECTIONTITLE": "Region 110",
                "YEAR_TAG": "2ndP",
                "CUST_PAY_LABOR": "2,824,589",
                "CUST_PAY_LABOR_CHANGE": "26.8%",
                "CUST_PAY_RO": "17,133",
                "CUST_PAY_RO_CHANGE": "1.0%",
                "RO_MECH_PARTS": "1,445,719",
                "RO_MECH_PARTS_CHANGE": "10.9%",
                "RETAIL_PARTS": "613,306",
                "RETAIL_PARTS_CHANGE": "14.6%",
                "WHOLESALES_PARTS": "3,463,349",
                "WHOLESALES_PARTS_CHANGE": "13.9%",
                "TOTAL_SOLD": "NULL",
                "ONE_VISIT_SOLD": "NULL",
                "TWO_VISIT_SOLD": "NULL",
                "SSI": "NULL",
                "VDQ": "NULL",
                "SPSI": "NULL",
                "PERCENT_SALES": "5.03",
                "BEFORE_TAXES": "2,578,990"
              },
              {
                "SECTIONID": "12",
                "SECTIONTITLE": "Region 110",
                "YEAR_TAG": "2ndP",
                "CUST_PAY_LABOR": "2,824,589",
                "CUST_PAY_LABOR_CHANGE": "26.8%",
                "CUST_PAY_RO": "17,133",
                "CUST_PAY_RO_CHANGE": "1.0%",
                "RO_MECH_PARTS": "1,445,719",
                "RO_MECH_PARTS_CHANGE": "10.9%",
                "RETAIL_PARTS": "613,306",
                "RETAIL_PARTS_CHANGE": "14.6%",
                "WHOLESALES_PARTS": "3,463,349",
                "WHOLESALES_PARTS_CHANGE": "13.9%",
                "TOTAL_SOLD": "NULL",
                "ONE_VISIT_SOLD": "NULL",
                "TWO_VISIT_SOLD": "NULL",
                "SSI": "NULL",
                "VDQ": "NULL",
                "SPSI": "NULL",
                "PERCENT_SALES": "5.03",
                "BEFORE_TAXES": "2,578,990"
              },
              {
                "SECTIONID": "12",
                "SECTIONTITLE": "Region 110",
                "YEAR_TAG": "2ndP",
                "CUST_PAY_LABOR": "2,824,589",
                "CUST_PAY_LABOR_CHANGE": "26.8%",
                "CUST_PAY_RO": "17,133",
                "CUST_PAY_RO_CHANGE": "1.0%",
                "RO_MECH_PARTS": "1,445,719",
                "RO_MECH_PARTS_CHANGE": "10.9%",
                "RETAIL_PARTS": "613,306",
                "RETAIL_PARTS_CHANGE": "14.6%",
                "WHOLESALES_PARTS": "3,463,349",
                "WHOLESALES_PARTS_CHANGE": "13.9%",
                "TOTAL_SOLD": "NULL",
                "ONE_VISIT_SOLD": "NULL",
                "TWO_VISIT_SOLD": "NULL",
                "SSI": "NULL",
                "VDQ": "NULL",
                "SPSI": "NULL",
                "PERCENT_SALES": "5.03",
                "BEFORE_TAXES": "2,578,990"
              }
            ]
          },
        ],
        [

          {
            "EXP": "NULL",
            "TDA": "24 Months",
            "PROVISIONS": "Facility Non-Waiver - Submit detailed written facility action plan",
            "DEADLINES": "NULL",
            "DEADLINES_EMPTY": "NULL",
            "PHASE": "Image USA II- Compliant",
            "DATE_LAST_MEASURED": "NULL"
          },
          {
            "EXP": "NULL",
            "TDA": "NULL",
            "PROVISIONS": "Compliance with Capitalization and Financial Requirements - Non-Waiver",
            "DEADLINES": "NULL",
            "DEADLINES_EMPTY": "NULL",
            "PHASE": "NULL",
            "DATE_LAST_MEASURED": "NULL"
          },
          {
            "EXP": "NULL",
            "TDA": "NULL",
            "PROVISIONS": "Achieve 90% Sales Efficiency",
            "DEADLINES": "NULL",
            "DEADLINES_EMPTY": "NULL",
            "PHASE": "NULL",
            "DATE_LAST_MEASURED": "NULL"
          }

        ]
      ],
      "reportHeaderData": [
        {
          "REGION_CODE": "110"
        }
      ]
    },
  ]

}; //NOSONAR

export const DealerReviewMockData = {
  body: [
    {
      "reportData": [
        [
          {
            "SECTIONID": "1",
            "SECTIONTITLE": "Sales",
            "sectionData": [
              {
                "SECTIONID": 1,
                "SECTIONTITLE": "Sales",
                "YEAR_TAG": "2ndP",
                "PCAR_SALES": "341",
                "PCAR_SALES_CHANGE": "-10.5",
                "PCAR_MARKET_SHARE": "24.97",
                "PCAR_LEADERSHIP": "TOY",
                "LTRUCK_SALES": "653",
                "LTRUCK_SALES_CHANGE": "6.7",
                "LTRUCK_MARKET_SHARE": "16.32",
                "LTRUCK_LEADERSHIP": "TOY",
                "TOTAL_NEW_SALES": "994",
                "TOTAL_NEW_SALES_CHANGE": "0.1",
                "TOTAL_USED_SALES": "40",
                "TOTAL_USED_SALES_CHANGE": "-40.3"
              },
              {
                "SECTIONID": 1,
                "SECTIONTITLE": "Sales",
                "YEAR_TAG": "Prior",
                "PCAR_SALES": "293",
                "PCAR_SALES_CHANGE": "-14.1",
                "PCAR_MARKET_SHARE": "23.59",
                "PCAR_LEADERSHIP": "TOY",
                "LTRUCK_SALES": "586",
                "LTRUCK_SALES_CHANGE": "-10.3",
                "LTRUCK_MARKET_SHARE": "14.12",
                "LTRUCK_LEADERSHIP": "TOY",
                "TOTAL_NEW_SALES": "879",
                "TOTAL_NEW_SALES_CHANGE": "-11.6",
                "TOTAL_USED_SALES": "92",
                "TOTAL_USED_SALES_CHANGE": "130.0"
              },
            ]
          }
        ],

      ],
      "reportHeaderData": [
        {
          "REGION_CODE": "800"
        }
      ]
    }
  ]
}; //NOSONAR

export const listHierarchyMembersData = [
  {
    "HIERARCHY_ID": 4,
    "LEVEL_ID": 10,
    "COMPANY_ID": 14,
    "REGION_CODE": "110",
    "DISTRICT_CODE": "A",
    "MEMBER_CODE": "4011",
    "MEMBER_NAME": "HOOMAN TOYOTA OF LONG BCH (Term.)",
    "COMPANY_NAME": "Toyota",
    "LEVEL_NAME": "Dealer"
  },
  {
    "HIERARCHY_ID": 4,
    "LEVEL_ID": 10,
    "COMPANY_ID": 14,
    "REGION_CODE": "110",
    "DISTRICT_CODE": "A",
    "MEMBER_CODE": "4033",
    "MEMBER_NAME": "CABE TOYOTA LONG BEACH",
    "COMPANY_NAME": "Toyota",
    "LEVEL_NAME": "Dealer"
  },
  {
    "HIERARCHY_ID": 4,
    "LEVEL_ID": 10,
    "COMPANY_ID": 14,
    "REGION_CODE": "110",
    "DISTRICT_CODE": "A",
    "MEMBER_CODE": "4042",
    "MEMBER_NAME": "NORWALK TOYOTA (Term.)",
    "COMPANY_NAME": "Toyota",
    "LEVEL_NAME": "Dealer"
  },
  {
    "HIERARCHY_ID": 4,
    "LEVEL_ID": 10,
    "COMPANY_ID": 14,
    "REGION_CODE": "110",
    "DISTRICT_CODE": "A",
    "MEMBER_CODE": "4080",
    "MEMBER_NAME": "PENSKE TOYOTA",
    "COMPANY_NAME": "Toyota",
    "LEVEL_NAME": "Dealer"
  }
]
export const DlrAndHrcyMemMockData = {
  body : [
    {
      "hierarchy-members-tmna": {
          "CompanyData": [
              {
                  "COMPANY_NAME": "Toyota",
                  "COMPANY_ID": 14
              },
              {
                  "COMPANY_NAME": "Lexus",
                  "COMPANY_ID": 15
              },
              {
                  "COMPANY_NAME": null,
                  "COMPANY_ID": 16
              }
          ],
          "HierarchyData": [
              {
                  "COMPANY_ID": 14,
                  "HIERARCHY_ID": 4,
                  "LEVEL_ID": 10,
                  "LEVEL_NAME": "Dealer"
              },
              {
                  "COMPANY_ID": 15,
                  "HIERARCHY_ID": 4,
                  "LEVEL_ID": 10,
                  "LEVEL_NAME": "Dealer"
              },
              {
                  "COMPANY_ID": 14,
                  "HIERARCHY_ID": 4,
                  "LEVEL_ID": 11,
                  "LEVEL_NAME": "District"
              },
              {
                  "COMPANY_ID": 15,
                  "HIERARCHY_ID": 4,
                  "LEVEL_ID": 11,
                  "LEVEL_NAME": "District"
              },
              {
                  "COMPANY_ID": 14,
                  "HIERARCHY_ID": 4,
                  "LEVEL_ID": 12,
                  "LEVEL_NAME": "Region"
              },
              {
                  "COMPANY_ID": 15,
                  "HIERARCHY_ID": 4,
                  "LEVEL_ID": 12,
                  "LEVEL_NAME": "Region"
              },
              {
                  "COMPANY_ID": 16,
                  "HIERARCHY_ID": 4,
                  "LEVEL_ID": 13,
                  "LEVEL_NAME": "National"
              }
          ],
          "HierarchyMemberData": [
              {
                  "HIERARCHY_ID": 4,
                  "LEVEL_ID": 10,
                  "COMPANY_ID": 14,
                  "REGION_CODE": "110",
                  "DISTRICT_CODE": "A",
                  "MEMBER_CODE": "4011",
                  "MEMBER_NAME": "HOOMAN TOYOTA OF LONG BCH (Term.)",
                  "COMPANY_NAME": "Toyota",
                  "LEVEL_NAME": "Dealer"
              },
              {
                  "HIERARCHY_ID": 4,
                  "LEVEL_ID": 10,
                  "COMPANY_ID": 14,
                  "REGION_CODE": "110",
                  "DISTRICT_CODE": "A",
                  "MEMBER_CODE": "4033",
                  "MEMBER_NAME": "CABE TOYOTA LONG BEACH",
                  "COMPANY_NAME": "Toyota",
                  "LEVEL_NAME": "Dealer"
              },
              {
                  "HIERARCHY_ID": 4,
                  "LEVEL_ID": 10,
                  "COMPANY_ID": 14,
                  "REGION_CODE": "110",
                  "DISTRICT_CODE": "A",
                  "MEMBER_CODE": "4042",
                  "MEMBER_NAME": "NORWALK TOYOTA (Term.)",
                  "COMPANY_NAME": "Toyota",
                  "LEVEL_NAME": "Dealer"
              },
              {
                  "HIERARCHY_ID": 4,
                  "LEVEL_ID": 10,
                  "COMPANY_ID": 14,
                  "REGION_CODE": "110",
                  "DISTRICT_CODE": "A",
                  "MEMBER_CODE": "4080",
                  "MEMBER_NAME": "PENSKE TOYOTA",
                  "COMPANY_NAME": "Toyota",
                  "LEVEL_NAME": "Dealer"
              }
          ]
      }
    }
  ]
}; //NOSONAR

export const DspmMonthlySummaryMock = {
  body: [
    {
      "reportData": [
          {
              "SECTIONID": "1",
              "SECTIONTITLE": "1",
              "sectionData": [
                  {
                      "SECTIONID": "1",
                      "SECTIONTITLE": "1",
                      "REGION_ALPHA_CODE": "CAT",
                      "RANK": "9",
                      "RECORD_TYPE": "CLS",
                      "DEALER NAME": "McDonough Toyota",
                      "DEALER CODE": "45030",
                      "PRIOR_YR_CHANGE": "-9.07",
                      "TXT_COLOR": "B",
                      "GPP_PRIOR_YR_AMT": null,
                      "GPP_LAST_YR_AMT": null,
                      "DEALER_TLE": null
                  },
                  {
                      "SECTIONID": "1",
                      "SECTIONTITLE": "1",
                      "REGION_ALPHA_CODE": "CAT",
                      "RANK": "10",
                      "RECORD_TYPE": "MRO",
                      "DEALER_NAME": "McDonough Toyota",
                      "DEALER_NO": "45030",
                      "PRIOR_YR_CHANGE": "-12.24",
                      "TXT_COLOR": "B",
                      "GPP_PRIOR_YR_AMT": null,
                      "GPP_LAST_YR_AMT": null,
                      "DEALER_TLE": null
                  },
                
              ]
          },
          {
            "SECTIONID": "2",
            "SECTIONTITLE": "2",
            "sectionData": [
                {
                    "SECTIONID": "2",
                    "SECTIONTITLE": "2",
                    "REGION_ALPHA_CODE": "CAT     ",
                    "RANK": "9",
                    "RECORD_TYPE": "GPP",
                    "DEALER_NAME": "McDonough Toyota",
                    "DEALER_NO": "45030",
                    "PRIOR_YR_CHANGE": "0.00",
                    "TXT_COLOR": "B",
                    "GPP_PRIOR_YR_AMT": "100.73",
                    "GPP_LAST_YR_AMT": "108.86",
                    "DEALER_TLE": null
                },
                {
                    "SECTIONID": "2",
                    "SECTIONTITLE": "2",
                    "REGION_ALPHA_CODE": "CAT     ",
                    "RANK": "9",
                    "RECORD_TYPE": "TOTAL_PANDA",
                    "DEALER_NAME": "McDonough Toyota",
                    "DEALER_NO": "45030",
                    "PRIOR_YR_CHANGE": "-0.18",
                    "TXT_COLOR": "R",
                    "GPP_PRIOR_YR_AMT": null,
                    "GPP_LAST_YR_AMT": null,
                    "DEALER_TLE": null
                },
               
            ]
        }
      ],
      "reportHeaderData": [
          {
              "CY": 2024,
              "PY": 2023,
              "CM": 5,
              "PM": 4
          }
      ]
  }
  ]
}; //NOSONAR

export const tcuvPerformanceMockData = {
  body: [
    {
        "reportData": [
            [
                {
                    "SECTIONID": "1",
                    "sectionData": [
                        {
                            "SECTIONID": 1,
                            "SECTIONLINENO": 1,
                            "MEASURE": "Total Dealership GP % Sales",
                            "DEALER_MTD_IND": "R",
                            "DEALERSHIP_MTD": "18.8100",
                            "DEALER_YTD_IND": "R",
                            "DEALERSHIP_YTD": 18.322,
                            "ACC_NO": "B1006",
                            "GUIDE": "11% to 13%"
                        },
                        {
                            "SECTIONID": 1,
                            "SECTIONLINENO": 2,
                            "MEASURE": "Used Department GP % Sales",
                            "DEALER_MTD_IND": "R",
                            "DEALERSHIP_MTD": "14.8330",
                            "DEALER_YTD_IND": "G",
                            "DEALERSHIP_YTD": 12.73,
                            "ACC_NO": "B3002",
                            "GUIDE": "10% to 13%"
                        },
                        {
                            "SECTIONID": 1,
                            "SECTIONLINENO": 3,
                            "MEASURE": "Used GP Contribution",
                            "DEALER_MTD_IND": "R",
                            "DEALERSHIP_MTD": "21.7280",
                            "DEALER_YTD_IND": "R",
                            "DEALERSHIP_YTD": 19.006,
                            "ACC_NO": "B2120",
                            "GUIDE": "10% to 15%"
                        },
                        {
                            "SECTIONID": 1,
                            "SECTIONLINENO": 4,
                            "MEASURE": "Used Vehicle Equity",
                            "DEALER_MTD_IND": "G",
                            "DEALERSHIP_MTD": "99.7790",
                            "DEALER_YTD_IND": "G",
                            "DEALERSHIP_YTD": 99.779,
                            "ACC_NO": "B9941",
                            "GUIDE": ">50%"
                        },
                        {
                            "SECTIONID": 1,
                            "SECTIONLINENO": 5,
                            "MEASURE": "Floor Plan Expense",
                            "DEALER_MTD_IND": "R",
                            "DEALERSHIP_MTD": "0.0000",
                            "DEALER_YTD_IND": "R",
                            "DEALERSHIP_YTD": 0,
                            "ACC_NO": "B9922",
                            "GUIDE": "1% to 2%"
                        },
                        {
                            "SECTIONID": 1,
                            "SECTIONLINENO": 6,
                            "MEASURE": "Used Vehicle Days Supply",
                            "DEALER_MTD_IND": "R",
                            "DEALERSHIP_MTD": "37.6740",
                            "DEALER_YTD_IND": "R",
                            "DEALERSHIP_YTD": 35.604,
                            "ACC_NO": "B9920",
                            "GUIDE": "45"
                        },
                        {
                            "SECTIONID": 1,
                            "SECTIONLINENO": 7,
                            "MEASURE": "Contracts In Transit (<= Guide)",
                            "DEALER_MTD_IND": "G",
                            "DEALERSHIP_MTD": "1.5450",
                            "DEALER_YTD_IND": "G",
                            "DEALERSHIP_YTD": 1.377,
                            "ACC_NO": "B9906",
                            "GUIDE": "3 Days"
                        },
                        {
                            "SECTIONID": 1,
                            "SECTIONLINENO": 8,
                            "MEASURE": "Used Sales Compensation (<= Guide)",
                            "DEALER_MTD_IND": "G",
                            "DEALERSHIP_MTD": "11.2320",
                            "DEALER_YTD_IND": "G",
                            "DEALERSHIP_YTD": 12.337,
                            "ACC_NO": "B9940",
                            "GUIDE": "21%"
                        },
                        {
                            "SECTIONID": 1,
                            "SECTIONLINENO": 9,
                            "MEASURE": "Ttl Used Finance Chargeback (< =Guide)",
                            "DEALER_MTD_IND": "R",
                            "DEALERSHIP_MTD": "48.8870",
                            "DEALER_YTD_IND": "R",
                            "DEALERSHIP_YTD": 32.448,
                            "ACC_NO": "B9929",
                            "GUIDE": "10%"
                        },
                        {
                            "SECTIONID": 1,
                            "SECTIONLINENO": 10,
                            "MEASURE": "Used Vehicle Advertising % of GP",
                            "DEALER_MTD_IND": "R",
                            "DEALERSHIP_MTD": "19.6510",
                            "DEALER_YTD_IND": "R",
                            "DEALERSHIP_YTD": 22.334,
                            "ACC_NO": "B9939",
                            "GUIDE": "10% to 13%"
                        }
                    ]
                }
            ],
            [
                {
                    "SECTIONID": "2",
                    "SECTIONTITLE": "TCUV GP PUVR/L",
                    "sectionData": [
                        {
                            "SECTIONID": 2,
                            "SECTIONTITLE": "TCUV GP PUVR/L",
                            "SECTIONLINENO": 1,
                            "INDICATOR": "",
                            "IND": "G",
                            "DEALERSHIP_MTD": 2336,
                            "DISTRICT_MTD": 135,
                            "REGIONAL_MTD": 845,
                            "NATIONAL_MTD": 1675,
                            "ACCT_NO": "B3052"
                        },
                        {
                            "SECTIONID": 2,
                            "SECTIONTITLE": "Other Toyota GP PUVR/L",
                            "SECTIONLINENO": 2,
                            "INDICATOR": "",
                            "IND": "G",
                            "DEALERSHIP_MTD": 2489,
                            "DISTRICT_MTD": 476,
                            "REGIONAL_MTD": 647,
                            "NATIONAL_MTD": 1427,
                            "ACCT_NO": "B3051"
                        },
                        {
                            "SECTIONID": 2,
                            "SECTIONTITLE": "Other Used GP PUVR/L",
                            "SECTIONLINENO": 3,
                            "INDICATOR": "",
                            "IND": "R",
                            "DEALERSHIP_MTD": 1465,
                            "DISTRICT_MTD": 108,
                            "REGIONAL_MTD": 347,
                            "NATIONAL_MTD": 1345,
                            "ACCT_NO": "B3007"
                        },
                        {
                            "SECTIONID": 2,
                            "SECTIONTITLE": "Total Used Vehicle GP PUVR/L",
                            "SECTIONLINENO": 4,
                            "INDICATOR": "",
                            "IND": "G",
                            "DEALERSHIP_MTD": 2141,
                            "DISTRICT_MTD": 242,
                            "REGIONAL_MTD": 653,
                            "NATIONAL_MTD": 1470,
                            "ACCT_NO": "B3046"
                        },
                        {
                            "SECTIONID": 2,
                            "SECTIONTITLE": "Reconditioning TCUV",
                            "SECTIONLINENO": 5,
                            "INDICATOR": "",
                            "IND": "G",
                            "DEALERSHIP_MTD": 620,
                            "DISTRICT_MTD": 1706,
                            "REGIONAL_MTD": 1731,
                            "NATIONAL_MTD": 1302,
                            "ACCT_NO": "B9623"
                        },
                        {
                            "SECTIONID": 2,
                            "SECTIONTITLE": "Reconditioning Other Toyota ",
                            "SECTIONLINENO": 6,
                            "INDICATOR": "",
                            "IND": "G",
                            "DEALERSHIP_MTD": 828,
                            "DISTRICT_MTD": 1124,
                            "REGIONAL_MTD": 1272,
                            "NATIONAL_MTD": 892,
                            "ACCT_NO": "B9624"
                        },
                        {
                            "SECTIONID": 2,
                            "SECTIONTITLE": "Reconditioning Other Used ",
                            "SECTIONLINENO": 7,
                            "INDICATOR": "",
                            "IND": "G",
                            "DEALERSHIP_MTD": 1127,
                            "DISTRICT_MTD": 1514,
                            "REGIONAL_MTD": 1666,
                            "NATIONAL_MTD": 1043,
                            "ACCT_NO": "B9625"
                        },
                        {
                            "SECTIONID": 2,
                            "SECTIONTITLE": "Turn Rate (Days)  TCUV",
                            "SECTIONLINENO": 8,
                            "INDICATOR": "",
                            "IND": "R",
                            "DEALERSHIP_MTD": 0,
                            "DISTRICT_MTD": 17.83,
                            "REGIONAL_MTD": null,
                            "NATIONAL_MTD": 20.78,
                            "ACCT_NO": ""
                        },
                        {
                            "SECTIONID": 2,
                            "SECTIONTITLE": "Turn Rate (Days) Other Toyota",
                            "SECTIONLINENO": 9,
                            "INDICATOR": "",
                            "IND": "R",
                            "DEALERSHIP_MTD": 59.89,
                            "DISTRICT_MTD": 45.41,
                            "REGIONAL_MTD": null,
                            "NATIONAL_MTD": 51.02,
                            "ACCT_NO": ""
                        },
                        {
                            "SECTIONID": 2,
                            "SECTIONTITLE": "Turn Rate (Days) Other",
                            "SECTIONLINENO": 10,
                            "INDICATOR": "",
                            "IND": "R",
                            "DEALERSHIP_MTD": 78.39,
                            "DISTRICT_MTD": 31.35,
                            "REGIONAL_MTD": 45.92,
                            "NATIONAL_MTD": 53.38,
                            "ACCT_NO": ""
                        },
                        {
                            "SECTIONID": 2,
                            "SECTIONTITLE": "Cost of Sale  TCUV",
                            "SECTIONLINENO": 11,
                            "INDICATOR": "",
                            "IND": "G",
                            "DEALERSHIP_MTD": 27103,
                            "DISTRICT_MTD": 31493,
                            "REGIONAL_MTD": 29310,
                            "NATIONAL_MTD": 30484,
                            "ACCT_NO": "B9908"
                        },
                        {
                            "SECTIONID": 2,
                            "SECTIONTITLE": "Cost of Sale  Other Toyota",
                            "SECTIONLINENO": 12,
                            "INDICATOR": "",
                            "IND": "G",
                            "DEALERSHIP_MTD": 22329,
                            "DISTRICT_MTD": 22445,
                            "REGIONAL_MTD": 22147,
                            "NATIONAL_MTD": 23997,
                            "ACCT_NO": "B9626"
                        },
                        {
                            "SECTIONID": 2,
                            "SECTIONTITLE": "Cost of Sale  Other Used",
                            "SECTIONLINENO": 13,
                            "INDICATOR": "",
                            "IND": "R",
                            "DEALERSHIP_MTD": 27856,
                            "DISTRICT_MTD": 24265,
                            "REGIONAL_MTD": 23219,
                            "NATIONAL_MTD": 23516,
                            "ACCT_NO": "B9627"
                        },
                        {
                            "SECTIONID": 2,
                            "SECTIONTITLE": "Cost of Sale  Total Used",
                            "SECTIONLINENO": 14,
                            "INDICATOR": "",
                            "IND": "G",
                            "DEALERSHIP_MTD": 25639,
                            "DISTRICT_MTD": 26509,
                            "REGIONAL_MTD": 25520,
                            "NATIONAL_MTD": 25740,
                            "ACCT_NO": "B9909"
                        },
                        {
                            "SECTIONID": 2,
                            "SECTIONTITLE": "Retail to Wholesale Ratio",
                            "SECTIONLINENO": 15,
                            "INDICATOR": "",
                            "IND": "G",
                            "DEALERSHIP_MTD": 7.58,
                            "DISTRICT_MTD": 4.49,
                            "REGIONAL_MTD": 4.05,
                            "NATIONAL_MTD": 3.76,
                            "ACCT_NO": "B9957"
                        }
                    ]
                }
            ],
            [
                {
                    "SECTIONID": "3",
                    "sectionData": [
                        {
                            "SECTIONID": 3,
                            "SECTIONLINENO": 1,
                            "MONTHLY_AVERAGE": "Wholesale Units (YTD)",
                            "DELAER_SHIP_MTD": 6,
                            "DISTRICT_MTD": 32,
                            "REGIONAL_MTD": 24,
                            "NATIONAL_MTD": 22,
                            "ACCT_NO": "B9628"
                        },
                        {
                            "SECTIONID": 3,
                            "SECTIONLINENO": 2,
                            "MONTHLY_AVERAGE": "Used Vehicle Sales",
                            "DELAER_SHIP_MTD": 45,
                            "DISTRICT_MTD": 146,
                            "REGIONAL_MTD": 102,
                            "NATIONAL_MTD": 83,
                            "ACCT_NO": "B9629"
                        },
                        {
                            "SECTIONID": 3,
                            "SECTIONLINENO": 3,
                            "MONTHLY_AVERAGE": "Number of Dealers",
                            "DELAER_SHIP_MTD": 1,
                            "DISTRICT_MTD": 12,
                            "REGIONAL_MTD": 138,
                            "NATIONAL_MTD": 2586,
                            "ACCT_NO": ""
                        }
                    ]
                }
            ]
        ],
        "reportHeaderData": [
            {
                "COMPANY_CODE": "14",
                "COMPANY_NAME": "Toyota",
                "COMPANY": "14 - Toyota",
                "HIERARCHY_LEAVEL_KEY": 10,
                "REGION_CODE": "110",
                "REGION": "110 - Los Angeles",
                "REGION_NAME": "Los Angeles",
                "DISTRICT_CODE": "110A",
                "DISTRICT_NAME": "LA District 1: Los Angeles/No. Orange County",
                "DISTRICT_DISPLAY_CODE": "A",
                "DEALER_CODE": "4033",
                "DEALER_NAME": "Cabe Toyota Long Beach",
                "DEALER": "04033 - Cabe Toyota Long Beach"
            }
        ]
    }
]
}
