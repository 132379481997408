import { Component, OnInit } from "@angular/core";
import { Observable, Subscription, forkJoin } from "rxjs";
import { SpinnerService } from "src/app/services/spinner.service";
import { CustomReportsFiltersService } from "../../services/custom-report-filters.service";
import { CustomReportsApiService } from "../../services/api/custom-report-api.service";
import { CustomReportsService } from "../../services/custom-report.service";
import { ToastService } from "src/app/services/toast.service";
import { Router } from "@angular/router";
import { DATA_FORMAT_FLAGS, dspmColumnNames, dspmColumnNames_1, HIERARCHY_TYPE, monthNames, REPORT_MSGS, reportUrlType } from "src/app/constants/constants";
import { getMonthAndYear, getObjFromFilteredArray } from "../../utils/common-utils";
import { dspmMonthlySummaryReport } from "../reportConstants";

@Component({
  selector: 'app-dspm-monthly-summary-report',
  templateUrl: './dspm-monthly-summary-report.component.html',
  styleUrls: ['./dspm-monthly-summary-report.component.scss']
})

export class DspmMonthlySummaryReport {

  public dspmMonthlySummaryReportjsonObj;
  public subscription: Subscription = new Subscription();
  public sources: Observable<any>[] = [];
  public reportId: number;
  public reportTitleToDisplay: string;
  public monthYear: any = {};
  public noDataToDisplayMsg: string;
  selectedFilteredValues: any = [];
  public companyCode: number;
  previousFilter: any = [];
  reportTitle: string;
  currentTabIndex: number = 0;
  selectedTabIndex: any;
  isFilterSame: any;
  public obj = Object.values;

  constructor(
    private readonly spinnerService: SpinnerService,
    private filterService: CustomReportsFiltersService,
    private customReportsApiService: CustomReportsApiService,
    private customerReportService: CustomReportsService,
    public toast: ToastService,
    public route: Router
  ) {
    const reportTypeNameFromUrl = this.route.url.split('/').pop();

    this.reportId = reportUrlType[reportTypeNameFromUrl];

    this.getDspmMOnthlySummaryReport();
  }
  ngOnInit(): void {
    this.subscribeReportData();
  }

  public removeAllFilters(): void {
    this.filterService?.setRemovedValues({});
  }

  public getTabDataSource(selectedFilter: any) {
    const obj = getObjFromFilteredArray(selectedFilter);
    const dateObj = getMonthAndYear(obj['monthYear']);
    const filterObj = obj['dealer'];
    let requestObj;
    this.monthYear = ' - ' + dateObj.searchMonth + '/' + dateObj.searchYear;  
    if (obj['hierarchy']?.id === HIERARCHY_TYPE.DISTRICT) {  // 11 - District
      this.reportTitleToDisplay = filterObj.DISTRICT_CODE + ' - ' + filterObj.MEMBER_NAME.toUpperCase();
      requestObj = this.createPayload(dateObj.searchMonth, filterObj.REGION_CODE, filterObj.DISTRICT_CODE, dateObj.searchYear, null);

    }
    this.sources = [this.customReportsApiService.getDspmMonthlySummaryReportData(requestObj).pipe(this.customerReportService.handleError()?.bind(this))
    ]
    return this.sources;
  }

  public createPayload(searchMonth, region, district, searchYear,compCode) {
    const requestObj = {
      month: searchMonth,
      year: searchYear,
      region_no: region,
      Company_Code: compCode,
      district_cd: district,
      report_type: this.reportId === 98 ? "monthly" : "yearly",
      view_id: "dspm_monthly_summary"
    }
    return requestObj;
  }

  public subscribeReportData() {
    this.subscription.add(
      this.filterService.getSelectedValues().subscribe({
        next: (selectedFilter) => {
          if (selectedFilter?.length > 0) {
            this.selectedFilteredValues = selectedFilter;
            this.spinnerService.displaySpinner();
            this.mapResponse(selectedFilter);
          }
        }, error: (error) => {
          console.error('Error handler:', error)
          this.spinnerService.hideSpinner();
        }
      })
    )
  }

  ngOnDestroy(): void {
    this.removeAllFilters();
    this.filterService.setSelectedValues([]);
    this.subscription.unsubscribe();
  }

  public getDspmMOnthlySummaryReport() {
    this.dspmMonthlySummaryReportjsonObj = {
      multiDataSources: true,
      datasource: []
    }
  }

  public async mapResponse(selectedFilter: any) {
    let currentTableResponse = [];
    await this.getTabDataSource(selectedFilter);
    this.subscription.add(forkJoin(this.sources).subscribe({
      next: (resp) => {
        this.customerReportService.setPdfExcelIcons(true);
        if (resp[0]?.status === 200 && resp[0].body[0]?.reportHeaderData[0] && resp[0].body[0].reportData?.length > 0) {
          const reportHeaderData = resp[0].body[0].reportHeaderData[0];
          const reportData = resp[0].body[0].reportData;
          const currentMonth = reportHeaderData?.CM;
          const currentYear = reportHeaderData?.CY;
          const tableHeader = [{
            [" "]: '2',
            [`${monthNames[currentMonth - 1].toUpperCase()} ${currentYear}`]: '2',
            [`${monthNames[currentMonth - 1]}  ${currentYear}`]: '2',
            [`${monthNames[currentMonth - 1]} ${currentYear}`]: '2'
          }];
          const tableHeader_1 = [{
            [" "]: '2',
            [`${monthNames[currentMonth - 1].toUpperCase()} ${currentYear}`]: '6',
            [`${monthNames[currentMonth - 1]}  ${currentYear}`]: '3',
          }];
          reportData.forEach((ele, index) => {
            if (ele.sectionData?.length > 0) {
              const obj = [{
                "datasourceName": `DspmMonthlySummaryReport ${index}`,
                "tableData": index === 0 ? this.mapFirstTableData(ele.sectionData, "CLS", "MRO") : this.mapSecondTableData(ele.sectionData, "WHOLESALE", "RETAIL", "TOTAL_PANDA", "GPP"),
                "sectionTitle": ele.SECTIONTITLE,
                "sectionId": ele.SECTIONID,
                "tableHeader": index === 0 ? tableHeader : tableHeader_1,
                "tableSubHeader": index === 0 ? dspmMonthlySummaryReport.dspmSubHeader : dspmMonthlySummaryReport.dspmSubHeader_1,
                "removeLineBreak": true,
                "showRowBorder": true,
                'showNegativeInRed': true,
              }];
              currentTableResponse.push(obj);
            }
          });
        } else {
          this.noDataToDisplayMsg = REPORT_MSGS.NO_RECORDS_FOUND;
        }
        this.dspmMonthlySummaryReportjsonObj.datasource = currentTableResponse;
        this.spinnerService.hideSpinner();
      },
      error: (err) => {
        console.error('Error handler: mapping response', err);
        this.spinnerService.hideSpinner();
      }
    }));

  }

  private mapFirstTableData(sectionData: any[], type1: string, type2: string): any[] {
    const result: any[] = [];
    sectionData.forEach((data) => {
      const existingDealer = result.find(dealer => dealer[dspmColumnNames[0]] === data.DEALER_NO);
      if (existingDealer) {
        if (data.RECORD_TYPE === type1) {
          existingDealer[dspmColumnNames[2]] = data.RANK;
          existingDealer[dspmColumnNames[3]] = data.PRIOR_YR_CHANGE + "%";
        } else if (data.RECORD_TYPE === type2) {
          existingDealer[dspmColumnNames[4]] = data.RANK;
          existingDealer[dspmColumnNames[5]] = data.PRIOR_YR_CHANGE + "%";
        }
      } else {
        result.push({
          [dspmColumnNames[0]]: data.DEALER_NO,
          [dspmColumnNames[1]]: data.DEALER_NAME,
          [dspmColumnNames[2]]: data.RECORD_TYPE === type1 ? data.RANK : null,
          [dspmColumnNames[3]]: data.RECORD_TYPE === type1 ? data.PRIOR_YR_CHANGE + "%" : null,
          [dspmColumnNames[4]]: data.RECORD_TYPE === type2 ? data.RANK : null,
          [dspmColumnNames[5]]: data.RECORD_TYPE === type2 ? data.PRIOR_YR_CHANGE + "%" : null,
          [dspmColumnNames[6]]: data.DEALER_TLE !== null ? `${data.DEALER_TLE}%` : null
        });
      }
    });
    return result;
  }

  private mapSecondTableData(sectionData: any[], type1: string, type2: string, type3: string, type4: string): any[] {
    const result: any[] = [];
    sectionData.forEach((data) => {
      const existingDealer = result.find(dealer => dealer[dspmColumnNames[0]] === data.DEALER_NO);
      if (existingDealer) {
        if (data.RECORD_TYPE === type1) {
          existingDealer[dspmColumnNames_1[0]] = data.RANK;
          existingDealer[dspmColumnNames_1[1]] = data.PRIOR_YR_CHANGE + '%';
        } else if (data.RECORD_TYPE === type2) {
          existingDealer[dspmColumnNames_1[2]] = data.RANK;
          existingDealer[dspmColumnNames_1[3]] = data.PRIOR_YR_CHANGE + '%';
        } else if (data.RECORD_TYPE === type3) {
          existingDealer[dspmColumnNames_1[4]] = data.RANK;
          existingDealer[dspmColumnNames_1[5]] = data.PRIOR_YR_CHANGE + '%';
        } else if (data.RECORD_TYPE === type4) {
          existingDealer[dspmColumnNames_1[6]] = data.RANK;
          existingDealer[dspmColumnNames_1[7]] = data.GPP_LAST_YR_AMT !== null ? `${data.GPP_LAST_YR_AMT}%` : null;
          existingDealer[dspmColumnNames_1[8]] = data.GPP_PRIOR_YR_AMT !== null ? `${data.GPP_PRIOR_YR_AMT}%` : null;;
        }
      } else {
        result.push({
          [dspmColumnNames[0]]: data.DEALER_NO,
          [dspmColumnNames[1]]: data.DEALER_NAME,
          [dspmColumnNames_1[0]]: data.RECORD_TYPE === type1 ? data.RANK : null,
          [dspmColumnNames_1[1]]: data.RECORD_TYPE === type1 ? data.PRIOR_YR_CHANGE + '%' : null,
          [dspmColumnNames_1[2]]: data.RECORD_TYPE === type2 ? data.RANK : null,
          [dspmColumnNames_1[3]]: data.RECORD_TYPE === type2 ? data.PRIOR_YR_CHANGE + '%' : null,
          [dspmColumnNames_1[4]]: data.RECORD_TYPE === type3 ? data.RANK : null,
          [dspmColumnNames_1[5]]: data.RECORD_TYPE === type3 ? data.PRIOR_YR_CHANGE + '%' : null,
          [dspmColumnNames_1[6]]: data.RECORD_TYPE === type4 ? data.RANK : null,
          [dspmColumnNames_1[7]]: data.GPP_LAST_YR_AMT !== null ? `${data.GPP_LAST_YR_AMT}%` : null,
          [dspmColumnNames_1[8]]: data.GPP_PRIOR_YR_AMT !== null ? `${data.GPP_PRIOR_YR_AMT}%` : null,
        });
      }
    });
    return result;
  }



}
