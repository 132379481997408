import * as _ from 'lodash';
import { Component, EventEmitter, Output, OnInit } from "@angular/core";
import { Observable, Subscription, catchError, forkJoin } from "rxjs";
import { CRGridSearch } from "src/app/modules/workout-sales/interfaces/custom-reports.interface";
import { SpinnerService } from "src/app/services/spinner.service";
import { CustomReportsFiltersService } from "../../services/custom-report-filters.service";
import { CustomReportsApiService } from "../../services/api/custom-report-api.service";
import { LexusSalesAtGlanceReport } from "../reportConstants";
import { getMonthAndYear, getObjFromFilteredArray } from "../../utils/common-utils";
import { CustomReportsService } from "../../services/custom-report.service";
import { ToastService } from "src/app/services/toast.service";
import { Router } from "@angular/router";
import { DATA_FORMAT_FLAGS, reportUrlType } from "src/app/constants/constants";

@Component({
  selector: 'app-lexus-sales-at-glance',
  templateUrl: './lexus-sales-at-glance.component.html',
  styleUrls: ['./lexus-sales-at-glance.component.scss']
})
export class LexusSalesAtGlanceComponent implements OnInit {

  profileHeader: string = 'DEALER PROFILE';
  showDepartmentTable: boolean = false;


  public obj = Object.values;
  @Output() updateSearch: EventEmitter<CRGridSearch> = new EventEmitter<CRGridSearch>();

  public currentTabIndex: number = 0;
  public lexusSalesAtGlancejsonObj: any;
  public selectedFilter = [];

  public selectedTabIndex = [0];
  public subscription: Subscription = new Subscription();
  public filtersSubscription: Subscription = new Subscription();
  public isProfileTable: boolean = false;
  public sources: Observable<any>[] = [];
  public tabList: string[] = [];
  public reportId: number;
  public formattedSelectedFilter;
  public reportTitleToDisplay: string;
  public monthYear: string;
  public testFlag: boolean = false;
  public previousFilter;
  isFilterSame: boolean;

  constructor(
    private readonly spinnerService: SpinnerService,
    private filterService: CustomReportsFiltersService,
    private customReportsApiService: CustomReportsApiService,
    private readonly customReportsService: CustomReportsService,
    public route: Router,
    public toast: ToastService

  ) {
    this.tabList = LexusSalesAtGlanceReport.LexusSalesAtGlanceTabNames;
    const reportTypeNameFromUrl = this.route.url.split('/').pop();
    this.reportId = reportUrlType[reportTypeNameFromUrl];
    this.getLexusSalesAtGlanceReport();
  }

  ngOnInit(): void {
    this.subscribeReportData();
    this.displayProfileSection();
  }


  public subscribeReportData() {
    this.filtersSubscription = this.filterService.getSelectedValues()?.pipe().subscribe({
      next: (filteredValues) => {
        if (filteredValues.length > 0) {
          this.spinnerService.displaySpinner();
          this.selectedFilter = filteredValues;
          this.isFilterSame = _.isEqual(this.selectedFilter, this.previousFilter);
          if (this.isFilterSame === false) {
            this.selectedTabIndex = [];
            this.selectedTabIndex.push(this.currentTabIndex);
          }
          if (this.lexusSalesAtGlancejsonObj?.tabList[this.currentTabIndex]?.datasource?.length > 0 || this.isFilterSame === false) {
            this.getLexusSalesAtGlanceReport();
          }
          this.mapResponse();
        } else {
          this.spinnerService.hideSpinner();
        }

      }, error: (error) => {
        console.error('Error handler:', error)
        this.spinnerService.hideSpinner();
      }
    });
  }

  getSelectedYear() {
    this.filterService.getSelectedValues()?.subscribe(val => {
      this.selectedFilter = val
    });
    return this.selectedFilter;
  }

  public tabChange(tabIndex: number) {
    this.currentTabIndex = tabIndex;
    const index = this.selectedTabIndex.findIndex(x => x === tabIndex);
    this.spinnerService.displaySpinner();
    if (index === -1) {
      this.selectedTabIndex.push(tabIndex);
      this.filtersSubscription?.unsubscribe();
      this.subscription?.unsubscribe();
      this.subscribeReportData()
    } else {
      this.spinnerService.hideSpinner();
    }
    this.displayProfileSection();
  }

  public async mapResponse() {
    //when we have datasource and navigating back to same tab without change in filter do not make api call
    // if (this.lexusSalesAtGlancejsonObj?.tabList[this.currentTabIndex]?.datasource?.length === 0) {
    let currentTableResponse = [];
    await this.getTabDataSource(this.currentTabIndex)
    this.subscription = forkJoin(this.sources).subscribe({
      next: (resp) => {
        let dataSources;
        this.customReportsService.setPdfExcelIcons(true);
        if (this.currentTabIndex === 0) {
          const reportData = resp[0]?.body[0].reportData;
          if (reportData && reportData.length > 0) {
            const obj = {
              "datasourceName": `scores`,
              "tableData": reportData,
              "tableHeader": LexusSalesAtGlanceReport.ScoresHeader_1,
              "columnHeaderName": this.obj(this.getDisplayedColumns(resp[0]?.body[0]?.reportHeaderData)),
              'isColumnBorder': ['OBJ', 'MONTH6'],
              "isfixedColumnWidthBorder": ['MEASURE'],
              "removeParentsForNegativeVal": true,
              'showNegativeInRed': true,
              "columnsForComparisonToShowInRed": [{ firstCol: 'MONTH1', secondCol: 'OBJ' }, { firstCol: 'MONTH2', secondCol: 'OBJ' }, { firstCol: 'MONTH3', secondCol: 'OBJ' }, { firstCol: 'MONTH4', secondCol: 'OBJ' }, { firstCol: 'MONTH5', secondCol: 'OBJ' }, { firstCol: 'MONTH6', secondCol: 'OBJ' }, { firstCol: 'DLR', secondCol: 'OBJ' }, { firstCol: 'DIST', secondCol: 'OBJ' }, { firstcol: 'DLR_AREA', secondCol: 'OBJ' }],

            }
            currentTableResponse.push(obj);
          }
        }
        else if (this.currentTabIndex === 1) {
          const reportData = resp[0]?.body[0].reportData;
          const reportHeaderData = resp[0]?.body[0].reportHeaderData;
          if (reportData && reportData.length > 0) {
            const displayedColumns = this.getDisplayedColumns(resp[0]?.body[0]?.reportHeaderData)
            const salesHeader = reportHeaderData[0]?.HEADER;
            const tableHeader = {
              [salesHeader]: 2,
              ...LexusSalesAtGlanceReport.SalesHeader[0]
            }
            const dynamicHeaders = [tableHeader];
            const obj = {
              "datasourceName": `sales`,
              "tableData": reportData,
              "tableHeader": dynamicHeaders,
              "columnHeaderName": this.obj(displayedColumns),
              'isColumnBorder': ['MTD_OBJ', 'MONTH6', 'MTD_OBJ_PECT', 'SALES_AS_AREA', 'YTD', 'LYTD', 'OU_UNITS'],
              "isfixedColumnWidthBorder": ['CATEGORY'],
              "isApplyBgColorOnRow": [3, 4],
              "removeParentsForNegativeVal": true,
              "defaultColumnsUnitFlag": [{ columns: ['MTD_OBJ', 'MONTH1', 'MONTH2', 'MONTH3', 'MONTH4', 'MONTH5', 'MONTH6', 'YTD', 'LYTD', 'OU_UNITS'], unitFlag: DATA_FORMAT_FLAGS.UnitsWithZeroDecimal }, {
                columns: ['MTD_OBJ_PECT', 'SALES_AS_AREA'], unitFlag: DATA_FORMAT_FLAGS.UnitsWithTwoDecimal
              }],
              "showNegativeInRed": true,
              "dataFormattingColumns": 'all',
              "columnsForComparisonToShowInRed": [{ firstCol: 'LSAAG_DLR', secondCol: 'LSAAG_AREA' }, { firstCol: 'LSAAG_YTD_DLR', secondCol: 'LSAAG_YTD_AREA' }, { firstCol: 'LSAAG_P_YTDCHANGE_DLR', secondCol: 'LSAAG_P_YTDCHANGE_AREA' }],

            };
            currentTableResponse.push(obj);
          }
        }
        else if (this.currentTabIndex === 2) {
          const reportData = resp[0]?.body[0].reportData;
          if (reportData && reportData.length > 0) {
            const reportHeaderData = resp[0]?.body[0].reportHeaderData;
            const dynamicHeaders = [{
              [reportHeaderData?.H_GROUP_0?.TITLE]: '', // SALES BY MODEL
              [reportHeaderData?.H_GROUP_1?.TITLE]: '3',// MONTH END SALES
              [reportHeaderData?.H_GROUP_2?.TITLE]: '3',// MONTH END SALES TO AVAILABILITY
              [reportHeaderData?.H_GROUP_3?.TITLE]: '3',// MONTH END DAYS SUPPLY
              [reportHeaderData?.H_GROUP_4?.TITLE]: '3',// YTD SALES
            }]
            const displayedColumns = this.getDisplayedColumns(resp[0]?.body[0]?.reportHeaderData)
            const obj = {
              "datasourceName": `sales_by_model`,
              "tableData": reportData,
              "tableHeader": dynamicHeaders,
              "columnHeaderName": this.obj(displayedColumns),
              "isColumnBorder": ['MTDPCTCHG', 'AREA_MTD_NJSTA', 'AREA_MTD_DS'],
              "isfixedColumnWidthBorder": ['MODEL_DISPLAY_NM'],
              "showNegativeInRed": true,
              "dataFormattingColumns": 'all',
              "removeParentsForNegativeVal": true,
              "defaultColumnsUnitFlag": [{ columns: ['CY_MTD', 'PY_MTD'], unitFlag: DATA_FORMAT_FLAGS.UnitsWithZeroDecimal }, { columns: ['DLR_MTD_NJSTA', 'DIST_MTD_NJSTA', 'AREA_MTD_NJSTA', 'DLR_MTD_DS', 'DIST_MTD_DS', 'AREA_MTD_DS'], unitFlag: DATA_FORMAT_FLAGS.UnitsWithOneDecimal }, { columns: ['MTDPCTCHG', 'YTDPCTCHG'], unitFlag: DATA_FORMAT_FLAGS.PercentWithOneDecimal }],
              "columnsForComparisonToShowInRed": [{ firstCol: 'DLR_MTD_NJSTA', secondCol: 'AREA_MTD_NJSTA' }, { firstCol: 'DLR_MTD_DS', secondCol: 'AREA_MTD_DS' }, { firstCol: 'LSAAG_P_YTDCHANGE_DLR', secondCol: 'LSAAG_P_YTDCHANGE_AREA' }],
            };
            currentTableResponse.push(obj);
          }
        }
        else if (this.currentTabIndex === 3) {
          const reportData = resp[0]?.body[0].reportData;
          if (reportData && reportData.length > 0) {
            const previousYear = new Date().getFullYear() - 1;
            const marketHeader = resp[0]?.body[0]?.reportHeaderData[0]?.HEADER;
            const ytdChange = `YTD %CHANGE ${previousYear}`;
            const dynamicHeaders = [{
              [marketHeader]: 1,
              "ROLLING 12 MONTHS": 3,
              "YTD": 3,
              [ytdChange]: 3,
              "SALES EFFICIENCY": '',
              "MARKET LEADER": '',
              "UNITS +/- LEADER": ''
            }];
            const obj = {
              "datasourceName": `market_share`,
              "tableData": reportData,
              "tableHeader": dynamicHeaders,
              "defaultUnitFlag": DATA_FORMAT_FLAGS.UnitsWithOneDecimal,
              "isColumnBorder": ['LSAAG_AREA', 'LSAAG_YTD_AREA', 'LSAAG_P_YTDCHANGE_AREA', 'LSAAG_SALESEFF', 'LSAAG_MKT_LDR', 'LSAAG_UNITS'],
              "isfixedColumnWidthBorder": ['LSAAG_CATEGORY'],
              "columnsForComparisonToShowInRed": [{ firstCol: 'LSAAG_DLR', secondCol: 'LSAAG_AREA' }, { firstCol: 'LSAAG_YTD_DLR', secondCol: 'LSAAG_YTD_AREA' }, { firstCol: 'LSAAG_P_YTDCHANGE_DLR', secondCol: 'LSAAG_P_YTDCHANGE_AREA' }],
              "showNegativeInRed": true,
              "dataFormattingColumns": 'all',
              "removeParentsForNegativeVal": true,
              "defaultColumnsUnitFlag": [{ columns: ['LSAAG_DLR', 'LSAAG_DIST', 'LSAAG_AREA', 'LSAAG_YTD_DLR', 'LSAAG_YTD_DIST', 'LSAAG_YTD_AREA', 'LSAAG_P_YTDCHANGE_DLR', 'LSAAG_P_YTDCHANGE_DIST', 'LSAAG_P_YTDCHANGE_AREA', 'LSAAG_SALESEFF'], unitFlag: DATA_FORMAT_FLAGS.UnitsWithOneDecimal },
              { columns: ['LSAAG_UNITS'], unitFlag: DATA_FORMAT_FLAGS.UnitsWithZeroDecimal }]
            };
            currentTableResponse.push(obj);
          }
        }
        else if (this.currentTabIndex === 4) {
          const reportData = resp[0]?.body[0].reportData;
          const reportHeaderData = resp[0]?.body[0].reportHeaderData;
          if (reportData && reportData.length > 0) {
            const grossProfitHeader = reportHeaderData[0]?.HEADER;
            const header = { ...LexusSalesAtGlanceReport.GrossProfitHeader[0] };
            delete header["GROSS PROFIT"];
            delete header["GROSS PROFIT"];
            const dynamicHeaders = [{
              [grossProfitHeader]: 13,
            }];
            const obj = {
              "datasourceName": `gross_profit`,
              "tableData": reportData,
              "tableHeader": dynamicHeaders,
              "tableSubHeader": LexusSalesAtGlanceReport.GrossProfitHeader,
              "tableSubHeader2": LexusSalesAtGlanceReport.GrossProfitSubHeader,
              "dataFormattingColumns": 'all',
              'isColumnBorder': ['LSAAG_MTD_AREA_GROSS', 'LSAAG_YTD_AREA_GROSS', 'LSAAG_MTD_AI_AREA'],
              "isfixedColumnWidthBorder": ['LSAAG_LEVEL_DISPLAY_NM'],
              "columnsForComparisonToShowInRed": [{ firstCol: 'LSAAG_MTD_DLR', secondCol: 'LSAAG_MTD_AREA_GROSS' }, { firstCol: 'LSAAG_YTD_DLR_GROSS', secondCol: 'LSAAG_YTD_AREA_GROSS' }, { firstCol: 'LSAAG_MTD_AI_DLR', secondCol: 'LSAAG_MTD_AI_AREA' }, { firstCol: 'LSAAG_YTD_AI_DLR', secondCol: 'LSAAG_YTD_AI_AREA' }],
              "removeParentsForNegativeVal": true,
              "showNegativeInRed": true,

            };
            currentTableResponse.push(obj);
          }
        }
        else if (this.currentTabIndex === 5) {
          if (resp && resp.length > 0) {
            if (resp?.length > 0) {
              const obj = {
                "datasourceName": `dealer_profile`,
                "tableData": resp[0]?.body,
              };
              currentTableResponse.push(obj);
            }
          }
        }

        if (dataSources) {
          currentTableResponse.push(dataSources)
        }

        if (currentTableResponse.length > 0) {
          this.lexusSalesAtGlancejsonObj.tabList[this.currentTabIndex].datasource = currentTableResponse;
        }
        this.previousFilter = _.cloneDeep(this.selectedFilter);
        this.spinnerService.hideSpinner();
      }, error: (err) => {
        console.error('Error handler: mapping response', err);
        this.spinnerService.hideSpinner();
      }
    })
  }

  getDisplayedColumns(reportHeaderData: any) {
    let columnHeaders: string[] = [];
    if (this.currentTabIndex === 0) {
      columnHeaders = [`RETAIL % OBJ (${this.formattedSelectedFilter['monthYear']})`, 'OBJ'];
    } else if (this.currentTabIndex === 1) {
      columnHeaders = ['', 'MTD_OBJ'];
    }
    let dynamicHeaders: string[] = [];
    if (this.currentTabIndex !== 2) {
      reportHeaderData.forEach((header: any) => {
        if (header?.MONTH_NM) {
          dynamicHeaders.push(header.MONTH_NM);
        }

      });
    }

    columnHeaders = columnHeaders.concat(dynamicHeaders.slice(0, 6));
    if (this.currentTabIndex === 0) {
      columnHeaders.push('DLR', 'DIST', 'AREA', "Nat'l");
    } else if (this.currentTabIndex === 1) {
      columnHeaders.push('MTD_OBJ_PECT', 'SALES_AS_AREA', 'YTD', 'LYTD', '', '');
    } else if (reportHeaderData && this.currentTabIndex === 2) {
      const yearHeader = reportHeaderData.H_GROUP_1?.HEADERS || {};
      const hGroup2 = reportHeaderData.H_GROUP_2?.HEADERS;
      const hGroup3 = reportHeaderData.H_GROUP_3?.HEADERS;
      const ytdHeader = reportHeaderData.H_GROUP_4?.HEADERS || {};
      columnHeaders = [
        '',
        yearHeader.RPT_YEAR, yearHeader.PRIOR_YEAR, yearHeader.CHANGE,
        hGroup3?.DLR, hGroup3?.DIST, hGroup3?.AREA,
        hGroup2?.DLR, hGroup2?.DIST, hGroup2?.AREA,
        ytdHeader.RPT_YEAR, ytdHeader.PRIOR_YEAR, ytdHeader.CHANGE
      ];
    }
    return columnHeaders;
  }

  public getTabDataSource(tabIndex: number) {
    const obj = getObjFromFilteredArray(this.selectedFilter);
    this.formattedSelectedFilter = getObjFromFilteredArray(this.selectedFilter);
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const dateObj = getMonthAndYear(this.formattedSelectedFilter['monthYear']);
    const monthNumber = Number(dateObj.searchMonth)
    const monthName = months[monthNumber - 1]
    const filterObj = obj['dealer'];
    this.reportTitleToDisplay = filterObj.DISTRICT_CODE + ' - ' + filterObj.MEMBER_CODE + ' - ' + filterObj.MEMBER_NAME + ' - ' + monthName.toUpperCase() + '-' + dateObj.searchYear;
    const requestObj = {
      month: dateObj.searchMonth,
      year: dateObj.searchYear,
      district_cd: filterObj?.REGION_CODE + filterObj?.DISTRICT_CODE,
      dealer_no: this.formattedSelectedFilter['dealer']?.MEMBER_CODE,
      region_no: filterObj.REGION_CODE,
      view_id: this.getViewId(tabIndex)
    }
    if (requestObj) {
      this.sources = [this.customReportsApiService.getLsaagScores(requestObj).pipe(this.customReportsService.handleError()?.bind(this)),
      ]
    }
  }

  ngOnDestroy(): void {
    this.customReportsService.setPdfExcelIcons(false);
  }

  handleError() {
    return function (obsv: Observable<any>) {
      return obsv.pipe(
        catchError((err) => {
          console.log(err);
          this.spinnerService.hideSpinner();
          return err;
        })
      );
    };
  }

  public displayProfileSection() {
    this.isProfileTable = this.currentTabIndex === 5;
  }

  public getLexusSalesAtGlanceReport() {
    this.lexusSalesAtGlancejsonObj = {
      tabList: [
        {
          tabName: this.tabList[0],
          filterCondition: 'LA - A - 04636 - AN TOYOTA CERRITOS',
          multiDataSources: true,
          datasource: []
        },
        {
          tabName: this.tabList[1],
          filterCondition: '',
          datasource: []
        },
        {
          tabName: this.tabList[2],
          filterCondition: '',
          datasource: []
        },
        {
          tabName: this.tabList[3],
          filterCondition: '',
          datasource: []
        },
        {
          tabName: this.tabList[4],
          filterCondition: '',
          datasource: []
        },
        {
          tabName: this.tabList[5],
          filterCondition: '',
          datasource: []
        }
      ]
    }

  }

  public getViewId(tabIndex: number) {
    const viewIds = ['lsaag_scores', 'lsaag_sales', 'lsaag_sales_by_model', 'lsaag_market_share', 'lsaag_gross_profit', 'lsaag_dealer_profile'];
    return `${viewIds[tabIndex]}`;
  }


}