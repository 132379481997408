import { Pipe, PipeTransform } from "@angular/core";
import { DATA_FORMAT_FLAGS } from "src/app/constants/constants";

@Pipe({
    name: 'dataFormattingPipe'
})

export class dataFormatPipe implements PipeTransform {
    constructor() { }

    public transform(pNumber: any, col: string, pUnitsFlag: any, dataFormattingColumns?: any, removeParentsForNegativeVal?: any, printZeros?: any, defaultColumnsUnitFlag?: any) {
        pUnitsFlag = this.getUnitFlag(defaultColumnsUnitFlag, pUnitsFlag, col);

        if (pNumber === "0" || pNumber === 0 || pNumber === "NULL" || pNumber === "R" || pNumber === "G") {
            if (printZeros) {
                return pNumber
            } else if (pNumber === "NULL" && col === 'LSAAG_SALESEFF') {
                return '-';
            } else if (pNumber === "NULL" && col === 'SALES_AS_AREA') {
                return 'N/A';
            } else {
                return '';
            }
        }
        let functionReturnValue = '';
        if ((!isNaN(pNumber) && (dataFormattingColumns?.includes(col) || dataFormattingColumns === 'all'))) {    // Need to remove the unitFlag from condition & Check weather if it is string or number?

            if (pNumber !== null && pNumber !== '') {
                functionReturnValue = this.getFormattedValue(pUnitsFlag, removeParentsForNegativeVal, pNumber);
            }
        }

        else {
            functionReturnValue = pNumber;
        }
        return functionReturnValue;
    }

    getUnitFlag(defaultColumnsUnitFlag, pUnitsFlag, col) {
        if (defaultColumnsUnitFlag && defaultColumnsUnitFlag.length > 0) {
            defaultColumnsUnitFlag.forEach((columnType) => {
                if (columnType.columns.includes(col)) {
                    pUnitsFlag = columnType.unitFlag;
                }
            })
        }
        return pUnitsFlag;
    }

    getFormattedValue(pUnitsFlag, removeParentsForNegativeVal, pNumber) {
        let functionReturnValue = '';
        switch (pUnitsFlag) {
            case DATA_FORMAT_FLAGS.DollarWithZeroDecimal:
                //dollar w/ 0 decimals
                functionReturnValue = removeParentsForNegativeVal ? this.formatNumber(pNumber, 0, -1, 'dollar', removeParentsForNegativeVal) : '$' + this.formatNumber(pNumber, 0, -1, 'dollar');
                break;
            case DATA_FORMAT_FLAGS.PercentWithOneDecimal:
                //percent w/ 1 decimal
                functionReturnValue = this.formatNumber(pNumber, 1, -1, 'percentage', removeParentsForNegativeVal) + "%";
                break;
            case DATA_FORMAT_FLAGS.UnitsWithOneDecimal:
                //units w/ 1 decimal
                functionReturnValue = this.formatNumber(pNumber, 1, -1, 'units', removeParentsForNegativeVal);
                break;
            case DATA_FORMAT_FLAGS.UnitsWithZeroDecimal:
                //units w/ 0 decimal
                functionReturnValue = this.formatNumber(pNumber, 0, -1, 'unitsWithZeroDecimal', removeParentsForNegativeVal);
                break;
            case DATA_FORMAT_FLAGS.RatioWithTwoDecimal:
                //ratio w/ 2 decimal
                functionReturnValue = this.formatNumber(pNumber, 2, -1) + ":1";
                break;
            case DATA_FORMAT_FLAGS.UnitsWithTwoDecimal:
                //units w/ 2 decimal
                functionReturnValue = this.formatNumber(pNumber, 2, -1, 'units', removeParentsForNegativeVal);
                break;
            case DATA_FORMAT_FLAGS.PercentWithZeroDecimal:
                //percent w/ 0 decimal
                functionReturnValue = this.formatNumber(pNumber, 0, -1, 'percentage', removeParentsForNegativeVal) + "%";
                break;
            case DATA_FORMAT_FLAGS.DollarWithTwoDecimal:
                //dollar w/ 2 decimals
                functionReturnValue = removeParentsForNegativeVal ? this.formatNumber(pNumber, 2, -1, 'dollar', removeParentsForNegativeVal) : '$' + this.formatNumber(pNumber, 2, -1, 'dollar', removeParentsForNegativeVal);
                break;
            case DATA_FORMAT_FLAGS.PercentWithTwoDecimal:
                //percent w/ 2 decimal  
                functionReturnValue = this.formatNumber(pNumber, 2, -1, 'percentage', removeParentsForNegativeVal) + "%";
                break;
            case DATA_FORMAT_FLAGS.ValueWithYesORNo:
                //Yes/No   
                functionReturnValue = pNumber == "1" ? "Yes" : "No";
                break;
            case DATA_FORMAT_FLAGS.PercentWithUnknownDecimal:
                //adding % sign for percentage value
                functionReturnValue = pNumber + '%';
                break;
            case DATA_FORMAT_FLAGS.RatioWithOneDecimal:
                //ratio w/ 1 decimal  0 if 0
                if (pNumber != "0") {
                    functionReturnValue = this.formatNumber(pNumber, 1, -1) + ":1";
                }
                else {
                    functionReturnValue = "0";
                }

                break;

            default:
                functionReturnValue = this.formatNumber(pNumber, 0, -1);
                break;

        }
        return functionReturnValue;
    }

    private formatNumber(sNumber: number, NumDigitsAfterDecimal, UseParensForNegativeNumbers, valueType?, removeParentsForNegativeVal?) {
        let sRtnValue;
        if (sNumber !== null) {
            const value = parseFloat(sNumber.toString()).toFixed(NumDigitsAfterDecimal);
            if (valueType === 'dollar' || valueType === 'unitsWithZeroDecimal') {
                sRtnValue = Number(value).toLocaleString();
            } else {
                sRtnValue = value;
            }
            sRtnValue = this.formatNegativeValue(removeParentsForNegativeVal, sRtnValue, sNumber, valueType, UseParensForNegativeNumbers)

        }
        return sRtnValue;
    }

    formatNegativeValue(removeParentsForNegativeVal, sRtnValue, sNumber, valueType, UseParensForNegativeNumbers) {
        if (removeParentsForNegativeVal) {
            if (sNumber < 0) {
                if (valueType === 'dollar') {
                    sRtnValue = sRtnValue.replace('-', '-$')
                }
            } else {
                let symbol = '';
                if (valueType === 'dollar') {
                    symbol = '$';
                } else {
                    //do nothing
                }
                sRtnValue = symbol + sRtnValue;
            }
        } else {
            sRtnValue = (UseParensForNegativeNumbers === -1 && sNumber < 0) ? "(" + sRtnValue.replace("-", "").trim() + ")" : sRtnValue;
        }
        return sRtnValue;
    }


}
