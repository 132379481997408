<mat-tab-group (focusChange)="tabChange($event.index)">
  <div *ngIf="tcuvPerformanceCardjsonObj?.tabList">
    <mat-tab #tabContent *ngFor="let tab of tcuvPerformanceCardjsonObj?.tabList">
      <ng-template mat-tab-label>{{ tab.tabName }}</ng-template>
      <div [ngClass]="tcuvPerformanceCardjsonObj?.datasource?.length === 0 ? '':'dxfw-content-table-container'">
        <div
          *ngFor="let currdataSource of tcuvPerformanceCardjsonObj?.tabList[currentTabIndex]?.datasource; let i = index">
          <div class="dfxw-part-table">
            <app-multi-header-table [tableIndex]="i" [multiHeaderTableData]='currdataSource' [reportId]="reportId">
            </app-multi-header-table>
          </div>
        </div>
        <div class="dxfw-no-data" *ngIf="selectedFilteredValues?.length === 0">Please select the filters...</div>
      </div>
    </mat-tab>
  </div>
</mat-tab-group>