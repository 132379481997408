import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { forkJoin, Observable, Subscription } from "rxjs";
import { DATA_FORMAT_FLAGS, HIERARCHY_TYPE, reportUrlType, REPORT_MSGS, TCUVSIKIDS } from "src/app/constants/constants";
import { SpinnerService } from "src/app/services/spinner.service";
import { ToastService } from 'src/app/services/toast.service';
import { CustomReportsApiService } from "../../services/api/custom-report-api.service";
import { CustomReportsFiltersService } from "../../services/custom-report-filters.service";
import { CustomReportsService } from "../../services/custom-report.service";
import { getMonthAndYear, getObjFromFilteredArray } from "../../utils/common-utils";
import * as _ from 'lodash';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-tcuv-performance-card',
  templateUrl: './tcuv-performance-card.component.html',
  styleUrls: ['./tcuv-performance-card.component.scss']
})
export class TCUVPeromanceCardComponent implements OnInit {
  public tcuvPerformanceCardjsonObj;
  public subscription: Subscription = new Subscription();
  public sources: Observable<any>[] = [];
  public reportId: number;
  public reportTitleToDisplay: string;
  public noDataToDisplayMsg: string;
  selectedFilteredValues: any = [];
  previousFilter: any = [];
  reportTitle: string;
  currentTabIndex: number = 0;
  selectedTabIndex: any;
  isFilterSame: any;
  public obj = Object.values;
  hideColums: string[];

  constructor(
    private readonly spinnerService: SpinnerService,
    private filterService: CustomReportsFiltersService,
    private customReportsApiService: CustomReportsApiService,
    private customerReportService: CustomReportsService,
    public toast: ToastService,
    public route: Router
  ) {
    const reportTypeNameFromUrl = this.route.url.split('/').pop();

    this.reportId = reportUrlType[reportTypeNameFromUrl];


    this.getTCUVPerformanceCard();
  }
  ngOnInit(): void {
    this.subscribeReportData();
  }

  public removeAllFilters(): void {
    this.filterService?.setRemovedValues({});
  }

  public async getTabDataSource(tabIndex: number) {
    const obj = getObjFromFilteredArray(this.selectedFilteredValues);
    const dateObj = getMonthAndYear(obj['monthYear']);
    const requestObj = {
      month: dateObj.searchMonth,
      year: dateObj.searchYear,
      dealerno: obj['dealer']?.MEMBER_CODE,
      view_id: this.getViewId(tabIndex)
    }
    if (tabIndex === 0) {
      this.sources = [this.customReportsApiService.getTCUVPerformaceReportCardData(requestObj, tabIndex).pipe(this.customerReportService.handleError()?.bind(this))]
    } else if (tabIndex === 1) {
      this.sources = [this.customReportsApiService.getTCUVPerformaceReportCardData(requestObj, tabIndex).pipe(this.customerReportService.handleError()?.bind(this))]
    } else if (tabIndex === 2 || tabIndex === 3) {
      requestObj["l_prorated_expenses"] = null;
      this.sources = [this.customReportsApiService.getTCUVPerformaceReportCardData(requestObj, tabIndex).pipe(this.customerReportService.handleError()?.bind(this))]
    } else if (tabIndex === 4 || tabIndex === 5 || tabIndex === 6) {
      requestObj['sik'] = TCUVSIKIDS[tabIndex];
      this.sources = [this.customReportsApiService.getTCUVPerformaceReportCardData(requestObj, tabIndex).pipe(this.customerReportService.handleError()?.bind(this))]
    }
    else {
      this.spinnerService.hideSpinner();
    }
    return this.sources
  }


  public tabChange(tabIndex: number) {
    this.currentTabIndex = tabIndex;
    this.sources = [];
    const index = this.selectedTabIndex.findIndex(x => x === tabIndex);
    this.spinnerService.displaySpinner();
    if (index === -1 && this.selectedFilteredValues.length > 0) {
      this.selectedTabIndex.push(tabIndex);
      this.filterService.setSelectedValues(this.selectedFilteredValues);
    } else {
      this.spinnerService.hideSpinner();
    }
  }

  public subscribeReportData() {
    this.subscription.add(this.filterService.getSelectedValues().subscribe({
      next: (selectedFilter) => {
        if (selectedFilter.length > 0) {
          this.spinnerService.displaySpinner();
          this.selectedFilteredValues = selectedFilter;
          this.isFilterSame = _.isEqual(this.selectedFilteredValues, this.previousFilter);
          if (this.isFilterSame === false) {
            this.selectedTabIndex = [];
            this.selectedTabIndex.push(this.currentTabIndex);
          }
          if (this.tcuvPerformanceCardjsonObj?.tabList[this.currentTabIndex]?.datasource?.length > 0 || this.isFilterSame === false) {
            this.getTCUVPerformanceCard(); //initialise to empty object
          }
          this.mapResponse();
        }
        else {
          this.spinnerService.hideSpinner();
        }
      }, error: (error) => {
        console.error('Error handler:', error)
        this.spinnerService.hideSpinner();
      }
    })
    )
  }

  ngOnDestroy(): void {
    this.customerReportService.setPdfExcelIcons(false);
    this.removeAllFilters();
    this.filterService.setSelectedValues([]);
    this.subscription.unsubscribe();
  }

  public getTCUVPerformanceCard() {
    let tabList = [
      {
        tabName: 'Summary',
        datasource: []
      },
      {
        tabName: 'Dashboard',
        datasource: []
      },
      {
        tabName: 'Advertising And Grosses',
        datasource: []
      },
      {
        tabName: 'Floor Plan And Processes',
        datasource: []
      },
      {
        tabName: 'Calendar Year Turns',
        datasource: []
      },
      {
        tabName: 'Gross ROI',
        datasource: []
      },
      {
        tabName: 'Net ROI',
        datasource: []
      }
    ];
    this.tcuvPerformanceCardjsonObj = {
      tabList: tabList
    }
  }

  public async mapResponse() {
    let currentTableResponse = [];
    await this.getTabDataSource(this.currentTabIndex);
    this.subscription.add(forkJoin(this.sources).subscribe({
      next: (resp) => {
        this.customerReportService.setPdfExcelIcons(true);
        if (this.currentTabIndex === 0) {
          if (resp.length > 0 && resp[0].status === 200) {
            let reportData = resp[0]?.body[0]?.reportData;
            if (reportData?.length > 0) {
              reportData.forEach((ele, index) => {
                if (ele[0]?.sectionData?.length > 0) {
                  const obj = {
                    "datasourceName": `TCUVPerformanceSummaryDataSource ${index}`,
                    "tableData": ele[0].sectionData,
                    "sectionTitle": ele[0].SECTIONTITLE,
                    "sectionId": ele[0].SECTIONID,
                    "removeLineBreak": true,
                    'dataFormattingColumns': 'all',
                    "isDisplayedColumns": false,
                    "removeParentsForNegativeVal": true,
                    "showNegativeInRed": true,
                    "isReduceSColumnWidth": ['DEALER_MTD_IND', 'DEALER_YTD_IND'],
                    "isFixedMeasureColumnWidth": ['TTL_TCUV_INVENTORY_VAL'],
                    "defaultColumnsUnitFlag": [{ columns: ['DEALERSHIP_MTD', 'DEALERSHIP_YTD'], unitFlag: DATA_FORMAT_FLAGS.UnitsWithTwoDecimal },
                    { columns: ['DISTRICT', 'NATIONAL', 'DEALER_TCUV', 'REGIONAL'], unitFlag: DATA_FORMAT_FLAGS.UnitsWithZeroDecimal },],
                    "isAllColumnsLeftAlignment": true,
                  };
                  currentTableResponse.push(obj);
                }
              });
            } else {
              this.noDataToDisplayMsg = REPORT_MSGS.NO_RECORDS_FOUND;
            }

          }
        } else if (this.currentTabIndex === 4 || this.currentTabIndex === 5 || this.currentTabIndex === 6) {
          let rowsBorderCount;
          if (this.currentTabIndex === 4) {
            rowsBorderCount = [4, 9, 14, 20, 23]
          } else if (this.currentTabIndex === 5) {
            rowsBorderCount = [4, 10, 15]
          } else {
            rowsBorderCount = [3, 8, 11]
          }
          if (resp.length > 0 && resp[0].status === 200) {
            let reportData = resp[0].body[0]?.reportData;
            if (reportData?.length > 0) {
              const obj = {
                "datasourceName": `TCUVPerformanceCalendarDataSource`,
                "tableData": reportData,
                "removeLineBreak": true,
                "isDisplayedColumns": false,
                "showRowBorder": true,
                "isReduceSColumnWidth": ['STEP', 'SYMBOL'],
                "removeParentsForNegativeVal": true,
                "showNegativeInRed": true,
                "defaultUnitFlag": DATA_FORMAT_FLAGS.UnitsWithZeroDecimal,
                'dataFormattingColumns': 'all',
                "defaultColumnsUnitFlag": [{
                  columns: ['TOTALS'], unitFlag: DATA_FORMAT_FLAGS.UnitsWithZeroDecimal
                },
                { columns: [], unitFlag: DATA_FORMAT_FLAGS.UnitsWithTwoDecimal }
                ],
                "dateFormattingColumns": ['TOTALS'],
                "isLeftAlignmentForROITabs": true,
                "rowShowBorderBottom": rowsBorderCount
              };
              // obj[0]['hideColumns'] = ['SYMBOL'];
              currentTableResponse.push(obj);
            } else {
              this.noDataToDisplayMsg = REPORT_MSGS.NO_RECORDS_FOUND;
            }

          }
        } else if (this.currentTabIndex === 1 || this.currentTabIndex === 2 || this.currentTabIndex === 3) {
          if (resp.length > 0 && resp[0].status === 200 && resp[0].body) {
            let reportData = resp[0]?.body;
            Object.keys(reportData).forEach(key => {
              let tcuvPerformanceDashboardHeader = [{ [key.toUpperCase()]: 8 }];

              reportData[key]?.forEach((ele, index) => {

                const DashboardSubHeader = [{
                  [ele.SECTIONTITLE]: 8,
                }];

                let displayedColumns;
                if (ele.sectionData?.length > 0) {
                  displayedColumns = this.getDisplayedColumns(ele.sectionData[0])

                  // Group section data using HEADER_1
                  const groupedSectionData = _.groupBy(ele.sectionData, 'HEADER_1');
                  Object.values(groupedSectionData).forEach((secData, i) => { // loop for each grouped data to show as table data
                    let obj;
                    // Create table data for the first table of the Section in the Category
                    if (i === 0) {
                      displayedColumns = this.getDisplayedColumns(secData[0]);
                      obj = {
                        "defaultColumnsUnitFlag": [{
                          columns: ['TTL_DLR_GROSS_PROFIT', 'TTL_DLR_SALES', 'TTL_SALES',
                            'TTL_DLR_GP', 'NET_PRFT_BEFORE_TAX', 'DEPT_GROSS_PFT', 'DEPT_SALES', 'DLR_GROSS_PRFT', 'TTL_USED_INVENTORY_VAL',
                            'AVG_USED_INVENTORY_VAL', 'AVG_TCUV_INVENTORY_VAL', 'TTL_TCUV_INVENTORY_VAL',
                            'TTL_USED_VEHICLE_COST', 'AVG_USED_VEHICLE_COST', 'TCUV_USED_VEHICLE_COST', 'AVG_TCUV_VEHICLE_COST',
                            'TTL_USED_VEHICLE_SOLD', 'TTL_USED_VEHICLE_INVENTRY', 'AVG_TCUV_INVENTORY_VAL', 'TTL_TCUV_INVENTORY_VAL', 'DLY_$_TRAVEL_RATE',
                            'TTL_USED_VEHICLE_INV_$', 'TOTAL_USED_VEHICLE_UPS', 'TOTAL_USED_VEHICLE_SALES', 'ADVERTISING_COST_PER_USED_UNIT_SOLD',
                            'USED_VEHICLE_ADVERTISING', 'MTD', 'YTD', 'TOTAL_USED_GROSS_PROFIT', 'TOTAL_USED_UNITS_SOLD', 'AVERAGE_USED_GROSS_PUVR_L',
                            'TOTAL_NON_CERT_TOYOTA_GROSS_PROFIT', 'TOTAL_NON_CERT_TOYOTA_UNITS_SOLD', 'TOTAL_OTHER_GROSS_PROFIT',
                            'TOTAL_OTHER_UNITS_SOLD', 'AVERAGE_OTHER_GROSS_PUVR_L', 'AVERAGE_WHOLESALE_GP_PUVW', 'USED_WHOLESALE_GP', 'UNITS_WHOLESALED',
                            'AVERAGE_FI_GP_PUVR_L', 'USED_FI_GP', 'TOTAL_USED_UNITS_SOLD_GROSS', 'TCUV_INVENTORY',
                            'TOTAL_INVENTORY', 'TOYOTA_USED_INVENTORY', 'TCUV_NOTES_PAYABLE', 'TOTAL_NOTES_PAYABLE', 'TOYOTA_USED_NOTES_PAYABLE', 'TOTAL_INVENTORY_EQUITY', 'TOYOTA_NOTES_PAYABLE_EQUITY',
                            'USED_VEHICLE_EQUITY', 'TTL_USED_VEHICLE_SALES_UNITS', 'TTL_USED_FLOOR_PLAN_INTEREST', 'TTL_USED_FLOOR_VEHICLE_GP', 'TTL_USED_FLOOR_PLAN_INTEREST_GP', 'TTL_AVERAGE_FLOOR_PLAN_EXPENSE_GP', 'TTL_NEW_SALES',
                            'TTL_USED_SALES', 'TTL_NEW_SALES', 'TTL_NEW_OTHER_SALES', 'TOTAL_USED_DEPT_COMPENSATION', 'USED_VEH_SALES_COMPENSATION', 'USED_VEH_SEC_COMPENSATION', 'NON_CERT_USED_CHARGEBACKS_INCOME',
                            'NON_CERT_USED_FIN_CHARGEBACKS', 'NON_CERT_USED_FIN_INCOME', 'OTHER_USED_INVENTORY', 'TOTALS', 'AVERAGE_NON_CERT_TOYOTA_GROSS_PUVR_L'], unitFlag: DATA_FORMAT_FLAGS.UnitsWithZeroDecimal
                        },
                        {
                          columns: ['GP_PERCENT_SALES', 'NET_SALES_PERCENT', 'NET_TO_GROSS', 'DEPT_GP_SALES', 'DEPT_GP_PERCENT', 'MTD', 'YTD', 'AVERAGE_OTHER_GROSS_PUVR_L',
                            'TTL_AVERAGE_FLOOR_PLAN_EXPENSE_PUVR', 'USED_VEHICLE_EQUITY', 'TTL_AVERAGE_FLOOR_PLAN_EXPENSE_GP', 'NON_CERT_USED_CHARGEBACKS_INCOME'
                          ], unitFlag: DATA_FORMAT_FLAGS.UnitsWithTwoDecimal
                        }
                        ],
                        "tableSubHeader": DashboardSubHeader,
                      };
                      if (index === 0) {
                        obj['tableHeader'] = tcuvPerformanceDashboardHeader;
                      }
                    } else {  // Create table data for other tables of the Section in the Category 
                      // and Section name will be hidden for each table except first table
                      displayedColumns = this.getDisplayedColumns(secData[0]);
                      obj = {
                        "tableSubHeaderWhiteBGColorClass": "bg-color-white",
                        "isAllColumnsLeftAlignment": true,
                        "defaultColumnsUnitFlag": [{
                          columns: ['TTL_DLR_GROSS_PROFIT', 'TTL_DLR_SALES', 'NET_PRFT_BEFORE_TAX',
                            'TTL_DLR_GP', 'NET_PRFT_BEFORE_TAX', 'DEPT_GROSS_PFT', 'DEPT_SALES', 'DLR_GROSS_PRFT', 'TTL_USED_INVENTORY_VAL',
                            'AVG_USED_INVENTORY_VAL', 'AVG_TCUV_INVENTORY_VAL', 'TTL_TCUV_INVENTORY_VAL', 'TTL_USED_INVENTORY_VAL', 'AVG_USED_INVENTORY_VAL',
                            'TTL_USED_VEHICLE_COST', 'AVG_USED_VEHICLE_COST', 'AVG_USED_VEHICLE_COST', 'TCUV_USED_VEHICLE_COST', 'AVG_TCUV_VEHICLE_COST',
                            'TTL_USED_VEHICLE_SOLD', 'TTL_USED_VEHICLE_INVENTRY', 'AVG_TCUV_INVENTORY_VAL', 'TTL_TCUV_INVENTORY_VAL', 'DLY_$_TRAVEL_RATE', 'TTL_USED_VEHICLE_COST',
                            'AVG_TCUV_VEHICLE_COST', 'TCUV_USED_VEHICLE_COST', 'TTL_USED_VEHICLE_INV_$', 'TOTAL_USE_VEHICLE_EXPENSES', 'TOTAL_USED_VEHICLE_UPS', 'COST_PER_USED_VEHICLE_UPS',
                            'TOTAL_USED_VEHICLE_RL_GP', 'USED_VEHICLE_ADVERTISING_EXPENSE', 'USED_VEH_ADVERTISING_USED_GP', 'AVERAGE_SALES_PER_MONTH', 'TTL_SALES_MT', 'AVERAGE_SALES_PER_DAY', 'AVERAGE_SALES_PER_MONTH_DAYS',
                            'AVERAGE_SALES_PER_DAY_YTD', 'CONTRACTS_IN_TRANSIT', 'TOTAL_USED_GP', 'TTL_USED_DEP_COMPENSATION', 'USED_DEPT_COMPENSATION_GP', 'TCUV_USED_CHARGEBACKS_INCOME', 'TCUV_USED_FIN_CHARGEBACKS', 'TCUV_USED_FIN_INCOME'], unitFlag: DATA_FORMAT_FLAGS.UnitsWithZeroDecimal
                        },
                        { columns: ['DLY_SLS_TRAVEL_RATE', 'DAYS_SUPPLY', 'USED_VEH_ADVERTISING_USED_GP', 'USED_DEPT_COMPENSATION_GP', 'TCUV_USED_CHARGEBACKS_INCOME'], unitFlag: DATA_FORMAT_FLAGS.UnitsWithTwoDecimal }
                        ],

                        "isReduceSColumnWidthPixels": ['TTL_TCUV_INVENTORY_VAL', 'TTL_TCUV_INVENTORY_UNITS', 'TCUV_USED_VEHICLE_COST',
                          'TCUV_USED_VEHICLE_SOLD', 'TTL_USED_VEHICLE_INVENTRY', 'HEADER_1']
                      };
                    };
                    obj['datasourceName'] = `tcuv-performance`;
                    obj['sectionTitle'] = ele.SECTIONTITLE;
                    obj['sectionId'] = ele.SECTIONID;
                    obj['tableData'] = secData;
                    obj['columnHeaderName'] = this.obj(displayedColumns);
                    obj["tableHeaderClass"] = "bg-color-yellow";
                    obj["tableSubHeaderBGColorClass"] = "bg-color-brown";
                    obj["tableSubHeaderWhiteBGColorClass"] = "bg-color-white";
                    obj["isReduceSColumnWidth"] = ['TABLE_TYPE'];
                    obj["istcuvPerfSessionWiseFormula"] = secData[0]?.FORMULA;
                    obj["printZeros"] = false;
                    obj['removeLineBreak'] = true;
                    obj['dataFormattingColumns'] = 'all';
                    obj["isAllColumnsLeftAlignment"] = true;
                    obj['hideColumns'] = ['HEADER_1', 'HEADER_2', 'HEADER_3', 'HEADER_4', 'SALES', 'SECTIONID', 'SECTIONTITLE', 'SUB_HEADER', 'GROSS_PROFIT', 'FORMULA', 'GPSALES',
                      'DLRGUIDE', 'OPERTAIONS', 'SALES', 'SPLIT_1', 'SPLIT_2', 'PLACEHOLDER_1', 'PLACEHOLDER_2', 'PLACEHOLDER_3', 'CATEGORY'];
                    obj['showNegativeInRed'] = true;
                    obj["removeParentsForNegativeVal"] = true;
                    currentTableResponse.push(obj);
                  });
                }
              })
            });
          }
        }
        this.tcuvPerformanceCardjsonObj.tabList[this.currentTabIndex].datasource = currentTableResponse;
        this.previousFilter = cloneDeep(this.selectedFilteredValues);
        this.spinnerService.hideSpinner();
      }, error: (err) => {
        console.error('Error handler: mapping response', err);
        this.spinnerService.hideSpinner();
      }
    })
    )
  }

  public getHeaderFromSectionData(sectionData) {
    const formattedSectionData = sectionData.map(data => _.pick(data, ['HEADER_1', 'HEADER_2', 'HEADER_3', 'HEADER_4']));
    return formattedSectionData;
  }

  public getViewId(tabIndex: number) {
    const viewIds = [
      'tcuv_performance_summary',
      'tcuv_performance_dashbaord',
      'tcuv_performance_advertising_grosses',
      'tcuv_floorplan_process_report',
      'tcuv_performance_cal_yr_trns_grss_net_roi',
      'tcuv_performance_cal_yr_trns_grss_net_roi',
      'tcuv_performance_cal_yr_trns_grss_net_roi'
    ];
    return `${viewIds[tabIndex]}`;
  }

  getDisplayedColumns(reportHeaderData: any) {
    reportHeaderData['OPERTAIONS'] = '';
    const data = _.pick(reportHeaderData, 'OPERTAIONS', 'HEADER_1', 'HEADER_2', 'HEADER_3', 'HEADER_4');
    return data;
  }
}  