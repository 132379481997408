import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { COMPANY_TYPE, DATA_VALIDATION_MEGS, HIERARCHY_TYPE, lexusReports, reportId, reportUrlType, toyotaReports } from 'src/app/constants/constants';
import { CRGridSearch } from 'src/app/modules/workout-sales/interfaces/custom-reports.interface';
import { SpinnerService } from 'src/app/services/spinner.service';
import { CustomReportsApiService } from '../../services/api/custom-report-api.service';
import { CustomReportsFiltersService } from '../../services/custom-report-filters.service';
import { CustomReportsService } from '../../services/custom-report.service';
import { formattedResponseForCompanySelect, formattedResponseForHierarchySelect } from '../../utils/common-utils';
import { Router } from '@angular/router';

@Component({
  selector: 'side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {

  @Input() menuTemplate: TemplateRef<any>;
  @Input() title: string = '';
  @Input() sideNav: MatSidenav;
  @Input() requiredFilters;
  @Input() reportId;
  @Input() searchFilterObject;
  @Output() searchChips: EventEmitter<CRGridSearch> = new EventEmitter<CRGridSearch>();
  @Output() searchFilter: EventEmitter<CRGridSearch> = new EventEmitter<CRGridSearch>();
  @Output() selectedDealers: EventEmitter<CRGridSearch> = new EventEmitter<CRGridSearch>();
  @Output() clearFilter: EventEmitter<string> = new EventEmitter<string>();
  requiredMsg: string = '';
  public expandedSideNav: boolean = true;
  companyDropdownData: any = [];
  hierarchyDropdownData: any = [];
  readonly subscription: Subscription = new Subscription();
  dealersResp: any;
  hierarchyResp: any = [];
  selectedCompanyVal: any;
  dateFilterResp: any = [];
  selectedHrchy: any;
  isMultiSelectDisabled: boolean = false;
  columnsToShowForMultiSelect = [];
  headersToShow = [];
  hierarchyAPIResp: any;
  hierarchyMembersResp: any;
  hierarchyTypeResp: any;
  filtersResp: any;
  private _removedFilteredKey;
  formattedHierarchyData: any;
  noDataMsg: string;
  reportHierarchyType: string;

  get removedFilterKey() {
    return this._removedFilteredKey;
  }
  @Input() set removedFilterKey(value: any) {
    this._removedFilteredKey = value;
    if (this._removedFilteredKey?.key === 'company') {
      this.clearFilterKey(this._removedFilteredKey?.key);
    }

  }

  constructor(public customReportsApiService: CustomReportsApiService,
    public filterService: CustomReportsFiltersService,
    private readonly spinnerService: SpinnerService,
    public changeDetector: ChangeDetectorRef,
    public customReportsFiltersService: CustomReportsFiltersService,
    public route: Router
  ) {
    const reportTypeNameFromUrl = this.route.url.split('/').pop();
    this.reportId = reportUrlType[reportTypeNameFromUrl];
    const reportList = JSON.parse(localStorage.getItem('report-list'));
    this.reportHierarchyType = reportList?.[this.reportId]?.departmentType;
  }

  ngOnInit() {
    this.getDlrHrcyMembersList();
    this.getRequiredFilterCalls();
  }

  getRequiredFilterCalls() {
    if (this.requiredFilters?.dateFilter && this.requiredFilters?.companyDropdownFilter && this.selectedCompanyVal && this.dateFilterResp?.length === 0) {
      this.getDate();
    }
    if (this.requiredFilters?.dateFilter && !this.requiredFilters?.companyDropdownFilter && this.dateFilterResp?.length === 0) {
      this.getDate();
    }
    if (!this.requiredFilters?.hierarchyDropdownFilter && this.hierarchyDropdownData?.length > 0) { //  No hierarchyFilter in Required Filters
      this.selectedHrchy = this.hierarchyDropdownData.find(x => x.id === HIERARCHY_TYPE.DEALER)?.id;
    }
    if (!this.requiredFilters?.companyDropdownFilter && this.companyDropdownData?.length > 0) {  //  No companyFilter in Required Filters
      if (toyotaReports.includes(this.reportId)) {
        this.selectedCompanyVal = COMPANY_TYPE.TOYOTA;
      } else if (lexusReports.includes(this.reportId)) {
        this.selectedCompanyVal = COMPANY_TYPE.LEXUS;
      }
      this.getFilteredHierarchyDrodownData();
    }
    if (this.requiredFilters?.companyDropdownFilter) {
      this.getFilteredHierarchyDrodownData();
      this.getFilteredHierarchyMember();
    }
    if (this.requiredFilters?.hierarchyDropdownFilter) {
      this.getFilteredHierarchyMember();
    }
    if (this.requiredFilters?.multiSelectDealerMemberFilter) {
      this.columnsToShowForMultiSelect = ['MEMBER_CODE', 'MEMBER_NAME'];
      this.getFilteredHierarchyMember();
    }
    if (this.requiredFilters?.multiSelectHierarchyMemberFilter) {
      this.getHierachyMemberHeader();
      this.getFilteredHierarchyMember();
    }
  }

  public handleSideNavToggle(): void {
    this.expandedSideNav = !this.expandedSideNav;
    this.sideNav?.toggle();
  }

  public updateSearchInfo(event): void {
    this.searchChips.emit(event);
    if (event?.key === 'company') {
      this.selectedHrchy = '';
      this.selectedCompanyVal = event.value.id;
      this.getRequiredFilterCalls();
    }
    else if (event?.key === 'hierarchy') {
      this.selectedHrchy = event.value.id;
      this.getRequiredFilterCalls();
    }
  }

  getDlrHrcyMembersList() {
    const userAccess: any = JSON.parse(sessionStorage.getItem('user_access'));
    if (userAccess && userAccess[`${this.reportHierarchyType}`]) {
      this.filtersResp = userAccess[`${this.reportHierarchyType}`];
      this.getFilteredData();
    } else {
      this.spinnerService.displaySpinner();
      this.subscription.add(this.customReportsApiService.getDlrAndHrcyMemData(this.selectedCompanyVal, this.selectedHrchy, `hierarchy-members-${this.reportHierarchyType}`).subscribe((resp => {
        if (resp.status === 200) {

          this.filtersResp = Object.values(resp?.body)[0];
          if (this.filtersResp?.HierarchyMemberData?.length > 0) {
            const listMmbr = { [this.reportHierarchyType]: this.filtersResp, ...userAccess }
            sessionStorage.setItem('user_access', JSON.stringify(listMmbr));
          }
          this.spinnerService.hideSpinner();
          this.getFilteredData();
        }
      }
      ),
        (err) => {
          console.log(err, 'errorrrrrrrrrr')
          this.spinnerService.hideSpinner();

        }
      )
      )
    }
  }

  getFilteredData() {
    this.companyDropdownData = this.filtersResp?.CompanyData ? formattedResponseForCompanySelect(this.filtersResp?.CompanyData) : [];
    this.hierarchyResp = this.filtersResp?.HierarchyMemberData ? _.clone(this.filtersResp?.HierarchyMemberData) : [];
    this.formattedHierarchyData = this.filtersResp?.HierarchyData ? formattedResponseForHierarchySelect(this.filtersResp?.HierarchyData) : [];
    this.hierarchyDropdownData = _.uniqBy(this.formattedHierarchyData, 'id');
    if (this.reportId === reportId.DEALER_REVIEW) {
      this.hierarchyDropdownData = this.hierarchyDropdownData.filter(ele => ele.name === 'Dealer');
    }
    if (this.reportId === reportId.DSPM_MONTHLY_SUMMARY_REPORT || this.reportId === reportId.DSPM_YTD_SUMMARY) {
      this.hierarchyDropdownData = this.hierarchyDropdownData.filter(ele => ele.name === 'District');
    }
    this.changeDetector.detectChanges();
  }

  getFilteredHierarchyMember() {
    if (this.filtersResp?.HierarchyMemberData?.length > 0) {
      this.isMultiSelectDisabled = false;
      if (this.selectedHrchy && this.selectedCompanyVal) {
        this.hierarchyResp = this.filtersResp?.HierarchyMemberData.filter((x) => (x.COMPANY_ID === this.selectedCompanyVal || x.COMPANY_ID === COMPANY_TYPE.BOTH) && x.LEVEL_ID === this.selectedHrchy); // Refer Note in getFilteredHierarchyDrodownData
      } else if (this.selectedHrchy) {
        this.hierarchyResp = _.filter(this.filtersResp?.HierarchyMemberData, { LEVEL_ID: this.selectedHrchy });
      } else if (this.selectedCompanyVal) {
        this.hierarchyResp = this.filtersResp?.HierarchyMemberData.filter((x) => x.COMPANY_ID === this.selectedCompanyVal || x.COMPANY_ID === COMPANY_TYPE.BOTH); //  // Refer Note in getFilteredHierarchyDrodownData
      }
    }
  }


  getDate(): void {
    this.spinnerService.displaySpinner();
    this.subscription.add(this.customReportsApiService.getDateAndMonth(this.reportId, this.selectedCompanyVal).subscribe((resp => {
      if (resp.status === 200) {
        this.spinnerService.hideSpinner();
        this.dateFilterResp = resp.body;
        if (this.dateFilterResp?.length === 0) {
          this.noDataMsg = DATA_VALIDATION_MEGS.NO_DATA_FOUND;
        }
      }
    }), (err) => {
      console.log(err, 'errrrrrrrrr');
      this.spinnerService.hideSpinner();
    }));
  }

  getHierachyMemberHeader() {
    if (this.reportHierarchyType === 'tfs') { //for tfs reports
      if (this.requiredFilters?.multiSelectDealerMemberFilter) {
        this.columnsToShowForMultiSelect = ['MEMBER_NAME'];
        this.headersToShow = [];
      } else {
        if (this.selectedHrchy === HIERARCHY_TYPE.DEALER) {
          this.columnsToShowForMultiSelect = ['REGION_CODE', 'MEMBER_CODE', 'MEMBER_NAME'];
          this.headersToShow = ['Region', 'Dlr.#', 'Dealer name']
        } else {
          this.columnsToShowForMultiSelect = ['MEMBER_NAME'];
          this.headersToShow = [];
        }
      }
    } else {
      if (this.requiredFilters?.multiSelectDealerMemberFilter) {
        this.columnsToShowForMultiSelect = ['MEMBER_CODE', 'MEMBER_NAME'];
      } else {
        if (this.selectedHrchy === HIERARCHY_TYPE.REGION) {
          this.columnsToShowForMultiSelect = ['REGION_CODE', 'MEMBER_NAME']
        } else {  // for district(11) and dealer(10)           
          this.columnsToShowForMultiSelect = ['REGION_CODE', 'DISTRICT_CODE', 'MEMBER_CODE', 'MEMBER_NAME'];
        }
        if (this.selectedHrchy === HIERARCHY_TYPE.DEALER) {
          this.headersToShow = ['Rgn.', 'Dstr', 'Dlr', 'Dealer name']
        } else {  //// for district(11) and region(12)
          this.headersToShow = [];
        }
      }
    }
  }

  getFilteredHierarchyDrodownData() {
    if (this.selectedCompanyVal && !this.selectedHrchy) {
      // Note: // For TFS report, due to some reason, we will not get data company id as 14 or 15 but 16 in hierarchy level: National, Region, DSSO, Area so we cannot filter hierarchy members based on company selection, so all hierarchy members will be shown for them
      // but for hierarchy level: Dealer, we will get data with company id 14 or 15, so hierarchy members will be shown for based on company selection for Dealer data
      // therefore, condition x.COMPANY_ID === COMPANY_TYPE.BOTH is added to show all hierarchy members for National, Region, DSSO, Area for TFS report
      this.hierarchyDropdownData = this.formattedHierarchyData.filter((x) => x.COMPANY_ID === this.selectedCompanyVal || x.COMPANY_ID === COMPANY_TYPE.BOTH);
    }
  }

  clearFilterKey(key) {
    if (key === 'company') {
      this.hierarchyDropdownData = [];
      this.hierarchyResp = [];
      this.dealersResp = [];
      this.selectedCompanyVal = '';
      this.selectedHrchy = '';
      this.dateFilterResp = [];
      this.columnsToShowForMultiSelect = [];
      this.changeDetector.detectChanges();
      this.getHierachyMemberHeader();
      this.getFilteredData();
      this.getFilteredHierarchyDrodownData();
    }
    this.clearFilter.emit(key);
  }

  public updateFilterSeletedDealers(event): void {
    this.selectedDealers.emit(event);
  }

  public fetchResult(): void {
    if (Object.keys(this.requiredFilters).length === this.searchFilterObject?.length) {
      this.searchFilter.emit();
    } else {
      this.requiredMsg = DATA_VALIDATION_MEGS.REQUIRED_FIELD_MSGS;
    }
  }

}
