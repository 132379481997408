import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, of, throwError, timeout } from 'rxjs';
import { UserProfileService } from '@toyota/dd365-platform-library';
import { CustomReportsCoreApiService } from './custom-report-core.service';
import { IConfig } from '../../../../interface/common.interface';
import { reportId } from 'src/app/constants/constants';
import { DealerReviewReportMockData, SalesAndPartsAnalysisMockData } from '../../mock/mock';

@Injectable({
  providedIn: 'root'
})
export class CustomReportsApiService {

  public lexusPOSnapshotDeatail = new BehaviorSubject<boolean>(false);
  public reportTitleName = new BehaviorSubject<string>('');
  public dlrHrcyMemValue = new BehaviorSubject<Array<any>>([]);

  constructor(
    private readonly customReportsCoreService: CustomReportsCoreApiService,
    private readonly userProfileService: UserProfileService,
  ) { }

  getCompanyList(): Observable<any> {
    return this.customReportsCoreService.getMockCompanyList()
      .pipe(map(resp => resp));
  }

  getDlrAndHrcyMemData(companyCode, levelId, viewId): Observable<any> {
    const userId = this.userProfileService.getProfile().userId;
    const requestObj = {
      user_id: userId,
      view_id: viewId
    }
    return this.customReportsCoreService.getDlrAndHrcyMemData(requestObj)
      .pipe(map(resp => resp));

  }

  getDateAndMonth(reportTitle, companyCode?): Observable<any> {
    if (reportTitle === reportId.LEXUS_PO_SNAPSHOT) {
      return this.customReportsCoreService.getLexusDateAndMonth()
        .pipe(map(resp => resp))
    } else if (companyCode) {
      return this.customReportsCoreService.getPartDeptDateAndMonth(companyCode)
        .pipe(map(resp => resp))
    } else {
      return this.customReportsCoreService.getTCUVDateAndMonth()
        .pipe(map(resp => resp))
    }
  }

  getResultsForTopTenMTDSalesDealerNationally(requestObj: any): Observable<any> {
    return this.customReportsCoreService.getResultsForTopTenMTDSalesDealerNationally(requestObj)
      .pipe(map(resp => resp))
  }


  getLexusDAAGForLexus(requestObj: any): Observable<any> {
    return this.customReportsCoreService.getLexusDAAG(requestObj)
      .pipe(map(resp => resp))
  }
 

  getLexusPOSnapshotExecutiveSummaryReportYTDSalesData(requestObj: any): Observable<any> {
    return this.customReportsCoreService.getLexusPOSnapshotExecutiveSummaryReportYTDSalesData(requestObj)
      .pipe(map(resp => resp))
  }

  getLexusPOSnapshotExecutiveSummaryReportMTDSalesData(requestObj: any): Observable<any> {
    return this.customReportsCoreService.getLexusPOSnapshotExecutiveSummaryReportMTDSalesData(requestObj)
      .pipe(map(resp => resp))
  }

  getResultsForMTDBestRecords(requestObj: any): Observable<any> {
    return this.customReportsCoreService.getResultsForMTDBestRecords(requestObj)
      .pipe(timeout(20000))
      .pipe(map(resp => resp))
  }

  getResultsForMarketShareRecords(requestObj: any): Observable<any> {
    return this.customReportsCoreService.getResultsForMarketShareRecords(requestObj)
      .pipe(map(resp => resp))
  }

  getResultsForLexusPOGroundedVSPurchased(requestObj: any): Observable<any> {
    return this.customReportsCoreService.getResultsForLexusPOGroundedVSPurchased(requestObj)
      .pipe(map(resp => resp))
  }

  getLexusPOAreaSalesPercYTDSalesData(requestObj: any): Observable<any> {
    return this.customReportsCoreService.getLexusPOAreaSalesPercYTDSalesData(requestObj)
      .pipe(map(resp => resp))
  }

  getLexusPOAreaSalesPercMTDSalesData(requestObj: any): Observable<any> {
    requestObj.view_id = 'areasales-vs-nation-mtd';
    return this.customReportsCoreService.getLexusPOAreaSalesPercMTDSalesData(requestObj)
      .pipe(map(resp => resp))
  }

  getResultsForCompetativeSalesAnalysisData(requestObj: any): Observable<any> {
    requestObj.view_id = 'compt-sales-analysis';
    return this.customReportsCoreService.getResultsForCompetativeSalesAnalysisData(requestObj)
      .pipe(map(resp => resp))
  }

  getTCUVProfileInfo(requestObj: any): Observable<any> {
    return this.customReportsCoreService.getTCUVProfileInfo(requestObj)
      .pipe(map(resp => resp))
  }

  getLsaagScores(requestObj: any): Observable<any> {
    return this.customReportsCoreService.getLsaagScores(requestObj)
      .pipe(map(resp =>
        resp))
  }


  getTdaagSlm(requestObj: any): Observable<any> {
    return this.customReportsCoreService.getTdaagSlm(requestObj).pipe(
      map(resp => {
        return resp;
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  getTCUVNVSSeriesCertsInfoSeries(requestObj: any): Observable<any> {
    return this.customReportsCoreService.getTCUVNVSSeriesCertsInfoSeries(requestObj)
      .pipe(map(resp => resp))
  }

  getTCUVNVSSeriesCertsInfoSourceofsales(requestObj: any): Observable<any> {
    return this.customReportsCoreService.getTCUVNVSSeriesCertsInfoSourceofsales(requestObj)
      .pipe(map(resp => resp))
  }

  getTCUVNVSSeriesCertsInfoComp(requestObj: any): Observable<any> {
    return this.customReportsCoreService.getTCUVNVSSeriesCertsInfoComp(requestObj)
      .pipe(map(resp => resp))
  }

  getTCUVNVSSeriesCertsInfoAgedunits(requestObj: any): Observable<any> {
    return this.customReportsCoreService.getTCUVNVSSeriesCertsInfoAgedunits(requestObj)
      .pipe(map(resp => resp))
  }

  getTCUVFinancialReportData(requestObj: any): Observable<any> {
    return this.customReportsCoreService.getTCUVFinancialReportData(requestObj)
      .pipe(map(resp => resp))
  }

  getPsFinancialReportData(filteredObj): Observable<any> {
    return this.customReportsCoreService.getPsFinancialReportData(filteredObj)
      .pipe(map(resp => resp));
  }

  getDspmMonthlySummaryReportData(filteredObj): Observable<any> {
    return this.customReportsCoreService.getDspmMonthlySummaryReportData(filteredObj)
      .pipe(map(resp => resp));
  }

  getNationalCompositeNewUsedDeptAnalysisData(requestObj: any): Observable<any> {
    return this.customReportsCoreService.getNationalCompositeNewUsedDeptAnalysisData(requestObj)
      .pipe(map(resp => resp))
  }
  
  getNationalDealerPerformanceFinancialServicesData(requestObj: any): Observable<any> {
    return this.customReportsCoreService.getNationalDealerPerformanceFinancialServicesData(requestObj)
      .pipe(map(resp => resp))
  }

  getDealerPerformaceNewUsedCombinedFsFinData(requestObj: any): Observable<any> {
    return this.customReportsCoreService.getDealerPerformaceNewUsedCombinedFsFinData(requestObj)
      .pipe(map(resp => resp))
  }

  getPartsAndServiceDepartmentAnalysisData(currentReportId, filteredObj): Observable<any> {
    if (currentReportId === reportId.PARTS_DEPARTMENT) {
      return this.customReportsCoreService.getPartsDepartmentAnalysisData(filteredObj)
        .pipe(map(resp => resp));
    } else {
      return this.customReportsCoreService.getServiceDepartmentAnalysisData(filteredObj)
        .pipe(map(resp => resp))
    }

  }
  getSalesVehiclesAnalysisData(reportTitle, filterObj): Observable<any> {
    if (reportTitle === reportId.SALES_ANALYSIS_NEW_VEHICLE) {
      return this.customReportsCoreService.getSalesAnalysisNewVehicle(filterObj)
        .pipe(map(resp => resp))
    } else {
      return this.customReportsCoreService.getSalesAnalysisUsedVehicle(filterObj)
        .pipe(map(resp => resp))
    }
  }

  getPartsServiceAndSalesAnalysisData(currentReportId, filteredObj): Observable<any> {
    if (currentReportId === reportId.SERVICE_ANALYSIS) {
      return this.customReportsCoreService.getServiceAnalysisData(filteredObj)
        .pipe(map(resp => resp));
    } else if (currentReportId === reportId.PARTS_ANALYSIS) {
      return this.customReportsCoreService.getPartsAnalysisData(filteredObj)
        .pipe(map(resp => resp))
    } else if (currentReportId === reportId.SALES_ANALYSIS_NEW_VEHICLE) {
      return this.customReportsCoreService.getSalesAnalysisNewVehicle(filteredObj)
        .pipe(map(resp => resp))
    } else {
      return this.customReportsCoreService.getSalesAnalysisUsedVehicle(filteredObj)
        .pipe(map(resp => resp))
    }
  }

  getDealerReviewData(requestObj: any): Observable<any> {
    return this.customReportsCoreService.getDealerReviewReportData(requestObj)
      .pipe(map(resp => resp))
  }

  getTCUVPerformaceReportCardData(requestObj: any, tabIndex: number): Observable<any> {
    return this.customReportsCoreService.getTCUVPerformaceReportCardSummaryData(requestObj)
      .pipe(map(resp => resp))
  }
}



